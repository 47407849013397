import { Fragment, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./AggregatorOnboardingEligiblityQuestions.module.scss";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import EligibilityQuestionsSectionOne from "./EligibilityQuestionsSectionOne/EligibilityQuestionsSectionOne.component";
import EligibilityQuestionsSectionTwo from "./EligibilityQuestionsSectionTwo/EligibilityQuestionsSectionTwo.component";
import { onboardingAnswerTypes } from "./../../../helpers/onboardingAnswerTypes";
import AggregatorEligibilityModal from "./AggregatorEligibilityModal.component";
import useAggregatorOnboardingQuestions from "./../../../custom-hooks/useAggregatorOnboardingQuestions";
import { Loader } from "./../../../UI/Loaders/Loaders";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { postData } from "../../../newApis/apiMethods";
import { errorHandler } from "./../../../helpers/errorHandler";
import Alert from "./../../../NewComponents/Alert/Alert.component";
import { useQueryCache } from "react-query";
import { useFilter } from "./useFilter";
import { appInsights } from "../../../config/appInsights";
import { v4 as uuidv4 } from "uuid";

const AggregatorOnboardingEligiblityQuestions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [info, setInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [section, setSection] = useState(1);
  const [modalActionType, setModalActionType] = useState("");
  const [questions, setQuestions] = useState([]);
  const [filterOptions, handleChange, filterParam] = useFilter();

  const dismissModalRef = useRef();
  const history = useHistory();
  const queryClient = useQueryCache();

  useEffect(() => {
    appInsights.trackPageView({
      name: "Eligibility Test Questions  - (AggregatorOnboardingEligiblityQuestions.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const addNewQuestion = () => {
    return setQuestions([
      ...questions,
      {
        // id: (questions[questions.length - 1]?.id ?? 0) + 1,
        id: uuidv4(),
        position: (questions[questions.length - 1]?.position ?? 0) + 1,
        questionCategoryId: section,
        question: "Question?",
        questionAnswerType: onboardingAnswerTypes.TEXT_ANSWER,
        isMandatory: false,
        isConditional: false,
        questionDomain: 0,
        conditionalQuestionId: 0,
        conditionalAnswerId: 0,
        productTypeIds: [],
        questionAnswers: [
          {
            id: 0,
            value: "",
            weight: 0,
          },
        ],
        isNewQuestion: true,
        readOnlyFields: false,
      },
    ]);
  };

  const {
    data,
    status,
    error: quetionsError,
  } = useAggregatorOnboardingQuestions({ ProductType: filterParam });

  useEffect(() => {
    if (data) {
      setQuestions(
        data.map((eachData) => {
          const _productTypeIds = eachData.productTypes.map((item) => item.id);
          return {
            ...eachData,
            isNewQuestion: false,
            readOnlyFields: true,
            productTypeIds: [..._productTypeIds],
          };
        })
      );
    }
  }, [data]);

  const newQuestions = questions.filter((question) => {
    return question.isNewQuestion === true;
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    let allNewQuestions = newQuestions;

    if (allNewQuestions.length < 1) {
      setInfo("Please add a new section");
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      return;
    }

    allNewQuestions.map((newQuestion) => {
      if (
        newQuestion.questionAnswerType === onboardingAnswerTypes.TEXT_ANSWER
      ) {
        newQuestion.questionAnswers = [];
      }
    });
    setLoading(true);
    const reqBody = {
      questions: allNewQuestions,
    };
    try {
      await postData(
        ajaxEndpoints.ADD_AGGREGATOR_ONBOARDING_QUESTIONS,
        reqBody
      );
      setLoading(false);
      setSuccess("Questions updated successfully!.");
      queryClient.invalidateQueries("getAggregatorOnboardingQuestions");
      setTimeout(() => {
        history.push("/loan-eligibility");
      }, 3000);

      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingEligibilityQuestions.jsx",
        },
      });
      setLoading(null);
      if (dismissModalRef && dismissModalRef.current) {
        dismissModalRef.current.click();
      }
      setError(errorHandler(error));
    }
  };

  const discardChanges = (e) => {
    e.preventDefault();
    setQuestions(data);
    setEditMode(false);
    dismissModalRef.current.click();
  };

  if (status === "loading") {
    return <Loader centered={true} text="Loading eligibility questions..." />;
  }
  if (status === "error" || quetionsError) {
    return (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            eligibility questions, please try again.
          </h4>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {info && <Alert message={info} type="info" />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Eligibility Test Questions
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {editMode ? (
            <Fragment>
              <div className={styles.filterContainer}>
                <div className={`form-group ${styles.filterItem}`}>
                  <select
                    className={styles.FilterInput}
                    onChange={handleChange}
                    name="productType"
                  >
                    <option value="" disabled selected hidden>
                      Product Type
                    </option>
                    {filterOptions?.map((type, index) => (
                      <option key={index} value={type?.value}>
                        {type?.name}
                      </option>
                    ))}
                  </select>
                  <i
                    className={`fas fa-solid fa-angle-down ${styles.Icon}`}
                  ></i>
                </div>
              </div>
              <button
                className="btn advancly-white-btn btn-md mr-2"
                onClick={() => setEditMode(false)}
              >
                Discard Changes
              </button>
              <button
                className="btn advancly-btn btn-md"
                data-toggle="modal"
                data-target="#eligibilityModal"
                onClick={() => setModalActionType("update")}
                disabled={loading || !newQuestions.length}
              >
                {loading ? "Updating" : "Update"} Questions
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button
                className="btn advancly-btn btn-md"
                onClick={() => setEditMode(true)}
              >
                Edit Questions
              </button>
            </Fragment>
          )}
        </div>
      </div>
      <hr />
      <div className="onboardingOuterLayout">
        <div className="onboardingInnerLayout">
          <div className={styles.cardSection}>Section {section} of 2</div>

          <div className="w-100">
            {(() => {
              switch (section) {
                case 1:
                  return (
                    <EligibilityQuestionsSectionOne
                      section={1}
                      setSection={setSection}
                      questions={questions}
                      setQuestions={setQuestions}
                      editMode={editMode}
                      addNewQuestion={addNewQuestion}
                      onSubmit={onSubmit}
                      submittingNewQuestion={loading}
                    />
                  );
                case 2:
                  return (
                    <EligibilityQuestionsSectionTwo
                      section={2}
                      setSection={setSection}
                      questions={questions}
                      setQuestions={setQuestions}
                      editMode={editMode}
                      addNewQuestion={addNewQuestion}
                      onSubmit={onSubmit}
                      submittingNewQuestion={loading}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <AggregatorEligibilityModal
        onSubmit={onSubmit}
        discardChanges={discardChanges}
        modalActionType={modalActionType}
        dismissModalRef={dismissModalRef}
      />
    </Fragment>
  );
};

export default AggregatorOnboardingEligiblityQuestions;
