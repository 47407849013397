export const convertToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //@ts-ignore
    reader.onload = () => resolve(reader.result?.split("base64,")[1]);
    reader.onerror = (error) => reject(error);
  });

export const normalizeBase64 = (base64String: string): string => {
  // Check if the base64 string is in a data URL format
  const dataUrlRegex = /^data:[a-zA-Z0-9/]+;base64,/;

  if (dataUrlRegex.test(base64String)) {
    // Remove the data URL prefix
    return base64String.split("base64,")[1];
  }

  // Assume it's already in raw base64 format
  return base64String;
};
