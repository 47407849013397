import { useCallback, useState } from "react";
import styles from "./ApproveDeclineMccApprovalSignature.module.scss";
import { ReactComponent as UploadIcon } from "../../../../../assets/svg/icons/uploadfile.svg";
import { convertToBase64 } from "../../../../../helpers/convertToBase64";

const MccApprovalSignatureUpload = ({
  handleApproveDecline,
  isLoading,
}: {
  isLoading: boolean;
  handleApproveDecline: (base64: string, fileName: string) => void;
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [base64String, setBase64String] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const handleImageChange = async (file: File) => {
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    const currentName = file.name;
    setFileName(currentName);
    // Convert the image to base64
    const fileBase64 = (await convertToBase64(file)) as string;
    setBase64String(fileBase64);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleImageChange(file);
    }
  };

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragActive(true);
    },
    []
  );

  const handleDragLeave = useCallback(() => {
    setDragActive(false);
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files?.[0];
    if (file) {
      handleImageChange(file);
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <div
        className={`${styles.dropzone} ${dragActive ? styles.active : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById("fileInput")?.click()}
      >
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <div>
          <UploadIcon />
          <p className={styles.documentUploadHeading}>Upload Files</p>
        </div>
        <p className={styles.documentUploadParagraph}>
          Drag & drop your files here or <span>browse</span>{" "}
        </p>
        <small className={styles.documentUploadSmall}>png or jpeg</small>
        {selectedImage && (
          <div className={styles.preview}>
            <h4>Image Preview:</h4>
            <img
              src={selectedImage}
              alt="Selected"
              className={styles.previewImage}
            />
          </div>
        )}
      </div>

      <div className="mt-3">
        <button
          className={styles.signaturebuttonsave}
          disabled={isLoading}
          onClick={() => {
            handleApproveDecline(base64String, fileName);
          }}
        >
          {isLoading ? "loading..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default MccApprovalSignatureUpload;
