import TableAccordion from "../../../../../../../components/AutomatedCredit/CreditAnalysis/Components/TableAccordion";
import { TextEditor } from "../../../../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";

import styles from "./loanPurpose.module.scss";

interface IProposedCollateral {
  defaultValue: string;
  handleChange: any;
}

const ProposedCollateral = ({
  defaultValue,
  handleChange,
}: IProposedCollateral) => {
  return (
    <div className={styles.CreditScoreComments}>
      <TableAccordion
        title={<span className={styles.Title}>PROPOSED COLLATERAL</span>}
        isDefaultOpen
      >
        <TextEditor onChange={handleChange} defaultValue={defaultValue} />
      </TableAccordion>
    </div>
  );
};

export default ProposedCollateral;
