import { Fragment, useState } from "react";

import { onboardingAnswerTypes } from "./../../../helpers/onboardingAnswerTypes";
import { SingleMultiEdit } from "./SingleMultiEdit.component";
import { ReactComponent as Bin } from "../../../assets/svg/icons/bin.svg";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { deleteData, postData } from "../../../newApis/apiMethods";
import { errorHandler } from "./../../../helpers/errorHandler";
import Alert from "./../../../NewComponents/Alert/Alert.component";
import { putDataWithDotNet } from "../../../newApis/dotNetApiMethods";
import CustomSearchDropDown from "../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import useAllProductType from "../../../custom-hooks/useAllProductType";
import { useQueryCache } from "react-query";
import { useHistory } from "react-router-dom";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import CustomBadge from "../../Badge/badge";
import { appInsights } from "../../../config/appInsights";

import styles from "./AggregatorOnboardingEligiblityQuestions.module.scss";

const EligibilityQuestionSegment = ({
  eachQuestion,
  editMode,
  setQuestions,
  questions,
  onSubmit,
  submittingNewQuestion,
}) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const {
    id,
    question,
    questionAnswerType,
    isMandatory,
    isConditional,
    conditionalQuestionId,
    conditionalAnswerId,
    questionAnswers,
    isNewQuestion,
    readOnlyFields,
    isActive,
  } = eachQuestion;

  const queryClient = useQueryCache();
  const history = useHistory();

  const domainOptions = [
    { label: "Default", value: 0 },
    { label: "Business Information", value: 1 },
    {
      label: "Industry",
      value: 2,
    },
  ];

  const questionDomain = {
    ["Industry"]: 2,
    ["Business"]: 1,
  };

  const defaultDomain = (_options) => {
    if (_options && _options.length > 0) {
      const result = _options.find(
        (_option) =>
          _option.value === questionDomain[eachQuestion?.questionDomainEnum]
      );

      return result;
    }

    return {};
  };

  const positionQuestion = (item, questionToUpdate) => {
    const index = questions.findIndex((item) => item === questionToUpdate);
    const modifiedQuestions = [
      ...questions.filter((question) => question.id !== id),
    ];

    modifiedQuestions.splice(index, 0, item);

    return modifiedQuestions;
  };

  const onQuestionChange = (e, id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      { ...questionToUpdate, question: e.target.value },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onQuestionAnswerTypeChange = (e, id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      { ...questionToUpdate, questionAnswerType: e.target.value },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onChangeDependeeQuestion = (e, id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      { ...questionToUpdate, conditionalQuestionId: Number(e.target.value) },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onChangeDependeeAnswer = (e, id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      { ...questionToUpdate, conditionalAnswerId: Number(e.target.value) },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onQuestionAnswerValueChange = (e, id, questionAnswerId) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const findQuestionAnswer = questionToUpdate.questionAnswers.find(
      (item) => item?.id === questionAnswerId
    );

    const index = questionToUpdate.questionAnswers.findIndex(
      (item) => item === findQuestionAnswer
    );

    const questionAnswer = [...questionToUpdate.questionAnswers];

    questionAnswer[index] = { ...findQuestionAnswer, value: e.target.value };

    const result = positionQuestion(
      {
        ...questionToUpdate,
        questionAnswers: questionAnswer,
      },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onQuestionAnswerWeightChange = (e, id, questionAnswerId) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const findQuestionAnswer = questionToUpdate.questionAnswers.find(
      (item) => item?.id === questionAnswerId
    );

    const index = questionToUpdate.questionAnswers.findIndex(
      (item) => item === findQuestionAnswer
    );

    const questionAnswer = [...questionToUpdate.questionAnswers];

    questionAnswer[index] = {
      ...findQuestionAnswer,
      weight: Number(e.target.value),
    };

    const result = positionQuestion(
      {
        ...questionToUpdate,
        questionAnswers: questionAnswer,
      },
      questionToUpdate
    );

    setQuestions(result);
  };
  const onQuestionAnswerDelete = async (id, questionAnswer) => {
    const questionAnswerId = questionAnswer.id;
    const questionToUpdate = questions.find((question) => question.id === id);
    const result = positionQuestion(
      {
        ...questionToUpdate,
        questionAnswers: questionToUpdate.questionAnswers.filter(
          (questionAnswer) => questionAnswer.id !== questionAnswerId
        ),
      },
      questionToUpdate
    );
    setQuestions(result);

    if (questionAnswer.newAnswer === true) return;

    try {
      setDeleting(true);
      setError(null);
      setSuccess(null);

      await deleteData(
        `${ajaxEndpoints.DELETE_AGGREGATOR_ONBOARDING_ANSWER}?questionanswerId=${questionAnswerId}`
      );
      setDeleting(false);
      setSuccess("Answer deleted successfully.");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "EligibilityQuestionSegment.component.jsx",
        },
      });
      setDeleting(false);
      setError(errorHandler(error));
    }
  };

  const onQuestionAnswerUpdate = async (questionToUpdate) => {
    setEditing(true);
    setSuccess(null);
    try {
      await putDataWithDotNet(
        ajaxEndpoints.UPDATE_AGGREGATOR_ONBOARDING_ANSWER,
        { ...questionToUpdate, questionAnswerId: questionToUpdate.id }
      );
      setEditing(false);
      setSuccess("Question Answer updated successfully.");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "EligibilityQuestionSegment.component.jsx",
        },
      });
      setEditing(false);
      setError(errorHandler(error));
    }
  };
  const onAddNewQuestionAnswer = async (questionToAdd, questionId) => {
    setEditing(true);
    setSuccess(null);
    const reqBody = [
      {
        questionId,
        value: questionToAdd.value,
        weight: questionToAdd.weight,
      },
    ];
    try {
      await postData(ajaxEndpoints.ADD_AGGREGATOR_ONBOARDING_ANSWER, reqBody);
      setEditing(false);
      setSuccess("Question Answer added successfully.");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "EligibilityQuestionSegment.component.jsx",
        },
      });
      setEditing(false);
      setError(errorHandler(error));
    }
  };

  const onQuestionAnswerAdd = (id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      {
        ...questionToUpdate,
        questionAnswers: [
          ...questionToUpdate.questionAnswers,
          {
            id:
              (questionToUpdate.questionAnswers[
                questionToUpdate.questionAnswers.length - 1
              ]?.id ?? 0) + 1,
            value: "",
            weight: 0,
            newAnswer: true,
          },
        ],
      },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onChangeIsDisabled = (e, id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      {
        ...questionToUpdate,
        isActive: JSON.parse(e.target.value),
      },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onProductTypeChange = (listInQuestion) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const productTypeIds = listInQuestion.map((item) => item.value);
    const result = positionQuestion(
      {
        ...questionToUpdate,
        productTypeIds: [...productTypeIds],
      },
      questionToUpdate
    );
    setQuestions(result);
  };

  const onQuestionDomainChange = (questionDomain) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      {
        ...questionToUpdate,
        questionDomain: questionDomain?.value,
      },
      questionToUpdate
    );

    setQuestions(result);
  };

  const onClickIsConditional = (value) => {
    const questionToUpdate = questions.find((question) => question.id === id);
    const result = positionQuestion(
      { ...questionToUpdate, isConditional: value === true ? true : false },
      questionToUpdate
    );
    setQuestions(result);
  };
  const onChangeIsMandatory = (e, id) => {
    const questionToUpdate = questions.find((question) => question.id === id);

    const result = positionQuestion(
      { ...questionToUpdate, isMandatory: e.target.checked },
      questionToUpdate
    );

    setQuestions(result);
  };

  const deleteQuestion = async (id, makeApiCall) => {
    if (makeApiCall) {
      setLoading(true);
      setError(null);
      setSuccess(null);
      try {
        await deleteData(
          `${ajaxEndpoints.DELETE_AGGREGATOR_ONBOARDING_QUESTION}?id=${id}`
        );
        setLoading(false);
        setQuestions(questions.filter((question) => question.id !== id));
        setSuccess("Question deleted successfully.");
        queryClient.invalidateQueries("getAggregatorOnboardingQuestions");
      } catch (error) {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "EligibilityQuestionSegment.component.jsx",
          },
        });
        setLoading(false);
        setError(errorHandler(error));
      }
    } else {
      setQuestions(questions.filter((question) => question.id !== id));
    }
  };

  const editQuestion = async (question) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    // questionAnswers;
    const questionReqBody = {
      ...question,
      questionId: question.id,
    };

    // Removed unwanted fields
    delete questionReqBody.id;
    delete questionReqBody.selectedAnswer;
    delete questionReqBody.questionAnswers;
    delete questionReqBody.isNewQuestion;
    delete questionReqBody.readOnlyFields;
    delete questionReqBody.productTypes;

    try {
      await putDataWithDotNet(
        ajaxEndpoints.UPDATE_AN_AGGREGATOR_ONBOARDING_QUESTION,
        questionReqBody
      );
      setLoading(false);
      makeQuestionEditable(id);
      setSuccess("Question updated successfully.");
      queryClient.invalidateQueries("getAggregatorOnboardingQuestions");
      setTimeout(() => {
        history.push("/loan-eligibility");
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "EligibilityQuestionSegment.component.jsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const makeQuestionEditable = (id) => {
    const questionToUpdate = questions.find((question) => question.id === id);
    const index = questions.findIndex((item) => item === questionToUpdate);
    const modifiedQuestions = [
      ...questions.filter((question) => question.id !== id),
    ];

    modifiedQuestions.splice(index, 0, {
      ...questionToUpdate,
      readOnlyFields: !questionToUpdate.readOnlyFields,
    });

    setQuestions(modifiedQuestions);
  };

  const { data: availableProductTypes } = useAllProductType();

  const cleanProductTypes = (arrayInQuestion) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      const result = arrayInQuestion.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      return result;
    }

    return [];
  };

  const getQuestionProductTypes = (listInQuestion) => {
    if (listInQuestion && listInQuestion.length > 0) {
      const result = listInQuestion.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });

      return result;
    }

    return [];
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      {!editMode ? (
        <div className="row mb-3">
          {(() => {
            switch (questionAnswerType) {
              case onboardingAnswerTypes.TEXT_ANSWER:
                return (
                  <div className="col-12">
                    <CustomInputField
                      badgeText={
                        eachQuestion?.productTypes &&
                        eachQuestion?.productTypes.length > 0 &&
                        eachQuestion?.productTypes.map(
                          (prdType) => prdType?.product
                        )
                      }
                      isShowBadge={true}
                      type="text"
                      label={question}
                      readOnly
                    />
                  </div>
                );
              case onboardingAnswerTypes.SINGLE_CHOICE:
                return (
                  <div className="col-12 mb-4">
                    <CustomSelectDropdown
                      isShowBadge
                      badgeText={
                        eachQuestion?.productTypes &&
                        eachQuestion?.productTypes.length > 0 &&
                        eachQuestion?.productTypes.map(
                          (prdType) => prdType?.product
                        )
                      }
                      label={question}
                      disabled
                    >
                      <option value=""></option>
                    </CustomSelectDropdown>
                  </div>
                );
              case onboardingAnswerTypes.MULTIPLE_CHOICE:
                return (
                  <div className="col-12 mb-4">
                    <label className="form-label d-flex justify-content-between">
                      <span>{question}</span>

                      {eachQuestion?.productTypes &&
                        eachQuestion?.productTypes.length > 0 &&
                        eachQuestion?.productTypes.map((prdType, index) => (
                          <CustomBadge key={index} text={prdType?.product} />
                        ))}
                    </label>

                    {questionAnswers &&
                      questionAnswers.map(({ value, id }) => {
                        return (
                          <div className="form-check" key={id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              disabled
                            />
                            <label className="form-check-label">{value}</label>
                          </div>
                        );
                      })}
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      ) : (
        <Fragment>
          <div className={styles.questionSegment}>
            {(() => {
              switch (questionAnswerType) {
                case onboardingAnswerTypes.TEXT_ANSWER:
                  return (
                    <div className="col-12">
                      <CustomInputField
                        isShowBadge
                        badgeText={
                          eachQuestion?.productTypes &&
                          eachQuestion?.productTypes.length > 0 &&
                          eachQuestion?.productTypes.map(
                            (prdType) => prdType?.product
                          )
                        }
                        type="text"
                        label={question}
                        showRequiredIcon
                        readOnly
                      />
                    </div>
                  );
                case onboardingAnswerTypes.SINGLE_CHOICE:
                  return (
                    <div className="col-12 mb-4">
                      <label className="form-label d-flex justify-content-between">
                        <span>
                          {question} <sup className="ml-1 crimson">*</sup>
                        </span>

                        {eachQuestion?.productTypes &&
                          eachQuestion?.productTypes.length > 0 &&
                          eachQuestion?.productTypes.map((prdType, index) => (
                            <CustomBadge key={index} text={prdType?.product} />
                          ))}
                      </label>

                      {questionAnswers &&
                        questionAnswers
                          .sort((a, b) => a.id - b.id)
                          .map(({ value }, _id) => {
                            return (
                              <div className="form-check" key={_id}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                />
                                <label className="form-check-label">
                                  {value}
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  );
                case onboardingAnswerTypes.MULTIPLE_CHOICE:
                  return (
                    <div className="col-12 mb-4">
                      <label className="form-label d-flex justify-content-between">
                        <span>
                          {question} <sup className="ml-1 crimson">*</sup>
                        </span>

                        {eachQuestion?.productTypes &&
                          eachQuestion?.productTypes.length > 0 &&
                          eachQuestion?.productTypes.map((prdType, index) => (
                            <CustomBadge key={index} text={prdType?.product} />
                          ))}
                      </label>

                      {questionAnswers &&
                        questionAnswers
                          .sort((a, b) => a.id - b.id)
                          .map(({ value }, _id) => {
                            return (
                              <div className="form-check" key={_id}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                />
                                <label className="form-check-label">
                                  {value}
                                </label>
                              </div>
                            );
                          })}
                    </div>
                  );

                default:
                  return null;
              }
            })()}

            {!isNewQuestion && (
              <div className="d-flex justify-content-between align-items-center">
                {readOnlyFields ? (
                  <button
                    className="btn advancly-white-btn btn-sm mr-3 w-100"
                    disabled={loading}
                    onClick={() => makeQuestionEditable(id)}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn advancly-white-btn btn-sm mr-3 w-100"
                    disabled={loading}
                    onClick={() => editQuestion(eachQuestion)}
                  >
                    {loading ? "Saving" : "Save"}
                  </button>
                )}

                <button
                  className="btn advancly-red-btn btn-sm w-100"
                  onClick={() => deleteQuestion(id, true)}
                  disabled={loading}
                >
                  {deleting ? "Deleting" : "Delete"}{" "}
                  <span className="ml-3">
                    <Bin />
                  </span>
                </button>
              </div>
            )}
          </div>
          {!readOnlyFields && (
            <div className={`${styles.questionSegment} mb-5`}>
              <div className="row">
                <div className="col-8">
                  <CustomInputField
                    type="text"
                    label="Question"
                    onChange={(e) => onQuestionChange(e, id)}
                    value={question}
                    defaultValue={question}
                  />
                </div>
                <div className="col-4">
                  <CustomSelectDropdown
                    label="Select an option"
                    onChange={(e) => onQuestionAnswerTypeChange(e, id)}
                    value={questionAnswerType}
                  >
                    <option value="">Select an option</option>
                    <option value={onboardingAnswerTypes.TEXT_ANSWER}>
                      Text Answer
                    </option>
                    {/* <option value={onboardingAnswerTypes.SINGLE_CHOICE}>
                      Single Choice
                    </option>
                    <option value={onboardingAnswerTypes.MULTIPLE_CHOICE}>
                      Multiple Choice
                    </option> */}
                  </CustomSelectDropdown>
                </div>
              </div>
              <div>
                <div>
                  <div className="row">
                    <div className="col-8">
                      <CustomSearchDropDown
                        label="Select Product Type"
                        labelClass="font-weight-bold d-block"
                        defaultValue={getQuestionProductTypes(
                          eachQuestion.productTypes
                        )}
                        defaultOptions={cleanProductTypes(
                          availableProductTypes
                        )}
                        onChange={onProductTypeChange}
                        isSearchable={false}
                        isMulti={true}
                        isShowLabel
                      />
                    </div>
                    <div className="col-4">
                      <CustomSelectDropdown
                        label="Is Active?"
                        defaultValue={isActive}
                        onChange={(e) => onChangeIsDisabled(e, id)}
                      >
                        <option value="">Select Option</option>
                        <option value={true}>TRUE</option>
                        <option value={false}>FALSE</option>
                      </CustomSelectDropdown>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-8">
                      <CustomSearchDropDown
                        label="Question Domain"
                        labelClass="font-weight-bold d-block"
                        defaultValue={defaultDomain(domainOptions)}
                        defaultOptions={domainOptions}
                        onChange={onQuestionDomainChange}
                        isShowLabel
                      />
                    </div>
                  </div>

                  <div>
                    <div className="font-weight-bold d-block">Answer</div>
                    <div className="d-block">
                      {questionAnswerType === onboardingAnswerTypes.TEXT_ANSWER
                        ? "Text answers are not weighted"
                        : "Kindly input the answers and their respective weighting"}
                    </div>
                  </div>
                </div>

                <div>
                  {(() => {
                    switch (questionAnswerType) {
                      case onboardingAnswerTypes.TEXT_ANSWER:
                        return (
                          <div className="row mt-3">
                            {questionAnswers &&
                              questionAnswers
                                .slice(0, 1)
                                .map((questionAnswer, questionAnswerId) => (
                                  <div className="col-12" key={id}>
                                    <CustomInputField
                                      type="text"
                                      label="Answer"
                                      placeholder="Text Answer"
                                      value={questionAnswer.value}
                                      defaultValue={questionAnswer.value}
                                      onChange={(e) =>
                                        onQuestionAnswerValueChange(
                                          e,
                                          id,
                                          questionAnswerId
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                          </div>
                        );
                      case onboardingAnswerTypes.SINGLE_CHOICE:
                        return (
                          <SingleMultiEdit
                            questionAnswers={questionAnswers}
                            questionId={id}
                            onQuestionAnswerAdd={onQuestionAnswerAdd}
                            onQuestionAnswerValueChange={
                              onQuestionAnswerValueChange
                            }
                            onQuestionAnswerWeightChange={
                              onQuestionAnswerWeightChange
                            }
                            onQuestionAnswerDelete={onQuestionAnswerDelete}
                            onQuestionAnswerUpdate={onQuestionAnswerUpdate}
                            editing={editing}
                            deleting={deleting}
                            onAddNewQuestionAnswer={onAddNewQuestionAnswer}
                          />
                        );
                      case onboardingAnswerTypes.MULTIPLE_CHOICE:
                        return (
                          <SingleMultiEdit
                            questionAnswers={questionAnswers}
                            questionId={id}
                            onQuestionAnswerAdd={onQuestionAnswerAdd}
                            onQuestionAnswerValueChange={
                              onQuestionAnswerValueChange
                            }
                            onQuestionAnswerWeightChange={
                              onQuestionAnswerWeightChange
                            }
                            onQuestionAnswerDelete={onQuestionAnswerDelete}
                            onQuestionAnswerUpdate={onQuestionAnswerUpdate}
                            editing={editing}
                            deleting={deleting}
                            onAddNewQuestionAnswer={onAddNewQuestionAnswer}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
                <div className="mb-3">
                  <p className="font-weight-bold">
                    Is this a conditional question?
                  </p>
                  <div>
                    <div
                      className="custom-control custom-radio custom-control-inline"
                      onClick={() => onClickIsConditional(true)}
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={isConditional}
                        onChange={() => null}
                      />
                      <label className="custom-control-label">Yes</label>
                    </div>
                    <div
                      className="custom-control custom-radio custom-control-inline"
                      onClick={() => onClickIsConditional(false)}
                    >
                      <input
                        type="radio"
                        className="custom-control-input"
                        checked={!isConditional}
                        onChange={() => null}
                      />
                      <label className="custom-control-label">No</label>
                    </div>
                  </div>
                </div>
                <div>
                  {isConditional && (
                    <Fragment>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mr-2">Select the dependee question:</p>
                        <div className="w-75">
                          <CustomSelectDropdown
                            value={conditionalQuestionId}
                            defaultValue={conditionalQuestionId}
                            onChange={(e) => onChangeDependeeQuestion(e, id)}
                          >
                            {questions &&
                              questions.length &&
                              questions
                                .sort((a, b) => a.position - b.position)
                                .map(({ question, id }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {question}
                                    </option>
                                  );
                                })}
                          </CustomSelectDropdown>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mr-2">Select the dependee answer:</p>
                        <div className="w-75">
                          <CustomSelectDropdown
                            value={conditionalAnswerId}
                            defaultValue={conditionalAnswerId}
                            onChange={(e) => onChangeDependeeAnswer(e, id)}
                          >
                            {questions &&
                              questions.length &&
                              questions.find(
                                (question) =>
                                  question.id === conditionalQuestionId
                              ) &&
                              questions
                                .find(
                                  (question) =>
                                    question.id === conditionalQuestionId
                                )
                                .questionAnswers.sort((a, b) => a.id - b.id)
                                .map(({ id, value }) => {
                                  return (
                                    <option value={id} key={id}>
                                      {value}
                                    </option>
                                  );
                                })}
                          </CustomSelectDropdown>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`isMandatory${id}`}
                      checked={isMandatory}
                      onChange={(e) => onChangeIsMandatory(e, id)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`isMandatory${id}`}
                    >
                      Required Question?
                    </label>
                  </div>
                </div>

                {isNewQuestion && (
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <button
                      className="btn advancly-white-btn btn-sm mr-3 w-100"
                      onClick={onSubmit}
                      disabled={loading || submittingNewQuestion}
                    >
                      {submittingNewQuestion ? "Submitting" : "Submit"}
                    </button>
                    <button
                      className="btn advancly-red-btn btn-sm w-100"
                      onClick={() => deleteQuestion(id, false)}
                      disabled={loading || submittingNewQuestion}
                    >
                      {loading ? "Deleting" : "Delete"}{" "}
                      <span className="ml-3">
                        <Bin />
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default EligibilityQuestionSegment;
