import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

interface ITextEditor {
  defaultValue: string;
  onChange: (data: string) => void;
  readOnly?: boolean;
  includeImageOptions?: boolean;
}

export const TextEditor = ({
  defaultValue,
  onChange,
  readOnly,
  includeImageOptions,
}: ITextEditor) => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(defaultValue) as any)
    )
  );

  const toolbarOptions = {
    options: ["inline", "blockType", "image", "list", "textAlign"],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    blockType: {
      inDropdown: false,
      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    },
    list: { options: ["unordered", "ordered"] },
    textAlign: {
      options: ["left", "center", "right", "justify"],
    },
    image: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: false,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: (file: any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              data: {
                url: reader.result,
              },
            });
          };

          reader.onerror = (reason) => reject(reason);

          reader.readAsDataURL(file);
        });
      },
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "90%",
        width: "90%",
      },
    },
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  return (
    <div>
      <div
        className="px-1 py-0"
        style={{
          minHeight: "250px",
          border: "none",
        }}
      >
        <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbarOptions}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
