export const addSerialNumber = (arrayInQuestion: any[] | undefined) => {
  if (arrayInQuestion) {
    let result = arrayInQuestion.map((_item, index) => {
      return {
        serialNumber: index + 1,
        ..._item,
      };
    });

    return result;
  }

  return [];
};

export const creditUserTypes = {
  CUSTOMER: 1,
  CREDIT_ANALYST: 3,
  RELATIONSHIP_MANAGER: 2,
};
