import React, { ChangeEvent, useEffect, useState } from "react";

import ajaxMethods from "../../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { appInsights } from "../../../../config/appInsights";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import DisbursementSummary from "./CreditAnalysis/DisbursementSummary/DisbursementSummary";
import { IFunderOptions, ISingleLoanDisbursement, IState } from "./interface";
import CreditScore from "./CreditAnalysis/CreditScore/CreditScore";
import UploadBankStatement from "./CreditAnalysis/UploadBankStatement/UploadBankStatement";
import AccountStatistics from "./CreditAnalysis/AccountStatistics/AccountStatistics";
import BankStatement from "./CreditAnalysis/BankStatement/BankeStatement";
import LoanHistory from "./CreditAnalysis/LoanHistory/LoanHistory";
import StockData from "./CreditAnalysis/StockData/StockData";
import Orders from "./CreditAnalysis/Orders/Orders";
import LoanSchedule from "./CreditAnalysis/LoanSchedule/loanSchedule";
import { dataProps, statusProps } from "./CreditAnalysis/LoanSchedule/type";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import OrderItems from "./CreditAnalysis/Orders/OrderItems";
import DirectDebit from "./CreditAnalysis/DirectDebit/DirectDebit";
import ApprovalDetails from "./CreditAnalysis/ApprovalDetails/ApprovalDetails";
import useApprovalDetails from "../../../../custom-hooks/useApprovalDetails";
import { useDispatch } from "react-redux";
import { refetchPendingDisbursements } from "../../../../redux/actions/loans";
import CreditBureauCRC from "../CreditBureauCRC/CreditBureauCRC";
import CreditBureauCheckHistory from "../CreditBureauCRC/CreditBureauCheckHistory";
import Card from "./CreditAnalysis/Card/Card";
import {
  handleSwalErrorAlert,
  handleSwalSuccessAlert,
} from "../../../../helpers";

const SingleLoanDisbursement = ({
  first_name,
  last_name,
  product_name,
  interest_rate,
  corebanking_tenant,
  product_variant,
  currency,
  loan_amount,
  loan_application_id,
  loan_id,
  onCloseModal,
  properties,
  funderOptions,
  loan_ref,
  borrower_id,
  bvn,
  borrower_type_value,
  rc_number,
  customer_id,
  loan_application_detail_id,
}: ISingleLoanDisbursement) => {
  const { data: approvalDetailsData, error: approvalDetailsError } =
    useApprovalDetails({
      loan_application_detail_id: loan_application_detail_id,
      customer_id: customer_id,
    });
  const [
    showDisbursementConfirmationModal,
    setshowDisbursementConfirmationModal,
  ] = useState(false);
  const [disbursementLoading, setDisbursementLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("disbursement-summary");
  const [isTabClickabale, setIsTabClickable] = useState(false);
  const [loanInfoFromSchedule, setLoanInfoFromSchedule] = useState({});
  const [loanScheduleResponse, setLoanScheduleResponse] = useState<dataProps>();
  const [saveLoanDetailsResponse, setsaveLoanDetailsResponse] =
    useState<statusProps>();
  const [state, setState] = useState<IState>({
    requestedAmount: properties?.dataRecord?.loan_amount_requested,
    comment: properties?.dataRecord?.credit_approval_comment,
    error: false,
    selectedFunder: properties?.dataRecord?.funder_id,
    selectedDate: properties.dataRecord?.loan_effective_date?.substring(0, 10),
    amountApproved: properties.dataRecord?.approved_loan_amount,
    approvedTenor: properties?.dataRecord?.approved_loan_tenure,
    requestedTenor: properties?.dataRecord?.loan_tenure,
    firstRepaymentDate: properties?.dataRecord?.first_repayment_date?.substring(
      0,
      10
    ),
    disbursement_channel_id: properties?.dataRecord?.disbursement_channel_id,
    disbursement_channel_account_id:
      properties?.dataRecord?.disbursement_channel_account_id,
    moratorium_type_id: properties?.dataRecord?.moratorium_type_id,
    amortization_type_id: properties?.dataRecord?.amortization_type_id,
    repayment_type: properties?.dataRecord?.repayment_frequency,
    interest_type_id: properties?.dataRecord?.interest_type_id,
  });
  const [orderItemlist, setOrderItemList] = useState([]);
  const [switchState, setSwitchState] = useState<boolean>(false);

  const scrollToTop = () => {
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
  };

  const dispatch = useDispatch();
  const renderContent = () => {
    switch (activeTab) {
      case "disbursement-summary":
        return (
          <DisbursementSummary
            disburseLoan={disburseLoan}
            product_name={product_name}
            interest_rate={interest_rate}
            corebanking_tenant={corebanking_tenant}
            product_variant={product_variant}
            currency={currency}
            handleGenerateLoanSchedule={handleGenerateLoanSchedule}
            handleSaveLoanDetails={handleSaveLoanDetails}
            onCloseModal={onCloseModal}
            first_name={first_name}
            last_name={last_name}
            loan_amount={loan_amount}
            loan_id={loan_id}
            state={state}
            handleOptionSelect={handleOptionSelect}
            loanScheduleResponse={loanScheduleResponse}
            saveLoanDetailsResponse={saveLoanDetailsResponse}
            funderOptions={funderOptions}
            switchState={switchState}
            setSwitchState={setSwitchState}
            showDisbursementConfirmationModal={
              showDisbursementConfirmationModal
            }
            setshowDisbursementConfirmationModal={
              setshowDisbursementConfirmationModal
            }
            disbursementLoading={disbursementLoading}
          />
        );
      case "credit-bureau":
        return (
          <CreditBureauCRC
            first_name={first_name}
            last_name={last_name}
            bvn={bvn}
            borrower_type_value={borrower_type_value}
            rc_number={rc_number}
            loan_id={loan_id}
          />
        );
      case "credit-bureau-check-history":
        return (
          <CreditBureauCheckHistory
            first_name={first_name}
            last_name={last_name}
            borrower_id={borrower_id}
            customer_id={customer_id}
            bvn={bvn}
            borrower_type_value={borrower_type_value}
            rc_number={rc_number}
          />
        );

      case "approval-details":
        return (
          <div>
            <ApprovalDetails
              loanApplicationId={loan_application_id}
              approvalDetailsData={approvalDetailsData}
            />
          </div>
        );

      case "loan-schedule":
        return (
          <LoanSchedule
            result={loanScheduleResponse}
            setActiveTab={setActiveTab}
            setLoanInfoFromSchedule={setLoanInfoFromSchedule}
          />
        );
      case "direct-debit":
        return (
          <DirectDebit
            properties={properties}
            loan_id={loan_id}
            loan_ref={loan_ref}
            first_name={first_name}
            last_name={last_name}
            state={state}
          />
        );

      case "card":
        return (
          <Card
            properties={properties}
            first_name={first_name}
            last_name={last_name}
          />
        );

      case "credit-score":
        return (
          <CreditScore
            properties={properties}
            loanInfoFromSchedule={loanInfoFromSchedule}
          />
        );
      case "upload-bank-statement":
        return <UploadBankStatement loanId={loan_id} properties={properties} />;
      case "account-statistics":
        return <AccountStatistics properties={properties} />;
      case "bank-statement":
        return <BankStatement properties={properties} />;
      case "loan-history":
        return <LoanHistory properties={properties} />;
      case "stock-information":
        return <StockData properties={properties} />;
      case "orders":
        return (
          <Orders
            properties={properties}
            setOrderItemList={setOrderItemList}
            setActiveTab={setActiveTab}
          />
        );
      case "order-items":
        return <OrderItems orderItemlist={orderItemlist} />;
      default:
        return null;
    }
  };

  const handleOptionSelect = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const generateLoanSchedule = async (
    loan_id: number,
    amountApproved: number,
    loan_effective_date: string,
    approvedTenor: number,
    firstRepaymentDate: string | null
  ) => {
    setLoanScheduleResponse({ status: "loading", data: null, error: false });
    const payload = {
      loanId: loan_id,
      principal: Number(amountApproved),
      loanEffectiveDate: loan_effective_date,
      loanTenor: Number(approvedTenor),
      firstRepaymentDate: firstRepaymentDate || null,
    };

    try {
      const response = await ajaxMethods.post(
        `${ajaxEndpoints.GENERATE_PENDING_LOAN_SCHEDULE}`,
        payload
      );

      if (response?.data?.status === true) {
        setLoanScheduleResponse({
          data: response?.data?.data,
          status: "success",
          error: false,
        });
        setActiveTab("loan-schedule");
        setIsTabClickable(true);
      } else {
        setLoanScheduleResponse({
          data: null,
          status: "error",
          error: true,
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "loanSchedule.tsx",
        },
      });
      setLoanScheduleResponse({
        data: null,
        status: "error",
        error: true,
      });
    }
  };

  const handleGenerateLoanSchedule = async () => {
    await generateLoanSchedule(
      properties?.dataRecord?.loan_id,
      Number(state?.requestedAmount),
      state.selectedDate,
      Number(state.requestedTenor),
      state.firstRepaymentDate
    );
  };

  const getFunderName = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_ALL_FUNDERS)
      .then((response) => {
        let funderList = [] as IFunderOptions[];

        if (response.data.length > 0) {
          funderList = [...response.data];
        }
        const funderOptions = funderList.map((funder, index) => {
          const { funder_id, funder_name } = funder;
          return (
            <option key={index} id={funder_id.toString()} value={funder_id}>
              {funder_name}
            </option>
          );
        });

        setState((prev: IState) => {
          return {
            ...prev,
            funderOptions: funderOptions,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Disburse.js",
          },
        });
      });
  };

  const disburseLoan = () => {
    const { comment, selectedDate, requestedAmount } = state;

    const {
      result,
      loans,
      resetLoansForDisbursement,

      // selectedFunder
    } = properties;

    if (comment && selectedDate && requestedAmount) {
      const params = {
        loan_ref: properties?.dataRecord.loan_ref,
        comment: comment,
        // funder_id: Number(selectedFunder),
        loan_effective_date: selectedDate,
        amount_approved: Number(state.requestedAmount),
        creditBureauChecked: switchState,
        approvedTenor: Number(state.requestedTenor) ?? null,
        firstRepaymentDate: state.firstRepaymentDate ?? null,
      };
      // const _disbursementConfirm = document.querySelector(
      //   "#disbursement-confirm"
      // ) as HTMLElement;
      // _disbursementConfirm.classList.add("hidden");

      // const _disbursementLoader = document.querySelector(
      //   "#disbursement-loader"
      // ) as HTMLElement;
      // _disbursementLoader.classList.remove("hidden");

      // const _disbursementSuccess = document.querySelector(
      //   "#disbursement-success"
      // ) as HTMLElement;

      // const _disbursementError = document.querySelector(
      //   "#disbursement-error"
      // ) as HTMLElement;
      setDisbursementLoading(true);
      ajaxMethods
        .post(ajaxEndpoints.DISBURSE_LOANS, params)
        .then((response) => {
          if (response?.status === 200) {
            setDisbursementLoading(false);

            // _disbursementLoader.classList.add("hidden");
            // _disbursementSuccess.classList.remove("hidden");
            result(loans);
            setshowDisbursementConfirmationModal(false);

            onCloseModal();
            resetLoansForDisbursement();
            handleSwalSuccessAlert(
              response?.data?.message ?? response?.data?.result?.message
            );
          } else if (response?.status === 400) {
            setDisbursementLoading(false);

            // _disbursementLoader.classList.add("hidden");
            // _disbursementError.classList.remove("hidden");
            result(loans);
            setshowDisbursementConfirmationModal(false);
            onCloseModal();
            resetLoansForDisbursement();
            handleSwalErrorAlert(
              response?.data?.message ?? response?.data?.result?.message
            );
          } else {
            setDisbursementLoading(false);

            // _disbursementLoader.classList.add("hidden");
            // _disbursementError.classList.remove("hidden");
            result(loans);
            setshowDisbursementConfirmationModal(false);

            onCloseModal();
            resetLoansForDisbursement();
            handleSwalErrorAlert(
              response?.data?.message ?? response?.data?.result?.message
            );
          }
        })
        .catch((error) => {
          setDisbursementLoading(false);

          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "Disburse.js",
            },
          });
          // _disbursementLoader.classList.add("hidden");
          // _disbursementError.classList.remove("hidden");
          result(loans);
          setshowDisbursementConfirmationModal(false);
          onCloseModal();
          resetLoansForDisbursement();
          handleSwalErrorAlert(
            error.response?.data?.message ?? "Could not disburse selected loans"
          );
        });
    } else {
      setState((prev) => {
        return {
          ...prev,
          error: true,
        };
      });
    }
    scrollToTop();
  };

  const saveLoanDetails = async (
    loan_id: number,
    loan_effective_date: string
  ) => {
    setsaveLoanDetailsResponse({ status: "loading", error: false });
    const payload = {
      loanId: loan_id,
      principalAmount: Number(state?.requestedAmount),
      loanEffectiveDate: loan_effective_date,
      disbursementChannelId: Number(state?.disbursement_channel_id),
      disbursementChannelAccountId: Number(
        state?.disbursement_channel_account_id
      ),
      repaymentFrequency: state?.repayment_type,
      moratoriumTypeId: Number(state?.moratorium_type_id),
      amortizationTypeId: Number(state?.amortization_type_id),
      interestTypeId: Number(state?.interest_type_id),
      firstRepaymentDate: String(state?.firstRepaymentDate),
      creditApprovalComment: String(state?.comment),
    };

    try {
      const response = await ajaxMethods.post(
        `${ajaxEndpoints.SAVE_SINGLE_LOAN_DETAIL}`,
        payload
      );

      if (response?.data?.status === true) {
        setsaveLoanDetailsResponse({
          status: "success",
          message: response?.data?.message,
          error: false,
        });
        dispatch(refetchPendingDisbursements());
      } else {
        setsaveLoanDetailsResponse({
          status: "error",
          error: true,
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DisbursementSummary.tsx",
        },
      });
      setsaveLoanDetailsResponse({
        status: "error",
        message: error?.response?.data?.message ?? error?.message,
        error: true,
      });
    }
  };

  const handleSaveLoanDetails = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    await saveLoanDetails(properties?.dataRecord?.loan_id, state?.selectedDate);
  };

  useEffect(() => {
    getFunderName();
  }, []);

  return (
    <React.Fragment>
      <div className="alignToggleTabItems animated fadeInRight m-b-0">
        <ToggleTab
          text="Disbursement Summary"
          id="disbursement-summary"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <ToggleTab
          text="Approval Details"
          id="approval-details"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Loan Schedule"
          id="loan-schedule"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Direct Debit"
          id="direct-debit"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Card"
          id="card"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          // isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Credit Decision"
          id="credit-score"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Upload bank statement"
          id="upload-bank-statement"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Credit Bureau Check History"
          id="credit-bureau-check-history"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Account Statistics"
          id="account-statistics"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Bank Statement"
          id="bank-statement"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Loan History"
          id="loan-history"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Orders"
          id="orders"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === "order-items" && (
          <ToggleTab
            text="Order Items"
            id="order-items"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        <ToggleTab
          text="Stock Information"
          id="stock-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      {loanScheduleResponse?.error && (
        <Alert message={"Unable to generate loan schedule"} />
      )}
      {approvalDetailsError && (
        <Alert message={approvalDetailsError as string} />
      )}

      {saveLoanDetailsResponse?.error ? (
        <Alert message={`${saveLoanDetailsResponse.message}`} />
      ) : (
        saveLoanDetailsResponse?.status === "success" && (
          <Alert
            type="success"
            message={`${saveLoanDetailsResponse.message}`}
          />
        )
      )}
      <div id="singleLoanDisbursementTop">{renderContent()}</div>
    </React.Fragment>
  );
};

export default SingleLoanDisbursement;
