import { Fragment, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import Alert from "../../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";
import { appInsights } from "../../../../../config/appInsights";

const schema = yup.object().shape({
  comment: yup.string().trim().required("Please enter your comment"),
});

export default function SubmitToCreditActionModal({ data, refetchData }: any) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const state = location?.state as { id: number };
  const dismissModalRef = useRef<HTMLButtonElement>(null);

  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(null);

  const handleSubmitToCredit = async (comment: string) => {
    setIsLoading(true);

    try {
      const payload = {
        loan_application_id: state?.id,
        funder_id: data?.data?.data.funder_id,
        comment: comment,
      };

      const response = await putData(
        ajaxEndpoints.SUBMIT_LOAN_APPLICATION_TO_CREDIT,
        payload
      );

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetchData();
        if (dismissModalRef) {
          dismissModalRef?.current?.click();
        }
        history.push("/rm-pending-loan-application");
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
        if (dismissModalRef) {
          dismissModalRef?.current?.click();
        }
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
      appInsights.trackException({
        exception: error,
        properties: { fileName: "LoanInformationTab" },
      });
      if (dismissModalRef) {
        dismissModalRef?.current?.click();
      }
    }
    setIsLoading(false);
  };

  const onSubmit = (formData: any) => {
    handleSubmitToCredit(formData?.comment);
  };

  const handleCloseModal = () => {
    const modal = document.getElementById("submitToCreditActionModal");

    const modalClassList = modal?.classList;

    if (!modalClassList?.contains("show")) {
      setError(null);
      setSuccess(null);
      reset();
    }
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div
        className="modal fade"
        id="submitToCreditActionModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="submitToCreditActionModal"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog ${
            data?.actionType === "cancel" && "modal-sm"
          } modal-dialog-centered`}
          role="document"
        >
          <div className="modal-content">
            <Fragment>
              <div className="modal-header">
                <h5 className="page-subtitle">Proceed to Credit?</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                  ref={dismissModalRef}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Fragment>
                  <p className="text-justify">
                    Please add a comment to complete this process.
                  </p>
                  <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                    <div className="row">
                      <div className="col-12">
                        <CustomTextArea
                          maxLength={256}
                          reference={register}
                          placeholder="Enter comment here..."
                          label="Relationship Manager Comment"
                          name="comment"
                          showRequiredIcon={true}
                          errors={errors?.comment}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end flex-wrap">
                        <button
                          className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                          type="button"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                          type="submit"
                        >
                          {isLoading ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Fragment>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
