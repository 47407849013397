import { useState } from "react";

import styles from "./CustomOnboardingAccordion.module.scss";

interface ICustomOnboardingAccordion {
  children: any;
  title: string;
}

const CustomOnboardingAccordion = ({
  children,
  title,
}: ICustomOnboardingAccordion) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.AccordionContainer}>
      <div className={styles.settingsCard}>
        <div className={styles.title}>{title}</div>
        <div>
          <span
            className="btn advancly-btn btn-md"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            View
          </span>
        </div>
      </div>
      <div className={styles.Content}>{isOpen && <div>{children}</div>}</div>
    </div>
  );
};

export default CustomOnboardingAccordion;
