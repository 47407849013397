import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";

const getLoanApplicationStatus = async () => {
  const { data } = await getData(`${ajaxEndpoints.LOAN_APPLICATION_STATUS}`);

  return data;
};

export default function useLoanApplicationStatus() {
  return useQuery("getLoanApplicationStatus", getLoanApplicationStatus, {
    refetchOnWindowFocus: false,
  });
}
