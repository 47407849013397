import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { appInsights } from "../../../config/appInsights";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { ReactComponent as Ellipses } from "../../../assets/svg/icons/dots-v.svg";
import TableWrapper from "../../AutomatedCredit/CreditAnalysis/Components/TableWrapper";
import { Dropdown, Table } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ColumnsType } from "antd/es/table";
import { addSerialNumber } from "../../AutomatedCredit/CreditAnalysis/util";
import ConfirmationModal from "../../../NewComponents/ConfirmationModal";
import { deleteData } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

import styles from "./loanProductDetailFees.module.scss";

const LoanProductDetailFees = () => {
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedFee, setSelectedFee] = useState<{
    fee: string;
    fee_calculation_type: string;
    fee_class: string;
    fee_id: string;
    id: number;
    loan_product_detail: number;
    loan_product_detail_id: number;
    serialNumber: number;
    value: number;
  }>();

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state as { name: string; id: number; fees: any[] };

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
    },
    { key: "delete", label: "Delete" },
  ];

  const handleDeleteClick = (data: any) => {
    setIsShowConfirmationModal(true);
    return setSelectedFee(data);
  };

  const columns: ColumnsType<any> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      align: "center",
    },
    {
      title: "Fee Calculation Type",
      dataIndex: "fee_calculation_type",
      key: "fee_calculation_type",
      align: "center",
    },
    {
      title: "Class",
      dataIndex: "fee_class",
      key: "fee_class",
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
              }

              if (key === "delete") {
                handleDeleteClick(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan product Detail Fees - (LoanProductDetailFees/index.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleAddFeeClick = () => {
    dispatch(
      showModal({
        modalIdentifier: "mapfeestoproductdetail",
        dataRecord: {},
      })
    );
  };

  const handleDeleteFee = async () => {
    setIsDeleting(true);

    try {
      const response = await deleteData(
        `${ajaxEndpoints.LOAN_PRODUCT_DETAIL_FEE}/${selectedFee?.id}`
      );

      if (response?.data?.status === true) {
        setIsShowConfirmationModal(false);
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );

        history.goBack();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
      appInsights.trackException({
        exception: error,
        properties: { fileName: "LoanProductDetailFees" },
      });
    }
    setIsDeleting(false);
  };

  return (
    <div className="animated fadeInRight">
      <div className="mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Loan Product ({state?.name})
        </h3>
      </div>

      <div className={styles.ActionContainer}>
        <div className={styles.FormButtonContainer}>
          <button
            className={`btn advancly-btn btn-md ${styles.CreateActionButton}`}
            onClick={handleAddFeeClick}
          >
            Add Fee
          </button>
        </div>
      </div>

      <TableWrapper>
        <Table
          columns={columns}
          loading={false}
          dataSource={addSerialNumber(state?.fees)}
          pagination={false}
          rowKey={(record) => record?.id}
        />
      </TableWrapper>

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleDeleteFee();
          }}
          error={""}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default LoanProductDetailFees;
