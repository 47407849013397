import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TRepaymentChannelResponse = {
  data: {
    data: {
      id: number;
      channel_name: string;
    }[];
  };
};

const getRepaymentChannels = async () => {
  const data = await getData(ajaxEndpoints.GET_REPAYMENT_CHANNEL_LIST);

  return data;
};

export default function useRepaymentChannels() {
  return useQuery<TRepaymentChannelResponse>(
    "getRepaymentChannels",
    getRepaymentChannels,
    {
      refetchOnWindowFocus: false,
    }
  );
}
