import React, { ChangeEvent, useEffect, useState } from "react";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { Link } from "react-router-dom";
import useLoanApplications, {
  TQuery,
} from "../../../custom-hooks/usePendingLoanApplications";
import { appInsights } from "../../../config/appInsights";
import styles from "./PreDisbursementPendingLoanApplication.module.scss";

const PreDisbursementPendingLoanApplication = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(10);

  const [searchQuery, setSearchQuery] = useState({});
  const [query, setQuery] = useState<TQuery>({
    Page: 1,
    PageSize: 10,
    search_value: "",
    start_date: "",
    end_date: "",
    // status: `status=3&status=6`,
    status: `status=18`,
  });
  useEffect(() => {
    appInsights.trackPageView({
      name: "PreDisbursmentPendingLoanApplication.tsx",
      isLoggedIn: true,
    });
  }, []);
  const handleSearch = () => {
    const _query = searchQuery as TQuery;

    return setQuery((prev: any) => {
      return {
        ...prev,
        ..._query,
      };
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setSearchQuery((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const { data: pendingLoanApplications, status } = useLoanApplications({
    Page: pageNumber,
    PageSize: size,
    end_date: query?.end_date,
    search_value: query?.search_value,
    start_date: query?.start_date,
    status: query?.status,
  });

  let RenderedComponent = null;

  RenderedComponent = (
    <div className="row m-x-sm m-y-2 grid__padding">
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-lighter-grey">
            <tr>
              <th>S/N</th>
              <th>Customer Name</th>
              <th>Customer Email</th>
              <th>Application Reference</th>
              <th>Application Date</th>
              <th>Review</th>
            </tr>
          </thead>
          <tbody>
            {pendingLoanApplications &&
              pendingLoanApplications?.data?.length > 0 &&
              pendingLoanApplications.data?.map((request: any, _id: any) => {
                const {
                  ref_name,
                  approval_date,
                  id,
                  funder_email,
                  funder_name,
                  funder_id,
                  generated_cam_file_path_pdf,
                  generated_cam_file_path_docx,
                } = request;
                return (
                  <tr key={_id}>
                    <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                    <td>{funder_name}</td>
                    <td>{funder_email}</td>
                    <td>{ref_name}</td>

                    <td>
                      {approval_date ? approval_date.substring(0, 10) : "NIL"}
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: `/predisbursement-pending-loan-application/${id}`,
                          state: {
                            id,
                            title: "Pre Disbursement Menu",
                            isViewOnly: false,
                            customerId: funder_id,
                            generatedCamDocx: generated_cam_file_path_docx,
                            generatedCamPdf: generated_cam_file_path_pdf,
                          },
                        }}
                        className="color-blue"
                      >
                        View Details
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="speed-1x animated fadeInRight">
      <h3 className="page-title d-flex align-items-center mb-3">
        <BackButtonComponent /> Pre Disbursement Menu
      </h3>

      <div className={`row ${styles.ActionBar}`}>
        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Customer Name:
          </span>
          <input
            type="text"
            name="search_value"
            className="form-control"
            placeholder="customer name"
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Reference:
          </span>
          <input
            type="text"
            name="search_value"
            className="form-control"
            placeholder="reference"
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Number of Results:
          </span>
          <div style={{ marginTop: "-0.65rem" }}>
            <CustomSelectDropdown
              onChange={(e) => setSize(Number(e.target.value))}
              value={size}
              name=""
            >
              <option value="">All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>

        <div className="col-3 d-flex justify-content-end">
          <button
            className="btn advancly-btn btn-md"
            disabled={status === "loading"}
            onClick={handleSearch}
          >
            Search{status === "loading" && <ButtonLoader />}
          </button>
        </div>
      </div>

      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={pendingLoanApplications?.totalCount}
        changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
      />
    </div>
  );
};

export default PreDisbursementPendingLoanApplication;
