import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";

const getApprovalDetails = async ({
  loan_application_detail_id,
  customer_id,
  loan_ref,
}: {
  loan_application_detail_id: string;
  customer_id: number;
  loan_ref?: string;
}) => {
  const data = await getData(
    `${ajaxEndpoints.GET_APPROVAL_DETAILS}?customer_id=${Number(
      customer_id
    )}&loan_application_detail_id=${loan_application_detail_id}&loan_ref=${loan_ref}`
  );
  return data.data;
};
export default function useApprovalDetails({
  loan_application_detail_id,
  customer_id,
  loan_ref,
}: {
  loan_application_detail_id: string | null;
  customer_id: number;
  loan_ref?: string;
}) {
  return useQuery(
    [
      { loan_application_detail_id, customer_id, loan_ref },
      "getApprovalDetails",
    ],
    getApprovalDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
