import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";
import { IResponse } from "../interfaces/response";

type TQuery = {
  tenantId: string;
};

export type TProductResponse = {
  currency_code: string;
  description: string;
  id: number;
  interest_rate_chart: string;
  maxDepositTerm: number;
  maximum_investment: number;
  minDepositTerm: number;
  minimum_investment: number;
  name: string;
  number_of_days_in_year: number;
  short_name: string;
};

const getMifosProductByTenantId = async ({ tenantId }: TQuery) => {
  let query = `?TenantId=${tenantId}`;

  const { data } = await getData(
    `${ajaxEndpoints.GET_MIFOS_PRODUCTS_DETAILS}${query}`
  );

  console.log("originalData: ", data);

  return data;
};

export default function useMifosProductByTenantid({ tenantId }: TQuery) {
  return useQuery<TProductResponse[]>(
    [{ tenantId }, "getMifosProductByTenantId"],
    getMifosProductByTenantId,
    {
      refetchOnWindowFocus: false,
      enabled: tenantId ? true : false,
    }
  );
}
