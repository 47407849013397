import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Dropdown, Table } from "antd";
import TableAccordion from "../../../Components/TableAccordion";
import { ColumnsType } from "antd/es/table";
import { ILoanApplicationCurrency } from "../util";
import useLoanApplicationCurrency from "../../../../../../custom-hooks/useLoanApplicationCurrency";
import { addSerialNumber } from "../../../util";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { showModal } from "../../../../../../redux/actions/modal";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import { ReactComponent as Ellipses } from "../../../../../../assets/svg/icons/dots-v.svg";
import { RootState } from "../../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../../helpers/roleTypes";

const CurrencyTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as { id: number };

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const {
    data: loanApplicationCurrency,
    isLoading,
    refetch,
  } = useLoanApplicationCurrency(state?.id);

  const handleDisableDropDownItem = (): boolean => {
    if (
      loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPLICATION_APPROVER) ||
      loggedInUser?.roles.includes(ROLE_TYPES.MCC)
    ) {
      return true;
    }
    return false;
  };

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
      disabled: handleDisableDropDownItem(),
    },
  ];

  const handleEditCurrency = (_data: ILoanApplicationCurrency) => {
    dispatch(
      showModal({
        modalIdentifier: "loanapplicationcurrencyedit",
        dataRecord: { data: _data, refetch },
      })
    );
  };

  const columns: ColumnsType<ILoanApplicationCurrency> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Currency",
      dataIndex: "exchangeCurrency",
      key: "exchangeCurrency",
      align: "center",
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchangeRate",
      key: "exchangeRate",
      align: "center",
    },
    {
      title: "Currency Code",
      dataIndex: "currencyCode",
      key: "currencyCode",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleEditCurrency(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            {isLoading ? <ButtonLoader /> : <Ellipses />}
          </button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="mt-3 mb-5">
      <TableAccordion
        title={<span style={{ display: "inline-block" }}>Currency</span>}
      >
        <Table
          columns={columns}
          dataSource={addSerialNumber(loanApplicationCurrency?.data)}
          pagination={false}
          rowClassName={(record) => record?.serialNumber}
          loading={isLoading}
        />
      </TableAccordion>
    </div>
  );
};

export default CurrencyTable;
