import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getCAMHistory = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.CAM_HISTORY_DOCUMENTS}${id}/cams`
  );
  return data;
};

export default function useCAMHistory(id: number) {
  return useQuery([id, "getCAMHistory"], getCAMHistory, {
    refetchOnWindowFocus: false,
    enabled: id ? true : false,
  });
}
