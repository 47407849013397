import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Table, Dropdown } from "antd";
import TableAccordion from "../../../Components/TableAccordion";
import { ColumnsType } from "antd/es/table";
import { OtherLendersDataType } from "../util";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ReactComponent as Ellipses } from "../../../../../../assets/svg/icons/dots-v.svg";
import doubleIcon from "../../../../../../assets/svg/icons/double-arrow.svg";
import useLoansByOtherLenders from "../../../../../../custom-hooks/useLoansByOtherLenders";
import { showModal } from "../../../../../../redux/actions/modal";
import { formatMoney } from "../../../../../../helpers/formatter";
import ConfirmationModal from "../../../../../../NewComponents/ConfirmationModal";
import { deleteData } from "../../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../../config/appInsights";

const OtherLendersTable = () => {
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedData, setSelectedData] = useState<OtherLendersDataType>();
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as { id: number };

  const {
    data: loansByOtherLenders,
    isLoading,
    refetch,
  } = useLoansByOtherLenders(state?.id);

  const handleAddIndebtedness = () => {
    dispatch(
      showModal({
        modalIdentifier: "createindebtedness",
        dataRecord: {
          refetchData: refetch,
          type: "create",
        },
      })
    );
  };

  const handleEditOtherLendersLoan = (data: OtherLendersDataType) => {
    dispatch(
      showModal({
        modalIdentifier: "createindebtedness",
        dataRecord: {
          refetchData: refetch,
          type: "edit",
          oldData: data,
        },
      })
    );
  };

  const handleDeleteOtherLendersLoan = (data: OtherLendersDataType) => {
    setIsShowConfirmationModal(true);
    return setSelectedData(data);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteData(
        `${ajaxEndpoints.DELETE_LOANS_BY_OTHER_LENDERS}/${selectedData?.id}?loanApplicationId=${state?.id}`
      );

      if (response?.data?.status_code === 200) {
        setIsShowConfirmationModal(false);
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetch();
      } else {
        setError(response?.data?.message);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "OtherLendersTable.tsx" },
      });
    }
    setIsDeleting(false);
  };

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
    },
    { key: "delete", label: "Delete" },
  ];

  const columns: ColumnsType<OtherLendersDataType> = [
    {
      title: "Bank",
      dataIndex: "bank",
      key: "bank",
      align: "center",
    },
    {
      title: "Loan Reference",
      dataIndex: "loanRef",
      width: "12%",
      key: "loanRef",
      align: "center",
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      key: "loanAmount",
      width: "12%",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.loanAmount, data?.currency)}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      align: "center",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      align: "center",
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      width: "12%",
      align: "center",
      render: (_, data) => {
        return (
          <span>{formatMoney(data?.outstandingAmount, data?.currency)}</span>
        );
      },
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      key: "tenor",
      align: "center",
    },
    {
      title: "Loan Status",
      dataIndex: "loanStatus",
      key: "loanStatus",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleEditOtherLendersLoan(_data);
              }

              if (key === "delete") {
                handleDeleteOtherLendersLoan(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];
  return (
    <div className="mt-3 mb-5">
      <TableAccordion
        title={
          <>
            <span style={{ display: "inline-block" }}>
              Indebtedness <img src={doubleIcon} alt="" />
              <span style={{ color: "#713fff" }}>Other Lenders</span>
            </span>

            <span
              style={{
                fontWeight: 400,
                color: "#713fff",
                textDecoration: "underline",
                cursor: "pointer",
                marginRight: "1.5rem",
              }}
              onClick={() => handleAddIndebtedness()}
            >
              Create
            </span>
          </>
        }
      >
        <Table
          columns={columns}
          loading={isLoading}
          dataSource={loansByOtherLenders?.data?.data}
          scroll={{ x: 1300 }}
          pagination={false}
        />
      </TableAccordion>

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleDelete();
          }}
          error={error}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default OtherLendersTable;
