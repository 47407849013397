import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";
import { getData } from "../newApis/apiMethods";
async function offerLetterHistory({
  isCurrent,
  loanApplicationId,
}: {
  loanApplicationId: number;
  isCurrent: boolean;
}) {
  const { data } = await getData(
    `${ajaxEndpoints.OFFER_LETTER_HISTORY}?loan_application_id=${loanApplicationId}&is_current=${isCurrent}`
  );
  return data;
}
const useOfferLetterHistory = ({
  isCurrent,
  loanApplicationId,
}: {
  loanApplicationId: number;
  isCurrent: boolean;
}) => {
  return useQuery(
    [{ isCurrent, loanApplicationId }, "offerLetterHistory"],
    offerLetterHistory,
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useOfferLetterHistory;
