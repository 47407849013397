import { useEffect } from "react";
import { customStyle } from "./customStyle";

import styles from "./TableWrapper.module.scss";

const TableWrapper = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    const style = document.createElement("style");
    document.head.appendChild(style);

    style.innerHTML = customStyle;

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className={styles.TableContainer}>
      <div className={`${styles.Content}`}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default TableWrapper;
