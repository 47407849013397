import React from "react";
import styles from "./PreDisbursementViewComments.module.scss";
import { ReactComponent as UserPick } from "../../../../../../assets/svg/userpick.svg";

const PreDisbursementViewComments = () => {
  const getPredisbursementComments = [
    {
      id: 1,
      comments: "lorem djjerururjundujdunjduddnd",
      usertype: "Admin Comment",
      usertypeId: 1,
    },
    {
      id: 2,
      comments: "lorem djjerucccsxcsssrurjundujdunjduddnd",
      usertype: "Customer Comment",
      usertypeId: 2,
    },
    {
      id: 3,
      comments: "lorem djjerururjundujdunjduddnd",
      usertype: "Admin Comment",
      usertypeId: 1,
    },
  ];
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content">
        <div className="Modal__body">
          <div className="d-flex justify-content-start  my-2">
            <h5 className="">View all comments</h5>
          </div>

          <div>
            {getPredisbursementComments.map(
              ({ id, comments, usertype, usertypeId }) => {
                return (
                  <div key={id}>
                    <div className={styles.commentsContainer}>
                      <p>{comments}</p>
                      <small
                        className={
                          usertypeId === 1
                            ? "d-flex justify-content-end"
                            : " d-flex justify-content-start"
                        }
                      >
                        <UserPick /> {usertype}
                      </small>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreDisbursementViewComments;
