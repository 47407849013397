import React, { useEffect } from "react";
import CustomInputField from "../../../../../../components/CustomHTMLElements/CustomInputField";
import PreDisbursementChecklistReadOnly from "./PreDisbursementChecklistReadOnly";
import { useForm } from "react-hook-form";

const ApprovalDetails = ({
  loanApplicationId,
  approvalDetailsData,
}: {
  loanApplicationId: number;

  approvalDetailsData: any;
}) => {
  const { register, setValue } = useForm({});

  useEffect(() => {
    if (approvalDetailsData) {
      setValue("applicationRefNo", approvalDetailsData?.loan_application_ref);
      setValue("product", approvalDetailsData?.product_name);
      setValue("currency", approvalDetailsData?.currency);
      setValue("approvedAmount", approvalDetailsData?.approved_amount);
      setValue("utilizedAmount", approvalDetailsData?.utilized_amount);
      setValue("amountRemaining", approvalDetailsData?.remaining_amount);
      setValue("interestRate", approvalDetailsData?.interest_rate);
      setValue("tenure", approvalDetailsData?.loan_tenure);
    }
    //eslint-disable-next-line
  }, [approvalDetailsData]);

  return (
    <div
      style={{
        padding: "1rem",
        height: "70vh",
        scrollbarColor: "auto",
        overflowY: "scroll",
      }}
    >
      <div>
        <div className="m-b-30">
          <form>
            <div className="row">
              <div className="col-6">
                <CustomInputField
                  type="text"
                  reference={register}
                  name="applicationRefNo"
                  label="Application Ref No"
                  readOnly
                />
              </div>
              <div className="col-6">
                <CustomInputField
                  name="product"
                  reference={register}
                  type="text"
                  label="Product"
                  readOnly
                />
              </div>
              <div className="col-6">
                <CustomInputField
                  name="currency"
                  reference={register}
                  type="text"
                  label="Currency"
                  readOnly
                />
              </div>

              <div className="col-6">
                <CustomInputField
                  name="approvedAmount"
                  reference={register}
                  type="text"
                  label="Approved Amount"
                  readOnly
                />
              </div>
              <div className="col-6">
                <CustomInputField
                  name="utilizedAmount"
                  reference={register}
                  type="text"
                  label="Utilized Amount"
                  readOnly
                />
              </div>
              <div className="col-6">
                <CustomInputField
                  name="amountRemaining"
                  reference={register}
                  type="text"
                  label="Amount Remaining"
                  readOnly
                />
              </div>

              <div className="col-6">
                <CustomInputField
                  name="interestRate"
                  reference={register}
                  type="text"
                  label="Interest Rate"
                  readOnly
                />
              </div>
              <div className="col-6">
                <CustomInputField
                  name="tenure"
                  reference={register}
                  type="text"
                  label="Tenure"
                  readOnly
                />
              </div>
            </div>
          </form>

          <div>
            {approvalDetailsData?.product_variant === "Spark" && (
              <>
                <h4 className="font-weight-bold">
                  Pre Disbursement Checklist{" "}
                </h4>
                <PreDisbursementChecklistReadOnly
                  loanApplicationId={loanApplicationId}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalDetails;
