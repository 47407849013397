// Redux Action CONSTANTS
import * as actionTypes from "./../actions/actionTypes";

// Ajax Methods
import ajaxMethods from "../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { appInsights } from "../../config/appInsights";

// Start asynchronous ajax call with UI loader display
export const transactionAjaxStart = () => {
  return { type: actionTypes.TRANSACTION_AJAX_START };
};

// Get all Transactions and handle success payload
export const getAdminStatistics = (queryString) => {
  return function (dispatch) {
    // dispatch(transactionAjaxStart());

    let endpoint = ajaxEndpoints.ADMIN_STATISTICS;
    // if (queryString) {
    //     endpoint = `${ajaxEndpoints.ALL_TRANSACTIONS}?${queryString}`;
    // }

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch(getAdminStatsSuccess(response));
        } else {
          // dispatch(getTransactionsFailure({
          //     errorMsg: 'other error'
          // }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "stats.js",
          },
        });
        // dispatch(getTransactionsFailure(error))
      });
  };
};

export const getAdminStatsSuccess = (response) => {
  return {
    type: actionTypes.GET_ADMIN_STATISTICS_SUCCESS,
    payload: response.data,
  };
};

export const getAggregatorStatistics = (xaggregator_id) => {
  return function (dispatch) {
    let endpoint = `${ajaxEndpoints.AGGREGATOR_STATISTICS}?xaggregator_id=${xaggregator_id}`;

    ajaxMethods
      .get(endpoint)
      .then((response) => {
        if (response) {
          dispatch(getAggregatorStatsSuccess(response));
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "stats.js",
          },
        });
      });
  };
};

export const getAggregatorStatsSuccess = (response) => {
  return {
    type: actionTypes.GET_AGGREGATOR_STATISTICS_SUCCESS,
    payload: response.data,
  };
};

// export const getAdminStatsFailure = (error) => {
// return {
//     type: actionTypes.GET_TRANSACTIONS_FAILURE,
//     error
// };
// };
