import LoanApplicationTransactionTrail from "./LoanApplicationTransactionLog";

interface IAuditTrail {
  data: any;
}

const AuditTrail = ({ data: loanAplication }: IAuditTrail) => {
  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <LoanApplicationTransactionTrail id={loanAplication?.data?.data?.id} />
    </div>
  );
};

export default AuditTrail;
