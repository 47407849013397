import React from "react";
import moment from "moment";

const CreditBureauTableFacilityHistory24 = ({
  creditFacilityHistory24,
}: any) => {
  return (
    <div
      style={{
        overflow: "auto",
      }}
    >
      <h5 className="table-caption">Credit Facility History 24</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              S/N
            </th>
            <th className="th-td-style th-style" scope="col">
              Account Number
            </th>
            <th className="th-td-style th-style" scope="col">
              Currency
            </th>
            <th className="th-td-style th-style" scope="col">
              Asset Classification
            </th>
            <th className="th-td-style th-style" scope="col">
              Institution Name
            </th>
            <th className="th-td-style th-style" scope="col">
              OwnerShip Indicator
            </th>
            <th className="th-td-style th-style" scope="col">
              Loan Status
            </th>
            <th className="th-td-style th-style" scope="col">
              Loan Type
            </th>
            <th className="th-td-style th-style" scope="col">
              Loan Type Value
            </th>
            <th className="th-td-style th-style" scope="col">
              Number Of Installments
            </th>
            <th className="th-td-style th-style" scope="col">
              Repayment Frequency
            </th>
            <th className="th-td-style th-style" scope="col">
              Last Repayment Date
            </th>
            <th className="th-td-style th-style" scope="col">
              Security Value
            </th>
            <th className="th-td-style th-style" scope="col">
              Ranking
            </th>
            <th className="th-td-style th-style" scope="col">
              Maturity Date
            </th>
          </tr>
        </thead>
        <tbody>
          {creditFacilityHistory24?.map((history24: any, index: number) => {
            const {
              acC_OPEN_DISB_DT,

              accounT_NUMBER,

              amounT_OVERDUE_CAL,

              asseT_CLASSIFICATION_CAL,

              currenT_BALANCE_CAL,

              currency,

              datE_REPORTED,

              datE_REPORTED_AGE,

              ifF_TYPE,

              institutioN_NAME,

              lastrepayamT_DT_CAL,

              loaN_STATUS,

              loaN_TYPE,

              loaN_TYPE_VALUE,

              maturitY_DT,

              nuM_OF_DAYS_IN_ARREARS_CAL,

              numbeR_OF_INSTALLMENTS,

              ownershiP_INDICATOR,

              ranking,

              reasoN_CODE,

              reasoN_CODE_VALUE,

              repaymenT_FREQUENCY,

              reporteD_DT_TEXT,

              sanctioneD_AMOUNT_CAL,

              securitY_COVERAGE,

              securitY_VALUE,

              sin,

              sno,

              typE_Basic_RPT,

              type,

              uniquE_ROOT_ID,
            } = history24;
            return (
              <tr key={sno}>
                <td className="th-td-style">{sno}</td>
                <td className="th-td-style">{accounT_NUMBER}</td>
                <td className="th-td-style">{currency}</td>
                <td className="th-td-style">{asseT_CLASSIFICATION_CAL}</td>
                <td className="th-td-style">{institutioN_NAME}</td>
                <td className="th-td-style">{ownershiP_INDICATOR}</td>
                <td className="th-td-style">{loaN_STATUS}</td>
                <td className="th-td-style">{loaN_TYPE}</td>
                <td className="th-td-style">{loaN_TYPE_VALUE}</td>
                <td className="th-td-style">{numbeR_OF_INSTALLMENTS}</td>
                <td className="th-td-style">{repaymenT_FREQUENCY}</td>
                <td className="th-td-style">
                  {moment(lastrepayamT_DT_CAL).format("DD/MM/YYYY")}
                </td>
                <td className="th-td-style">{securitY_VALUE}</td>
                <td className="th-td-style">{ranking}</td>
                <td className="th-td-style">
                  {" "}
                  {moment(maturitY_DT).format("DD/MM/YYYY")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableFacilityHistory24;
