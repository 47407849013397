import React from "react";

const CreditBureauTableInquiryProduct = ({ inquiryProduct }: any) => {
  return (
    <div>
      <h5 className="table-caption">Inquiry Product</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Bank
            </th>
            <th className="th-td-style th-style" scope="col">
              Micro
            </th>
            <th className="th-td-style th-style" scope="col">
              Mortgage
            </th>
            <th className="th-td-style th-style" scope="col">
              nbfc
            </th>
            <th className="th-td-style th-style" scope="col">
              Product Type
            </th>
            <th className="th-td-style th-style" scope="col">
              Other
            </th>
            <th className="th-td-style th-style" scope="col">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {inquiryProduct?.map((product: any, index: number) => {
            const { bank, micro, mortgage, nbfc, other, producT_TYPE, total } =
              product;
            return (
              <tr key={index}>
                <td className="th-td-style">{bank}</td>
                <td className="th-td-style">{micro}</td>
                <td className="th-td-style">{mortgage}</td>
                <td className="th-td-style">{nbfc}</td>
                <td className="th-td-style">{producT_TYPE}</td>
                <td className="th-td-style">{other}</td>
                <td className="th-td-style">{total}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableInquiryProduct;
