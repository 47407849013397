import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";

type TParams = {
  loanApplicationId: number;
};

const getConditionsPredisbursement = async ({ loanApplicationId }: TParams) => {
  const data = await getData(
    `${ajaxEndpoints.PRE_DISBURSEMENT_PRECONDITIONS}?loanApplicationId=${loanApplicationId}&loanConditionClass=external&loanConditionType=precedent`
  );

  return data;
};

export default function useConditionsPredisbursement({
  loanApplicationId,
}: TParams) {
  return useQuery<any>(
    [{ loanApplicationId }, "getConditionsPredisbursement"],
    getConditionsPredisbursement,
    { enabled: loanApplicationId ? true : false, refetchOnWindowFocus: false }
  );
}
