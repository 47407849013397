import React from "react";
import { Fragment, useState, useEffect } from "react";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import { useLocation } from "react-router-dom";
import usePendingLoanApplicationsById from "../../../custom-hooks/usePendingLoanApplicationById";
import LoanInformation from "./Tabs/LoanInformation";
import BusinessInformation from "./Tabs/BusinessInformation";
import Collateral from "./Tabs/Collateral/Documents";
import FinancialInformation from "./Tabs/FinancialInformation/Documents";
import Industry from "./Tabs/Industry";
import { Loader } from "../../../UI/Loaders/Loaders";
import AuditTrail from "./Tabs/AuditTrail";

import { appInsights } from "../../../config/appInsights";
import Feedback from "./Tabs/Feedback";

import styles from "./ViewLoanApplication.module.scss";
import OfferLetter from "./Tabs/OfferLetter";
import PreDisbursementChecklistReadOnly from "../../../modals/Modals/Loans/Disburse/CreditAnalysis/ApprovalDetails/PreDisbursementChecklistReadOnly";
import usePredisbursementData from "../../../custom-hooks/usePredisbursementData";
import CamHistoryForViewLoanApplications from "./CamHistoryForViewLoanApplications";
import Disbursement from "../Disbursement/Disbursement";

const ViewLoanApplicationReadOnly = () => {
  const [activeTab, setActiveTab] = useState("loan-information");

  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
    customerId: number;
    application_type: string;
    application_type_id: number;
    ref_name: string;
  };

  const {
    data: predisbursementData,
    status: predisbursementStatus,
    error: predisbursementError,
  } = usePredisbursementData({
    loanApplicationId: state?.id,
    customerId: state?.customerId,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Review Onboarding Request  - (AggregatorOnboardingReview.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: application,
    refetch,
    status,
    error,
  } = usePendingLoanApplicationsById(state?.id);

  let RenderedComponent = null;
  if (!application && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Loan
            Applications
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Loan Application..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Loan Applications, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div>
          {(() => {
            switch (activeTab) {
              case "loan-information":
                return (
                  <LoanInformation data={application} refetchData={refetch} />
                );
              case "business-information":
                return (
                  <BusinessInformation
                    data={application}
                    refetchData={refetch}
                  />
                );
              case "industry":
                return <Industry data={application} refetchData={refetch} />;
              case "financial-information":
                return (
                  <FinancialInformation
                    data={application}
                    refetchData={refetch}
                  />
                );
              case "collateral":
                return <Collateral data={application} refetchData={refetch} />;
              case "audit_trail":
                return <AuditTrail data={application} />;
              case "feedback":
                return <Feedback data={application} refetchData={refetch} />;
              case "camhistory":
                return (
                  <CamHistoryForViewLoanApplications
                    predisbursementData={predisbursementData}
                    loanApplicationId={state?.id}
                    predisbursementStatus={predisbursementStatus}
                    predisbursementError={predisbursementError}
                  />
                );
              case "offer_letter":
                return <OfferLetter data={application} refetchData={refetch} />;
              case "predisbursementchecklist":
                return (
                  <PreDisbursementChecklistReadOnly
                    loanApplicationId={state?.id}
                  />
                );

              case "disbursement":
                return <Disbursement refName={state?.ref_name} />;

              default:
                return null;
            }
          })()}
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> {state?.title}
        </h3>
      </div>

      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Name</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Email</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Type</div>
          <div className={styles.content}>{state?.application_type}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Reference:</div>
          <div className={styles.content}>
            {application?.data?.data?.ref_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Date</div>
          <div className={styles.content}>
            {application?.data?.data?.application_date.split("T")[0]}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Status</div>
          <div className={styles.content}>
            {application?.data?.data?.status}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Relationship Manager Name</div>
          <div className={styles.content}>
            {application?.data?.data?.relationship_manager.trim()
              ? application?.data?.data?.relationship_manager.trim()
              : "NIL"}
          </div>
        </div>
      </div>

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Loan Information"
          id="loan-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Business Information"
            id="business-information"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Industry"
            id="industry"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Financial Information"
            id="financial-information"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Collateral"
            id="collateral"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Approval Trail"
            id="audit_trail"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text=" Appraisal Feedback"
            id="feedback"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {state?.application_type_id === 1 && (
          <ToggleTab
            text="CAM History"
            id="camhistory"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Offer Letter"
            id="offer_letter"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Pre-disbursement Checklist "
            id="predisbursementchecklist"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        <ToggleTab
          text="Disbursement "
          id="disbursement"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>{RenderedComponent}</div>
    </Fragment>
  );
};

export default ViewLoanApplicationReadOnly;
