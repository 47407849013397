import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getLoanApplicationDetailFeeLog = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_APPLICATION_DETAIL_FEE_LOG}?loan_application_detail_id=${id}`
  );

  return data;
};

export default function useLoanApplicationDetailFeeLog(id: number) {
  return useQuery(
    [id, "getLoanApplicationDetailFeeLog"],
    getLoanApplicationDetailFeeLog,
    {
      refetchOnWindowFocus: false,
    }
  );
}
