import TableAccordion from "../../../../Components/TableAccordion";
import { TextEditor } from "../../../../Components/TextEditor";

import styles from "./borroweinfo.module.scss";

interface IBorrowerInfo {
  data: any;
  setBusinessComments: React.Dispatch<
    React.SetStateAction<{
      business_comment: string;
      industry_comment: string;
      ownership_comment: string;
      governance_and_management_comment: string;
      borrower_info: string;
    }>
  >;
  defaultValue: string;
  readOnly: boolean;
}

const BorrowerInfo = ({
  data,
  setBusinessComments,
  defaultValue,
  readOnly,
}: IBorrowerInfo) => {
  const handleChange = (data: string) => {
    setBusinessComments((prev) => {
      return {
        ...prev,
        borrower_info: data,
      };
    });
  };

  return (
    <div className={styles.BusinessInfo}>
      <TableAccordion
        title={<span className={styles.Title}>BORROWER INFORMATION</span>}
        isDefaultOpen
      >
        <TextEditor
          onChange={handleChange}
          defaultValue={defaultValue}
          readOnly={readOnly}
        />
      </TableAccordion>
    </div>
  );
};

export default BorrowerInfo;
