import { useState } from "react";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { appInsights } from "../../../../../config/appInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextEditor } from "../../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";

const schema = yup.object().shape({
  comments: yup.string().required("Please enter comments"),
});

const TransferApplicationComments = (props: any) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const location = useLocation();
  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const {
    dataRecord: { refetch, action },
  } = props;

  const state = location?.state as {
    id: number;
  };

  const handleTransfer = async (value: { comments: string }) => {
    setIsLoading(true);
    try {
      const payload = {
        loan_application_id: Number(state?.id),
        comment: value?.comments,
      };
      const response = await putData(
        `${
          action === "ca_transfer"
            ? ajaxEndpoints.CA_RETURN_APPLICATION_TO_CUSTOMER
            : action === "approveandsendtomcc"
            ? ajaxEndpoints.APPROVE_AND_SEND_TO_MCC
            : action === "returntoanalyst"
            ? ajaxEndpoints.RM_RETURN_APPLICATION_TO_ANALYST
            : ajaxEndpoints.RM_RETURN_APPLICATION_TO_ANALYST
        }`,
        payload
      );
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
          history.goBack();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "RMCommentsModal" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <form onSubmit={handleSubmit(handleTransfer)}>
            <CustomTextArea
              maxLength={256}
              placeholder="Enter message here..."
              label="Comments"
              name="comments"
              reference={register}
              errors={errors?.comments}
            />

            <button
              className="create zoom-element btn login-btn"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Transfer"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TransferApplicationComments;
