import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getPredisbursementData = async ({
  loanApplicationId,
  customerId,
}: {
  loanApplicationId: number;
  customerId: number;
}) => {
  const data = await getData(
    `${ajaxEndpoints.PRE_DISBURSEMENT_DATA}/${loanApplicationId}/pre_disbursement?customer_id=${customerId}`
  );

  return data;
};

export default function usePredisbursementData({
  loanApplicationId,
  customerId,
}: {
  loanApplicationId: number;
  customerId: number;
}) {
  return useQuery(
    [
      { loanApplicationId, customerId },
      "getPendingLoanApplicationsPreDisbursment",
    ],
    getPredisbursementData,
    {
      refetchOnWindowFocus: false,
    }
  );
}
