import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";

import styles from "./fees.module.scss";

const schema = yup.object().shape({
  value: yup.string().required("Please enter value"),
  name: yup.string().required("Please enter name"),
  description: yup.string().required("Please enter description"),
});

enum LoanClass {
  Loan = 1,
  Wallet = 2,
  FixedDeposit = 3,
}

enum ClassficationType {
  Percentage = 1,
  Value = 2,
}

const Fees = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState(
    props?.dataRecord?.oldData?.calculation_type_id
  );
  const [selectedClass, setSelectedClass] = useState(
    props?.dataRecord?.oldData?.class_id
  );

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeType = (data: { label: string; value: string }) => {
    return setSelectedType(data?.value);
  };

  const handleChangeClass = (data: { label: string; value: string }) => {
    return setSelectedClass(data?.value);
  };

  const onSubmit = (values: any) => {
    if (props?.dataRecord?.type === "create") {
      return handleCreate(values);
    }

    return handleEdit(values);
  };

  const handleCreate = async (values: any) => {
    setIsLoading(true);

    if (!selectedType) {
      return setErrorMessage("You have not selected calculation type");
    }

    if (!selectedClass) {
      return setErrorMessage("You have not selected loan class");
    }

    try {
      const payload = {
        name: values?.name,
        description: values?.description,
        calculation_type: selectedType,
        value: values?.value,
        class: selectedClass,
      };

      const response = await postData(ajaxEndpoints.FEES, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);

    if (!selectedType) {
      return setErrorMessage("You have not selected calculation type");
    }

    if (!selectedClass) {
      return setErrorMessage("You have not selected loan class");
    }

    try {
      const payload = {
        name: values?.name,
        description: values?.description,
        calculation_type: selectedType,
        value: values?.value,
        class: selectedClass,
        id: props?.dataRecord?.oldData?.id,
      };

      const response = await putData(ajaxEndpoints.FEES, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ overflow: "scroll" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 ${styles.Title}`}>
            {props?.dataRecord?.type === "create" ? "Create Fees" : "Edit Fees"}
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <label>Name</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    step="any"
                    name="name"
                    ref={register}
                    defaultValue={props?.dataRecord?.oldData?.name}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <CustomTextArea
                  maxLength={512}
                  reference={register}
                  placeholder="Enter description..."
                  label="Description"
                  name="description"
                  defaultValue={props?.dataRecord?.oldData?.description}
                  showRequiredIcon={true}
                  errors={errors?.description?.message}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>Calculation Type</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={[
                      {
                        label: "Percentage",
                        value: ClassficationType.Percentage,
                      },
                      { label: "Value", value: ClassficationType.Value },
                    ]}
                    defaultValue={{
                      label: props?.dataRecord?.oldData?.calculation_type,
                      value: props?.dataRecord?.oldData?.calculation_type_id,
                    }}
                    onChange={handleChangeType}
                    isSearchable={false}
                    isMulti={false}
                    placeholder="Select Class"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>Class</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={[
                      { label: "Loan", value: LoanClass.Loan },
                      { label: "Wallet", value: LoanClass.Wallet },
                      { label: "Fixed Deposit", value: LoanClass.FixedDeposit },
                    ]}
                    defaultValue={{
                      label: props?.dataRecord?.oldData?.class,
                      value: props?.dataRecord?.oldData?.class_id,
                    }}
                    onChange={handleChangeClass}
                    isSearchable={false}
                    isMulti={false}
                    placeholder="Select Class"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <label>Value</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    step="any"
                    name="value"
                    ref={register}
                    defaultValue={props?.dataRecord?.oldData?.value}
                  />
                </div>
              </div>
            </div>

            <div className="Form__action-buttons mt-1 mb-3">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Fees;
