import { useState } from "react";
import { Link } from "react-router-dom";
import CustomSelectDropdown from "./../../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../Pagination/Pagination.component";
import { Loader } from "./../../../UI/Loaders/Loaders";
import useAggregatorOnboardingStatus from "../../../custom-hooks/useAggregatorsOnboardingStatus";
import { AGGREGATOR_ONBOARDING_STATUS } from "./../../../helpers/aggregatorOnboardingStatus";

export default function DeclinedRequests() {
  // const [searchString, setSearchString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [count, setCount] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { data, status, error } = useAggregatorOnboardingStatus({
    Page: pageNumber,
    PageSize: size,
    onboardingStatus: `onboardingStatus=7`,
  });

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const resetTable = () => {
    setCount(null);
    setPageNumber(1);
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Declined Onboarding
            Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading declined onboarding requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              declined onboarding requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no declined
              requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Business Name</th>
                  <th>Contact Person</th>
                  <th>Contact Email</th>
                  <th>Request Date</th>
                  <th>Initial Status</th>
                  <th>Subsequent Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request, _id) => {
                    const {
                      aggregatorId,
                      businessName,
                      modifiedDate,
                      contactEmail,
                      contactPerson,
                      onboardingStatus,
                      subsequentOnboardingStatus,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{businessName}</td>
                        <td>{contactPerson}</td>
                        <td>{contactEmail}</td>
                        <td>{modifiedDate?.substring(0, 10)}</td>
                        <td>
                          {onboardingStatus &&
                          AGGREGATOR_ONBOARDING_STATUS[onboardingStatus]
                            ? AGGREGATOR_ONBOARDING_STATUS[onboardingStatus]
                            : onboardingStatus}
                        </td>
                        <td>{subsequentOnboardingStatus}</td>
                        <td>
                          <Link
                            to={`/aggregator-onboarding/${aggregatorId}?declined=true`}
                            className="color-blue"
                          >
                            Review Request
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div>
          {status === "success" && (
            <span>
              You have{" "}
              <span className="font-weight-bold">{count} declined</span>{" "}
              onboarding requests
            </span>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-end align-items-center">
          <div className="input-group mr-3 mt-3" style={{ maxWidth: "200px" }}>
            <input
              type="search"
              className="form-control"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div style={{ width: "150px", marginTop: "17px" }}>
            <CustomSelectDropdown
              onChange={(e) => {
                resetTable();
                setSize(e.target.value);
              }}
              value={size}
            >
              <option value={10000}>All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>
      </div>
      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={count}
        changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
      />
    </div>
  );
}
