import { useSelector } from "react-redux";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { Fragment, useLayoutEffect, useState } from "react";
import { showModal } from "../../../redux/actions/modal";
import { useDispatch } from "react-redux";

const CustomerOfferLetterSlaViewer = ({
  dataRecord: { documentUrl, type, customer_id, id, loan_application_id, offer_letter_detail_id },
  onCloseModal,
}: {dataRecord: any, onCloseModal: () => void}) => {
  const [documentData, setDocumentData] = useState({
    documentUrl: documentUrl,
    type: type,
    isLoading: true,
  });

  const dispatch = useDispatch()

  const updatedDocumentData = useSelector(
    // @ts-ignore
    (state) => state?.documentViewerReducer
  );

  useLayoutEffect(() => {
    if (updatedDocumentData?.documentUrl) {
      setDocumentData({
        documentUrl: updatedDocumentData?.documentUrl,
        type: updatedDocumentData?.type,
        isLoading: updatedDocumentData?.isLoading,
      });
    }
  }, [updatedDocumentData]);

  const handleApproveRejectOfferSla = (action: "approve" | "reject") => {
    dispatch(
      showModal({
        modalIdentifier: "approverejectoffersla",
        dataRecord: {
          action,
          id,
          loan_application_id,
          customer_id,
          offer_letter_detail_id
        },
      })
    );
  };

  const handleApproval = async (action: "approve" | "reject") => {
    handleApproveRejectOfferSla(action)
  }

  const handleReject = async (action: "approve" | "reject") => {
    handleApproveRejectOfferSla(action)
  }

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1"></h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {documentData?.isLoading ? (
              <ButtonLoader />
            ) : (
              <>
                <object
                  type={documentData?.type}
                  data={documentData?.documentUrl}
                  width="1000"
                  height="800"
                  style={{
                    objectFit: "contain",
                  }}
                ></object>
              </>
            )}
          </div>
          <div className="Form__action-buttons m-t-2">
            {<Fragment>
              <button type="button"
                className="btn zoom-element btn login-btn" onClick={() => handleApproval("approve")}>Approve</button>
              <button type="button"
                className="btn zoom-element btn reject_btn" onClick={() => handleReject("reject")}>Reject</button>
            </Fragment>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOfferLetterSlaViewer;
