import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getBusinessSectors = async () => {
  const data = await getData(ajaxEndpoints.GET_BUSINESS_SECTORS);
  return data.data;
};

export default function useBusinessSectors() {
  return useQuery(["getBusinessSectors"], getBusinessSectors, {
    refetchOnWindowFocus: false,
  });
}
