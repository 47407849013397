import moment from "moment";

import style from "./viewComments.module.scss";

const ViewComments = ({ comments }) => {
  return (
    <div className={style.commentsContainer}>
      <div className={style.commentsSection}>
        {comments &&
          comments.length &&
          comments.map((comment, index) => (
            <div key={index} className={style.existingComments}>
              <p>
                {comment?.first_name} {comment?.last_name}
                <span>({comment?.user_type})</span>
                <span>
                  {moment(comment?.datetime_created).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </span>
              </p>
              <p className={style.comments}>{comment?.comment}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ViewComments;
