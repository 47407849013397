import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TResponse = {
  data: {
    data: {
      id: number;
      channel_name: string;
      account_name: string;
      account_number: string;
      bank_name: string;
    }[];
  };
};

const getDisbursementChannelAccount = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.GET_DISBURSEMENT_ACCOUNTS}?disbursement_channel_id=${id}`
  );

  return data;
};

export default function useDisbursementChannelAccount(id: number) {
  return useQuery<TResponse>(
    [id, "getDisbursementChannelAccount"],
    getDisbursementChannelAccount,
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );
}
