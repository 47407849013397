import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getLoansByOtherLenders = async (loanApplicationId: number) => {
  const data = await getData(
    `${ajaxEndpoints.GET_LOANS_BY_OTHER_LENDERS}?loanApplicationId=${loanApplicationId}`
  );

  return data;
};

export default function useLoansByOtherLenders(loanApplicationId: number) {
  return useQuery(
    [loanApplicationId, "loanByOtherLenders"],
    getLoansByOtherLenders,
    {
      refetchOnWindowFocus: false,
    }
  );
}
