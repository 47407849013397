import React, { Fragment, useRef, useState } from "react";
import styles from "./AddDOCAndFileType.module.scss";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import toast from "react-hot-toast";
import FileUploadIcon from "../../../../assets/svg/icons/file-upload-fill.svg";
import { convertToBase64 } from "../../../../helpers/convertToBase64";
import { postData } from "../../../../newApis/dotNetApiMethods";
import * as apiEndpoints from "../../../../api/ajax-endpoints";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { useQueryCache } from "react-query";

interface AddDOCAndFileTypeProps {
  properties: any;
  onCloseModal: any;
}

// interface FileProps {
//   type: string;
//   doc: any;
// }

const AddDOCAndFileType = ({
  properties: {
    modalIdentifier,
    customer_id,
    loan_application_id,
    refetchOfferLetterSLA,
  },
  onCloseModal,
}: AddDOCAndFileTypeProps) => {
  const [fileToUpload, setFileToUpload] = useState<FileList | null>();
  const [fileType, setFileType] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [confirmationTab, setConfirmationTab] = useState<boolean>(false);
  const [documentPayload, setDocumentPayload] = useState({});
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  // const [fileUploading, setFileUploading] = useState<FileProps[]>([]);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFileType(e.target.value);
    // const chosenFileType = e.target.value === "3" ? "Offer Letter" : "SLA"
    // setFileUploading((prev) => [...prev, { type: chosenFileType, doc: "" }]);
  };

  const validateFileToUpload = async (file: any) => {
    if (file && file.length) {
      if (
        file[0].name.endsWith(".pdf") ||
        file[0].name.endsWith(".doc") ||
        file[0].name.endsWith(".docx")
      ) {
        if (!fileType) {
          toast.error("Please select a file type");
        } else {
          setFileToUpload(file);

          const fileBase64 = await convertToBase64(file[0]);

          const documentToUpload = {
            loan_application_id: loan_application_id,
            customer_id: customer_id,
            offer_letter_details: [
              {
                document_type_id: fileType,
                base64_file_string: fileBase64,
                filename_with_extension: file[0].name,
              },
            ],
            comment: "",
          };

          setDocumentPayload(documentToUpload);

          // setFileUploading((prevState) => {
          //   const updatedFileUploads = [...prevState];
          //   updatedFileUploads[prevState.length - 1].doc =
          //     file && file[0];
          //   return updatedFileUploads;
          // });
        }
      } else {
        setFileToUpload(null);
      }
    }
  };

  const resetAllFields = () => {
    setFileToUpload(null);

    setFileType("");
  };

  // const removeDoc = (index: number) => {
  //   setFileUploading((prev) => {
  //     const newArray = prev.slice();
  //     newArray.splice(index, 1);
  //     return newArray;
  //   });
  // };

  const handleUploadDocAndFileType = async () => {
    setLoading(true);

    try {
      const response = await postData(
        apiEndpoints.UPLOAD_DOC_FILETYPE,
        documentPayload
      );
      if (response?.data?.status === true) {
        queryCache.invalidateQueries([
          { customer_id, loan_application_id },
          "fetchOfferAndSLA",
        ]);

        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.data?.message },
            })
          );
        }, 2000);
      } else {
        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "failure", message: response?.data?.message },
            })
          );
        }, 2000);
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setLoading(false);
      resetAllFields();
    }
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <Fragment>
            <h3 className={styles.modal_title}>
              Upload Document And File Type
            </h3>

            {/* <div className={styles.files_container}>
              {properties.status === "Not Sent" &&
                fileUploading.length > 0 &&
                fileUploading.map((fl, index) => (
                  <div key={index} className={styles.file_container}>
                    <h4 className={styles.file_type}>{fl?.type}</h4>
                    {fl?.doc && (
                      <div className={styles.display_icon_doc_name}>
                        <img src={documentIcon} alt="" />
                        <span className={styles.file_name}>{fl?.doc.name}</span>
                      </div>
                    )}
                    <span
                      role="button"
                      aria-label="cancel-button"
                      className={styles.cancel_button}
                      onClick={() => removeDoc(index)}
                    >
                      x
                    </span>
                  </div>
                ))}
            </div> */}
            <div className={styles.doc_upload_container}>
              {!confirmationTab && (
                <div>
                  <div className={styles.doc_type_selector}>
                    <CustomSelectDropdown
                      onChange={handleFileTypeChange}
                      name="file type"
                      value={fileType}
                      className="round"
                    >
                      <option value="">select document type</option>
                      <option value={3}>Offer letter</option>
                      <option value={4}>SLA</option>
                    </CustomSelectDropdown>
                  </div>

                  <div className={styles.upload_box}>
                    <input
                      type="file"
                      className="d-none"
                      ref={fileInput}
                      id="offer-sla-upload-input"
                      accept=".docx, .doc, .pdf" // Accept Word and PDF files only
                      onChange={(e) => {
                        validateFileToUpload(e.target.files);
                      }}
                    />
                    <div>
                      <Fragment>
                        <div className="upload-box-section">
                          <div
                            className="upload-box-icon-border"
                            onDrop={(e) => {
                              e.preventDefault();
                              if (!fileType) {
                                toast.error("Please select a file type");
                              }
                              validateFileToUpload(e.dataTransfer.files);
                            }}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <img src={FileUploadIcon} alt="Upload File" />
                            {!fileToUpload ? (
                              <Fragment>
                                <div className="color-blue font-weight-bold">
                                  Upload File
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  Drag & drop your file here or{" "}
                                  <span
                                    className="color-light-blue"
                                    onClick={() => fileInput.current?.click()}
                                  >
                                    browse
                                  </span>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div className="color-blue font-weight-bold">
                                  {fileToUpload[0].name.length > 50
                                    ? fileToUpload[0].name.substring(0, 50) +
                                      "..."
                                    : fileToUpload[0].name}
                                </div>
                                <div>
                                  {(fileToUpload[0].size / 1024).toFixed(2)}
                                  kb
                                </div>
                                <div
                                  className="color-light-blue"
                                  onClick={resetAllFields}
                                >
                                  <u>Remove</u>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </div>

                  <button
                    className="btn advancly-btn btn-md"
                    disabled={loading || !fileToUpload}
                    onClick={() => {
                      setConfirmationTab(true);
                    }}
                  >
                    Upload Files
                  </button>
                </div>
              )}
              {confirmationTab && (
                <div>
                  <div>
                    <p className="d-flex justify-content-center">
                      {" "}
                      Are you sure you want to Upload File?
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn advancly-btn btn-md"
                        onClick={handleUploadDocAndFileType}
                      >
                        {loading ? "Uploading Files..." : "Yes"}
                      </button>
                      <button
                        className="btn advancly-btn btn-md"
                        onClick={() => onCloseModal()}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default AddDOCAndFileType;
