import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";

type TStatus = "Pending" | "Approved" | "Declined";

export type Tdata = {
  id: number;
  name: string;
  status: TStatus;
  ref_name: string;
  application_date: string;
  approval_date: string;
  repayment_source: string;
  funder_id: number;
  funder_name: string;
  funder_email: string;
  total_proposed_amount: number;
  relationship_manager: string | null;
  status_id: number;
  application_type: string;
  application_type_id: number;
};

type TResponse = {
  data: Tdata[];
  totalCount: number;
};

export type TQuery = {
  Page: number;
  PageSize: number;
  search_value?: string;
  start_date?: string;
  end_date?: string;
  status?: string;
  application_type_id?: string;
};

const getPendingLoanApplicationsMccApprovals = async ({
  Page,
  PageSize,
  search_value,
  start_date,
  end_date,
  status,
  application_type_id,
}: TQuery) => {
  let query = `?Page=${Page}&PageSize=${PageSize}`;
  if (search_value) {
    query = `${query}&search_value=${search_value}`;
  }
  if (start_date) {
    query = `${query}&start_date=${start_date}`;
  }
  if (end_date) {
    query = `${query}&end_date=${end_date}`;
  }
  if (application_type_id) {
    query = `${query}&application_type_id=${Number(application_type_id)}`;
  }
  if (status) {
    query = `${query}&${status}`;
  }

  const data = await getData(
    `${ajaxEndpoints.LOAN_APPLICATION_MCC_APPROVALS}${query}`
  );

  return data;
};

export default function usePendingLoanApplicationsMccApprovals({
  Page,
  PageSize,
  search_value,
  start_date,
  end_date,
  status,
  application_type_id,
}: TQuery) {
  return useQuery<TResponse>(
    [
      {
        Page,
        PageSize,
        search_value,
        start_date,
        end_date,
        status,
        application_type_id,
      },
      "getPendingLoanApplicationsMccApprovals",
    ],
    getPendingLoanApplicationsMccApprovals,
    {
      refetchOnWindowFocus: false,
    }
  );
}
