import React, { Component } from "react";

// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

import { connect } from "react-redux";

// Ajax End Points
// import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";

import ajaxApi from "../../../../../../api/axios-register";

//import Forms
// import ProductCategoryInterface from "./../Product/Forms/ProductCategoryForm/ProductCategoryInterface";
import ViewProducts from "./ViewProducts";

import {
  AggregatorsEmbedProduct,
  FundersProduct,
  modifyProductTableRows,
  reOrderAggregatorRows,
  reOrderFunderProductRows,
  filterDataByColumns,
  modifyAggregatorProductEmbedTableRows,
} from "../../../../../../redux/actions/Product";
import { setToast } from "../../../../../../redux/actions/toast";
import { showModal } from "../../../../../../redux/actions/modal";
import { getDataWithDotNet } from "../../../../../../newApis/dotNetApiMethods";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../../config/appInsights";

// import { Loader } from '../../../../../../../UI/Loaders/Loaders';

class ListofProducts extends Component {
  //State
  state = {
    systemEdit: false,
    loading: false,
    systemForm: {
      productCategoryList: [],
      productCategoryName: "",
    },
    systemFormCopy: {
      productCategoryList: [],
      productCategoryName: "",
    },
    aggregatorTableData: {},
    funderTableData: {},
    loadingAggregatorProduct: false,
    error: "",
  };

  // Life Cycle Hooks
  componentDidMount() {
    this.getAggregatorsEmbedProduct();
    this.getFundersProduct();
  }

  // Component Methods

  editModeHandler = (target) => {
    let selectedForm = target.dataset["form"];

    this.setState({
      [`${selectedForm}Edit`]: true,
    });
  };

  modalHandler = (modalData) => {
    this.props.showModal(modalData);
  };

  inputChangeHandler = (target) => {
    const { name, value, dataset } = target;
    let selectedForm = dataset["form"];

    this.setState({
      [`${selectedForm}FormCopy`]: {
        ...this.state[`${selectedForm}FormCopy`],
        [name]: value,
      },
    });
  };

  cancelUpdateHandler = (target) => {
    let selectedForm = target.dataset["form"];

    this.setState({
      [`${selectedForm}Edit`]: false,
      [`${selectedForm}FormCopy`]: this.state[`${selectedForm}Form`],
    });
  };

  //get all product categories
  populateCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.PRODUCT_CATEGORY_LIST)
      .then((response) => {
        let productCategoryList = [];

        if (response.data.length > 0) {
          productCategoryList = [...response.data];
        } else {
          productCategoryList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }

        this.setState({
          systemEdit: false,
          systemForm: {
            ...this.state.systemForm,
            productCategoryList: [...productCategoryList],
          },
          systemFormCopy: {
            ...this.state.systemFormCopy,
            productCategoryList: [...productCategoryList],
          },
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ListOfProducts.js",
          },
        });
      });
  };

  addCategoryHandler = () => {
    const productCategoryName = {
      productCategoryName: this.state.systemFormCopy.productCategoryName,
    };

    ajaxMethods
      .post(ajaxEndpoints.ADD_PRODUCT_CATEGORY, productCategoryName)
      .then((response) => {
        let message = "";
        if (response.status === 200) {
          message = response.data.message;
          this.props.onSetToast("success", message);
          this.populateCategories();
        } else if (response.status === 400) {
          message = response.data.message;
          this.props.onSetToast("error", message);
        } else {
          this.props.onSetToast(
            "warning",
            "Unable to connect to Advancly services"
          );
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ListOfProducts.js",
          },
        });
        //
        this.props.onSetToast(
          "warning",
          "Unable to connect to Advancly services"
        );
      });
  };

  // get aggregator product
  getAggregatorsEmbedProduct = async () => {
    this.setState({ loadingAggregatorProduct: true });
    try {
      const data = await getDataWithDotNet(
        ajaxEndpoints.GET_ALL_EMBED_PRODUCT_DETAILS
      );

      this.setState({ loadingAggregatorProduct: false });
      const reOrderedTableData = reOrderAggregatorRows(
        AggregatorsEmbedProduct,
        data.data
      );

      const tableName = "aggregator";
      const actions = [
        { action: "view", identifier: tableName },
        { action: "Edit", identifier: tableName },
        { action: "Product Types", identifier: "loanaggregatorproducttype" },
      ];
      const { columns, rows } = reOrderedTableData;
      let newRows = modifyAggregatorProductEmbedTableRows(
        data?.data,
        rows,
        tableName,
        actions,
        this.modalHandler
      );

      let columnFilteredTable = filterDataByColumns(
        {
          columns: columns,
          rows: newRows,
        },

        "exclude",
        ["bank_id"]
      );

      this.setState({ aggregatorTableData: columnFilteredTable });
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ListOfProducts.js",
        },
      });
      this.setState({ aggregatorTableData: null, error: errorHandler(error) });
    }
  };

  getFundersProduct = () => {
    this.setState({ loading: true });

    ajaxApi
      .get(`${ajaxEndpoints.GET_ALL_FUNDERS_PRODUCT}`)
      .then((response) => {
        this.setState({ loading: false });
        if (response.data.status_code === 200) {
          const reOrderedTableData = reOrderFunderProductRows(
            FundersProduct,
            response.data.data
          );

          const tableName = "funders";
          const actions = [{ action: "view", identifier: tableName }];
          const { columns, rows } = reOrderedTableData;

          let newRows = modifyProductTableRows(
            rows,
            tableName,
            actions,
            this.modalHandler
          );
          let columnFilteredTable = {};

          columnFilteredTable = filterDataByColumns(
            {
              columns: columns,
              rows: newRows,
            },
            "exclude",
            [
              "product_code",
              "mapping_id",
              "funder_id",
              "aggregator_id",
              "product_category_id",
            ]
          );

          this.setState({ funderTableData: columnFilteredTable });
        } else {
          this.setState({ funderTableData: {} });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ListOfProducts.js",
          },
        });
        this.setState({ loading: false });

        if (error.response) {
          setToast("error", error.response.data.message);
        }
        this.setState({ funderTableData: {} });
      });
  };

  render() {
    const {
      loading,
      aggregatorTableData,
      funderTableData,
      loadingAggregatorProduct,
      error,
    } = this.state;
    const { showProductsView } = this.props;

    return (
      <div className="row clearfix grid__padding">
        {/*Datatables*/}
        {(() => {
          switch (showProductsView) {
            case "aggregatorProductsView":
              return (
                <div className=" col-sm-12 mt-30">
                  <div className="row">
                    <div className="col-lg-12 col-md-9 col-sm-12">
                      <ViewProducts
                        loading={loadingAggregatorProduct}
                        tableData={aggregatorTableData}
                        error={error}
                        recordName="Aggregator"
                      />
                    </div>
                  </div>
                </div>
              );

            case "funderProductsView":
              return (
                <div className=" col-sm-12 mt-30">
                  <div className="row">
                    <div className="col-lg-12 col-md-9 col-sm-12">
                      <ViewProducts
                        loading={loading}
                        tableData={funderTableData}
                        recordName="Funder"
                        getFundersProduct={this.getFundersProduct}
                      />
                    </div>
                  </div>
                </div>
              );

            default:
              return null;
          }
        })()}
      </div>
    );
  }
}
export default connect(null, { showModal })(ListofProducts);
