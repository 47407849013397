import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getTransactionTrail = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_APPLICATION_TRANSACTION_TRAIL}?loan_application_id=${id}`
  );

  return data;
};

export default function useLoanApplicationTransactionTrail(id: number) {
  return useQuery([id, "getTransactionTrail"], getTransactionTrail, {
    refetchOnWindowFocus: false,
  });
}
