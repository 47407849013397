import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type Tdata = {
  id: number;
  name: string;
  is_active: boolean;
  loan_product_category_id: string;
  pub_date: string;
  loan_product_details: {
    amortization_type: number;
    core_banking_product_id: string;
    core_banking_tenant: string;
    currency: string;
    disbursement_channel_id: number;
    interest_type: number;
    loan_type_id: number;
    maximum_amount: number;
    maximum_interest_rate: number;
    maximum_tenor: number;
    minimum_amount: number;
    minimum_interest_rate: number;
    minimum_tenor: number;
    moratorium_type: number;
    repayment_channel_account_id: number;
    repayment_channel_id: number;
    repayment_frequency?: string;
    repayment_type?: string;
  }[];
};

type TLoanProductResponse = {
  data: {
    data: Tdata[];
    totalCount: number;
    totalPages: number;
  };
};

type TQuery = {
  Page: number;
  PageSize: number;
};

const getLoanProducts = async ({ Page, PageSize }: TQuery) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_PRODUCT}?Page=${Page}&PageSize=${PageSize}`
  );

  return data;
};

export default function useLoanProducts({ Page, PageSize }: TQuery) {
  return useQuery<TLoanProductResponse>(
    [{ Page, PageSize }, "getLoanProducts"],
    getLoanProducts,
    {
      refetchOnWindowFocus: false,
    }
  );
}
