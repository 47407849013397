import * as yup from "yup";

export const editProductSchema = yup.object().shape({
  interest_type_id: yup.string().required("Please select interest type"),
  maximum_tenor: yup.string().required("Please enter maximum tenure"),
  minimum_tenor: yup.string().required("Please select minimum tenure"),
  minimum_interest_rate: yup
    .string()
    .required("Please enter minimum interest rate"),
  maximum_interest_rate: yup
    .string()
    .required("Please enter maximum interest rate"),
  maximum_amount: yup.string().required("Please enter maximum amount"),
  minimum_amount: yup.string().required("Please enter minimum amount"),
  repayment_type: yup.string().required("Please select repayment type"),
  core_banking_tenant: yup
    .string()
    .required("Please select core banking tenant"),
  core_banking_product_id: yup
    .string()
    .required("Please select core banking product"),
  loan_type_id: yup.string().required("Please select loan type"),
  moratorium_type_id: yup.string().required("Please select moratorium type"),
  amortization_type_id: yup
    .string()
    .required("Please select amortization type"),
  disbursement_channel_id: yup
    .string()
    .required("Please select disbursement channel"),
  repayment_channel_id: yup
    .string()
    .required("Please select repayment channel"),
  disbursement_channel_account_id: yup
    .string()
    .required("Please select disbursement channel account"),
});

export const createProductSchema = yup.object().shape({
  interest_type_id: yup.string().required("Please select interest type"),
  maximum_tenor: yup.string().required("Please enter maximum tenure"),
  minimum_tenor: yup.string().required("Please select minimum tenure"),
  minimum_interest_rate: yup
    .string()
    .required("Please enter minimum interest rate"),
  maximum_interest_rate: yup
    .string()
    .required("Please enter maximum interest rate"),
  maximum_amount: yup.string().required("Please enter maximum amount"),
  minimum_amount: yup.string().required("Please enter minimum amount"),
  repayment_type: yup.string().required("Please select repayment frequency"),
  // core_banking_tenant: yup
  //   .string()
  //   .required("Please select core banking tenant"),
  // core_banking_product_id: yup
  //   .string()
  //   .required("Please select core banking product"),
  // loan_type_id: yup.string().required("Please select loan type"),
  moratorium_type_id: yup.string().required("Please select moratorium type"),
  amortization_type_id: yup
    .string()
    .required("Please select amortization type"),
  disbursement_channel_id: yup
    .string()
    .required("Please select disbursement channel"),
  repayment_channel_id: yup
    .string()
    .required("Please select repayment channel"),
  disbursement_channel_account_id: yup
    .string()
    .required("Please select disbursement channel account"),
});
