import { useState } from "react";
import { useDispatch } from "react-redux";
import documentIcon from "../../assets/icons/fontawesome/svgs/regular/document-upload.svg";
import { Loader } from "../../UI/Loaders/Loaders";
import styles from "./PrepareOfferLetter.module.scss";
import { showModal } from "../../redux/actions/modal";
import { Tdata } from "../../custom-hooks/usePendingLoanApplications";
import { useFetchCam } from "../../custom-hooks/useFetchCam";
import useFetchOfferAndSLA from "../../custom-hooks/useFetchOfferAndSLA";
import { LoanOfferDetails } from "../../interfaces/offerLetterResponse";
import useDocumentViewer from "../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { putData, postBlobData } from "../../newApis/dotNetApiMethods";
import * as apiEndpoints from "../../api/ajax-endpoints";
import moment from "moment";
import ConfirmationModal from "../../NewComponents/ConfirmationModal";
import { useQueryCache } from "react-query";
import { PrepareOfferLetterSection } from "../PrepareOfferLetter/PrepareOfferLetter";
import DOMPurify from "dompurify";
import useOfferLetterHistory from "../../custom-hooks/useOfferLetterHistory";
import {
  loanApplicationStatus,
  loanApplicationOfferLetterStatus,
} from "../AutomatedCredit/util";

type TakeAction = "sendDoc" | "approve";

interface DownloadStatus {
  [id: number]: boolean;
}

const OfferLetterComp = (props: any) => {
  const [isLoadingSendOffer, setIsLoadingSendOffer] = useState(false);
  const [isLoadingApproveOffer, setIsLoadingApproveOffer] = useState(false);
  const [actionToTake, setActionToTake] = useState<TakeAction>("sendDoc");
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const { handleViewDocument, handleViewCustomerOfferLetterSla } =
    useDocumentViewer();
  const [isDownloadingDoc, setIsDownloadingDoc] = useState<DownloadStatus>({});
  const queryCache = useQueryCache();

  // Borrower Details
  const {
    funder_name,
    funder_email,
    funder_id,
    ref_name,
    application_date,
    status: loanCurrentStatus,
    relationship_manager,
    status_id: loanCurrentStatusId,
    id,
  } = props.selectedLoanData as Tdata;
  // Get CAM history
  const { data: camHistory, isLoading: isLoadingCamHistory } = useFetchCam({
    id,
  });
  const { data: offerLetterHistory, isLoading: isLoadingofferLetterHistory } =
    useOfferLetterHistory({
      isCurrent: false,
      loanApplicationId: id,
    });
  function createMarkup(html: any) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  // Get Offer letter and SLA
  const { data: OfferLetterSLA, isLoading: isLoadingOfferLetterSLA } =
    useFetchOfferAndSLA({
      customer_id: funder_id,
      loan_application_id: id,
      is_current: true,
    });

  const viewCAMHistory = () => {
    dispatch(
      showModal({
        modalIdentifier: "camdocumenthistory",
        action: "viewcam",
        dataRecord: camHistory,
        isLoadingCamHistory,
      })
    );
  };

  const adddocandfiletype = () => {
    dispatch(
      showModal({
        modalIdentifier: "uploaddocandfiletype",
        customer_id: funder_id,
        loan_application_id: id,
      })
    );
  };

  const uploadofferdocs = (
    document_type_id: number,
    offer_letter_details_id: number
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "uploadofferdocs",
        customer_id: funder_id,
        loan_application_id: id,
        id: OfferLetterSLA?.data?.id,
        document_type_id: document_type_id,
        offer_letter_details_id: offer_letter_details_id,
      })
    );
  };

  const handleViewDoc = (doc_url: string) => {
    if (doc_url) {
      handleViewDocument(false, doc_url);
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve CAM document",
          },
        })
      );
    }
  };

  const handleOfferSLAFromCustomer = (
    doc_url: string,
    offer_letter_detail_id: number
  ) => {
    if (doc_url) {
      handleViewCustomerOfferLetterSla(
        false,
        doc_url,
        OfferLetterSLA?.data.id!,
        id,
        funder_id,
        offer_letter_detail_id
      );
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve CAM document",
          },
        })
      );
    }
  };

  const handleSendOfferSLADoc = async () => {
    setIsLoadingSendOffer(true);

    const reqBody = {
      id: OfferLetterSLA?.data.id,
      loan_application_id: id,
      customer_id: funder_id,
    };

    try {
      const response = await putData(apiEndpoints.SEND_OFFER_SLA, reqBody);

      if (response?.data?.status === true) {
        setIsShowConfirmationModal(false);

        queryCache.invalidateQueries([
          { customer_id: funder_id, loan_application_id: id },
          "fetchOfferAndSLA",
        ]);

        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );

        goBack();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoadingSendOffer(false);
    }
  };

  const handleDeleteOfferLetterSlaModal = (
    offerLetterId: number,
    offerLetterDocumentId: number
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "deleteofferletter",
        dataRecord: {
          offerLetterId,
          offerLetterDocumentId,
          loanApplicationId: id,
          customerId: funder_id,
        },
      })
    );
  };

  const handleDeclinedHistoryModal = () => {
    dispatch(
      showModal({
        modalIdentifier: "declinedofferhistory",
        dataRecord: {
          offerLetterHistory,
          isLoadingofferLetterHistory,
        },
      })
    );
  };

  const handleSendOfferSLADocConfirmation = () => {
    setActionToTake("sendDoc");
    setIsShowConfirmationModal(true);
  };

  const handleOfferSLAApproval = async () => {
    setIsLoadingApproveOffer(true);

    const reqBody = {
      id: OfferLetterSLA?.data.id,
      loan_application_id: id,
      customer_id: funder_id,
    };
    try {
      const response = await putData(apiEndpoints.APPROVE_OFFER, reqBody);
      if (response.data.status === true) {
        setIsShowConfirmationModal(false);

        queryCache.invalidateQueries([
          { customer_id: funder_id, loan_application_id: id },
          "fetchOfferAndSLA",
        ]);

        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );

        goBack();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      setIsShowConfirmationModal(false);

      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoadingApproveOffer(false);
    }
  };

  const handleOfferSLAApprovalConfirmation = () => {
    setActionToTake("approve");
    setIsShowConfirmationModal(true);
  };

  const handleDownloadDoc = async (
    document_file_path: string,
    docId: number
  ) => {
    setIsDownloadingDoc((prevstate) => ({ ...prevstate, [docId]: true }));
    const payload = {
      loan_application_id: id,
      document_file_path: document_file_path,
    };
    try {
      const response = await postBlobData(apiEndpoints.DOWNLOAD_CAM, payload);

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Doc_${id}_${moment()
            .format("l")
            .replaceAll("/", "_")}.${document_file_path}`
        );
        link.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message ?? "Unable to download document",
            },
          })
        );
      }
    } catch (error: any) {
      // appInsights.trackException({
      //   exception: error,
      //   properties: {
      //     fileName: "CreditAnalysis.tsx",
      //   },
      // });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message:
              error?.response?.data?.message ?? "Unable to download document",
          },
        })
      );
    }
    setIsDownloadingDoc((prevstate) => ({ ...prevstate, [docId]: false }));
  };

  const goBack = () => {
    props.setCurrentSection(PrepareOfferLetterSection.PendingLoans);
  };

  return (
    <div className="speed-1x animated fadeInRight">
      <div className={styles.borrower_details_container}>
        <div className={styles.borrower_details_header}>
          <h4 className={styles.header_title}>Loan Details</h4>
          <button className={styles.download_cam_btn} onClick={viewCAMHistory}>
            View CAM
          </button>
        </div>
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  <th>Reference</th>
                  <th>Application Date</th>
                  <th>Status</th>
                  <th>Relationship Manger’s Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{funder_name}</td>
                  <td>{funder_email}</td>
                  <td>{ref_name}</td>
                  <td>{application_date.substring(0, 10)}</td>
                  <td>{loanCurrentStatus}</td>
                  <td>
                    {relationship_manager === null
                      ? "NIL"
                      : relationship_manager}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr />
        <div className="d-flex justify-content-between">
          <div className="">
            {offerLetterHistory?.length > 0 && (
              <button
                className="btn advancly-btn btn-md"
                onClick={handleDeclinedHistoryModal}
              >
                View Declined Offers History
              </button>
            )}
          </div>
          <div className={styles.offer_letter_status}>
            {OfferLetterSLA?.data?.status !==
              "Advancly Offer Letter Approved" && (
              <button
                className="btn advancly-btn btn-md"
                onClick={adddocandfiletype}
              >
                Add File
              </button>
            )}
            <p className={styles.status_container}>
              Status:{" "}
              <span
                className={`${
                  OfferLetterSLA?.data?.status_id ===
                  loanApplicationOfferLetterStatus.CustomerOfferLetterNotSent
                    ? styles.customer_accepted
                    : OfferLetterSLA?.data?.status_id ===
                      loanApplicationOfferLetterStatus.CustomerOfferLetterAcceptance
                    ? styles.advancly_accepted
                    : styles.document_not_uploaded
                }`}
              >
                {isLoadingOfferLetterSLA || !OfferLetterSLA?.data
                  ? "Document Not Uploaded"
                  : OfferLetterSLA?.data.status}
              </span>
            </p>
          </div>
        </div>

        {isLoadingOfferLetterSLA ? (
          <Loader centered={true} text="Loading Documents..." />
        ) : OfferLetterSLA?.data ? (
          <>
            <div className="table-responsive">
              <table className={styles.doc_table}>
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>S/N</th>
                    <th>Date</th>
                    <th>Document Type</th>
                    <th>Legal Upload</th>
                    {OfferLetterSLA?.data?.offer_letter_details[0]
                      ?.customer_document_url ||
                    OfferLetterSLA?.data?.offer_letter_details[1]
                      ?.customer_document_url ? (
                      <th>Customer Upload</th>
                    ) : null}
                    {OfferLetterSLA?.data?.offer_letter_details[0]
                      ?.customer_document_url ||
                    OfferLetterSLA?.data?.offer_letter_details[1]
                      ?.customer_document_url ? (
                      <th>Approval Status</th>
                    ) : null}
                    {OfferLetterSLA?.data?.customer_comment && (
                      <th>Customer Comment</th>
                    )}
                    {OfferLetterSLA?.data?.rm_comment && <th>RM Comment</th>}
                    {OfferLetterSLA?.data?.offer_letter_details[0]
                      ?.customer_document_url ||
                    OfferLetterSLA?.data?.offer_letter_details[1]
                      ?.customer_document_url ? (
                      <th>Execution</th>
                    ) : null}
                  </tr>
                </thead>
                {OfferLetterSLA?.data?.offer_letter_details.map(
                  (offer: LoanOfferDetails, index: number) => (
                    <tbody key={offer?.id}>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{offer.datetime_created.substring(0, 10)}</td>
                        <td>{offer.document_type}</td>
                        <td>
                          <div className={styles.offer_doc}>
                            <span>
                              {offer?.legal_document_filename?.length > 20
                                ? offer?.legal_document_filename.substring(
                                    0,
                                    20
                                  ) + "..."
                                : offer?.legal_document_filename}
                            </span>
                            <div>
                              <button
                                className={styles.view_offer_btn}
                                onClick={() =>
                                  handleViewDoc(offer?.legal_document_url)
                                }
                                disabled={!offer?.legal_document_url}
                              >
                                View
                              </button>
                              {loanCurrentStatusId ===
                                loanApplicationStatus.APPROVED_BY_MCC && (
                                <button
                                  className={`${styles.delete_offer_btn} `}
                                  onClick={() =>
                                    handleDeleteOfferLetterSlaModal(
                                      OfferLetterSLA?.data?.id,
                                      offer?.id
                                    )
                                  }
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                        {offer?.customer_document_filename && (
                          <td>
                            <div className={styles.offer_doc}>
                              <span>
                                {offer?.customer_document_filename?.length > 20
                                  ? offer?.customer_document_filename?.substring(
                                      0,
                                      20
                                    ) + "..."
                                  : offer?.customer_document_filename}
                              </span>
                              {offer.approval_status === "Approved" ? (
                                <button
                                  className={styles.view_offer_btn}
                                  onClick={() =>
                                    handleDownloadDoc(
                                      offer?.customer_document_url,
                                      offer?.id
                                    )
                                  }
                                  disabled={isDownloadingDoc[offer?.id]}
                                >
                                  {isDownloadingDoc[offer?.id]
                                    ? "Processing..."
                                    : "Download"}
                                </button>
                              ) : (
                                <button
                                  className={styles.view_offer_btn}
                                  onClick={() =>
                                    handleOfferSLAFromCustomer(
                                      offer?.customer_document_url,
                                      offer.id
                                    )
                                  }
                                  disabled={!offer?.customer_document_url}
                                >
                                  View
                                </button>
                              )}
                            </div>
                          </td>
                        )}
                        {offer?.customer_document_url && (
                          <td>{offer.approval_status}</td>
                        )}
                        {OfferLetterSLA?.data?.customer_comment && (
                          <td
                            dangerouslySetInnerHTML={createMarkup(
                              OfferLetterSLA?.data?.customer_comment
                            )}
                          ></td>
                        )}
                        {OfferLetterSLA?.data?.rm_comment && (
                          <td
                            style={{ fontSize: "14px" }}
                            dangerouslySetInnerHTML={createMarkup(
                              OfferLetterSLA?.data?.rm_comment
                            )}
                          ></td>
                        )}
                        {offer?.customer_document_url && (
                          <td>
                            {offer?.legal_signed_document_url ? (
                              <div className={styles.offer_doc}>
                                <span>
                                  {offer?.legal_signed_document_filename
                                    ?.length > 20
                                    ? offer?.legal_signed_document_filename.substring(
                                        0,
                                        20
                                      ) + "..."
                                    : offer?.legal_signed_document_filename}
                                </span>
                                <button
                                  className={styles.view_offer_btn}
                                  onClick={() =>
                                    handleViewDoc(
                                      offer?.legal_signed_document_url
                                    )
                                  }
                                  disabled={!offer?.legal_signed_document_url}
                                >
                                  View
                                </button>
                              </div>
                            ) : (
                              <button
                                className={styles.table_upload_btn}
                                onClick={() =>
                                  uploadofferdocs(
                                    offer.document_type_id,
                                    offer.id
                                  )
                                }
                              >
                                <img src={documentIcon} alt="" />
                                <span>Upload file</span>
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  )
                )}
              </table>
            </div>
            {OfferLetterSLA?.data.status_id ===
              loanApplicationOfferLetterStatus.CustomerOfferLetterNotSent && (
              <button
                className={`btn advancly-btn btn-md ${styles.send_to_customer_btn}`}
                onClick={handleSendOfferSLADocConfirmation}
                disabled={isLoadingSendOffer}
              >
                {isLoadingSendOffer ? "Processing..." : "Send to Customer"}
              </button>
            )}

            {OfferLetterSLA?.data.status_id !==
              loanApplicationOfferLetterStatus.CustomerOfferLetterNotSent && (
              <button
                className={`btn advancly-btn btn-md ${styles.send_to_customer_btn}`}
                onClick={handleOfferSLAApprovalConfirmation}
                disabled={
                  isLoadingApproveOffer ||
                  OfferLetterSLA?.data.status ===
                    "Advancly Offer Letter Approved"
                }
              >
                {isLoadingApproveOffer ? "Processing..." : "Approve Offer"}
              </button>
            )}
          </>
        ) : null}
      </div>

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            actionToTake === "approve"
              ? handleOfferSLAApproval()
              : handleSendOfferSLADoc();
          }}
          isLoading={isLoadingApproveOffer || isLoadingSendOffer}
        />
      )}
    </div>
  );
};

export default OfferLetterComp;
