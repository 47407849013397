import { useState } from "react";

import ToggleTab from "../../../../../NewComponents/ToggleTab/ToggleTab.component";
import NewLoanConditions from "./New";
import ConditionFromSetup from "./FromSetup";

import styles from "./loanspecific.module.scss";

const LoanSpecificConditions = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState("new");

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ overflow: "scroll" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <div className="alignToggleTabItems mb-3">
            <ToggleTab
              text="New"
              id="new"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="From Setup"
              id="setup"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          <div>
            {(() => {
              switch (activeTab) {
                case "new":
                  return (
                    <NewLoanConditions
                      data={props}
                      setSuccessMessage={setSuccessMessage}
                      setErrorMessage={setErrorMessage}
                    />
                  );

                case "setup":
                  return (
                    <ConditionFromSetup
                      data={props}
                      setSuccessMessage={setSuccessMessage}
                      setErrorMessage={setErrorMessage}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanSpecificConditions;
