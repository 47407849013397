import React, { ChangeEvent, useEffect, useState } from "react";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

import styles from "./AggregatorProduct.module.scss";
import { useForm } from "react-hook-form";
import useMoratoriumTypes from "../../../custom-hooks/useMoratoriumTypes";
import useCoreBankingTenantInfo from "../../../custom-hooks/useCoreBankingTenant";
import useLoanTypes from "../../../custom-hooks/useLoanTypes";
import useDisbursementChannels from "../../../custom-hooks/useDisbursementChannels";
import useRepaymentChannels from "../../../custom-hooks/useRepaymentChannels";
import useDisbursementChannelAccount from "../../../custom-hooks/useDisbursementChannelAccount";
import useRepaymentChannelAccount from "../../../custom-hooks/useRepaymentChannelAccount";
import useMifosProductByTenantid from "../../../custom-hooks/useMifosProductByTenantId";
import { yupResolver } from "@hookform/resolvers/yup";
import { TFormData } from "../../../components/LoanProduct/types";
import { formatMoney } from "../../../helpers/formatter";
import {
  repaymentFrequencys,
  scheduleType,
} from "../../../models/product-data-model";

import { createProductSchema } from "../../../components/LoanProduct/loanProductSchema";
import useEmbedAggregators from "../../../custom-hooks/useEmbedAggregators";
import useLoanProductDetailNoQuery from "../../../custom-hooks/useLoanProductDetailNoQuery";
import { appInsights } from "../../../config/appInsights";
import { postData } from "../../../newApis/apiMethods";
import { errorHandler } from "../../../helpers/errorHandler";
import { putData } from "../../../newApis/dotNetApiMethods";

const AggregatorEmbedProductModal = ({
  properties: { dataRecord },
  onCloseModal,
}: {
  properties: {
    dataRecord: any;
  };
  onCloseModal: () => void;
}) => {
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [maximumAmount, setMaximumAmount] = useState(0);
  // const [selectedLoanType, setSelectedLoanType] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedDisbursementChannel, setSelectedDisbursementChannel] =
    useState<number>(0);
  const [selectedRepaymentChannel, setSelectedRepaymentChannel] =
    useState<number>(0);

  const [selectedLoanProductDetail, setSelectedLoanProductDetail] =
    useState<number>();

  const [selectedCoreBankTenant, setSelectedCoreBankTenant] = useState("");

  const { data: moratoriumTypes } = useMoratoriumTypes();
  const { data: coreBankingTenant } = useCoreBankingTenantInfo();
  const { data: loanTypes } = useLoanTypes();
  const { data: disbursementChannels } = useDisbursementChannels();
  const { data: repaymentChannels } = useRepaymentChannels();
  const { data: embedAggregators } = useEmbedAggregators();
  const { data: disbursementChannelAccount } = useDisbursementChannelAccount(
    selectedDisbursementChannel
  );

  const { data: repaymentChannelAccount } = useRepaymentChannelAccount(
    selectedRepaymentChannel
  );
  const { data: loanProductDetailData } = useLoanProductDetailNoQuery();

  const { data: mifosProduct } = useMifosProductByTenantid({
    tenantId: selectedCoreBankTenant,
  });
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    resolver: yupResolver(createProductSchema),
  });

  const handleChangeMinimumAmount = (e: ChangeEvent<HTMLInputElement>) => {
    setMinimumAmount(Number(e.target.value));
  };

  const handleChangeMaximumAmount = (e: ChangeEvent<HTMLInputElement>) => {
    setMaximumAmount(Number(e.target.value));
  };

  const handleDisbursementChannelChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDisbursementChannel(Number(e.target.value));
  };

  const handleRepaymentChannelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedRepaymentChannel(Number(e.target.value));
  };

  const handleLoanProductDetail = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLoanProductDetail(Number(e.target.value));
  };

  const EditOrViewCurrentProduct =
    dataRecord?.existingAggregatorEmbedData?.find(
      ({ id }: { id: number }) =>
        Number(id) === Number(dataRecord?.currentProductId)
    );

  const findCurrentLoanDetail = loanProductDetailData?.data?.data?.find(
    (detail: { id: number }) =>
      Number(selectedLoanProductDetail) === Number(detail?.id)
  );
  const core_banking_tenant = watch("core_banking_tenant");

  useEffect(() => {
    if (core_banking_tenant) {
      setSelectedCoreBankTenant(core_banking_tenant);
    }
  }, [core_banking_tenant]);

  useEffect(() => {
    if (
      dataRecord?.actionType === "view" ||
      dataRecord?.actionType === "Edit"
    ) {
      setValue("minimum_tenor", EditOrViewCurrentProduct?.minimum_tenor);
      setTimeout(() => {
        setValue(
          "loanProductDetail",
          EditOrViewCurrentProduct?.loan_product_detail_id
        );
      }, 3000);

      setTimeout(() => {
        setValue("embedAggregators", EditOrViewCurrentProduct?.aggregator_id);
      }, 2000);
      setValue("maximum_tenor", EditOrViewCurrentProduct?.maximum_tenor);
      setValue("productName", EditOrViewCurrentProduct?.name);
      setValue(
        "minimum_interest_rate",
        EditOrViewCurrentProduct?.minimum_interest_rate
      );
      setValue(
        "maximum_interest_rate",
        EditOrViewCurrentProduct?.maximum_interest_rate
      );
      setValue("minimum_amount", EditOrViewCurrentProduct?.minimum_amount);
      setMinimumAmount(Number(EditOrViewCurrentProduct?.minimum_amount));

      setValue("maximum_amount", EditOrViewCurrentProduct?.maximum_amount);
      setMaximumAmount(Number(EditOrViewCurrentProduct?.maximum_amount));

      setTimeout(() => {
        setSelectedDisbursementChannel(
          Number(EditOrViewCurrentProduct?.disbursement_channel_id)
        );

        setValue(
          "disbursement_channel_id",
          EditOrViewCurrentProduct?.disbursement_channel_id
        );
      }, 2000);

      setTimeout(() => {
        setValue(
          "disbursement_channel_account_id",
          EditOrViewCurrentProduct?.disbursement_channel_account_id
        );
      }, 4000);

      setTimeout(() => {
        setSelectedRepaymentChannel(
          Number(EditOrViewCurrentProduct?.repayment_channel_id)
        );
        setValue(
          "repayment_channel_id",
          EditOrViewCurrentProduct?.repayment_channel_id
        );
      }, 2000);

      setTimeout(() => {
        setValue(
          "repayment_channel_account_id",
          EditOrViewCurrentProduct?.repayment_channel_account_id
        );
      }, 3000);

      setTimeout(() => {
        setValue("loan_type_id", EditOrViewCurrentProduct?.loan_type_id);
      }, 5000);

      setTimeout(() => {
        setValue("currency", EditOrViewCurrentProduct?.currency);
      }, 4000);
      setTimeout(() => {
        setValue(
          "interest_type_id",
          EditOrViewCurrentProduct?.interest_type_id
        );
      }, 4000);
      setTimeout(() => {
        setValue("repayment_type", EditOrViewCurrentProduct?.repayment_type);
      }, 2000);
      setTimeout(() => {
        if (EditOrViewCurrentProduct?.amortization_type_id) {
          let amortization_type_id = Number(
            EditOrViewCurrentProduct?.amortization_type_id
          );
          setValue("amortization_type_id", amortization_type_id);
        }
      }, 2000);
      setTimeout(() => {
        setValue(
          "moratorium_type_id",
          EditOrViewCurrentProduct?.moratorium_type_id
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "core_banking_tenant",
          EditOrViewCurrentProduct?.core_banking_tenant
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "core_banking_product_id",
          EditOrViewCurrentProduct?.core_banking_product_id
        );
      }, 5000);
    }
    //eslint-disable-next-line
  }, [dataRecord?.actionType, EditOrViewCurrentProduct]);

  // Effect when loan detail is changed
  useEffect(() => {
    if (selectedLoanProductDetail && dataRecord?.actionType !== "view") {
      setValue("minimum_tenor", findCurrentLoanDetail?.minimum_tenor);
      setValue("maximum_tenor", findCurrentLoanDetail?.maximum_tenor);
      setValue(
        "minimum_interest_rate",
        findCurrentLoanDetail?.minimum_interest_rate
      );
      setValue(
        "maximum_interest_rate",
        findCurrentLoanDetail?.maximum_interest_rate
      );
      setValue("minimum_amount", findCurrentLoanDetail?.minimum_amount);
      setValue("maximum_amount", findCurrentLoanDetail?.maximum_amount);

      setTimeout(() => {
        setValue("currency", findCurrentLoanDetail?.currency);
      }, 4000);
      setTimeout(() => {
        setValue("interest_type_id", findCurrentLoanDetail?.interest_type_id);
      }, 4000);
      setTimeout(() => {
        setValue("repayment_type", findCurrentLoanDetail?.repayment_type);
      }, 2000);
      setTimeout(() => {
        setSelectedDisbursementChannel(
          Number(findCurrentLoanDetail?.disbursement_channel_id)
        );
        setValue(
          "disbursement_channel_id",
          findCurrentLoanDetail?.disbursement_channel_id
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "disbursement_channel_account_id",
          findCurrentLoanDetail?.disbursement_channel_account_id
        );
      }, 4000);
      setTimeout(() => {
        setSelectedRepaymentChannel(
          Number(findCurrentLoanDetail?.repayment_channel_id)
        );
        setValue(
          "repayment_channel_id",
          findCurrentLoanDetail?.repayment_channel_id
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "repayment_channel_account_id",
          findCurrentLoanDetail?.repayment_channel_account_id
        );
      }, 3000);
      setTimeout(() => {
        setValue(
          "amortization_type_id",
          findCurrentLoanDetail?.amortization_type_id
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "moratorium_type_id",
          findCurrentLoanDetail?.moratorium_type_id
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "core_banking_tenant",
          findCurrentLoanDetail?.core_banking_tenant
        );
      }, 2000);
      setTimeout(() => {
        setValue(
          "core_banking_product_id",
          findCurrentLoanDetail?.core_banking_product_id
        );
      }, 5000);
      setTimeout(() => {
        setValue("loan_type_id", findCurrentLoanDetail?.loan_type_id);
      }, 5000);
    }

    //eslint-disable-next-line
  }, [selectedLoanProductDetail, findCurrentLoanDetail]);

  const onSubmit = async (values: TFormData) => {
    const {
      core_banking_product_id,
      core_banking_tenant,
      loanProductDetail,
      disbursement_channel_account_id,
      disbursement_channel_id,
      embedAggregators,
      loan_type_id,
      maximum_amount,
      maximum_interest_rate,
      maximum_tenor,
      minimum_amount,
      minimum_interest_rate,
      minimum_tenor,
      maximum_running_loans,
      productName,
      repayment_channel_account_id,
      repayment_channel_id,
      interest_type_id,
      amortization_type_id,
      repayment_type,
      moratorium_type_id,
      moratorium_duration,
    } = values;
    const reqBody = {
      aggregator_id: Number(embedAggregators),
      loan_product_detail_id: Number(loanProductDetail),
      name: productName,
      minimum_amount: Number(minimum_amount),
      maximum_amount: Number(maximum_amount),
      minimum_interest_rate: Number(minimum_interest_rate),
      maximum_interest_rate: Number(maximum_interest_rate),
      interest_type_id: Number(interest_type_id),
      minimum_tenor: Number(minimum_tenor),
      maximum_tenor: Number(maximum_tenor),
      disbursement_channel_id: Number(disbursement_channel_id),
      repayment_channel_id: Number(repayment_channel_id),
      disbursement_channel_account_id: disbursement_channel_account_id,
      repayment_channel_account_id: Number(repayment_channel_account_id),
      repayment_type: String(repayment_type),
      moratorium_type_id: Number(moratorium_type_id),
      moratorium_duration: Number(moratorium_duration),
      maximum_running_loans: Number(maximum_running_loans),
      amortization_type_id: Number(amortization_type_id),
      core_banking_tenant: core_banking_tenant,
      core_banking_product_Id: Number(core_banking_product_id),
      loan_type_id: Number(loan_type_id),
    };
    try {
      setLoading(true);
      {
        let responseData;

        if (dataRecord?.actionType === "Edit") {
          responseData = await putData(ajaxEndpoints.EMBED_PRODUCT_DETAIL, {
            ...reqBody,
            id: dataRecord?.currentProductId,
          });
          //@ts-ignore
          setSuccess(responseData?.message);
        } else {
          responseData = await postData(
            ajaxEndpoints.EMBED_PRODUCT_DETAIL,
            reqBody
          );
          setSuccess(responseData?.message);
        }
      }
      setLoading(false);

      setTimeout(() => {
        setSuccess(null);
        onCloseModal();
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "AggregatorProductModal.tsx" },
      });
      setLoading(false);

      setError(errorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown "
      role="document"
      // key={data?.unique_id}
      // className={styles.ProductDetailContainer}
    >
      <div
        className="IssueModal Modal__content "
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body ">
          {success && <p className="alert alert-success">{success}</p>}
          {error && <p className="alert alert-danger">{error}</p>}

          <h3
            className="Modal__title p-3 "
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            Aggregator Embed Products
          </h3>
          <div className="d-flex justify-content-end">
            {/* <i
          className="fas fa-times fa-lg cursor-pointer"
          onClick={() => handleDeleteProductDetail(data?.unique_id)}
        /> */}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row m-t-1">
              <div className="col-xs-4">
                <label>Embed Aggregators</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="embedAggregators"
                    ref={register}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Embed Aggregators</option>
                    {embedAggregators &&
                      embedAggregators?.map(
                        (embedAggregator: {
                          aggregator_id: number;
                          biz_name: number;
                        }) => (
                          <option
                            key={embedAggregator?.aggregator_id}
                            value={embedAggregator?.aggregator_id}
                          >
                            {embedAggregator?.biz_name}
                          </option>
                        )
                      )}
                  </select>
                  <i className="adv-aggregator-icon fas fa-user"></i>{" "}
                  {/* <span className={styles.errorMessage}>
                    {errors?.repayment_channel_id?.message}
                  </span> */}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Loan Product Detail</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="loanProductDetail"
                    ref={register}
                    onChange={handleLoanProductDetail}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Loan Product Detail</option>
                    {loanProductDetailData &&
                      loanProductDetailData?.data?.data?.map(
                        (loanProductDetail: { name: string; id: number }) => (
                          <option
                            key={loanProductDetail.id}
                            value={loanProductDetail.id}
                          >
                            {loanProductDetail?.name}
                          </option>
                        )
                      )}
                  </select>
                  <i className="adv-aggregator-icon fas fa-tag"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Product Name</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="productName"
                    ref={register}
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-book"></i>{" "}
                </div>
              </div>
            </div>
            <div className="row m-t-1">
              <div className="col-xs-4">
                <label>Minimum Tenure</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="minimum_tenor"
                    ref={register}
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.minimum_tenor?.message}
                  </span>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Maximum Tenure</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="maximum_tenor"
                    ref={register}
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.maximum_tenor?.message}
                  </span>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Minimum Interest Rate</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="minimum_interest_rate"
                    ref={register}
                    step="any"
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.minimum_interest_rate?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row m-t-1">
              <div className="col-xs-4">
                <label>Maximum Interest Rate</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="maximum_interest_rate"
                    ref={register}
                    step="any"
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.maximum_interest_rate?.message}
                  </span>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Min Amount {formatMoney(minimumAmount)}</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="minimum_amount"
                    onChange={handleChangeMinimumAmount}
                    ref={register}
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-money"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.minimum_amount?.message}
                  </span>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Max Amount {formatMoney(maximumAmount)}</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="maximum_amount"
                    onChange={handleChangeMaximumAmount}
                    ref={register}
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-money"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.maximum_amount?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Disbursement Channel</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="disbursement_channel_id"
                    ref={register}
                    onChange={handleDisbursementChannelChange}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Disbursement Channel</option>
                    {disbursementChannels &&
                      disbursementChannels?.data.data.map(
                        (_disbursementChannel) => (
                          <option
                            key={_disbursementChannel?.id}
                            value={_disbursementChannel?.id}
                          >
                            {_disbursementChannel?.channel_name}
                          </option>
                        )
                      )}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.disbursement_channel_id?.message}
                  </span>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Disbursement Channel Accounts</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="disbursement_channel_account_id"
                    ref={register}
                    style={
                      dataRecord?.actionType === "view"
                        ? {
                            pointerEvents: "none",
                            backgroundColor: "#f0f0f0",
                          }
                        : { backgroundColor: "" }
                    }
                  >
                    <option value="">
                      {selectedDisbursementChannel
                        ? "Select Disbursement Channel Account"
                        : "Select Disbursement Channel first"}
                    </option>
                    {disbursementChannelAccount &&
                      disbursementChannelAccount?.data?.data.map(
                        (_channelAccount) => (
                          <option
                            key={_channelAccount?.id}
                            value={_channelAccount?.id}
                          >
                            {_channelAccount?.bank_name}/
                            {_channelAccount?.account_number}/
                            {_channelAccount?.account_name}
                          </option>
                        )
                      )}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.disbursement_channel_account_id?.message}
                  </span>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Repayment Channel</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="repayment_channel_id"
                    ref={register}
                    onChange={handleRepaymentChannelChange}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Repayment Channel</option>
                    {repaymentChannels &&
                      repaymentChannels?.data.data.map((_repaymentChannel) => (
                        <option
                          key={_repaymentChannel?.id}
                          value={_repaymentChannel?.id}
                        >
                          {_repaymentChannel?.channel_name}
                        </option>
                      ))}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.repayment_channel_id?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Repayment Channel Account</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="repayment_channel_account_id"
                    ref={register}
                    style={
                      dataRecord?.actionType === "view"
                        ? {
                            pointerEvents: "none",
                            backgroundColor: "#f0f0f0",
                          }
                        : { backgroundColor: "" }
                    }
                  >
                    <option value="">Select Repayment Channel</option>
                    {repaymentChannelAccount &&
                      repaymentChannelAccount?.data.data.map(
                        (_repaymentChannelAccount) => (
                          <option
                            key={_repaymentChannelAccount?.id}
                            value={_repaymentChannelAccount?.id}
                          >
                            {_repaymentChannelAccount?.bank_name}/
                            {_repaymentChannelAccount?.account_number}/
                            {_repaymentChannelAccount?.account_name}
                          </option>
                        )
                      )}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.repayment_channel_account_id?.message}
                  </span>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Maximum Running Loans</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="maximum_running_loans"
                    ref={register}
                    readOnly={dataRecord?.actionType === "view" ? true : false}
                  />
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>

              <div className="col-xs-4">
                <label>Core Banking Product</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="core_banking_product_id"
                    placeholder="Select core banking product"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    ref={register}
                  >
                    <option value="">Select core banking product</option>
                    {mifosProduct &&
                      mifosProduct.length > 0 &&
                      mifosProduct.map((_item) => (
                        <option
                          key={_item?.id}
                          value={_item?.id}
                          data-currency={_item?.currency_code}
                        >
                          {_item?.name}
                        </option>
                      ))}
                  </select>
                  <span className={styles.errorMessage}>
                    {errors?.core_banking_product_Id?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Core Banking Tenant</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="core_banking_tenant"
                    placeholder="Select tenant"
                    ref={register}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Core Banking Tenant</option>
                    {coreBankingTenant &&
                      coreBankingTenant?.data.map(
                        (_tenant: {
                          id: number;
                          tenant_id: string;
                          full_name: string;
                        }) => (
                          <option key={_tenant?.id} value={_tenant?.tenant_id}>
                            {_tenant?.full_name}
                          </option>
                        )
                      )}
                  </select>
                  <span className={styles.errorMessage}>
                    {errors?.core_banking_tenant?.message}
                  </span>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Loan Type </label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="loan_type_id"
                    ref={register}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Loan Type</option>
                    {loanTypes &&
                      loanTypes?.data?.data.map((_loanType) => (
                        <option key={_loanType?.id} value={_loanType?.id}>
                          {_loanType?.name}
                        </option>
                      ))}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.loan_type_id?.message}
                  </span>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Moratorium Duration</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="moratorium_duration"
                    ref={register}
                  />
                  <i className="adv-aggregator-icon fas fa-money"></i>{" "}
                </div>
              </div>

              <div className="col-xs-4">
                <label>Repayment Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="repayment_type"
                    ref={register}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Repayment Type</option>
                    {repaymentFrequencys.map((_repaymentFrequency) => (
                      <option
                        key={_repaymentFrequency?.repayment_Type}
                        value={_repaymentFrequency?.repayment_Type}
                      >
                        {_repaymentFrequency?.repaymentFrequencyName}
                      </option>
                    ))}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.repayment_frequency?.message}
                  </span>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Currency</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="currency"
                    ref={register}
                    readOnly
                  />
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Moratorium Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="moratorium_type_id"
                    ref={register}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Moratorium Type</option>
                    {moratoriumTypes &&
                      moratoriumTypes.data?.data.map((_moratoriumType) => (
                        <option
                          key={_moratoriumType?.id}
                          value={_moratoriumType?.id}
                        >
                          {_moratoriumType?.name}
                        </option>
                      ))}
                  </select>
                  <span className={styles.errorMessage}>
                    {errors?.moratorium_type?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row m-t-2"></div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Amortization Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="amortization_type_id"
                    ref={register}
                    disabled={dataRecord?.actionType === "view" ? true : false}
                  >
                    <option value="">Select Amortization type</option>
                    <option value={0}>Equal Principal Payments</option>
                    <option value={1}>Equal Installments</option>
                  </select>
                  <span className={styles.errorMessage}>
                    {errors?.amortization_type?.message}
                  </span>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Interest Type </label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="interest_type_id"
                    disabled={dataRecord?.actionType === "view" ? true : false}
                    ref={register}
                  >
                    <option value="">Select Interest type</option>
                    {scheduleType.map((_item) => (
                      <option
                        key={_item?.schedule_Type}
                        value={_item?.schedule_Type}
                      >
                        {_item?.scheduleTypeName}
                      </option>
                    ))}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                  <span className={styles.errorMessage}>
                    {errors?.interest_type?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              {(dataRecord?.actionType === "Edit" ||
                dataRecord?.actionType === null) && (
                <button
                  className="btn advancly-btn btn-sm m-0 transition-3d-hover"
                  disabled={loading}
                  type="submit"
                >
                  <i className="fas fa-save mr-3" />
                  {loading ? "Loading..." : " Save Embed Product"}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AggregatorEmbedProductModal;
