import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export type Tdata = {
  class: string;
  covenant: string;
  id: number;
  type: string;
};

type TResponse = {
  data: {
    data: Tdata[];
  };
};

const getConvenants = async () => {
  const data = await getData(ajaxEndpoints.GET_CONVENANTS);

  return data;
};

export default function useConvenants() {
  return useQuery<TResponse>("getConvenants", getConvenants, {
    refetchOnWindowFocus: false,
  });
}
