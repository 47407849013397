import { useEffect, useState } from "react";

import { DataType, ILoanInformation } from "./util";
import LoanRequesTable from "./Components/LoanRequestTable";
import OtherLendersTable from "./Components/OtherLendersTable";
import TotalIndebtednessTable from "./Components/TotalndebtednessTable";
import AdvanclyLoansTable from "./Components/AdvanclyLoansTable";
import CurrencyTable from "./Components/CurrencyTable";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";

const LoanRequest = ({ data, refetchData }: ILoanInformation) => {
  const [loanInformationDetails, setLoanInformationDetails] =
    useState<DataType[]>();
  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );
  useEffect(() => {
    if (data) {
      setLoanInformationDetails(data?.data?.data.loan_application_details);
    }
  }, [data]);

  return (
    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <LoanRequesTable
        loanInformationDetails={loanInformationDetails}
        refetchData={refetchData}
        data={data}
      />

      <AdvanclyLoansTable loanApplication={data} />

      {loggedInUser?.roles &&
        loggedInUser?.roles?.length > 0 &&
        loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_APPRAISAL) && (
          <OtherLendersTable />
        )}

      <TotalIndebtednessTable refetchData={refetchData} />

      <CurrencyTable />
    </div>
  );
};

export default LoanRequest;
