import { useState } from "react";
import { Dropdown, Table } from "antd";
import TableWrapper from "../../../CreditAnalysis/Components/TableWrapper";

import { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";
import { useLocation } from "react-router-dom";
import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { addSerialNumber } from "../../../CreditAnalysis/util";

import styles from "./feedback.module.scss";
import { loanApplicationStatus } from "../../../util";

export type TFeedback = {
  comments: TFeedbackComments[];
  date_created: string;
  feedback: string;
  feedback_status: string;
  feedback_status_id: number;
  id: number;
  type: string;
  type_id: string;
  user: string;
  user_id: number;
};

export type TFeedbackComments = {
  comment: string;
  date_created: string;
  document_file_name: string;
  document_url: string;
  id: number;
  user: string;
  user_id: number;
  user_type: string;
  user_type_id: number;
};

const Feedback = ({ data: loanApplication, refetchData }: any) => {
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [error, setError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();

  const {
    data: {
      data: { feedbacks, status_id },
    },
  } = loanApplication;

  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
  };

  const items: ItemType[] = [
    {
      key: "add_comment",
      label: "Add Comment",
    },
    { key: "view_comment", label: "View Comments" },
  ];

  const columns: ColumnsType<TFeedback> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "feedback_status",
      key: "feedback_status",
      align: "center",
    },
    {
      title: " feedback message",
      dataIndex: "feedback",
      key: "feedback",
      align: "center",
    },
    {
      title: "Date",
      key: "date_created",
      dataIndex: "date_created",
      align: "center",
      render: (_, _data) => <span>{_data?.date_created.split("T")[0]}</span>,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          disabled={
            status_id ===
            loanApplicationStatus?.OFFER_LETTER_DECLINED_BY_CUSTOMER
          }
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "add_comment" && !state?.isViewOnly) {
                handleAddComment(_data);
              }

              if (key === "view_comment") {
                handleViewComments(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];

  const handleAddComment = (data: TFeedback) => {
    dispatch(
      showModal({
        modalIdentifier: "addfeedbackcomment",
        dataRecord: {
          refetchData,
          data,
          action: "create",
        },
      })
    );
  };

  const handleViewComments = (_data: TFeedback) => {
    dispatch(
      showModal({
        modalIdentifier: "viewfeedbackcomment",
        dataRecord: {
          refetchData,
          data: _data,
        },
      })
    );
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      {/* <div className="d-flex justify-content-end w-100 pr-3 mb-3">
        <button
          className="btn advancly-btn btn-md m-0"
          onClick={handleAddConditions}
        >
          Add Feedback
        </button>
      </div> */}
      <div className="col-sm-12">
        <TableWrapper>
          <Table
            columns={columns}
            dataSource={addSerialNumber(feedbacks)}
            pagination={false}
            rowKey={(record) => record?.id}
          />
        </TableWrapper>
      </div>
    </div>
  );
};

export default Feedback;
