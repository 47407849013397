import useAnAggregatorOnboardingParameters from "../../../../../custom-hooks/useAnAggregatorOnboardingParameters";
import SeniorManagement from "../../../../../components/AggregatorOnboardingReview/Tabs/SeniorManagement/SeniorManagement";
import { Loader } from "../../../../../UI/Loaders/Loaders";

import styles from "./ownershipreview.module.scss";

const GovernanceReview = (props: any) => {
  const {
    data: aggregatorOnboardingDetails,
    status,
    error,
  } = useAnAggregatorOnboardingParameters({
    aggregator_id: props?.dataRecord?.data,
    productType: "",
    documentOwnerType: "",
  });

  let RenderedComponent = null;
  if (!aggregatorOnboardingDetails && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Senior Management
            Information
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Senior Management Information..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Senior management information.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <SeniorManagement
          isFromCreditAnalysis
          seniorManagement={
            aggregatorOnboardingDetails &&
            aggregatorOnboardingDetails?.senior_managements
          }
        />
      );
    }
  }

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div
          className="Modal__body"
          style={{ height: "90vh", overflowY: "scroll" }}
        >
          <h3 className="Modal__title p-b-2">Senior Management</h3>
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default GovernanceReview;
