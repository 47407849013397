import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";

async function fetchCAMData({id}: {id: number}) {
    const { data } = await getData(`/account/loan_applications/${id}/cams`)
    return data
}

export function useFetchCam({id}: {id: number}) {
    // const fallback: CamData[] = []
    return useQuery([{id}, "fetchCAMData"], fetchCAMData, 
        {
            refetchOnWindowFocus: false
        }
    )
}