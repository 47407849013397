import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../../config/appInsights";
import { postData, putData } from "../../../../../../newApis/dotNetApiMethods";
import CustomSearchDropDown from "../../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import CustomTextArea from "../../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "../loanspecific.module.scss";

const schema = yup.object().shape({
  covenant: yup.string().required("Please enter covenant"),
});

const NewLoanConvenants = ({
  data: props,
  setErrorMessage,
  setSuccessMessage,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLoanApplicationDetail, setSelectedLoanApplicationDetail] =
    useState("");

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();
  const state = location?.state as { id: number };

  const handleApplicationRequestChange = (selectedItem: {
    label: string;
    value: string;
  }) => {
    return setSelectedLoanApplicationDetail(selectedItem?.value);
  };

  const {
    dataRecord: {
      data: {
        data: {
          data: { loan_application_details },
        },
      },
    },
  } = props;

  const handleCreate = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = [
        {
          covenant: values?.covenant,
          loanApplicationDetailId: selectedLoanApplicationDetail,
          loanApplicationId: state?.id,
          covenantId: null,
        },
      ];

      const response = await postData(
        ajaxEndpoints.LOAN_APPLICATION_CONVENANTS,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Modals/LoanApplication/LoanSpecificConvenants/New",
        },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = {
        covenant: values?.covenant,
        loanApplicationId: state?.id,
        id: props?.dataRecord?.oldData?.id,
      };

      const response = await putData(
        ajaxEndpoints.LOAN_APPLICATION_CONVENANTS,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "LoanSpecificConvenants/New" },
      });
    }
    setIsLoading(false);
  };

  const onSubmit = (values: any) => {
    if (props?.dataRecord?.type === "create") {
      return handleCreate(values);
    }

    return handleEdit(values);
  };

  const getLoanApplicationDetailList = (
    _listInQuestion:
      | { approved_loan_product_detail: string; id: number }[]
      | undefined
  ) => {
    if (_listInQuestion) {
      const result = _listInQuestion.map((_item) => {
        return {
          label: _item?.approved_loan_product_detail,
          value: _item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const getDefaultLoanApplicationDetail = (
    _listInQuestion:
      | { approved_loan_product_detail: string; id: number }[]
      | undefined,
    loanApplicationDetailId: number
  ) => {
    if (_listInQuestion) {
      const result = _listInQuestion.find(
        (_item) => _item?.id === loanApplicationDetailId
      );

      return {
        label: result?.approved_loan_product_detail,
        value: result?.id,
      };
    }

    return {};
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-sm-12">
          <label>Loan Application Request</label>
          <div className="col-md-12 p-0">
            <CustomSearchDropDown
              defaultOptions={getLoanApplicationDetailList(
                loan_application_details
              )}
              defaultValue={getDefaultLoanApplicationDetail(
                loan_application_details,
                props?.dataRecord?.oldData?.loanApplicationDetailId
              )}
              onChange={handleApplicationRequestChange}
              isSearchable={false}
              isMulti={false}
              placeholder="Select Type"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <CustomTextArea
            maxLength={512}
            reference={register}
            placeholder="Enter Covenant..."
            label="Covenant"
            name="covenant"
            defaultValue={props?.dataRecord?.oldData?.covenant}
            showRequiredIcon={true}
            errors={errors?.covenant?.message}
          />
        </div>
      </div>

      <div className="Form__action-buttons m-t-2">
        <button type="submit" className="btn zoom-element btn login-btn">
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default NewLoanConvenants;
