import React from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler.component";

interface ICustomTextArea {
  name?: string;
  placeholder?: string;
  reference?: any;
  label?: string;
  errors?: object;
  maxLength?: number;
  defaultValue?: string;
  minLength?: number;
  onChange?: (e?: any) => void;
  showRequiredIcon?: boolean;
  extraLabel?: string;
  rows?: number;
  bottomLabel?: string;
  readOnly?: boolean;
  otherLabel?: string;
  disabled?: boolean;
}

export default function CustomTextArea({
  name = "",
  placeholder = "",
  reference = null,
  label = "",
  errors,
  maxLength,
  defaultValue = "",
  minLength,
  onChange,
  showRequiredIcon = false,
  extraLabel,
  rows,
  bottomLabel = "",
  readOnly = false,
  otherLabel = "",
  disabled,
}: ICustomTextArea) {
  return (
    <div className="mb-4">
      <div className="d-flex align-items-center">
        <label className="form-label">{label}</label>
        {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
        {extraLabel && extraLabel.length && (
          <label className="form-label ml-2">
            <small className="crimson">{extraLabel}</small>
          </label>
        )}
        {otherLabel && otherLabel.length && (
          <label className="form-label ml-2">
            <small className="dateText">{otherLabel}</small>
          </label>
        )}
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          name={name}
          placeholder={placeholder}
          ref={reference}
          onKeyPress={enforceMaxLength}
          maxLength={maxLength}
          minLength={minLength}
          defaultValue={defaultValue}
          onChange={onChange}
          rows={rows ? rows : 3}
          readOnly={readOnly}
          disabled={disabled}
        />
        {bottomLabel && bottomLabel.length && !errors && (
          <small>{bottomLabel}</small>
        )}
        <ErrorHandler errors={errors} />
      </div>
    </div>
  );
}
