import { useState } from "react";

import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DownloadUploadedDocument } from "../../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomBadge from "../../../../../Badge/badge";
import LoanDocumentsActionModal from "../../../Components/DocumentsApprovalModal";
import { showModal } from "../../../../../../redux/actions/modal";
import { ReactComponent as MessageIcon } from "../../../../../../assets/svg/icons/message-icon-2.svg";
import useDocumentViewer from "../../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { RefetchOptions } from "react-query/types/core/query";
import { TPendingLoanResponse } from "../../../../../../custom-hooks/usePendingLoanApplicationById";
import CustomOnboardingAccordion from "../../../../../AggregatorOnboardingReview/Components/CustomOnboardingAccordion";
import CustomInputField from "../../../../../CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "./Collateral.module.scss";

interface ICollateral {
  data: TPendingLoanResponse;
  refetchData: (
    options?: RefetchOptions | undefined
  ) => Promise<TPendingLoanResponse | undefined>;
}

export default function Collateral({ data, refetchData }: ICollateral) {
  const [actionData, setActionData] = useState({});

  const {
    data: {
      data: { collateral_information },
    },
  } = data;

  const collateralTypes = {
    INVESTMENT: 1,
    BANK_GUARANTEE: 2,
    EQUITY_CONTRIBUTION: 3,
    LANDED_PROPERTY: 4,
  };

  const { handleViewDocument } = useDocumentViewer();

  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { id } = match.params as any;

  const handleViewComments = (
    e: any,
    existingComments: string,
    type: string,
    itemId: number,
    statusId: number
  ) => {
    e.preventDefault();
    dispatch(
      showModal({
        modalIdentifier: "rmloanapplicationcomments",
        dataRecord: {
          type,
          comments: existingComments,
          itemId,
          loanApplicationId: id,
          refetchData,
          statusId,
        },
      })
    );
  };

  const renderCollateralData = (_item: any) => {
    if (_item?.collateral_type_id === collateralTypes?.BANK_GUARANTEE) {
      return (
        <div className="col-xs-12 col-md-6 col-sm-6">
          <CustomInputField
            type="text"
            label="Bank Name"
            readOnly
            defaultValue={_item?.bank}
          />
        </div>
      );
    } else if (_item?.collateral_type_id === collateralTypes?.INVESTMENT) {
      return (
        <>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Tenure"
              readOnly
              defaultValue={_item?.tenure}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Effective Date"
              readOnly
              defaultValue={_item?.effective_date}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Maturity Date"
              readOnly
              defaultValue={_item?.maturity_date}
            />
          </div>
        </>
      );
    } else if (_item?.collateral_type_id === collateralTypes?.LANDED_PROPERTY) {
      return (
        <>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Forced Sale Value"
              readOnly
              defaultValue={_item?.forced_sale_value}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Market Value"
              readOnly
              defaultValue={_item?.market_value}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="When Last Valued"
              readOnly
              defaultValue={_item?.when_last_valued}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Name of Valuer"
              readOnly
              defaultValue={_item?.name_of_valuer}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Registered Owner"
              readOnly
              defaultValue={_item?.registered_owner}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Location"
              readOnly
              defaultValue={_item?.location}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Developments and use of Land"
              readOnly
              defaultValue={_item?.development_and_use_of_land}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Comments on similar assets"
              readOnly
              defaultValue={_item?.comment_on_asset}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Comments on similar assets"
              readOnly
              defaultValue={_item?.comment_on_asset}
            />
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6">
            <CustomInputField
              type="text"
              label="Available title documents"
              readOnly
              defaultValue={_item?.available_title_document}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <div className="w-100">
          {collateral_information && collateral_information.length > 0 ? (
            collateral_information.map((_item: any, index: number) => (
              <CustomOnboardingAccordion
                key={index}
                title={_item?.collateral_type}
              >
                <div className="row pl-3">
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <CustomInputField
                      type="text"
                      label="Currency"
                      readOnly
                      defaultValue={_item?.currency}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6 col-sm-6">
                    <CustomInputField
                      type="text"
                      label="Value"
                      readOnly
                      defaultValue={_item?.value}
                    />
                  </div>
                </div>

                <div className="row pl-3">{renderCollateralData(_item)}</div>

                <div className={styles.uploadedDocsContainer}>
                  <div>
                    {_item?.documents.map(
                      (uploadedDocument: any, _id: number) => (
                        <div className="row" key={_id}>
                          <div className="col-12 d-flex">
                            <div className="d-flex" key={uuidv4()}>
                              <div
                                className={`ml-3 w-100 ${styles.downloadUploadDocContainer}`}
                              >
                                <DownloadUploadedDocument
                                  name={uploadedDocument.file_name}
                                  pathname={uploadedDocument.upload_path}
                                  handleView={() =>
                                    handleViewDocument(
                                      false,
                                      uploadedDocument?.upload_path
                                    )
                                  }
                                />
                                <CustomTextArea
                                  label="Description"
                                  readOnly
                                  defaultValue={uploadedDocument?.description}
                                />
                              </div>

                              {/* <div className="d-flex ml-5">
                                <div className="d-flex">
                                  {uploadedDocument.approval_status ===
                                    documentApprovalStatusString.DECLINED && (
                                    <button
                                      className="btn advancly-red-btn btn-md"
                                      disabled
                                    >
                                      Declined
                                    </button>
                                  )}
                                  {uploadedDocument.approval_status ===
                                    documentApprovalStatusString.APPROVED && (
                                    <button
                                      className="btn advancly-green-btn btn-md"
                                      disabled
                                    >
                                      Approved
                                    </button>
                                  )}
                                  {(uploadedDocument.approval_status ===
                                    documentApprovalStatusString.APPROVED ||
                                    uploadedDocument.approval_status ===
                                      documentApprovalStatusString.DECLINED) && (
                                    <div
                                      className={styles.commentsAction}
                                      onClick={(e) =>
                                        handleViewComments(e, uploadedDocument)
                                      }
                                    >
                                      <MessageIcon
                                        className={styles.messageIcon}
                                      />
                                      <span className={styles.commentText}>
                                        Comment
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {uploadedDocument.approval_status ===
                                  documentApprovalStatusString.PENDING &&
                                  !isAudit(
                                    [...loggedInUser?.roles],
                                    [...auditRoleList]
                                  ) && (
                                    <>
                                      <button
                                        className="btn advancly-btn btn-md"
                                        data-toggle="modal"
                                        data-target="#loanDocumentsActionModal"
                                        disabled={handleDisableDocumentAction(
                                          loggedInUser
                                        )}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "approve",
                                            data: {
                                              ...uploadedDocument,
                                              funder_id:
                                                data?.data?.data?.funder_id,
                                            },
                                          })
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn advancly-off-2-btn btn-md ml-3"
                                        data-toggle="modal"
                                        data-target="#loanDocumentsActionModal"
                                        disabled={handleDisableDocumentAction(
                                          loggedInUser
                                        )}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "decline",
                                            data: {
                                              ...uploadedDocument,
                                              funder_id:
                                                data?.data?.data?.funder_id,
                                            },
                                          })
                                        }
                                      >
                                        Decline
                                      </button>
                                    </>
                                  )}
                                <span className={styles.documentTimeStamp}>
                                  {moment(uploadedDocument?.uploaded_at).format(
                                    "lll"
                                  )}
                                </span>
                              </div> */}
                            </div>
                          </div>
                          <div>
                            {uploadedDocument?.productTypes &&
                              uploadedDocument.productTypes.map(
                                (_item: any, index: number) => (
                                  <CustomBadge
                                    key={index}
                                    text={_item?.product}
                                  />
                                )
                              )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div
                  className={`${styles.commentsAction} col-sm-4 col-md-4 col-lg-4 `}
                  onClick={(e) =>
                    handleViewComments(
                      e,
                      _item?.comment,
                      "collateral",
                      _item?.id,
                      data?.data?.data?.status_id
                    )
                  }
                >
                  <MessageIcon className={styles.messageIcon} />
                  <span className={styles.commentText}>RM Comments</span>
                </div>
              </CustomOnboardingAccordion>
            ))
          ) : (
            <div className="w-100 d-flex align-items-center justify-content-center py-3">
              <h3 className="font-weight-bold">
                Aggregator is yet to upload Collateral Information
              </h3>
            </div>
          )}
        </div>
      </div>
      <LoanDocumentsActionModal data={actionData} refetchData={refetchData} />
    </div>
  );
}
