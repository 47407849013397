import React, { Fragment, useState } from "react";
import styles from "./CamDocumentHistory.module.scss";
import { postBlobData } from "../../../../newApis/dotNetApiMethods";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { Dropdown } from "antd";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import { ReactComponent as DownloadIcon } from "../../../../assets/svg/icons/download_underline.svg";
import { useDispatch } from "react-redux";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { showModal } from "../../../../redux/actions/modal";
import moment from "moment";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

interface CamDocumentHistoryProps {
  properties: any;
  onCloseModal: any;
}

interface CAM {
  loan_application_id: number;
  created_by_user_name: string;
  date_time_created: string;
  approval_status: string;
  approval_level: string;
  pdf_url: string;
  word_url: string;
}

const CamDocumentHistory = ({
  properties,
  onCloseModal,
}: CamDocumentHistoryProps) => {
  const [isDownloadingCAM, setIsDownloadingCAM] = useState(false);
  const [selectedCamUrl, setSelectedCamUrl] = useState("");
  const { handleViewDocument } = useDocumentViewer();
  const dispatch = useDispatch();

  const {
    dataRecord: { data },
    isLoadingCamHistory,
  } = properties;

  const camFormatItems: ItemType[] = [
    {
      key: "pdf",
      label: "PDF",
    },
    {
      key: "docx",
      label: "WORD",
    },
    {
      key: "view",
      label: "VIEW",
    },
  ];

  const handleDownloadCAM = async (document_file_path: string) => {
    setIsDownloadingCAM(true);
    setSelectedCamUrl(document_file_path);
    const payload = {
      loan_application_id: data[0].loan_application_id,
      document_file_path: document_file_path,
    };
    try {
      const response = await postBlobData(ajaxEndpoints.DOWNLOAD_CAM, payload);

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `CAM_${data[0].loan_application_id}_${moment()
            .format("l")
            .replaceAll("/", "_")}.${document_file_path}`
        );
        link.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message:
                response?.data?.message ?? "Unable to download CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      // appInsights.trackException({
      //   exception: error,
      //   properties: {
      //     fileName: "CreditAnalysis.tsx",
      //   },
      // });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message:
              error?.response?.data?.message ??
              "Unable to download CAM document",
          },
        })
      );
    }
    setIsDownloadingCAM(false);
  };

  const handleViewCAM = (document_file_path: string) => {
    // setIsDownloadingCAM(true);

    // try {
    // const response = await getData(
    //   `/account/loan_applications/${data[0].loan_application_id}/view_cam`
    // );

    const camPath = document_file_path;

    if (camPath) {
      handleViewDocument(false, camPath);
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve CAM document",
          },
        })
      );
    }
    // } catch (error: any) {
    //   appInsights.trackException({
    //     exception: error,
    //     properties: {
    //       fileName: "OfferLetterComp.tsx",
    //     },
    //   });
    //   dispatch(
    //     showModal({
    //       modalIdentifier: "feedback",
    //       dataRecord: {
    //         type: "failure",
    //         message: error?.response?.data?.message ?? "Server Error",
    //       },
    //     })
    //   );
    // }
    // setIsDownloadingCAM(false);
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <Fragment>
            <h3 className={styles.modal_title}>CAM Document History</h3>
            {isLoadingCamHistory ? (
              <p>Loading...</p>
            ) : data.length === 0 ? (
              <p>No available data</p>
            ) : (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Approval Level</th>
                    <th>Download Type</th>
                  </tr>
                </thead>
                {data.map((cam: CAM) => (
                  <tbody key={cam.loan_application_id}>
                    <tr>
                      <td>{cam.created_by_user_name}</td>
                      <td>{cam.date_time_created.substring(0, 10)}</td>
                      <td>{moment(cam.date_time_created).format("hh:mm A")}</td>
                      <td>
                        <span className={styles.pendingStatus}>
                          {cam.approval_status}
                        </span>
                      </td>
                      <td>{cam.approval_level}</td>
                      <td className={styles.cam_button}>
                        <Dropdown
                          trigger={["click"]}
                          menu={{
                            items: camFormatItems,
                            onClick: ({ key }) => {
                              if (key === "pdf") {
                                handleDownloadCAM(cam.pdf_url);
                              }
                              if (key === "docx") {
                                handleDownloadCAM(cam.word_url);
                              }
                              if (key === "view") {
                                handleViewCAM(cam.pdf_url);
                              }
                            },
                          }}
                        >
                          <button className="btn advancly-btn btn-md mr-3">
                            {isDownloadingCAM &&
                            selectedCamUrl === (cam.pdf_url || cam.word_url) ? (
                              <ButtonLoader />
                            ) : (
                              "Download CAM"
                            )}
                            <DownloadIcon className={styles.DownloadIcon} />
                          </button>
                        </Dropdown>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default CamDocumentHistory;
