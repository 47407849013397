import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "./../../NewComponents/CustomHTMLElements/CustomTextArea";
import { convertToBase64 } from "./../../helpers/convertToBase64";
import {
  DownloadUploadedDocument,
  DeleteUploadedDocument,
} from "./../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useAnAggregatorOfferLettersAndSlas from "../../custom-hooks/useAnAggregatorOfferLettersAndSlas";
import { useRouteMatch, useHistory } from "react-router-dom";
import Loader from "../../NewComponents/Loader/Loader.component";
import { postData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "./../../NewComponents/Alert/Alert.component";
import CustomSelectDropdown from "../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import useAggregatorEligibilityDocuments from "./../../custom-hooks/useAggregatorEligibilityDocuments";
import { putDataWithDotNet } from "../../newApis/dotNetApiMethods";
import { v4 as uuidv4 } from "uuid";
import { appInsights } from "../../config/appInsights";
import useDocumentViewer from "../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

const schema = yup.object().shape({
  message: yup
    .string()
    .trim()
    .required("Please enter your message")
    .min(5, "Message must have at least 5 characters")
    .max(256, "Message cannot be longer than 256 characters"),
});

export default function AggregatorOnboardingReviewModal({
  dismissModalRef,
  modalActionType,
  declineRequest,
  loading,
  initialOnboardingCode,
}) {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const [allFiles, setAllFiles] = useState([]);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(null);

  const history = useHistory();

  const { handleViewDocument } = useDocumentViewer();

  const match = useRouteMatch();
  const { id } = match.params;
  const {
    status,
    data,
    error: offerLetterError,
  } = useAnAggregatorOfferLettersAndSlas(id);

  if (data && data.length && !allFiles.length && initialOnboardingCode !== 9) {
    let modifiedFiles = [];
    data.map((eachData) => {
      return eachData.aggregator_documents.map((doc) => {
        return modifiedFiles.push({
          document_field_id: doc.document_id,
          base64_file_string: "",
          admin_upload_path: doc.admin_upload_path,
          filename_with_extension: doc.admin_filename,
          isNewFile: false,
          isEdited: false,
          id: doc.id,
        });
      });
    });
    setAllFiles(modifiedFiles);
  }

  const {
    status: docsStatus,
    data: docsData,
    error: docsError,
  } = useAggregatorEligibilityDocuments({
    isActive: true,
    providerType: 2,
  });

  const onFileChange = async (e, id) => {
    if (!e.target.files[0]) return;
    setError(null);

    const fileName = e.target.files[0].name;

    const isValidFile =
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".doc") ||
      fileName.endsWith(".docx");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }

    const fileBase64 = await convertToBase64(e.target.files[0]);

    // Remove file
    document.getElementById(`file-selector${id}`).value = "";
    let newFiles = [...allFiles];
    newFiles[id].filename_with_extension = fileName;
    newFiles[id].base64_file_string = fileBase64;
    newFiles[id].isEdited = true;
    setAllFiles(newFiles);
  };

  const changeFileType = (e, id) => {
    let newFiles = [...allFiles];
    newFiles[id].document_field_id = Number(e.target.value);
    setAllFiles(newFiles);
  };

  const updateFile = async (file, fileId) => {
    setUpdating(true);
    setError(null);
    setSuccess(null);
    const reqBody = {
      aggregator_documents: [file],
    };
    try {
      await putDataWithDotNet(
        `${ajaxEndpoints.AN_AGGREGATOR_OFFER_LETTERS_AND_SLAS}/${id}`,
        reqBody
      );
      setUpdating(false);
      let newFiles = [...allFiles];
      newFiles[fileId].isNewFile = false;
      newFiles[fileId].isEdited = false;
      setAllFiles(newFiles);
      setSuccess("File has been edited.");
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingReviewModal.component.jsx",
        },
      });
      setUpdating(false);
      setError(errorHandler(error));
    }
  };

  const uploadAllFiles = async (e) => {
    e.preventDefault();
    setUpdating(true);
    setError(null);
    setSuccess(null);
    const reqBody = {
      aggregator_id: Number(id),
    };

    try {
      await postData(`${ajaxEndpoints.ACTIVATE_AGGREGATOR}`, reqBody);
      setUpdating(false);
      dismissModalRef.current.click();
      setSuccess("Aggregator has been approved!");
      setTimeout(() => {
        history.push("/aggregator-onboarding");
      }, 2000);
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "AggregatorOnboardingReviewModal.component.jsx",
        },
      });
      setUpdating(false);
      setError(errorHandler(error));
    }
  };

  const startFileEdit = (document_field_id) => {
    let newFiles = [...allFiles];
    newFiles[document_field_id].isEdited = true;
    setAllFiles(newFiles);
  };

  const addFile = () => {
    setAllFiles([
      ...allFiles,
      {
        filename_with_extension: "",
        base64_file_string: "",
        document_field_id: "",
        isNewFile: true,
        isEdited: false,
        id: uuidv4(),
        admin_upload_path: "",
      },
    ]);
  };

  const removeFile = (id) => {
    setAllFiles(allFiles.filter((file) => file.id !== id));
  };

  const handleCloseModal = () => {
    const modalClassList = document.getElementById(
      "addOnboardingReviewModal"
    ).classList;

    if (!modalClassList.contains("show")) {
      return setAllFiles([]);
    }
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div
        className="modal fade"
        id="addOnboardingReviewModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addOnboardingReviewModalLabel"
        aria-hidden="true"
        onClick={handleCloseModal}
      >
        <div
          className={`modal-dialog ${
            modalActionType === "cancel" && "modal-sm"
          } modal-dialog-centered`}
          role="document"
        >
          <div className="modal-content">
            {(() => {
              switch (modalActionType) {
                case "approve":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="page-subtitle">
                          Approve Onboarding Request
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                          onClick={handleCloseModal}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {status === "loading" || docsStatus === "loading" ? (
                          <div className="d-flex align-items-center justify-content-center w-100 p-5">
                            <Loader />
                          </div>
                        ) : offerLetterError || docsError ? (
                          <div className="d-flex justify-content-center align-items center">
                            <h3 className="color-red">An error occured</h3>
                          </div>
                        ) : (
                          <Fragment>
                            {error && (
                              <p className="alert alert-danger small">
                                {error}
                              </p>
                            )}
                            <p className="text-justify">
                              A mail will be sent to inform this Aggregator once
                              you have accepted their onboarding request. Please
                              attach all necessary documents.
                            </p>
                            <form onSubmit={uploadAllFiles}>
                              <div className="row">
                                <div className="col-12 d-flex justify-content-end flex-wrap">
                                  <button
                                    className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                    type="button"
                                    data-dismiss="modal"
                                    disabled={loading || updating}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="btn advancly-btn btn-sm transition-3d-hover mt-2"
                                    type="submit"
                                    disabled={loading || updating}
                                  >
                                    {loading
                                      ? "Approving..."
                                      : "Approve Request"}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  );
                case "decline":
                  return (
                    <Fragment>
                      <div className="modal-header">
                        <h5 className="page-subtitle">
                          Decline Onboarding Request
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissModalRef}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p className="text-justify">
                          Please add a comment to complete this process. A mail
                          will be sent to inform this Aggregator once you have
                          declined.
                        </p>
                        <form onSubmit={handleSubmit(declineRequest)}>
                          <div className="row">
                            <div className="col-12">
                              <CustomTextArea
                                maxLength="256"
                                reference={register}
                                placeholder="Enter message here..."
                                label="Admin Comment"
                                name="message"
                                showRequiredIcon={true}
                                errors={errors.message}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-end flex-wrap">
                              <button
                                className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                                type="button"
                                data-dismiss="modal"
                                disabled={loading | updating}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn advancly-dimred-btn btn-sm transition-3d-hover mt-2"
                                type="submit"
                                disabled={loading | updating}
                              >
                                {loading ? "Declining..." : "Decline Request"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Fragment>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
