import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";

const getEmbedAggregators = async () => {
  const data = await getData(ajaxEndpoints.EMBEDAGGREGATORS);

  return data?.data;
};

export default function useEmbedAggregators() {
  return useQuery<any>("getEmbedAggregators", getEmbedAggregators, {
    refetchOnWindowFocus: false,
  });
}
