import React, { Fragment, useEffect, useState } from "react";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import usePendingLoanApplicationsById from "../../../custom-hooks/usePendingLoanApplicationById";
import { showModal } from "../../../redux/actions/modal";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/icons/download_underline.svg";
// import { ItemType } from "antd/es/menu/hooks/useItems";
import { ItemType } from "antd/es/menu/interface";

import { useDispatch } from "react-redux";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { postBlobData } from "../../../newApis/dotNetApiMethods";
import moment from "moment";
import useDocumentViewer from "../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

import styles from "./MccPendingLoanFinalScreen.module.scss";
import { useFetchCam } from "../../../custom-hooks/useFetchCam";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../../config/appInsights";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import FinancialInformation from "../../../components/AutomatedCredit/CreditAnalysis/Tabs/FinancialInformation/Documents";

import LoanRequest from "../../AutomatedCredit/CreditAnalysis/Tabs/LoanRequest";
import LoanConditions from "../../AutomatedCredit/CreditAnalysis/Tabs/LoanConditions";
import LoanConvenants from "../../AutomatedCredit/CreditAnalysis/Tabs/LoanConvenants";
import Collateral from "../../AutomatedCredit/ViewLoanApplicationDetails/Tabs/Collateral/Documents";
import Feedback from "../../AutomatedCredit/ViewLoanApplicationDetails/Tabs/Feedback";
import CreditScore from "../../AutomatedCredit/CreditAnalysis/Tabs/CreditScore";
import BusinessInformation from "../../AutomatedCredit/CreditAnalysis/Tabs/BusinessInformation";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import { Dropdown } from "antd";

const MccPendingLoanFinalScreen = () => {
  const [activeTab, setActiveTab] = useState("loan_request");
  //   const [isGeneratingCam, setIsGeneratingCam] = useState(false);
  const [isDownloadingCAM, setIsDownloadingCAM] = useState(false);

  const { handleViewDocument } = useDocumentViewer();

  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
    generatedCamDocx: string;
    generatedCamPdf: string;
  };
  const loanId = state?.id;
  const { data: camHistory, isLoading: isLoadingCamHistory } = useFetchCam({
    id: loanId,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "MccPendingLoanFinalScreen.tsx)",
      isLoggedIn: true,
    });
  }, []);

  //   const handleGenerateCAM = async () => {
  //     setIsGeneratingCam(true);
  //     const payload = {
  //       loan_application_id: state?.id,
  //       // document_format: documentFormat,
  //     };
  //     try {
  //       const response = await postData(ajaxEndpoints.GENERATE_CAM, payload);

  //       if (response.status === 200) {
  //         dispatch(
  //           showModal({
  //             modalIdentifier: "feedback",
  //             dataRecord: {
  //               type: "success",
  //               message: response?.data?.message,
  //             },
  //           })
  //         );

  //         // const blob = response?.data;
  //         // const url = window.URL.createObjectURL(new Blob([blob]));
  //         // const link = document.createElement("a");
  //         // link.href = url;
  //         // link.setAttribute(
  //         //   "download",
  //         //   `CAM_${state?.id}_${moment()
  //         //     .format("l")
  //         //     .replaceAll("/", "_")}.${documentFormat}`
  //         // );
  //         // link.click();
  //       } else {
  //         dispatch(
  //           showModal({
  //             modalIdentifier: "feedback",
  //             dataRecord: {
  //               type: "failure",
  //               message:
  //                 response?.data?.message ?? "Unable to generate CAM document",
  //             },
  //           })
  //         );
  //       }
  //     } catch (error: any) {
  //       appInsights.trackException({
  //         exception: error,
  //         properties: {
  //           fileName: "CreditAnalysis.tsx",
  //         },
  //       });
  //       dispatch(
  //         showModal({
  //           modalIdentifier: "feedback",
  //           dataRecord: {
  //             type: "failure",
  //             message:
  //               error?.response?.data?.message ??
  //               "Unable to retrieve CAM document",
  //           },
  //         })
  //       );
  //     }
  //     setIsGeneratingCam(false);
  //   };

  const handleDownloadCAM = async (documentFormat: string) => {
    setIsDownloadingCAM(true);
    const payload = {
      loan_application_id: state?.id,
      document_file_path:
        documentFormat === "pdf"
          ? state.generatedCamPdf
          : state.generatedCamDocx,
    };
    try {
      const response = await postBlobData(ajaxEndpoints.DOWNLOAD_CAM, payload);

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `CAM_${state?.id}_${moment().format("l").replaceAll("/", "_")}.${
            documentFormat === "pdf"
              ? state.generatedCamPdf
              : state.generatedCamDocx
          }`
        );
        link.click();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message:
                response?.data?.message ?? "Unable to download CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreditAnalysis.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message:
              error?.response?.data?.message ??
              "Unable to download CAM document",
          },
        })
      );
    }
    setIsDownloadingCAM(false);
  };

  const handleViewCAM = async () => {
    setIsDownloadingCAM(true);

    try {
      //   const response = await getData(
      //     `/account/loan_applications/${state?.id}/view_cam`
      //   );
      if (state.generatedCamPdf) {
        handleViewDocument(false, state.generatedCamPdf);
      } else {
        dispatch(
          showModal({
            modalIndentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: "Unable to retrieve CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreditAnalysis.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? "Server Error",
          },
        })
      );
    }
    setIsDownloadingCAM(false);
  };

  const camFormatItems: ItemType[] = [
    {
      key: "pdf",
      label: "PDF",
    },
    {
      key: "docx",
      label: "WORD",
    },
    {
      key: "view",
      label: "VIEW",
    },
  ];

  const actionItems: ItemType[] = [
    {
      key: "approve",
      label: "Approve",
    },
    {
      key: "decline",
      label: "Decline",
    },
    {
      key: "returntoanalyst",
      label: "Return To Analyst",
    },
  ];

  const {
    data: application,
    refetch,
    status,
    error,
  } = usePendingLoanApplicationsById(state?.id);

  const handleSendLoanApplicationBackToAnalyst = () => {
    dispatch(
      showModal({
        modalIdentifier: "transferapplicationcomments",
        dataRecord: {
          refetch,
          action: "returntoanalyst",
        },
      })
    );
  };

  const handleApproveDeclineLoanApplication = (
    action: "approve" | "decline"
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "approvedeclineloanapplication",
        dataRecord: {
          refetch,
          action,
        },
      })
    );
  };

  const handleCAMHistory = (e: any) => {
    e.preventDefault();

    dispatch(
      showModal({
        modalIdentifier: "camdocumenthistory",
        action: "viewcam",
        dataRecord: camHistory,
        isLoadingCamHistory,
      })
    );
  };
  let RenderedComponent = null;
  if (!application && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Loan
            Applications
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Loan Application..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Loan Applications, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div>
          {(() => {
            switch (activeTab) {
              case "loan_request":
                return <LoanRequest data={application} refetchData={refetch} />;
              case "business_information":
                return (
                  <BusinessInformation
                    data={application}
                    refetchData={refetch}
                  />
                );
              case "credit_score":
                return <CreditScore data={application} refetchData={refetch} />;
              case "financial_analysis":
                return (
                  <FinancialInformation
                    data={application}
                    refetchData={refetch}
                  />
                );
              case "conditions":
                return <LoanConditions data={application} />;
              case "convenants":
                return <LoanConvenants data={application} />;
              case "collateral":
                return <Collateral data={application} refetchData={refetch} />;
              case "feedback":
                return <Feedback data={application} refetchData={refetch} />;

              default:
                return null;
            }
          })()}
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> {state?.title}
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {/* <button
            className="btn advancly-btn btn-md mr-3"
            onClick={handleGenerateCAM}
          >
            {isGeneratingCam ? <ButtonLoader /> : "Generate CAM"}
          </button> */}
          <Dropdown
            trigger={["click"]}
            menu={{
              items: camFormatItems,
              onClick: ({ key }) => {
                if (key === "pdf") {
                  handleDownloadCAM("pdf");
                }
                if (key === "docx") {
                  handleDownloadCAM("docx");
                }
                if (key === "view") {
                  handleViewCAM();
                }
              },
            }}
          >
            <button className="btn advancly-btn btn-md mr-3">
              {isDownloadingCAM ? <ButtonLoader /> : "Download CAM"}
              <DownloadIcon className={styles.DownloadIcon} />
            </button>
          </Dropdown>
          {camHistory?.data?.length > 0 && (
            <button
              className="btn advancly-btn btn-md mr-3"
              onClick={(e) => handleCAMHistory(e)}
            >
              {"View CAM History"}
            </button>
          )}
          <Dropdown
            trigger={["click"]}
            menu={{
              items: actionItems,
              onClick: ({ key }) => {
                if (key === "approve") {
                  handleApproveDeclineLoanApplication("approve");
                }

                if (key === "decline") {
                  handleApproveDeclineLoanApplication("decline");
                }

                if (key === "returntoanalyst") {
                  handleSendLoanApplicationBackToAnalyst();
                }
              },
            }}
          >
            <button className="btn advancly-white-btn mr-2">Take Action</button>
          </Dropdown>
        </div>
      </div>

      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Name</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Email</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Reference:</div>
          <div className={styles.content}>
            {application?.data?.data?.ref_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Date</div>
          <div className={styles.content}>
            {application?.data?.data?.application_date.split("T")[0]}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Status</div>
          <div className={styles.content}>
            {application?.data?.data?.status}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Relationship Manager Name</div>
          <div className={styles.content}>
            {application?.data?.data?.relationship_manager.trim()
              ? application?.data?.data?.relationship_manager.trim()
              : "NIL"}
          </div>
        </div>
      </div>

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Loan Request"
          id="loan_request"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Business Information"
          id="business_information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Financial Analysis"
          id="financial_analysis"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Credit Decision"
          id="credit_score"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Conditions"
          id="conditions"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Covenant"
          id="convenants"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Collateral"
          id="collateral"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Approval Feedback"
          id="feedback"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>{RenderedComponent}</div>
    </Fragment>
  );
};

export default MccPendingLoanFinalScreen;
