import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export const getLoanTotalIndebtedness = async (loanApplicationId: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_TOTAL_INDEBTEDNESS}?loanApplicationId=${loanApplicationId}`
  );
  return data;
};

export default function useLoanTotalIndebtedness(loanApplicationId: number) {
  return useQuery(
    [loanApplicationId, "totalIndebtedness"],
    getLoanTotalIndebtedness,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
}
