import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useMoratoriumTypes from "../../../../custom-hooks/useMoratoriumTypes";
import useLoanTypes from "../../../../custom-hooks/useLoanTypes";
import useCoreBankingTenantInfo from "../../../../custom-hooks/useCoreBankingTenant";
import useDisbursementChannels from "../../../../custom-hooks/useDisbursementChannels";
import useRepaymentChannels from "../../../../custom-hooks/useRepaymentChannels";
import {
  repaymentFrequencys,
  scheduleType,
} from "../../../../models/product-data-model";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

import styles from "./LoanProductDetail.module.scss";
import { createProductSchema } from "../../loanProductSchema";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import useDisbursementChannelAccount from "../../../../custom-hooks/useDisbursementChannelAccount";
import useRepaymentChannelAccount from "../../../../custom-hooks/useRepaymentChannelAccount";
import { TFormData } from "../../types";
import { formatMoney } from "../../../../helpers/formatter";
import useMifosProductByTenantid from "../../../../custom-hooks/useMifosProductByTenantId";
import useBankList from "../../../../custom-hooks/useBankList";
import { postData } from "../../../../newApis/apiMethods";
import { appInsights } from "../../../../config/appInsights";
import { errorHandler } from "../../../../helpers/errorHandler";

interface ILoanProductDetail {
  handleDeleteProductDetail: (arg: string) => void;
  data: TFormData;
  productDetailList: TFormData[];
  setProductDetailList: any;
}

const LoanProductDetail = ({
  handleDeleteProductDetail,
  data,
  productDetailList,
  setProductDetailList,
}: ILoanProductDetail) => {
  const [loadingDisbursementName, setLoadingDisbursementName] = useState(false);
  const [disbursementNameError, setDisbursementNameError] =
    useState<string>("");
  const [loadingRepaymentName, setLoadingRepaymentName] = useState(false);
  const [repaymentNameError, setRepaymentNameError] = useState<string>("");
  const [currentDisbursementBank, setCurrentDisbursementBank] =
    useState<string>("");
  const [currentRepaymentBank, setCurrentRepaymentBank] = useState<string>("");
  const [currentDisburesmentVerifiedName, setCurrentDisburesmentVerifiedName] =
    useState("");
  const [currentRepaymentVerifiedName, setCurrentRepaymentVerifiedName] =
    useState("");
  const [repaymentCurrentAccountNumber, setRepaymentCurrentAccountNumber] =
    useState<string>("");
  const [disbursmentCurrentAccountNumber, setDisbursmentCurrentAccountNumber] =
    useState<string>("");
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [maximumAmount, setMaximumAmount] = useState(0);
  const [selectedDisbursementChannel, setSelectedDisbursementChannel] =
    useState<number>(0);
  const [selectedRepaymentChannel, setSelectedRepaymentChannel] =
    useState<number>(0);
  const [coreBankingCurrency, setCoreBankingCurrency] = useState<
    string | undefined
  >("");
  const [selectedCoreBankTenant, setSelectedCoreBankTenant] = useState("");
  const { data: bankListData } = useBankList();
  const { data: moratoriumTypes } = useMoratoriumTypes();
  const { data: coreBankingTenant } = useCoreBankingTenantInfo();
  const { data: loanTypes } = useLoanTypes();
  const { data: disbursementChannels } = useDisbursementChannels();
  const { data: repaymentChannels } = useRepaymentChannels();
  const { data: disbursementChannelAccount } = useDisbursementChannelAccount(
    selectedDisbursementChannel
  );
  const { data: repaymentChannelAccount } = useRepaymentChannelAccount(
    selectedRepaymentChannel
  );
  const { data: mifosProduct } = useMifosProductByTenantid({
    tenantId: selectedCoreBankTenant,
  });

  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(createProductSchema),
  });

  const dispatch = useDispatch();

  const handleChangeMinimumAmount = (e: ChangeEvent<HTMLInputElement>) => {
    return setMinimumAmount(Number(e.target.value));
  };

  const handleChangeMaximumAmount = (e: ChangeEvent<HTMLInputElement>) => {
    return setMaximumAmount(Number(e.target.value));
  };

  const handleDisbursementChannelChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    return setSelectedDisbursementChannel(Number(e.target.value));
  };

  const handleRepaymentChannelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    return setSelectedRepaymentChannel(Number(e.target.value));
  };

  const handleDisbursementBankChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentDisbursementBank(e.target.value);
  };

  const handleRepaymentBankChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentRepaymentBank(e.target.value);
  };

  const validateDisbursementAccount = (e: ChangeEvent<HTMLInputElement>) => {
    setDisbursmentCurrentAccountNumber(e.target.value);
  };
  const validateRepaymentAccount = (e: ChangeEvent<HTMLInputElement>) => {
    setRepaymentCurrentAccountNumber(e.target.value);
  };
  const getDisbursementAccountName = async (
    bankCode: string,
    bankaccountNumber: string
  ) => {
    const reqBody = {
      bank_code: bankCode,
      bank_account_num: bankaccountNumber,
    };

    try {
      setLoadingDisbursementName(true);
      const responseName = await postData(
        ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER,
        reqBody
      );
      setLoadingDisbursementName(false);
      setCurrentDisburesmentVerifiedName(responseName?.bank_account_name);
    } catch (error) {
      setDisbursementNameError(errorHandler(error));

      setTimeout(() => {
        setDisbursementNameError("");
      }, 3000);
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "LoanProductDetail.tsx" },
      });
    }
  };

  const getRepaymentAccountName = async (
    bankCode: string,
    bankaccountNumber: string
  ) => {
    const reqBody = {
      bank_code: bankCode,
      bank_account_num: bankaccountNumber,
    };

    try {
      setLoadingRepaymentName(true);
      const responseName = await postData(
        ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER,
        reqBody
      );
      setLoadingRepaymentName(false);
      setCurrentRepaymentVerifiedName(responseName?.bank_account_name);
    } catch (error) {
      setRepaymentNameError(errorHandler(error));
      setTimeout(() => {
        setRepaymentNameError("");
      }, 3000);
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "LoanProductDetail.tsx" },
      });
    }
  };
  const getRepaymentBankCode = bankListData?.find(
    (bank: { id: any }) => Number(bank.id) === Number(currentRepaymentBank)
  )?.code;
  const getDisbursementBankCode = bankListData?.find(
    (bank: { id: any }) => Number(bank.id) === Number(currentDisbursementBank)
  )?.code;

  useEffect(() => {
    if (
      currentDisbursementBank &&
      disbursmentCurrentAccountNumber.length >= 10
    ) {
      getDisbursementAccountName(
        getDisbursementBankCode,
        disbursmentCurrentAccountNumber
      );
    }
    //eslint-disable-next-line
  }, [currentDisbursementBank, disbursmentCurrentAccountNumber]);
  useEffect(() => {
    if (currentRepaymentBank && repaymentCurrentAccountNumber.length >= 10) {
      getRepaymentAccountName(
        getRepaymentBankCode,
        repaymentCurrentAccountNumber
      );
    }
    //eslint-disable-next-line
  }, [currentRepaymentBank, repaymentCurrentAccountNumber]);

  const handleCoreBankingProductChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    let currency =
      e.currentTarget.options[e.target.selectedIndex].dataset.currency;
    setCoreBankingCurrency(currency);
  };
  const onSubmit = (values: TFormData) => {
    let detailToBeUpdated = productDetailList.find(
      (_item) => _item?.unique_id === data?.unique_id
    );
    if (detailToBeUpdated) {
      let indexOfDataToBeUpdated = productDetailList.indexOf(detailToBeUpdated);

      let newProductDetailList = [...productDetailList];
      newProductDetailList[indexOfDataToBeUpdated] = {
        ...values,
        unique_id: data?.unique_id,
      };

      setProductDetailList(newProductDetailList);
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "success",
            message:
              "Saved. Proceed to add another product detail or go ahead to create product",
          },
        })
      );
    }
  };

  const core_banking_tenant = watch("core_banking_tenant");

  useEffect(() => {
    if (core_banking_tenant) {
      setSelectedCoreBankTenant(core_banking_tenant);
    }
  }, [core_banking_tenant]);

  return (
    <div key={data?.unique_id} className={styles.ProductDetailContainer}>
      <div className="d-flex justify-content-end">
        <i
          className="fas fa-times fa-lg cursor-pointer"
          onClick={() => handleDeleteProductDetail(data?.unique_id)}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-t-1">
          <div className="col-xs-4">
            <label>Minimum Tenure</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="minimum_tenor"
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.minimum_tenor?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Maximum Tenure</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="maximum_tenor"
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_tenor?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Minimum Interest Rate</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="minimum_interest_rate"
                ref={register}
                step="any"
              />
              <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.minimum_interest_rate?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-1">
          <div className="col-xs-4">
            <label>Maximum Interest Rate</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="maximum_interest_rate"
                ref={register}
                step="any"
              />
              <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_interest_rate?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Min Amount {formatMoney(minimumAmount)}</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="minimum_amount"
                onChange={handleChangeMinimumAmount}
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-money"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.minimum_amount?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Max Amount {formatMoney(maximumAmount)}</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="maximum_amount"
                onChange={handleChangeMaximumAmount}
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-money"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_amount?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Disbursement Channel</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursement_channel_id"
                ref={register}
                onChange={handleDisbursementChannelChange}
              >
                <option value="">Select Disbursement Channel</option>
                {disbursementChannels &&
                  disbursementChannels?.data.data.map(
                    (_disbursementChannel) => (
                      <option
                        key={_disbursementChannel?.id}
                        value={_disbursementChannel?.id}
                      >
                        {_disbursementChannel?.channel_name}
                      </option>
                    )
                  )}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.disbursement_channel_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Channel Accounts</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursement_channel_account_id"
                ref={register}
              >
                <option value="">
                  {selectedDisbursementChannel
                    ? "Select Disbursement Channel Account"
                    : "Select Disbursement Channel first"}
                </option>
                {disbursementChannelAccount &&
                  disbursementChannelAccount?.data.data.map(
                    (_channelAccount) => (
                      <option
                        key={_channelAccount?.id}
                        value={_channelAccount?.id}
                      >
                        {_channelAccount?.bank_name}/
                        {_channelAccount?.account_number}/
                        {_channelAccount?.account_name}
                      </option>
                    )
                  )}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.disbursement_channel_account_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Bank Name</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursement_bank_id"
                ref={register}
                onChange={handleDisbursementBankChange}
              >
                <option value="">Select Disbursment Bank Name</option>
                {bankListData &&
                  bankListData?.map((bank: { id: number; name: string }) => (
                    <option key={bank?.id} value={bank?.id}>
                      {bank?.name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Disbursement Account Number</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="disbursement_account_number"
                ref={register}
                onChange={validateDisbursementAccount}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Account Name</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="disbursement_account_name"
                ref={register}
                readOnly
                value={currentDisburesmentVerifiedName}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              {loadingDisbursementName && <span>loading.....</span>}
              <span className={styles.errorMessage}>
                {disbursementNameError}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Repayment Channel</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_channel_id"
                ref={register}
                onChange={handleRepaymentChannelChange}
              >
                <option value="">Select Repayment Channel</option>
                {repaymentChannels &&
                  repaymentChannels?.data.data.map((_repaymentChannel) => (
                    <option
                      key={_repaymentChannel?.id}
                      value={_repaymentChannel?.id}
                    >
                      {_repaymentChannel?.channel_name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.repayment_channel_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Repayment Channel Account</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_channel_account_id"
                ref={register}
              >
                <option value="">Select Repayment Channel</option>
                {repaymentChannelAccount &&
                  repaymentChannelAccount?.data.data.map(
                    (_repaymentChannelAccount) => (
                      <option
                        key={_repaymentChannelAccount?.id}
                        value={_repaymentChannelAccount?.id}
                      >
                        {_repaymentChannelAccount?.bank_name}/
                        {_repaymentChannelAccount?.account_number}/
                        {_repaymentChannelAccount?.account_name}
                      </option>
                    )
                  )}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.repayment_channel_account_id?.message}
              </span>
            </div>
          </div>
        </div>
        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Repayment Bank Name</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_bank_id"
                ref={register}
                onChange={handleRepaymentBankChange}
              >
                <option value="">Select Repayment Bank Name</option>
                {bankListData &&
                  bankListData?.map((bank: { id: number; name: string }) => (
                    <option key={bank?.id} value={bank?.id}>
                      {bank?.name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>

          <div className="col-xs-4">
            <label>Repayment Account Number</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                ref={register}
                name="repayment_account_number"
                onChange={validateRepaymentAccount}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
          <div className="col-xs-4">
            <label>Repayment Account Name</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="repayment_account_name"
                ref={register}
                value={currentRepaymentVerifiedName}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              {loadingRepaymentName && <span>loading.....</span>}
              <span className={styles.errorMessage}>{repaymentNameError}</span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Maximum Running Loans</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="maximum_running_loans"
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>

          <div className="col-xs-4">
            <label>Core Banking Tenant</label>
            <div className="form-group ">
              <select
                className="round user__form form-control"
                name="core_banking_tenant"
                placeholder="Select tenant"
                ref={register}
              >
                <option value="">Select Core Banking Tenant</option>
                {coreBankingTenant &&
                  coreBankingTenant?.data.map(
                    (_tenant: {
                      id: number;
                      tenant_id: string;
                      full_name: string;
                    }) => (
                      <option key={_tenant?.id} value={_tenant?.tenant_id}>
                        {_tenant?.full_name}
                      </option>
                    )
                  )}
              </select>
              <span className={styles.errorMessage}>
                {errors?.core_banking_tenant?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Loan Type </label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="loan_type_id"
                ref={register}
              >
                <option value="">Select Loan Type</option>
                {loanTypes &&
                  loanTypes?.data?.data.map((_loanType) => (
                    <option key={_loanType?.id} value={_loanType?.id}>
                      {_loanType?.name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.loan_type_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Moratorium Duration</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="moratorium_duration"
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-money"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_amount?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Repayment Type</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_type"
                ref={register}
              >
                <option value="">Select Repayment Type</option>
                {repaymentFrequencys.map((_repaymentFrequency) => (
                  <option
                    key={_repaymentFrequency?.repayment_Type}
                    value={_repaymentFrequency?.repayment_Type}
                  >
                    {_repaymentFrequency?.repaymentFrequencyName}
                  </option>
                ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.repayment_frequency?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Core Banking Product</label>
            <div className="form-group ">
              <select
                className="round user__form form-control"
                name="core_banking_product_id"
                placeholder="Select core banking product"
                ref={register}
                onChange={handleCoreBankingProductChange}
              >
                <option value="">Select core banking product</option>
                {mifosProduct &&
                  mifosProduct.length > 0 &&
                  mifosProduct.map((_item) => (
                    <option
                      key={_item?.id}
                      value={_item?.id}
                      data-currency={_item?.currency_code}
                    >
                      {_item?.name}
                    </option>
                  ))}
              </select>
              <span className={styles.errorMessage}>
                {errors?.core_banking_product_Id?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Currency</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="currency"
                readOnly
                value={coreBankingCurrency}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
          <div className="col-xs-4">
            <label>Moratorium Type</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="moratorium_type_id"
                ref={register}
              >
                <option value="">Select Moratorium Type</option>
                {moratoriumTypes &&
                  moratoriumTypes.data?.data.map((_moratoriumType) => (
                    <option
                      key={_moratoriumType?.id}
                      value={_moratoriumType?.id}
                    >
                      {_moratoriumType?.name}
                    </option>
                  ))}
              </select>
              <span className={styles.errorMessage}>
                {errors?.moratorium_type?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Amortization Type</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="amortization_type_id"
                ref={register}
              >
                <option value="">Select Amortization type</option>
                <option value={0}>Equal Principal Payments</option>
                <option value={1}>Equal Installments</option>
              </select>
              <span className={styles.errorMessage}>
                {errors?.amortization_type?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Interest Type </label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="interest_type_id"
                ref={register}
              >
                <option value="">Select Interest type</option>
                {scheduleType.map((_item) => (
                  <option
                    key={_item?.schedule_Type}
                    value={_item?.schedule_Type}
                  >
                    {_item?.scheduleTypeName}
                  </option>
                ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.interest_type?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Repayer Type </label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayerTypeId"
                ref={register}
              >
                <option value="">Select Repayer type</option>
                <option value={1}>Borrower</option>
                <option value={2}> Aggregator</option>
                <option value={3}> Third-party</option>
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.RepayerTypeId?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Type </label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursementTypeId"
                ref={register}
              >
                <option value="">Select Interest type</option>
                <option value={1}>Borrower</option>
                <option value={2}> Aggregator</option>
                <option value={3}> Third-party</option>
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.interest_type?.message}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn advancly-btn btn-sm m-0 transition-3d-hover"
            type="submit"
          >
            <i className="fas fa-save mr-3" />
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoanProductDetail;
