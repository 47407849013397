import { useDispatch } from "react-redux";
import TableAccordion from "../../../../Components/TableAccordion";
import { TextEditor } from "../../../../Components/TextEditor";
import { showModal } from "../../../../../../../redux/actions/modal";

import styles from "./industry.module.scss";

interface IIndustry {
  data: any;
  setBusinessComments: React.Dispatch<
    React.SetStateAction<{
      business_comment: string;
      industry_comment: string;
      ownership_comment: string;
      governance_and_management_comment: string;
      borrower_info: string;
    }>
  >;
  defaultValue: string;
  readOnly: boolean;
}

const Industry = ({
  data,
  setBusinessComments,
  defaultValue,
  readOnly,
}: IIndustry) => {
  const dispatch = useDispatch();

  const handleChange = (data: string) => {
    setBusinessComments((prev) => {
      return {
        ...prev,
        industry_comment: data,
      };
    });
  };
  const handleClick = () => {
    dispatch(
      showModal({
        modalIdentifier: "creditanalysisindutryinformationreview",
        dataRecord: {
          data: data,
        },
      })
    );
  };

  return (
    <div className={styles.Industry}>
      <TableAccordion title={<span className={styles.Title}>INDUSTRY</span>}>
        <TextEditor
          onChange={handleChange}
          defaultValue={defaultValue}
          readOnly={readOnly}
        />
        <div className={styles.ActionBar}>
          <span onClick={handleClick}>View Details</span>
        </div>
      </TableAccordion>
    </div>
  );
};

export default Industry;
