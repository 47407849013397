import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export const getLoanClassification = async () => {
  const { data } = await getData(`${ajaxEndpoints.GET_LOAN_CLASSIFICATIONS}`);
  return data;
};

export default function useLoanClassification() {
  return useQuery("loanClassification", getLoanClassification, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
}
