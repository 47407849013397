import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import CustomInputField from "../../../CustomHTMLElements/CustomInputField";

import useCountryCodes from "./../../../../custom-hooks/useCountryCodes";
import { ROLE_TYPES } from "../../../../helpers/roleTypes";
import useBusinessSectors from "../../../../custom-hooks/useBusinessSectors";
import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { putData } from "../../../../newApis/dotNetApiMethods";
import { showModal } from "../../../../redux/actions/modal";

export default function BusinessInformation({ businessInfo, refetch }) {
  const [state, setState] = useState({
    businessSector: businessInfo?.sector_id,
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const { data } = useCountryCodes();

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);
  const dispatch = useDispatch();

  const { data: businessSectors } = useBusinessSectors();

  const updateBusinessSector = async () => {
    setIsUpdating(true);
    try {
      const response = await putData(ajaxEndpoints.UPDATE_BUSINESS_SECTOR, {
        sector_id: Number(state?.businessSector),
        customer_id: Number(businessInfo?.aggregator_id),
      });

      if (response?.data?.status_code === 200) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetch();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsUpdating(false);
  };

  const handleChangeSector = (e) => {
    setState((prev) => {
      return {
        ...prev,
        businessSector: e.target.value,
      };
    });
  };

  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <form>
          <div className="row">
            <div className="col-12">
              <CustomInputField
                type="text"
                label="Business Name"
                defaultValue={businessInfo?.biz_name}
                readOnly
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="email"
                label="Business Email Address"
                readOnly
                defaultValue={businessInfo?.biz_email}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                label="Business Phone Number"
                readOnly
                defaultValue={businessInfo?.biz_phone?.trim()}
              />
            </div>
            <div className="col-12 col-lg-6">
              <div style={{ marginTop: "-0.24rem" }}>
                <CustomSelectDropdown
                  onChange={(e) => {
                    handleChangeSector(e);
                  }}
                  label="Business Sector"
                  defaultValue={businessInfo?.sector_id}
                  disabled={
                    !loggedInUser?.roles.includes(
                      ROLE_TYPES.LEGAL_ONBOARDING_APPROVER
                    )
                  }
                >
                  {businessSectors &&
                    businessSectors.data.length > 0 &&
                    businessSectors.data.map((_sector) => (
                      <option
                        key={_sector?.category_id}
                        value={_sector?.category_id}
                      >
                        {_sector?.category_name}
                      </option>
                    ))}
                </CustomSelectDropdown>
              </div>

              {loggedInUser?.roles.includes(
                ROLE_TYPES.LEGAL_ONBOARDING_APPROVER
              ) && (
                <button
                  className="btn advancly-btn transition-3d-hover mb-3"
                  type="button"
                  onClick={updateBusinessSector}
                >
                  {isUpdating ? "Updating" : "Update"}
                </button>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                label="What country is your business located?"
                readOnly
                defaultValue={
                  businessInfo?.country_code && data && data.length
                    ? data.find(
                        (country) =>
                          country.country_code === businessInfo?.country_code
                      ).name
                    : businessInfo?.country_code
                }
              />
            </div>
            <div className="col-12">
              <CustomInputField
                type="text"
                label="Business Physical Address"
                readOnly
                defaultValue={businessInfo?.biz_Address}
              />
            </div>
            <div className="col-12">
              <CustomInputField
                type="text"
                label="Business Type"
                readOnly
                defaultValue={businessInfo?.business_type}
              />
            </div>
            <div className="col-12">
              <CustomInputField
                type="text"
                label="Business Registration Number"
                readOnly
                defaultValue={businessInfo?.biz_registration_number}
              />
            </div>
            <div className="col-12">
              <CustomInputField
                type="text"
                label="Business Short Name"
                readOnly
                defaultValue={businessInfo?.biz_short_name}
              />
            </div>
            <div className="col-12">
              <CustomTextArea
                label="What does your business do?"
                readOnly
                defaultValue={businessInfo?.biz_description}
              />
            </div>
            <div className="col-12">
              <CustomTextArea
                label="Who are your customers?"
                readOnly
                defaultValue={businessInfo?.biz_overview}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
