import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export const getAdvanclyIndebtedness = async (loanApplicationId: number) => {
  const { data } = await getData(
    `${ajaxEndpoints.GET_ADVANCLY_INDEBTEDNESS}?loanApplicationId=${loanApplicationId}`
  );
  return data;
};

export default function useAdvanclyIndebtedness(loanApplicationId: number) {
  return useQuery(
    [loanApplicationId, "advanclyIndebtedness"],
    getAdvanclyIndebtedness,
    {
      refetchOnWindowFocus: false,
    }
  );
}
