import { useState } from "react";

import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DownloadUploadedDocument } from "../../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomBadge from "../../../../../Badge/badge";
import LoanDocumentsActionModal from "../../../Components/DocumentsApprovalModal";
import { showModal } from "../../../../../../redux/actions/modal";
import { ReactComponent as MessageIcon } from "../../../../../../assets/svg/icons/message-icon-2.svg";
import useDocumentViewer from "../../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { RefetchOptions } from "react-query/types/core/query";
import { TPendingLoanResponse } from "../../../../../../custom-hooks/usePendingLoanApplicationById";
import CustomTextArea from "../../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "./AggregatorOnboardingReview.module.scss";

interface IFinancialDocuments {
  data: TPendingLoanResponse | undefined;
  refetchData: (
    options?: RefetchOptions | undefined
  ) => Promise<TPendingLoanResponse | undefined>;
}

export default function FinancialDocuments({
  data,
  refetchData,
}: IFinancialDocuments) {
  const [actionData, setActionData] = useState({});

  console.log("dataFinan: ", data);

  const { handleViewDocument } = useDocumentViewer();

  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { id } = match.params as any;

  const handleViewComments = (
    e: any,
    existingComments: string,
    type: string,
    itemId: number,
    statusId: number
  ) => {
    e.preventDefault();
    dispatch(
      showModal({
        modalIdentifier: "rmloanapplicationcomments",
        dataRecord: {
          type,
          comments: existingComments,
          itemId,
          loanApplicationId: id,
          refetchData,
          statusId,
        },
      })
    );
  };

  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <div className="w-100">
          <div className={styles.uploadedDocsContainer}>
            {data &&
            data?.data?.data?.financial_information &&
            data?.data?.data?.financial_information.length ? (
              <div>
                {data?.data?.data?.financial_information.map(
                  (uploadedDocument: any, _id: number) => (
                    <div className="row mb-5" key={_id}>
                      <div className="col-12 d-flex justify-content-between">
                        <label className="form-label">
                          {_id + 1}. {uploadedDocument.financial_type}
                        </label>
                      </div>
                      <div className="col-12 d-flex">
                        <div>
                          {uploadedDocument.documents.length < 1 ? (
                            <small className="text-center">
                              No documents uploaded for this category
                            </small>
                          ) : (
                            uploadedDocument.documents.map((item: any) => (
                              <div className="d-flex" key={uuidv4()}>
                                <div
                                  className={`w-100 ${styles.downloadUploadDocContainer}`}
                                >
                                  <DownloadUploadedDocument
                                    name={item.file_name}
                                    pathname={item.upload_path}
                                    handleView={() =>
                                      handleViewDocument(
                                        false,
                                        item?.upload_path
                                      )
                                    }
                                  />
                                  <CustomTextArea
                                    label="Description"
                                    readOnly
                                    defaultValue={item?.description}
                                  />
                                </div>
                              </div>
                            ))
                          )}
                          <div
                            className={`${styles.commentsAction} col-sm-4 col-md-4 col-lg-4 `}
                            onClick={(e) =>
                              handleViewComments(
                                e,
                                uploadedDocument?.comment,
                                "financial_information",
                                uploadedDocument?.id,
                                data?.data?.data?.status_id
                              )
                            }
                          >
                            <MessageIcon className={styles.messageIcon} />
                            <span className={styles.commentText}>Comment</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        {uploadedDocument?.productTypes &&
                          uploadedDocument.productTypes.map(
                            (item: any, index: number) => (
                              <CustomBadge key={index} text={item?.product} />
                            )
                          )}
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-center py-3">
                <h3 className="font-weight-bold">
                  Aggregator is yet to upload financial documents
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoanDocumentsActionModal data={actionData} refetchData={refetchData} />
    </div>
  );
}
