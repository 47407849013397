import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";

const getBankList = async () => {
  const bankListData = await getData(ajaxEndpoints.GET_BANK_LIST);
  return bankListData?.data;
};

export default function useBankList() {
  return useQuery(["getBankList"], getBankList, {
    refetchOnWindowFocus: false,
  });
}
