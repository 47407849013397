import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export type Tdata = {
  calculation_type: string;
  calculation_type_id: number;
  class: string;
  class_id: number;
  description: string;
  id: number;
  name: string;
  value: number;
};

export type TFees = {
  data: {
    data: Tdata[];
  };
};

const getFees = async () => {
  const data = await getData(ajaxEndpoints.FEES);

  return data;
};

export default function useFees() {
  return useQuery<TFees>("getFees", getFees, {
    refetchOnWindowFocus: false,
  });
}
