import React, { Fragment, useState } from "react";
import styles from "./DeclinedOfferLetterHistory.module.scss";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import moment from "moment";
const DeclinedOfferLetterHistory = ({ dataRecord }: any) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const { handleViewDocument } = useDocumentViewer();
  const dispatch = useDispatch();
  const parser = new DOMParser();
  const handleRowClick = (offerLetterHistoryId: number) => {
    const isRowExpanded = expandedRows.includes(offerLetterHistoryId);
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter((id) => id !== offerLetterHistoryId)
      : [...expandedRows, offerLetterHistoryId];

    setExpandedRows(newExpandedRows);
  };

  const handleViewDoc = (doc_url: string) => {
    if (doc_url) {
      handleViewDocument(false, doc_url);
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve CAM document",
          },
        })
      );
    }
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div
        className={`Modal__content ${styles.declinedOfferLetterModalContent}`}
      >
        <div className="Modal__body">
          <Fragment>
            <h3 className={styles.modal_title}>Declined Offer History</h3>
            {dataRecord.isLoadingofferLetterHistory ? (
              <p>Loading...</p>
            ) : dataRecord.offerLetterHistory.length === 0 ? (
              <p>No available data</p>
            ) : (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>Offer letter code</th>
                    <th>Created By</th>
                    <th>Customer</th>
                    <th>Customer Comment</th>
                    <th>Date Created</th>
                    <th>Time Created</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {dataRecord.offerLetterHistory.map(
                  (offerLetterHistory: any) => {
                    const stringParser = parser.parseFromString(
                      offerLetterHistory.customer_comment,
                      "text/html"
                    );
                    return (
                      <tbody key={offerLetterHistory.id}>
                        <tr>
                          <td>{offerLetterHistory.reference_number}</td>
                          <td>{offerLetterHistory.created_by_user}</td>
                          <td>{offerLetterHistory.customer}</td>
                          <td>{stringParser.body.textContent}</td>
                          <td>
                            {offerLetterHistory.date_time_created.substring(
                              0,
                              10
                            )}
                          </td>

                          <td>
                            {moment(
                              offerLetterHistory.date_time_created
                            ).format("hh:mm A")}
                          </td>
                          <td>
                            <span className={styles.pendingStatus}>
                              {offerLetterHistory.status}
                            </span>
                          </td>

                          <td
                            className={styles.decline_button}
                            onClick={() =>
                              handleRowClick(offerLetterHistory.id)
                            }
                          >
                            {expandedRows.includes(offerLetterHistory.id)
                              ? "View Less"
                              : "View More"}
                          </td>
                        </tr>
                        {expandedRows.includes(offerLetterHistory.id) && (
                          <tr>
                            <td colSpan={9}>
                              <table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>Document Type</th>
                                    <th>Legal Document Name</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {offerLetterHistory?.offer_letter_details?.map(
                                    (detail: {
                                      id: number;
                                      legal_document_filename: string;
                                      document_type: string;
                                      legal_document_url: string;
                                    }) => {
                                      return (
                                        <tr key={detail.id}>
                                          <td>{detail.document_type}</td>
                                          <td>
                                            {detail.legal_document_filename.slice(
                                              0,
                                              30
                                            )}
                                          </td>
                                          <td>
                                            <button
                                              className="btn advancly-btn btn-md mr-3"
                                              onClick={() =>
                                                handleViewDoc(
                                                  detail?.legal_document_url
                                                )
                                              }
                                              disabled={
                                                !detail?.legal_document_url
                                              }
                                            >
                                              View Offer
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    );
                  }
                )}
              </table>
            )}
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default DeclinedOfferLetterHistory;
