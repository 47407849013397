import LoanInformationDetailLog from "./LoanInformationDetailLog/index";

const LoanFeeLog = (props: any) => {
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className="Modal__title mb-5">Loan Application Fee Log</h2>

          <LoanInformationDetailLog
            detailId={props?.dataRecord?.loan_info_data?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default LoanFeeLog;
