import { useState } from "react";
import ApproveDeclineMccApprovalRichtext from "./ApproveDeclineMccApprovalRichtext";
import ApproveDeclineMccApprovalSignature from "./ApproveDeclineMccApprovalSignature";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import { postData } from "../../../../../newApis/apiMethods";
import { useHistory } from "react-router-dom";

interface IapproveDeclineProps {
  dataRecord: {
    action: string;
    loanApplicationId: number;
    camId: number;
  };
  onCloseModal: () => void;
}
const ApproveDeclineMccApprovalModal = (props: IapproveDeclineProps) => {
  const [currentMccApproval, setCurrentMccApproval] = useState(1);
  const [getApprovedRichTextComments, setGetApprovedRichTextComments] =
    useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleApproveDecline = async (
    base64string: string,
    fileName?: string
  ) => {
    setIsLoading(true);
    const payload = {
      loanApplicationId: Number(props?.dataRecord?.loanApplicationId),
      camId: props?.dataRecord?.camId,
      base64SignatureFileString: base64string,
      fileNameWithExtension: fileName ? fileName : "signature.png",
      comment: getApprovedRichTextComments,
    };
    try {
      const response = await postData(
        `${
          props?.dataRecord?.action === "approvemccapproval"
            ? ajaxEndpoints.APPROVE_MCC_APPROVAL
            : ajaxEndpoints.DECLINE_MCC_APPROVAL
        }`,
        payload
      );
      if (response?.status === true) {
        setSuccessMessage(response?.message);
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
          history.push("/MCC-approvals-pending-loan-applications");
        }, 2000);
      } else {
        setErrorMessage(response?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message ?? error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ApproveDeclineMccApprovalModal.tsx" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {(() => {
        switch (currentMccApproval) {
          case 1:
            return (
              <ApproveDeclineMccApprovalRichtext
                props={props}
                setCurrentMccApproval={setCurrentMccApproval}
                setGetApprovedRichTextComments={setGetApprovedRichTextComments}
              />
            );
          case 2:
            return (
              <ApproveDeclineMccApprovalSignature
                successMessage={successMessage}
                errorMessage={errorMessage}
                isLoading={isLoading}
                handleApproveDecline={handleApproveDecline}
              />
            );
          default:
            return;
        }
      })()}
    </div>
  );
};

export default ApproveDeclineMccApprovalModal;
