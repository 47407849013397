import React from "react";
import moment from "moment";

const CreditBureauTableInquiryHistoryDetails = ({
  inquiryHistoryDetails,
}: any) => {
  return (
    <div>
      <h5 className="table-caption">Inquiry History Details</h5>
      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              S/n
            </th>
            <th className="th-td-style th-style" scope="col">
              Facility Type
            </th>
            <th className="th-td-style th-style" scope="col">
              Institution Type
            </th>
            <th className="th-td-style th-style" scope="col">
              Inquiry Date
            </th>
          </tr>
        </thead>
        <tbody>
          {inquiryHistoryDetails?.map((inquiryDetail: any) => {
            const { facilitY_TYPE, inquirY_DATE, institutioN_TYPE, sno } =
              inquiryDetail;
            return (
              <tr key={sno}>
                <td className="th-td-style">{sno}</td>
                <td className="th-td-style">{facilitY_TYPE}</td>
                <td className="th-td-style">{institutioN_TYPE}</td>
                <td className="th-td-style">
                  {" "}
                  {moment(inquirY_DATE).format("DD/MM/YYYY")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableInquiryHistoryDetails;
