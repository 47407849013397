import React, { useEffect, useState } from "react";
import { appInsights } from "../../../config/appInsights";
import { useLocation } from "react-router-dom";
import PreDisbursementCamOfferLetter from "./PreDisbursementCamOfferLetter/PreDisbursementCamOfferLetter";
import PreDisbursementConditions from "./PreDisbursementConditions/PreDisbursementConditions";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import usePredisbursementData from "../../../custom-hooks/usePredisbursementData";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actions/modal";

const PreDisbursementMainMenu = () => {
  const [activeTab, setActiveTab] = useState("predisbursementcamofferletter");
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
    generatedCamDocx: string;
    generatedCamPdf: string;
    customerId: number;
  };
  const {
    data: predisbursementData,
    status: predisbursementStatus,
    error: predisbursementError,
  } = usePredisbursementData({
    loanApplicationId: state?.id,
    customerId: state?.customerId,
  });

  const handleApproveAndDeferPreDisbursement = () => {
    const customerId = state?.customerId;
    const loanId = state?.id;
    dispatch(
      showModal({
        modalIdentifier: "completepredisbursement",
        action: "completepredisbursement",
        dataRecord: { loanId, customerId },
      })
    );
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "PreDisbursementMainMenu.tsx)",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;

  RenderedComponent = (
    <div>
      {(() => {
        switch (activeTab) {
          case "predisbursementcamofferletter":
            return (
              <PreDisbursementCamOfferLetter
                predisbursementData={predisbursementData}
                loanApplicationId={state?.id}
                predisbursementStatus={predisbursementStatus}
                predisbursementError={predisbursementError}
              />
            );
          case "predisbursementconditions":
            return (
              <PreDisbursementConditions
                loanApplicationId={state?.id}
                customerId={state?.customerId}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );

  return (
    <div>
      <h3 className="page-title d-flex align-items-center mb-3">
        <BackButtonComponent /> Pre Disbursement Menu
      </h3>

      <div className="my-3 d-flex justify-content-end">
        <button
          className="create btn advancly-btn mr-2"
          onClick={handleApproveAndDeferPreDisbursement}
        >
          Complete Pre disbursement{" "}
        </button>
      </div>
      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="CAM/Offer Letter"
          id="predisbursementcamofferletter"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Conditions"
          id="predisbursementconditions"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <div>{RenderedComponent}</div>
    </div>
  );
};

export default PreDisbursementMainMenu;
