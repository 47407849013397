import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

const getLoanInformationDetailLog = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_INFORMATION_DETAIL_LOG}?loan_application_detail_id=${id}`
  );

  return data;
};

export default function useLoanInformationDetailLog(id: number) {
  return useQuery(
    [id, "getLoanInformationDetailLog"],
    getLoanInformationDetailLog,
    {
      refetchOnWindowFocus: false,
    }
  );
}
