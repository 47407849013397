import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "./conditions.module.scss";

const schema = yup.object().shape({
  condition: yup.string().required("Please enter condition"),
});

const Condition = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState(
    props?.dataRecord?.oldData?.type ?? ""
  );
  const [selectedClass, setSelectedClass] = useState(
    props?.dataRecord?.oldData?.class ?? ""
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleClassChange = (selectedItem: {
    label: string;
    value: string;
  }) => {
    return setSelectedClass(selectedItem?.value);
  };

  const handleTypeChange = (selectedItem: { label: string; value: string }) => {
    return setSelectedType(selectedItem?.value);
  };

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: any) => {
    if (props?.dataRecord?.type === "create") {
      return handleCreate(values);
    }

    return handleEdit(values);
  };

  const handleCreate = async (values: any) => {
    setIsLoading(true);
    if (!selectedType) {
      setIsLoading(false);
      return setErrorMessage("You have not selected type");
    }

    if (!selectedClass) {
      setIsLoading(false);
      return setErrorMessage("You have not selected class");
    }
    try {
      const payload = {
        class: selectedClass,
        type: selectedType,
        condition: values?.condition,
      };

      const response = await postData(ajaxEndpoints.ADD_CONDITIONS, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreateIndebtedness" },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);
    if (!selectedType) {
      setIsLoading(false);
      return setErrorMessage("You have not selected type");
    }

    if (!selectedClass) {
      setIsLoading(false);
      return setErrorMessage("You have not selected class");
    }
    try {
      const payload = {
        class: selectedClass,
        type: selectedType,
        condition: values?.condition,
        id: props?.dataRecord?.oldData?.id,
      };

      const response = await putData(ajaxEndpoints.EDIT_CONDITION, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Conditions" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 ${styles.Title}`}>
            {props?.dataRecord?.type === "create"
              ? "Create Conditions"
              : "Edit Conditions"}
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12">
                <label>Class</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={[
                      { label: "Internal", value: "internal" },
                      { label: "External", value: "external" },
                    ]}
                    defaultValue={{
                      label: props?.dataRecord?.oldData?.class,
                      value: props?.dataRecord?.oldData?.class,
                    }}
                    onChange={handleClassChange}
                    isSearchable={false}
                    isMulti={false}
                    placeholder="Select Class"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>Types</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={[
                      { label: "Precedent", value: "precedent" },
                      { label: "Subsequent", value: "subsequent" },
                    ]}
                    defaultValue={{
                      label: props?.dataRecord?.oldData?.type,
                      value: props?.dataRecord?.oldData?.type,
                    }}
                    onChange={handleTypeChange}
                    isSearchable={false}
                    isMulti={false}
                    placeholder="Select Type"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <CustomTextArea
                  maxLength={512}
                  reference={register}
                  placeholder="Enter condition..."
                  label="Condition"
                  name="condition"
                  defaultValue={props?.dataRecord?.oldData?.condition}
                  showRequiredIcon={true}
                  errors={errors?.condition?.message}
                />
              </div>
            </div>

            <div className="Form__action-buttons m-t-2">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Condition;
