import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export type Tdata = {
  id: number;
  name: string;
  currency: string;
  interest_type: string;
  maximum_interest_rate: number;
  maximum_tenor: number;
  minimum_interest_rate: number;
  minimum_tenor: number;
  moritorium_type: string;
  repayment_frequency: string;
};

export type TProductDetailsResponse = {
  data: {
    data: Tdata[];
  };
};

export const getLoanProductDetails = async (queryValue: string | undefined) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_PRODUCT_DETAILS}?query_value=${queryValue}`
  );

  return data;
};

export default function useLoanProductDetails(queryValue: string | undefined) {
  return useQuery<TProductDetailsResponse>(
    [queryValue, "getLoanProductDetails"],
    getLoanProductDetails,
    {
      refetchOnWindowFocus: false,
      enabled: queryValue ? true : false,
    }
  );
}
