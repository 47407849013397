import { useState } from "react";
import { Dropdown, Table } from "antd";
import TableWrapper from "../../Components/TableWrapper";

import { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";
import useLoanApplicationConditions from "../../../../../custom-hooks/useLoanApplicationConditions";
import { useLocation } from "react-router-dom";
import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { addSerialNumber } from "../../util";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import ConfirmationModal from "../../../../../NewComponents/ConfirmationModal";
import { deleteData } from "../../../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../../../config/appInsights";

import styles from "./LoanConditions.module.scss";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";

const LoanConditions = ({ data: loanApplication }: any) => {
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [error, setError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();
  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const location = useLocation();
  const state = location?.state as {
    id: number;
  };

  const {
    data: loanConditions,
    refetch,
    isLoading,
  } = useLoanApplicationConditions(state?.id);

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
      disabled:
        loggedInUser?.roles &&
        loggedInUser?.roles?.length > 0 &&
        !loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_APPRAISAL),
    },
    {
      key: "delete",
      label: "Delete",

      disabled:
        loggedInUser?.roles &&
        loggedInUser?.roles?.length > 0 &&
        !loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_APPRAISAL),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleEditConditions(_data);
              }

              if (key === "delete") {
                handleDeleteConditons(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];
  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteData(
        `${ajaxEndpoints.LOAN_APPLICATION_CONDITIONS}/${selectedRecord?.id}`
      );
      if (response?.data?.status === true) {
        setIsShowConfirmationModal(false);
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetch();
      } else {
        setError(response?.data?.message);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "(LoanConditions/index.tsx)" },
      });
    }
    setIsDeleting(false);
  };

  const handleAddConditions = () => {
    dispatch(
      showModal({
        modalIdentifier: "createloanspecificconditions",
        dataRecord: {
          refetchData: refetch,
          type: "create",
          data: loanApplication,
        },
      })
    );
  };

  const handleEditConditions = (data: any) => {
    dispatch(
      showModal({
        modalIdentifier: "createloanspecificconditions",
        dataRecord: {
          refetchData: refetch,
          type: "edit",
          data: loanApplication,
          oldData: data,
        },
      })
    );
  };

  const handleDeleteConditons = (_data: any) => {
    setIsShowConfirmationModal(true);
    return setSelectedRecord(_data);
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <div className="d-flex justify-content-end w-100 pr-3 mb-3">
        <button
          className="btn advancly-btn btn-md m-0"
          onClick={handleAddConditions}
        >
          Add Conditions
        </button>
      </div>
      <div className="col-sm-12">
        <TableWrapper>
          <Table
            columns={columns}
            loading={isLoading}
            dataSource={addSerialNumber(loanConditions?.data?.data)}
            pagination={false}
            rowKey={(record) => record?.id}
          />
        </TableWrapper>
      </div>

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleDelete();
          }}
          error={error}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default LoanConditions;
