import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "./convenants.module.scss";

const schema = yup.object().shape({
  covenant: yup.string().required("Please enter convenant"),
});

const Convenants = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: any) => {
    if (props?.dataRecord?.type === "create") {
      return handleCreate(values);
    }

    return handleEdit(values);
  };

  const handleCreate = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = {
        covenant: values?.covenant,
      };

      const response = await postData(ajaxEndpoints.ADD_CONVENANTS, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Covenants/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = {
        covenant: values?.covenant,
        id: props?.dataRecord?.oldData?.id,
      };

      const response = await putData(ajaxEndpoints.EDIT_CONVENANTS, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Covenants/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 ${styles.Title}`}>
            {props?.dataRecord?.type === "create"
              ? "Create Covenants"
              : "Edit Covenants"}
          </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12">
                <CustomTextArea
                  maxLength={512}
                  reference={register}
                  placeholder="Enter covenant..."
                  label="Covenant"
                  name="covenant"
                  defaultValue={props?.dataRecord?.oldData?.covenant}
                  showRequiredIcon={true}
                  errors={errors?.covenant?.message}
                />
              </div>
            </div>

            <div className="Form__action-buttons m-t-2">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Convenants;
