import { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Loader } from "../../UI/Loaders/Loaders";
import Paginator from "../Pagination/Pagination.component";
import { appInsights } from "../../config/appInsights";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import useLoanProducts from "../../custom-hooks/useLoanProduct";
import { getCurrencyInfo } from "../FunderInvestmentProduct/helper";

import Styles from "./LoanProduct.module.scss";

const LoanProduct = () => {
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const history = useHistory();

  const {
    data: loanProducts,
    error,
    status,
    refetch,
  } = useLoanProducts({ Page, PageSize });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Product Details - (LoanProduct.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleCreateLoanProduct = () => {
    history.push(`/loan-product/new`);
  };

  let RenderedComponent = null;
  if (!loanProducts && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt py-5">
            <i className="fas fa-bell" /> Unable to Fetch Loan Products
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading investment funds requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Products, Please Reload The Page.
            </h4>
          </div>
        </div>
      );
    } else if (
      (loanProducts &&
        loanProducts?.data &&
        loanProducts?.data.data.length === 0) ||
      !loanProducts
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5 ">
              <i className="fas fa-bell" /> There are currently no loan
              products.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          {loanProducts &&
            loanProducts?.data &&
            loanProducts?.data?.data.map((request, _id) => {
              const { name, id, loan_product_details } = request;
              return (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12" key={_id}>
                  <div
                    className={`${Styles.investmentLayoutCard} zoom-element`}
                  >
                    <div className={Styles.title}>{name}</div>
                    <div className={Styles.content}>
                      {loan_product_details.map((item, index) => (
                        <small key={index} className="color-grey mb-2">
                          {`${getCurrencyInfo(item?.currency)?.name} `}
                        </small>
                      ))}
                    </div>

                    <div className={Styles.link}>
                      <Link
                        to={{
                          pathname: `/loan-product/old/${id}`,
                          state: { id, name },
                        }}
                        className="color-blue"
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </>
      );
    }
  }

  return (
    <div className="animated fadeInRight">
      <div className="mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Loan Product
        </h3>
      </div>

      <div className={Styles.ActionContainer}>
        <div className={Styles.FilterOptions}>
          <div>
            <div className={`form-group ${Styles.FormGroup}`}>
              <input
                type="text"
                className={Styles.SearchInput}
                placeholder="Search"
                name="search"
              />
              <i className={`fas fa-search ${Styles.Icon}`}></i>
            </div>
          </div>
          <div className={Styles.FormButtonContainer}>
            <button
              type="submit"
              className={`btn advancly-btn btn-md ${Styles.SearchActionButton}`}
            >
              Search
            </button>
          </div>
        </div>

        <div>
          <div className={Styles.FormButtonContainer}>
            <button
              className={`btn advancly-btn btn-md ${Styles.SearchActionButton}`}
              onClick={handleCreateLoanProduct}
            >
              Create Loan Product
            </button>
          </div>
        </div>
      </div>

      <div className="mt-5">{RenderedComponent}</div>
      <Paginator
        size={PageSize}
        page={Page}
        count={loanProducts?.data?.totalCount}
        changeCurrentPage={(pageNumber: number) => setPage(pageNumber)}
      />
    </div>
  );
};

export default LoanProduct;
