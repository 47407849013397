import React from "react";
import moment from "moment";

const CreditBureauTableReportDetailBvn = ({ reportDetailBVN }: any) => {
  return (
    <div>
      <h5 className="table-caption">Report Detail BVN</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Institution Name
            </th>
            <th className="th-td-style th-style" scope="col">
              BVN Number
            </th>
            <th className="th-td-style th-style" scope="col">
              CIR Number
            </th>
            <th className="th-td-style th-style" scope="col">
              report Order Date
            </th>
            <th className="th-td-style th-style" scope="col">
              search Confidence Store
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="th-td-style">
              {reportDetailBVN?.reportDetailBVN?.institutioN_NAME}
            </td>
            <td className="th-td-style">
              {reportDetailBVN?.reportDetailBVN?.bvN_NUMBER}
            </td>
            <td className="th-td-style">
              {reportDetailBVN?.reportDetailBVN?.ciR_NUMBER}
            </td>
            <td className="th-td-style">
              {moment(
                reportDetailBVN?.reportDetailBVN?.reporT_ORDER_DATE
              ).format("DD/MM/YYYY")}
            </td>
            <td className="th-td-style">
              {reportDetailBVN?.reportDetailBVN?.searcH_CONFIDENCE_SCORE}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableReportDetailBvn;
