import { Table } from "antd";

import type { ColumnsType } from "antd/es/table";

import useLoanInformationDetailLog from "../../../../../../custom-hooks/useLoanInformationDetailLog";
import { DataType } from "./type";
import { formatMoney } from "../../../../../../helpers/formatter";
import { Loader } from "../../../../../../UI/Loaders/Loaders";

const LoanInformationDetailLog = ({ detailId }: any) => {
  const {
    data: loanInformationDetailLog,
    error,
    status,
  } = useLoanInformationDetailLog(detailId);

  console.log("loanInformationDetailLog: ", loanInformationDetailLog);

  const addSerialNumber = (dataInQuestion: any[] | undefined) => {
    if (dataInQuestion && dataInQuestion.length > 0) {
      const result = dataInQuestion.map((_item, index) => {
        return {
          serialNo: index + 1,
          ..._item,
        };
      });

      return result;
    }

    return [];
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "Old Product Detail",
      dataIndex: "old_loan_product_detail",
      key: "old_loan_product_detail",
      align: "center",
    },
    {
      title: "New Product Detail",
      dataIndex: "new_loan_product_detail",
      width: "12%",
      key: "new_loan_product_detail",
      align: "center",
    },
    {
      title: "Old Tenor",
      dataIndex: "old_tenor",
      key: "old_tenor",
      align: "center",
    },
    {
      title: "New Tenor",
      dataIndex: "new_tenor",
      key: "new_tenor",
      align: "center",
    },
    {
      title: "Old Amount",
      dataIndex: "old_amount",
      key: "old_amount",
      align: "center",
      render: (_, _data) => {
        return <span>{formatMoney(_data?.old_amount, _data?.currency)}</span>;
      },
    },
    {
      title: "New Amount",
      dataIndex: "new_amount",
      key: "new_amount",
      align: "center",
      render: (_, _data) => {
        return <span>{formatMoney(_data?.new_amount, _data?.currency)}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: "Old Interest Rate",
      dataIndex: "old_interest_rate",
      key: "old_interest_rate",
      align: "center",
    },
    {
      title: "New Interest Rate",
      dataIndex: "new_interest_rate",
      key: "new_interest_rate",
      align: "center",
    },
    {
      title: "User",
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
    },
    {
      title: "Created Date",
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      render: (_, _data) => {
        return <span>{_data?.created_date.split("T")[0]}</span>;
      },
    },
  ];

  let RenderedComponent = null;
  if (!loanInformationDetailLog && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Loan Transaction
            Transaction Trail
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Loan Transacton trail..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Transaction Trail, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <Table
          columns={columns}
          dataSource={addSerialNumber(loanInformationDetailLog?.data?.data)}
          scroll={{ x: 1300 }}
        />
      );
    }
  }

  return <div className="mt-3 mb-5">{RenderedComponent}</div>;
};

export default LoanInformationDetailLog;
