import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { DownloadUploadedDocument } from "../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomBadge from "../../../Badge/badge";
import OnBoardingDocumentsActionModal from "./Components/DocumentsApprovalModal";
import { handleDisableDocumentAction } from "./helper";
import { documentApprovalStatus } from "../../util";
import { showModal } from "../../../../redux/actions/modal";
import { ReactComponent as MessageIcon } from "../../../../assets/svg/icons/message-icon-2.svg";
import { auditRoleList, isAudit } from "../../../../helpers/roleTypes";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

import styles from "../../AggregatorOnboardingReview.module.scss";

export default function OnboardingDocuments({
  uploadedDocuments,
  filterOptions,
  handleChange,
  activeTab,
  refetch,
  environmentData,
}) {
  const [actionData, setActionData] = useState({});

  const { handleViewDocument } = useDocumentViewer();

  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { id } = match.params;

  const loggedInUser = useSelector((state) => state?.authReducer?.loggedInUser);

  const handleViewComments = (e, file) => {
    dispatch(
      showModal({
        modalIdentifier: "uploadeddoccomments",
        dataRecord: {
          comments: file?.comments,
          document_file_id: file?.aggregator_document_id,
        },
      })
    );
  };

  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <div className="w-100">
          <div className={styles.filterContainer}>
            <div className={`form-group ${styles.filterItem}`}>
              <select
                className={styles.FilterInput}
                onChange={handleChange}
                name="productType"
              >
                {filterOptions?.map((type, index) => (
                  <option key={index} value={type?.value}>
                    {type?.name}
                  </option>
                ))}
              </select>
              <i className={`fas fa-solid fa-angle-down ${styles.Icon}`}></i>
            </div>
          </div>
          <div className={styles.uploadedDocsContainer}>
            {uploadedDocuments && uploadedDocuments.length ? (
              <div>
                {uploadedDocuments.map((uploadedDocument, _id) => (
                  <div className="row mb-5" key={_id}>
                    <div className="col-12 d-flex justify-content-between">
                      <label className="form-label">
                        {_id + 1}. {uploadedDocument.document_field_Name}
                      </label>
                    </div>
                    <div className="col-12 d-flex">
                      <div>
                        {uploadedDocument.files &&
                          uploadedDocument.files.map((item) => (
                            <div className="d-flex" key={uuidv4()}>
                              <div
                                className={styles.downloadUploadDocContainer}
                              >
                                <DownloadUploadedDocument
                                  name={item.file_name}
                                  pathname={item.upload_path}
                                  isViewDocument
                                  handleView={() =>
                                    handleViewDocument(false, item?.upload_path)
                                  }
                                />
                              </div>

                              <div className="d-flex ml-5">
                                <div className="d-flex">
                                  {item.approval_status ===
                                    documentApprovalStatus.DECLINED && (
                                    <button
                                      className="btn advancly-red-btn btn-md"
                                      disabled
                                    >
                                      Declined
                                    </button>
                                  )}
                                  {item.approval_status ===
                                    documentApprovalStatus.APPROVED && (
                                    <button
                                      className="btn advancly-green-btn btn-md"
                                      disabled
                                    >
                                      Approved
                                    </button>
                                  )}
                                  {(item.approval_status ===
                                    documentApprovalStatus.APPROVED ||
                                    item.approval_status ===
                                      documentApprovalStatus.DECLINED) && (
                                    <div
                                      className={styles.commentsAction}
                                      onClick={(e) =>
                                        handleViewComments(e, item)
                                      }
                                    >
                                      <MessageIcon
                                        className={styles.messageIcon}
                                      />
                                      <span className={styles.commentText}>
                                        Comment
                                      </span>
                                    </div>
                                  )}
                                </div>

                                {item.approval_status ===
                                  documentApprovalStatus.PENDING &&
                                  !isAudit(
                                    [...loggedInUser?.roles],
                                    [...auditRoleList]
                                  ) && (
                                    <>
                                      <button
                                        className="btn advancly-btn btn-md"
                                        data-toggle="modal"
                                        data-target="#onBoardingDocumentsActionModal"
                                        disabled={handleDisableDocumentAction(
                                          loggedInUser
                                        )}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "approve",
                                            data: item,
                                          })
                                        }
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn advancly-off-2-btn btn-md ml-3"
                                        data-toggle="modal"
                                        data-target="#onBoardingDocumentsActionModal"
                                        disabled={handleDisableDocumentAction(
                                          loggedInUser
                                        )}
                                        onClick={() =>
                                          setActionData({
                                            actionType: "decline",
                                            data: item,
                                          })
                                        }
                                      >
                                        Decline
                                      </button>
                                    </>
                                  )}
                                <span className={styles.documentTimeStamp}>
                                  {moment(item?.uploaded_at).format("lll")}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div>
                      {uploadedDocument?.productTypes &&
                        uploadedDocument.productTypes.map((item, index) => (
                          <CustomBadge key={index} text={item?.product} />
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-center py-3">
                <h3 className="font-weight-bold">
                  Aggregator is yet to upload any document
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <OnBoardingDocumentsActionModal data={actionData} refetchData={refetch} />
    </div>
  );
}
