import { Loader } from "../../../../UI/Loaders/Loaders";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

export interface IloanSchedule {
  daysInPeriod: number;
  dueDate: [];
  principalLoanBalanceOutstanding: number;
  principalDue: number;
  principalDisbursed: number;
  interestDue: number;
  totalDueForPeriod: number;
}
const GenerateLoanScheduleApprovals = ({ loanScheduleResponse }: any) => {
  let RenderedComponent = null;
  if (!loanScheduleResponse?.data && loanScheduleResponse?.status === "error") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Generate Loan Schedule
          </h4>
        </div>
      </div>
    );
  } else if (loanScheduleResponse?.status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Fetching loan schedule..." />
    );
  } else {
    if (loanScheduleResponse?.error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              borrower's loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      loanScheduleResponse?.data &&
      loanScheduleResponse?.data.periods.length === 0
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no loans for
              this borrower
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <MDBTable responsive minimal="true" bordered hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Days In Period</th>
              <th>Due Date</th>
              <th>Loan balance Outstanding</th>
              <th>Principal Disbursed</th>
              <th>Principal Due</th>
              <th>Interest Due</th>
              <th>Total Due for Period</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {loanScheduleResponse?.data &&
              loanScheduleResponse?.data.periods.map(
                (loanSchedule: IloanSchedule, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{loanSchedule.daysInPeriod}</td>
                      <td>
                        <div style={{ width: "100px" }}>
                          {loanSchedule.dueDate.join("-")}
                        </div>
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.principalLoanBalanceOutstanding),
                          loanScheduleResponse?.data?.currency?.code
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.principalDisbursed),
                          loanScheduleResponse?.data?.currency?.code
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.principalDue),
                          loanScheduleResponse?.data?.currency?.code
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.interestDue),
                          loanScheduleResponse?.data?.currency?.code
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loanSchedule.totalDueForPeriod),
                          loanScheduleResponse?.data?.currency?.code
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            <tr>
              <td className="total">Total</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="total">
                {currencyFormatter(
                  Number(loanScheduleResponse?.data?.totalPrincipalDisbursed),
                  loanScheduleResponse?.data?.currency?.code
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(loanScheduleResponse?.data?.totalPrincipalExpected),
                  loanScheduleResponse?.data?.currency?.code
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(loanScheduleResponse?.data?.totalInterestCharged),
                  loanScheduleResponse?.data?.currency?.code
                )}
              </td>
              <td className="total">
                {currencyFormatter(
                  Number(loanScheduleResponse?.data?.totalRepaymentExpected),
                  loanScheduleResponse?.data?.currency?.code
                )}
              </td>
            </tr>
          </MDBTableBody>
        </MDBTable>
      );
    }
  }

  return (
    <div className="rendered-component">
      <div>{RenderedComponent}</div>
    </div>
  );
};

export default GenerateLoanScheduleApprovals;
