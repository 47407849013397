import { ChangeEvent, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";

//Data Models
import { currency } from "../../../helpers/stringControllers";
import {
  getMonthArray,
  getLineValuesArray,
} from "../../../helpers/statsControllers";

const FinancialStats = (props: any) => {
  const [state, setState] = useState({
    lineDataSelection: "disbursement",
    lineDataOptions: [
      { id: "disbursement", name: "Disbursed Loans" },
      { id: "loan", name: "Active Loans" },
      { id: "repayment", name: "Repaid Loans" },
    ],
  });

  const {
    total_disbursement,
    all_revenue,
    total_repayment,
    total_loan_request,
    total_denied_loan,
    total_borrower,
    pending_loan,
    total_disbursed,
    monthly_disbursed,
    monthly_loans,
    monthly_repayment,
  } = props.stats;

  const loanRequestData = {
    labels: ["Disbursed", "Pending", "Denied"],
    datasets: [
      {
        data: [total_disbursed, pending_loan, total_denied_loan],
        backgroundColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255,99,132,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const monthlyDisbursementData = {
    labels: monthly_disbursed ? getMonthArray(monthly_disbursed) : [],
    datasets: [
      {
        label: "Disbursed Loans",
        data: monthly_disbursed ? getLineValuesArray(monthly_disbursed) : [],
        backgroundColor: [
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const monthlyLoansData = {
    labels: monthly_loans ? getMonthArray(monthly_loans) : [],
    datasets: [
      {
        label: "Active Loans",
        data: monthly_loans ? getLineValuesArray(monthly_loans) : [],
        backgroundColor: [
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const monthlyRepaymentData = {
    labels: monthly_repayment ? getMonthArray(monthly_repayment) : [],
    datasets: [
      {
        label: "Repaid Loans",
        data: monthly_repayment ? getLineValuesArray(monthly_repayment) : [],
        backgroundColor: [
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleLineDataSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    setState((prev) => {
      return {
        ...prev,
        lineDataSelection: e.target.value,
      };
    });
  };

  return (
    <div id="finance" className="table-view animated fadeInRight">
      {/*Welcome Block*/}
      <div className="block-header">
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-7 dashboard-title">
            <h3 className="m-l-1">
              <aside className="rounder">1</aside>
              &nbsp; Financial Statistics
            </h3>
          </div>
        </div>
      </div>

      {/*User & Financial Stats*/}
      <h3 style={{ margin: "20px 0 0 20px" }}>Overview</h3>
      <div className="row clearfix mt-30 grid__padding">
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">&#8358;14,500,000</h3> */}
              <h3 className="m-t-0">&#8358;{currency(all_revenue)}</h3>
              <p className="text-muted">Total Revenue</p>
              {/* <small>(Amount)</small> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">{(count) ? count : 'unavailable'}</h3> */}
              <h3 className="m-t-0">&#8358;{currency(total_disbursement)}</h3>
              <p className="text-muted">Total Disbursement</p>
              {/* <small>(Number)</small> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">75,273</h3> */}
              <h3 className="m-t-0">&#8358;{currency(total_repayment)}</h3>
              <p className="text-muted">Total Repayment</p>
              {/* <small>&nbsp;</small> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="body text-center">
              {/* <h3 className="m-t-0">3%</h3> */}
              <h3 className="m-t-0">{total_borrower}</h3>
              <p className="text-muted">No. of Borrowers</p>
              {/* <small>&nbsp;</small> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row clearfix mt-30 grid__padding">
                  <div className="col-lg-3 col-md-6">
                      <div className="card">
                          <div className="body text-center">
                              <h3 className="m-t-0">&#8358;14,500,000</h3>
                              <p className="text-muted">Total Revenue Till Date</p>
                              <div className="progress progress-xs m-t-sm white bg">
                                  <div className="progress-bar success"
                                      datatoggle="tooltip"
                                      dataoriginaltitle="20%" style={{width: "20%"}}>
                                  </div>
                              </div>
                              <small>20% higher than last month</small>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                      <div className="card">
                          <div className="body text-center">
                              <h3 className="m-t-0">268</h3>
                              <p className="text-muted">Denied Loan Requests</p>
                              <div className="progress progress-xs m-t-sm white bg">
                                  <div className="progress-bar progress-bar-danger"
                                      datatoggle="tooltip"
                                      dataoriginaltitle="32%" style={{width: "32%"}}>
                                  </div>
                              </div>
                              <small>20% higher than last month</small>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                      <div className="card">
                          <div className="body text-center">
                              <h3 className="m-t-0">75,273</h3>
                              <p className="text-muted">Total Loan Requests</p>
                              <div className="progress progress-xs m-t-sm white bg">
                                  <div className="progress-bar progress-bar-warning"
                                      datatoggle="tooltip"
                                      dataoriginaltitle="68%" style={{width: "68%"}}>
                                  </div>
                              </div>
                              <small>15% higher than last month</small>
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                      <div className="card">
                          <div className="body text-center">
                              <h3 className="m-t-0">3%</h3>
                              <p className="text-muted">Interest Rate</p>
                              <div className="progress progress-xs m-t-sm white bg">
                                  <div className="progress-bar progress-bar-success"
                                      datatoggle="tooltip"
                                      dataoriginaltitle="20%" style={{width: "20%"}}>
                                  </div>
                              </div>
                              <small> 1% higher than last month</small>
                          </div>
                      </div>
                  </div>
              </div> */}

      {/*Top Performance*/}

      <div className="clearfix">
        <div className="row clearfix mt-30 grid__padding">
          <div className="col-lg-3 col-md-6">
            <div className="card top-report">
              <div className="body">
                <h3 className="m-t-0 text-center">Loan Requests</h3>
                <p className="text-muted text-center">
                  Total: {total_loan_request}
                </p>
                <div className="p-b-2">
                  <Doughnut data={loanRequestData} options={{}} />
                </div>
                <p
                  className="text-muted row-center"
                  style={{ visibility: "hidden" }}
                >
                  <small className="card-btn">View more</small>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-7">
            <div className="card">
              <div className="body text-center">
                <h3 className="m-t-0">
                  Trend of &nbsp;
                  <select
                    onChange={handleLineDataSelection}
                    id="line-data-select"
                    className="table-fetch-select"
                    value={state.lineDataSelection}
                  >
                    {/* <option value="" selected disabled>Please select</option> */}
                    {state.lineDataOptions.map((option, i) => (
                      <option key={i} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </h3>
                <p className="text-muted">{/* Revenue over time */}</p>
                <Line
                  // data={this.state.lineDataSelection === "disbursement" ? monthlyDisbursementData : "loan" ? monthlyLoansData : monthlyDisbursementData}
                  data={(() => {
                    switch (state.lineDataSelection) {
                      case "disbursement":
                        return monthlyDisbursementData;
                      case "loan":
                        return monthlyLoansData;
                      case "repayment":
                        return monthlyRepaymentData;
                      default:
                        return;
                    }
                  })()}
                  width={1000}
                  height={250}
                  options={{
                    maintainAspectRatio: true,
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
                          <div className="card top-report">
                              <div className="body">
                                  <h3 className="m-t-0 text-center">48</h3>
                                  <p className="text-muted text-center">Total Number
                                      of Ecosystems</p>
                                  <div className="list-group no-border no-radius">
                                      <div className="list-group-item">
                                          <span
                                              className="pull-right text-muted"> </span>
                                          <i className="label label-xs red m-r-sm"/>
                                          Taxify
                                      </div>
                                      <div className="list-group-item">
                                          <span className="pull-right text-muted"/>
                                          <i className="label label-xs orange m-r-sm"/>
                                          Uber
                                      </div>
                                      <div className="list-group-item">
                                          <span className="pull-right text-muted"/>
                                          <i className="label label-xs yellow m-r-sm"/>
                                          Konga
                                      </div>
                                      <div className="list-group-item">
                                          <span className="pull-right text-muted"/>
                                          <i className="label label-xs green m-r-sm"/>
                                          Jumia
                                      </div>
                                  </div>
                                  <p className="text-muted row-center underline">
                                      <small>View All</small>
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                          <div className="card top-report">
                              <div className="body">
                                  <h3 className="m-t-0 text-center">&nbsp;</h3>
                                  <p className="text-muted text-center">Activity
                                      Summary(Total)</p>
                                  <div className="list-group no-border no-radius">
                                      <div className="list-group-item">
                                          <span
                                              className="pull-right text-muted">5,293,200</span>
                                          <i className="label label-xs m-r-sm"></i>
                                          Transactions
                                      </div>
                                      <div className="list-group-item">
                                          <span
                                              className="pull-right text-muted">1,203,000</span>
                                          <i className="label label-xs m-r-sm"></i>
                                          Signups
                                      </div>
                                      <div className="list-group-item">
                                          <span
                                              className="pull-right text-muted">180,230</span>
                                          <i className="label label-xs m-r-sm"></i>
                                          Logins
                                      </div>
                                      <div className="list-group-item">
                                          <span
                                              className="pull-right text-muted">130,100</span>
                                          <i className="label label-xs m-r-sm"></i>
                                          Loan Attempts
                                      </div>
                                  </div>
                                  <p className="text-muted row-center underline">
                                      <small>View more</small>
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                          <div className="card top-report">
                              <div className="body">
                                  <h3 className="m-t-0 text-center">374</h3>
                                  <p className="text-muted text-center">Failed Loan
                                      Attempts</p>
                                  <div className="p-a-md">
                                      <span
                                          className="pull-right text-warning">50%</span>
                                      <small>Low Credit Score rating</small>
                                      <div
                                          className="progress progress-xs m-t-sm white bg">
                                          <div
                                              className="progress-bar progress-bar-warning"
                                              data-toggle="tooltip"
                                              data-original-title="40%"
                                              style={{width: "40%"}}/>
                                      </div>
                                      <span className="pull-right text-warning">34%</span>
                                      <small>Low Behavioural Rating</small>
                                      <div
                                          className="progress progress-xs m-t-sm white bg">
                                          <div
                                              className="progress-bar progress-bar-warning"
                                              data-toggle="tooltip"
                                              data-original-title="25%"
                                              style={{width: "25%"}}/>
                                      </div>
                                      <span
                                          className="pull-right text-warning">16%</span>
                                      <small>Already Existing Loans</small>
                                      <div
                                          className="progress progress-xs m-t-sm white bg">
                                          <div
                                              className="progress-bar progress-bar-warning"
                                              data-toggle="tooltip"
                                              data-original-title="15%"
                                              style={{width: "15%"}}/>
                                      </div>
                                  </div>
                                  <p className="text-muted row-center underline">
                                      <small>View more</small>
                                  </p>
                              </div>
                          </div>
                      </div> */}
        </div>
      </div>
    </div>
  );
};

export default FinancialStats;
