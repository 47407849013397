import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { handleFormatAmountNoCurrency } from "../../../../../helpers/formatter";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../../../config/appInsights";

import styles from "./loanApplicationCurrency.module.scss";

const schema = yup.object().shape({
  exchangeRate: yup.string().required("Please enter exchange rate"),
});

const EditLoanApplicationCurrency = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    dataRecord: {
      data: { currencyCode, exchangeCurrency, exchangeRate, id: currencyId },
      refetch,
    },
  } = props;

  const location = useLocation();
  const state = location?.state as { id: number };

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = {
        exchangeRate: Number(
          handleFormatAmountNoCurrency(String(values?.exchangeRate))
        ),
        id: currencyId,
        loanApplicationId: state?.id,
        currencyCode,
        exchangeCurrency,
      };

      const response = await putData(
        ajaxEndpoints.LOAN_APPLICATIONS_CURRENCY,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "LoanApplicationCurrency" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className="Modal__title mb-5">Edit Loan Application Currency</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12">
                <label>Currency</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    step="any"
                    defaultValue={exchangeCurrency}
                    name="exchangeCurrency"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <label>Currency Code</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={currencyCode}
                    name="currencyCode"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <label>Exchange Rate</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    value={exchangeRate}
                    name="exchangeRate"
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.approved_amount?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="Form__action-buttons m-t-2">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLoanApplicationCurrency;
