import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { useLocation } from "react-router-dom";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import { handleFormatAmountNoCurrency } from "../../../../../helpers/formatter";
import { NumericFormat } from "react-number-format";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";
import LoanPurpose from "./Components/LoanPurpose";
import ProposedCollateral from "./Components/ProposedCollateral";

import styles from "./EditLoanInformation.module.scss";

const schema = yup.object().shape({
  approved_amount: yup.string().required("Please enter approved amount "),
  approved_interest_rate: yup
    .string()
    .required("Please enter approved interest rate"),
  Approved_tenor: yup.string().required("Please enter approved tenor"),
  // comment: yup.string().required("Please enter comments"),
});

type TLoanInformationPayload = {
  // comment: string;
  loan_application_id: number;
  funder_id: number;
  loan_application_details: {
    id: number;
    approved_loan_product_detail_id: number;
    approved_amount: number;
    approved_interest_rate: number;
    Approved_tenor: number;
  }[];
  loan_purpose?: string;
  proposed_collateral?: string;
};

const EditLoanInformationModal = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedProductDetail, setSelectedProductDetail] = useState<number>(0);
  const [loanPurpose, setLoanPurpose] = useState("");
  const [proposedCollateral, setProposedCollateral] = useState("");

  const {
    dataRecord: {
      productDetails,
      loan_info_data: {
        approved_amount,
        approved_interest_rate,
        approved_loan_product_detail_id,
        approved_tenor,
        proposed_amount,
        proposed_interest_rate,
        proposed_loan_product_detail_id,
        proposed_tenor,
        comment,
        proposed_collateral,
        loan_purpose,
      },
    },
  } = props;

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  useLayoutEffect(() => {
    setSelectedProductDetail(approved_loan_product_detail_id);
  }, [props]);

  const location = useLocation();
  const state = location?.state as { id: number };

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const cleanProductDetails = (arrayInQuestion: any) => {
    if (arrayInQuestion && arrayInQuestion.length > 0) {
      const result = arrayInQuestion.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      return result;
    }

    return [];
  };

  const getPreselectedProductDetail = (
    _options: any,
    type: "proposed" | "recommended"
  ) => {
    if (_options) {
      if (_options.length > 0) {
        let result = {} as any;

        if (type === "proposed") {
          result = _options.find(
            (_option: any) => _option?.id === proposed_loan_product_detail_id
          );
        } else {
          result = _options.find(
            (_option: any) => _option?.id === approved_loan_product_detail_id
          );
        }

        return { label: result?.name, value: result?.id };
      }
    }

    return {};
  };

  const handleDropdownChange = (selectedItem: any) => {
    setSelectedProductDetail(selectedItem?.value);
    return null;
  };

  const handleProposedCollateralChange = (collateral: string) => {
    return setProposedCollateral(collateral);
  };

  const handleLoanPurposeChange = (loanPurpose: string) => {
    return setLoanPurpose(loanPurpose);
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);

    if (!selectedProductDetail) {
      setIsLoading(false);
      return setErrorMessage("You have not selected approved product detail");
    }
    try {
      const payload: TLoanInformationPayload = {
        // comment: values?.comment,
        loan_application_id: state?.id,
        funder_id: props?.dataRecord?.loan_application_data?.funder_id,
        loan_application_details: [
          {
            id: props?.dataRecord?.loan_info_data?.id,
            approved_loan_product_detail_id: selectedProductDetail,
            approved_amount: Number(
              handleFormatAmountNoCurrency(String(values?.approved_amount))
            ),
            approved_interest_rate: Number(values?.approved_interest_rate),
            Approved_tenor: Number(values?.Approved_tenor),
          },
        ],
      };

      if (loanPurpose) {
        const newLoanApplicationDetails = payload.loan_application_details.map(
          (_item) => {
            return { ..._item, loan_purpose: loanPurpose };
          }
        );

        payload.loan_application_details = newLoanApplicationDetails;
      }

      if (proposedCollateral) {
        const newLoanApplicationDetails = payload.loan_application_details.map(
          (_item) => {
            return { ..._item, proposed_collateral: proposedCollateral };
          }
        );

        payload.loan_application_details = newLoanApplicationDetails;
      }

      const response = await putData(ajaxEndpoints.LOAN_DETAIL_UPDATE, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "EditLoanInformation" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "scroll" }}>
          <h2 className="Modal__title mb-5">Edit Loan Information</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label>Proposed Amount</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    defaultValue={proposed_amount}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <label>Recommended Amount</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    defaultValue={approved_amount}
                    name="approved_amount"
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.approved_amount?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label>Proposed Interest Rate</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    step="any"
                    defaultValue={proposed_interest_rate}
                    disabled
                  />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <label>Recommended Interest Rate</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="approved_interest_rate"
                    step="any"
                    ref={register}
                    defaultValue={approved_interest_rate}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.approved_interest_rate?.message}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label className="label-txt">Proposed Loan Product</label>

                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    disabled
                    defaultOptions={cleanProductDetails(
                      productDetails?.data?.data
                    )}
                    defaultValue={getPreselectedProductDetail(
                      productDetails?.data?.data,
                      "proposed"
                    )}
                    onChange={handleDropdownChange}
                    isSearchable={false}
                    isMulti={false}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-xs-12">
                <label className="label-txt">Recommended Loan Product</label>

                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    name="approved_loan_product_detail_id"
                    defaultOptions={cleanProductDetails(
                      productDetails?.data?.data
                    )}
                    defaultValue={getPreselectedProductDetail(
                      productDetails?.data?.data,
                      "recommended"
                    )}
                    onChange={handleDropdownChange}
                    isSearchable={false}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-12">
                <label>Proposed Tenor</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={proposed_tenor}
                    disabled
                  />
                </div>
              </div>

              <div className="col-sm-6 col-xs-12">
                <label>Recommended Tenor</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="Approved_tenor"
                    ref={register}
                    defaultValue={approved_tenor}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.Approved_tenor?.message}
                  </span>
                </div>
              </div>
            </div>

            {loggedInUser.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL) && (
              <>
                <LoanPurpose
                  defaultValue={loan_purpose ?? ""}
                  handleChange={handleLoanPurposeChange}
                />

                <ProposedCollateral
                  defaultValue={proposed_collateral ?? ""}
                  handleChange={handleProposedCollateralChange}
                />
              </>
            )}

            {/* {loggedInUser.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) && (
              <CustomTextArea
                maxLength={256}
                reference={register}
                placeholder="Enter comment here..."
                label="RM Comment"
                name="comment"
                showRequiredIcon={true}
                errors={errors?.comment}
                defaultValue={comment}
              />
            )}
            {loggedInUser.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL) && (
              <CustomTextArea
                maxLength={256}
                reference={register}
                placeholder="Enter comment here..."
                label="Credit analyst comments"
                name="comment"
                showRequiredIcon={true}
                errors={errors?.comment}
                defaultValue={comment}
              />
            )} */}

            <div className="Form__action-buttons m-t-2">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLoanInformationModal;
