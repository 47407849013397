import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";

import { TextEditor } from "../../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";
interface IApproveDeclineApprovalRichtext {
  setGetApprovedRichTextComments: Dispatch<SetStateAction<string | null>>;
  setCurrentMccApproval: Dispatch<SetStateAction<number>>;
  props: {
    dataRecord: {
      action: string;
      loanApplicationId: number;
      camId: number;
    };
  };
}
const ApproveDeclineMccApprovalRichtext = ({
  setGetApprovedRichTextComments,
  setCurrentMccApproval,
  props: { dataRecord },
}: IApproveDeclineApprovalRichtext) => {
  const { handleSubmit } = useForm({});
  const { action } = dataRecord;

  const handleChange = (data: string) => {
    setGetApprovedRichTextComments(data);
  };
  const handleApproveDeclineMccApprovalRichtext = () => {
    setCurrentMccApproval(2);
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown "
      role="document"
    >
      <div
        className="LoanModal Modal__content"
        style={{ overflow: "visible", minHeight: "70vh", height: "auto" }}
      >
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 font-weight-bold`}>
            {action === "approvemccapproval"
              ? "State reason for approving this user"
              : "State reason for declining this user"}
          </h2>
          <form
            onSubmit={handleSubmit(handleApproveDeclineMccApprovalRichtext)}
          >
            <TextEditor
              onChange={handleChange}
              defaultValue={""}
              includeImageOptions={true}
            />

            <button
              className="create zoom-element btn login-btn mt-3"
              type="submit"
            >
              Proceed
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveDeclineMccApprovalRichtext;
