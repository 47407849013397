import { useEffect, useState } from "react";

import styles from "./TableAccordion.module.scss";
import { customStyle } from "./customStyle";

interface ICustomOnboardingAccordion {
  children?: any;
  title?: string | React.ReactNode;
  isDefaultOpen?: boolean;
}

const TableAccordion = ({
  children,
  title,
  isDefaultOpen,
}: ICustomOnboardingAccordion) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen ?? false);

  useEffect(() => {
    const style = document.createElement("style");
    document.head.appendChild(style);

    style.innerHTML = customStyle;

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className={styles.AccordionContainer}>
      <div className={styles.settingsCard}>
        <div className={styles.title}>{title}</div>
        <div>
          {isOpen && (
            <span
              className={styles.ArrowUp}
              onClick={() => setIsOpen((prev) => !prev)}
            ></span>
          )}
          {!isOpen && (
            <span
              className={styles.ArrowRight}
              onClick={() => setIsOpen((prev) => !prev)}
            ></span>
          )}
        </div>
      </div>
      <div className={`${styles.Content} ${isOpen && styles.TopBorder}`}>
        {isOpen && <div>{children}</div>}
      </div>
    </div>
  );
};

export default TableAccordion;
