export const onBoardingStatusCode = {
  INITIATED: 0,
  BUSINESS_DETAIL_IN_PROGRESS: 1,
  BUSINESS_DETAIL_COMPLETED: 2,
  DOCUMENT_UPLOAD_IN_PROGRESS: 3,
  DOCUMENT_UPLOADED_COMPLETED: 4,
  LEGAL_IN_PROGRESS: 5,
  ONBOARDING_DECLINE: 7,
  CUSTOMER_ONBOARDED: 9,
};

export const onBoardingReadableName = {
  [onBoardingStatusCode.INITIATED]: "Initiated",
  [onBoardingStatusCode.BUSINESS_DETAIL_IN_PROGRESS]:
    "Business Details In Progress",
  [onBoardingStatusCode.BUSINESS_DETAIL_COMPLETED]:
    "Business Details Completed",
  [onBoardingStatusCode.DOCUMENT_UPLOAD_IN_PROGRESS]:
    "Document Upload In Progress",
  [onBoardingStatusCode.DOCUMENT_UPLOADED_COMPLETED]:
    "Document Upload Completed",
  [onBoardingStatusCode.LEGAL_IN_PROGRESS]: "Legal In Progress",
  [onBoardingStatusCode.ONBOARDING_DECLINE]: "Onboarding Declined",
  [onBoardingStatusCode.CUSTOMER_ONBOARDED]: "Customer Onboarded",
};

export const documentApprovalStatus = {
  DECLINED: 3,
  APPROVED: 2,
  PENDING: 1,
};

export const documentApprovalStatusString = {
  DECLINED: "Declined",
  APPROVED: "Approved",
  PENDING: "Pending",
};
