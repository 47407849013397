import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { handleFormatAmountNoCurrency } from "../../../../../helpers/formatter";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import { appInsights } from "../../../../../config/appInsights";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";
import CustomSelectDropdown from "../../../../../components/CustomHTMLElements/CustomSelectDropdown";

import styles from "./repaymentCapacity.module.scss";

const schema = yup.object().shape({
  average_free_net_inflow: yup
    .string()
    .required("Please enter average free inflow "),

  all_principal_payment_obligations: yup
    .string()
    .required("Please enter principal repayment obligations"),

  all_interest_obligations: yup
    .string()
    .required("Please enter principal interest obligations"),

  other_obligations: yup.string().required("Please enter other obligations"),
  preferred_option: yup.string(),
});

const RepaymentCapacity = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();
  const state = location?.state as { id: number };

  const onSubmit = (values: {
    average_free_net_inflow: string;
    all_principal_payment_obligations: string;
    all_interest_obligations: string;
    other_obligations: string;
    preferred_option: string;
  }) => {
    if (props?.dataRecord?.action === "update") {
      return handleUpdate(values);
    }

    return handleCreate(values);
  };

  const handleUpdate = async (values: {
    average_free_net_inflow: string;
    all_principal_payment_obligations: string;
    all_interest_obligations: string;
    other_obligations: string;
    preferred_option: string;
  }) => {
    setIsLoading(true);

    try {
      const payload = {
        id: props?.dataRecord?.repaymentCapacity?.id,
        loan_application_id: Number(state?.id),
        preferred_option: JSON.parse(values?.preferred_option),
        average_free_net_inflow: Number(
          handleFormatAmountNoCurrency(String(values?.average_free_net_inflow))
        ),
        all_principal_payment_obligations: Number(
          handleFormatAmountNoCurrency(
            String(values?.all_principal_payment_obligations)
          )
        ),
        all_interest_obligations: Number(
          handleFormatAmountNoCurrency(String(values?.all_interest_obligations))
        ),
        other_obligations: Number(
          handleFormatAmountNoCurrency(String(values?.other_obligations))
        ),
      };
      const response = await putData(ajaxEndpoints.REPAYMENT_CAPACITY, payload);
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/RepaymentCapacity" },
      });
    }
    setIsLoading(false);
  };

  const handleCreate = async (values: {
    average_free_net_inflow: string;
    all_principal_payment_obligations: string;
    all_interest_obligations: string;
    other_obligations: string;
    preferred_option: string;
  }) => {
    setIsLoading(true);

    try {
      const payload = {
        loan_application_id: Number(state?.id),
        preferred_option: JSON.parse(values?.preferred_option),
        average_free_net_inflow: Number(
          handleFormatAmountNoCurrency(String(values?.average_free_net_inflow))
        ),
        all_principal_payment_obligations: Number(
          handleFormatAmountNoCurrency(
            String(values?.all_principal_payment_obligations)
          )
        ),
        all_interest_obligations: Number(
          handleFormatAmountNoCurrency(String(values?.all_interest_obligations))
        ),
        other_obligations: Number(
          handleFormatAmountNoCurrency(String(values?.other_obligations))
        ),
      };
      const response = await postData(
        ajaxEndpoints.REPAYMENT_CAPACITY,
        payload
      );
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/RepaymentCapacity" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className="Modal__title mb-4 font-weight-bold">
            Repayment Capacity
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12">
                <label>Average free inflow</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    name="average_free_net_inflow"
                    defaultValue={
                      props?.dataRecord?.repaymentCapacity
                        ?.average_free_net_inflow
                    }
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.average_free_net_inflow?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>All principal payment obligations</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    defaultValue={
                      props?.dataRecord?.repaymentCapacity
                        ?.all_principal_payment_obligations
                    }
                    name="all_principal_payment_obligations"
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.all_principal_payment_obligations?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>All principal interest obligations</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    name="all_interest_obligations"
                    defaultValue={
                      props?.dataRecord?.repaymentCapacity
                        ?.all_interest_obligations
                    }
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.all_interest_obligations?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>Other Obligations</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    defaultValue={
                      props?.dataRecord?.repaymentCapacity?.other_obligations
                    }
                    name="other_obligations"
                    getInputRef={register}
                  />
                  <span className={styles.errorMessage}>
                    {errors?.other_obligations?.message}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label>Preferred Option</label>
                <div className="form-group inputWithIcon">
                  <CustomSelectDropdown
                    name="preferred_option"
                    reference={register}
                    errors={errors?.preferred_option}
                    defaultValue={props?.dataRecord?.repaymentCapacity?.preferred_option.toString()}
                  >
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                  </CustomSelectDropdown>
                </div>
              </div>
            </div>

            <div className="Form__action-buttons m-t-2">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? <ButtonLoader /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RepaymentCapacity;
