import { ChangeEvent, useEffect, useRef, useState } from "react";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { appInsights } from "../../../../../config/appInsights";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { convertToBase64 } from "../../../../../helpers/convertToBase64";
import { DownloadUploadedDocument } from "../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { getData } from "../../../../../newApis/apiMethods";

import styles from "./addFeedbackComment.module.scss";

const schema = yup.object().shape({
  comments: yup.string().required("Please enter comments"),
});

const AddFeedbackComment = (props: any) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [fileToUpload, setFileToUpload] = useState<FileList | null>();
  const [documentPayload, setDocumentPayload] = useState({});
  const [isGettingBaseUrl, setIsGettingBaseUrl] = useState(false);

  const { handleViewDocument } = useDocumentViewer();

  const fileInputElement = useRef<HTMLInputElement>(null);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    dataRecord: {
      refetchData,
      data: selectedFeedback,
      selectedComment,
      action,
    },
  } = props;

  const handleGetBase64Url = async (filePath: string) => {
    setIsGettingBaseUrl(true);
    try {
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${filePath}`
      );

      setDocumentPayload((prev) => {
        return {
          ...prev,
          base64_file_string: data.base64_string,
          filename_with_extension: selectedComment?.document_file_name,
        };
      });
    } catch (error) {}
    setIsGettingBaseUrl(false);
  };

  useEffect(() => {
    if (action === "update") {
      handleGetBase64Url(selectedComment?.document_url);
    }
  }, []);

  const handleAddComment = async (value: { comments: string }) => {
    setIsLoading(true);
    try {
      const payload = {
        loan_application_feedback_id: selectedFeedback?.id,
        comment: value?.comments,
        ...documentPayload,
      };
      const response = await postData(
        ajaxEndpoints.ADD_COMMENT_TO_FEEDBACK,
        payload
      );
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "RMCommentsModal" },
      });
    }
    setIsLoading(false);
  };

  const handleEditComment = async (value: { comments: string }) => {
    setIsLoading(true);
    try {
      const payload = {
        loan_application_feedback_id:
          selectedComment?.loan_application_feedback_id,
        comment: value?.comments,
        id: selectedComment?.id,
        ...documentPayload,
      };
      const response = await putData(
        ajaxEndpoints.ADD_COMMENT_TO_FEEDBACK,
        payload
      );
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "RMCommentsModal" },
      });
    }
    setIsLoading(false);
  };

  const handleUploadClick = () => {
    if (fileInputElement.current !== null) {
      return fileInputElement.current.click();
    }
  };

  const validateFileToUpload = async (file: FileList | null) => {
    setError(null);

    if (file && file.length) {
      if (file[0].name.endsWith(".pdf")) {
        setFileToUpload(file);
        const fileBase64 = await convertToBase64(file[0]);

        const documentToUpload = {
          base64_file_string: fileBase64,
          filename_with_extension: file[0].name,
        };

        setDocumentPayload(documentToUpload);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
      }
    }
  };

  const getModalTitle = (_action: "update" | "create" | "view") => {
    switch (_action) {
      case "create":
        return "Add Feedback Comment";
      case "update":
        return "Edit Feedback Comment";
      case "view":
        return "Feedback Comment";

      default:
        break;
    }
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div
          className={`Modal__body ${styles.FeedbackCommentForm}`}
          style={{ overflow: "hidden" }}
        >
          <h2 className={`Modal__title mb-5 ${styles.Title}`}>
            {getModalTitle(action)}
          </h2>
          <form
            onSubmit={handleSubmit(
              action === "create" ? handleAddComment : handleEditComment
            )}
          >
            <CustomTextArea
              maxLength={256}
              placeholder="Enter message here..."
              label="Comments"
              name="comments"
              reference={register}
              errors={errors?.comments}
              defaultValue={selectedComment?.comment}
              disabled={action === "view"}
            />

            <div className={styles.UploadCreditScore}>
              {action !== "view" && (
                <div className={styles.InputContainer}>
                  <label>Supporting Document (optional) </label>
                  <div className={styles.CustomInput}>
                    <p className={styles.Text} onClick={handleUploadClick}>
                      Upload File (pdf)
                    </p>

                    <input
                      type="file"
                      className="d-none"
                      ref={fileInputElement}
                      id="deal-slip-upload-input"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        validateFileToUpload(e.target.files);
                      }}
                    />
                  </div>
                </div>
              )}

              {/* <div className={styles.ActionButton}>
                <button
                  type="button"
                  className={`create zoom-element btn login-btn`}
                  //   onClick={handleUploadCreditScore}
                >
                  {isUploading ? <ButtonLoader /> : "Upload"}
                </button>
              </div> */}
            </div>
            <small className={styles.UploadedFile}>
              {fileToUpload && fileToUpload[0]?.name.trim()}
            </small>
            {error && (
              <small className="text-danger font-weight-bold text-center">
                {error}
              </small>
            )}

            {selectedComment?.document_url && (
              <div>
                <div className="row mb-5">
                  <div className="col-12 d-flex">
                    <div
                      className={`w-100 ${styles.downloadUploadDocContainer}`}
                    >
                      <DownloadUploadedDocument
                        name={selectedComment?.document_file_name}
                        pathname={selectedComment?.document_url}
                        handleView={() =>
                          handleViewDocument(
                            false,
                            selectedComment?.document_url
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {action !== "view" && (
              <button
                className="create zoom-element btn login-btn mt-5"
                type="submit"
              >
                {isLoading ? "Loading..." : "Submit"}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddFeedbackComment;
