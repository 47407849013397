import React, { useState } from "react";
import { TextEditor } from "../../../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { showModal } from "../../../../../../redux/actions/modal";
import { Alert } from "antd";
import CustomInputField from "../../../../../../components/CustomHTMLElements/CustomInputField";
import { putData } from "../../../../../../newApis/dotNetApiMethods";
import { useDispatch } from "react-redux";
import { useQueryCache } from "react-query";

interface AcceptOrDeferProps {
  dataRecord: {
    id: number;
    loanApplicationId: number;
    status: number;
    customerId: number;
  };
  onCloseModal: () => void;
}
const PreDisbursementApproveOrDeferModal = ({
  dataRecord,
  onCloseModal,
}: AcceptOrDeferProps) => {
  const [getApprovedRichTextComments, setGetApprovedRichTextComments] =
    useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const queryCache = useQueryCache();

  const handleChange = (data: string) => {
    setGetApprovedRichTextComments(data);
  };

  const [selectedDate, setSelectedDate] = useState<string>("");
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const datePicked = event.target.value;
    const currentDate = new Date().toISOString().split("T")[0];

    if (datePicked < currentDate) {
      return setError("Please select today's date or after today.");
    }
    setSelectedDate(datePicked);
  };
  const handleApprovalOrDefer = async () => {
    if (dataRecord?.status === 3 && !selectedDate) {
      return setError("Please select a date.");
    }
    setIsLoading(true);

    const reqBody = {
      loan_application_condition_id: dataRecord?.id,
      loan_application_id: dataRecord.loanApplicationId,
      customer_id: dataRecord?.customerId,
      deferral_due_date:
        dataRecord?.status === 3 && selectedDate ? selectedDate : null,
      status: dataRecord?.status,
      comment: getApprovedRichTextComments,
    };

    try {
      const response = await putData(
        ajaxEndpoints.PRE_DISBURSEMENT_STATUS_CONDITION,
        reqBody
      );

      if (response?.data?.status === true) {
        onCloseModal();
        queryCache.invalidateQueries([
          {
            loanApplicationId: dataRecord?.loanApplicationId,
          },
          "getConditionsPredisbursement",
        ]);
        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: {
                type: "success",
                message: response?.data?.message,
              },
            })
          );
        }, 2000);
      } else {
        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: {
                type: "success",
                message: response?.data?.message,
                reroute: true,
              },
            })
          );
        }, 2000);
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content">
        {error && <Alert message={error} />}

        <div className="Modal__body">
          {showNext === true && (
            <div className="d-flex justify-content-center ">
              <h2 className=" font-weight-bold" style={{ color: "#200865" }}>
                {dataRecord.status === 2
                  ? "Are you sure you want to Approve condition?"
                  : "Are you sure you want to Defer condition?"}
              </h2>
            </div>
          )}
          {showNext === false && (
            <div>
              <form>
                {dataRecord.status === 3 && (
                  <div>
                    <CustomInputField
                      type="date"
                      name="deferdate"
                      label="Please select Defer Date:"
                      value={selectedDate}
                      onChange={handleDateChange}
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                )}
                <h5
                  className={` p-1 font-weight-bold`}
                  style={{ color: "#200865" }}
                >
                  Add Comments{" "}
                </h5>
                <TextEditor onChange={handleChange} defaultValue={""} />
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="create zoom-element btn  login-btn"
                    onClick={() => setShowNext(true)}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {showNext === true && (
            <div className="d-flex justify-content-around px-4 py-4">
              <button
                className="create zoom-element btn  login-btn"
                onClick={handleApprovalOrDefer}
              >
                Yes{" "}
                {isLoading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </button>
              <button
                className="create zoom-element btn login-btn"
                onClick={onCloseModal}
              >
                No
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreDisbursementApproveOrDeferModal;
