import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Table, Dropdown } from "antd";
import TableAccordion from "../../../Components/TableAccordion";
import { ColumnsType } from "antd/es/table";
import { DataType } from "../util";
import { formatMoney } from "../../../../../../helpers/formatter";
import { attachClassNameToTag } from "../../../../../../helpers/attachClassNameToTag";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ReactComponent as Ellipses } from "../../../../../../assets/svg/icons/dots-v.svg";
import useLoanProductDetails from "../../../../../../custom-hooks/useLoanProductDetail";
import { showModal } from "../../../../../../redux/actions/modal";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import { RootState } from "../../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../../helpers/roleTypes";

interface ILoanRequesTable {
  loanInformationDetails: any;
  refetchData: any;
  data: any;
}

const LoanRequesTable = ({
  loanInformationDetails,
  refetchData,
  data,
}: ILoanRequesTable) => {
  const [selectedRecord, setSelectedRecord] = useState<DataType | null>();
  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const dispatch = useDispatch();

  const handleEditLoanInformation = (_data: DataType) => {
    return setSelectedRecord(_data);
  };

  const handleViewLog = (_data: DataType) => {
    dispatch(
      showModal({
        modalIdentifier: "loanapprovaltrail",
        dataRecord: {
          loan_info_data: _data,
        },
      })
    );
  };

  const handleFeeLog = (_data: DataType) => {
    dispatch(
      showModal({
        modalIdentifier: "loanapplicationdetailfeelog",
        dataRecord: {
          loan_info_data: _data,
        },
      })
    );
  };

  const handleFees = (_data: DataType) => {
    dispatch(
      showModal({
        modalIdentifier: "loanapplicationdetailfees",
        dataRecord: {
          loan_info_data: _data,
        },
      })
    );
  };

  const { data: productDetails, isLoading } = useLoanProductDetails(
    selectedRecord?.currency
  );

  const modalState = useSelector((state: RootState) => state?.modalReducer);

  useEffect(() => {
    if (modalState?.showModal === false) {
      setSelectedRecord(null);
    }
  }, [modalState.showModal]);

  useEffect(() => {
    if (productDetails) {
      dispatch(
        showModal({
          modalIdentifier: "loaninformationedit",
          dataRecord: {
            loan_info_data: selectedRecord,
            loan_application_data: data?.data?.data,
            refetch: refetchData,
            productDetails,
          },
        })
      );
    }
  }, [productDetails]);

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
      // disabled:
      //   loggedInUser?.roles &&
      //   loggedInUser?.roles?.length > 0 &&
      //   !loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_APPRAISAL),
    },
    {
      key: "fees",
      label: "Fees",
      disabled:
        loggedInUser?.roles &&
        loggedInUser?.roles?.length > 0 &&
        !loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_APPRAISAL),
    },
    { key: "viewLog", label: "View Log" },
    { key: "viewFeeLog", label: "View Fee Log" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Approved Amount",
      dataIndex: "approved_amount",
      width: "12%",
      key: "approved_amount",
      align: "center",
      render: (_, _data) => {
        return (
          <span>{formatMoney(_data?.approved_amount, _data?.currency)}</span>
        );
      },
    },
    {
      title: "Approved Interest Rate",
      dataIndex: "approved_interest_rate",
      key: "approved_interest_rate",
      align: "center",
    },
    {
      title: "Approved Tenor",
      dataIndex: "approved_tenor",
      key: "approved_tenor",
      align: "center",
    },
    {
      title: "Approved Loan Product",
      dataIndex: "approved_loan_product_detail",
      key: "approved_loan_product_detail",
      align: "center",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status",
      key: "approval_status",
      align: "center",
      render: (_, _data) => {
        return (
          <span className={attachClassNameToTag(_data?.approval_status)}>
            {attachClassNameToTag(_data?.approval_status)}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleEditLoanInformation(_data);
              }
              if (key === "viewLog") {
                handleViewLog(_data);
              }

              if (key === "fees") {
                handleFees(_data);
              }

              if (key === "viewFeeLog") {
                handleFeeLog(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            {isLoading ? <ButtonLoader /> : <Ellipses />}
          </button>
        </Dropdown>
      ),
    },
  ];
  return (
    <div className="mt-3 mb-5">
      <TableAccordion title="Loan Request">
        <Table
          columns={columns}
          dataSource={loanInformationDetails}
          scroll={{ x: 1300 }}
          pagination={false}
          rowKey={(record) => record?.id}
        />
      </TableAccordion>
    </div>
  );
};

export default LoanRequesTable;
