import { useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Loader } from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import useLoanProductById from "../../../custom-hooks/useLoanProductById";
import { formatMoney } from "../../../helpers/formatter";

import styles from "./ViewLoanProduct.module.scss";

const ViewLoanProduct = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { id: number; name: string };

  const { data: loanProduct, error, status } = useLoanProductById(state?.id);

  const handleMapFeesClick = (data: any) => {
    console.log("selectedDta: ", data);
    history.push({
      pathname: `/loan-product/old/${data?.id}/fees`,
      state: data,
    });
  };

  const handleViewMore = (data: any) => {
    history.push({
      pathname: `/loan-product/old/${data?.id}/view`,
      state: { id: state?.id, isEdit: false },
    });
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "View Loan Product - (ViewLoanProducr.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleEditLoanProduct = () => {
    history.push({
      pathname: `/loan-product/old/${state?.id}/edit`,
      state: { id: state?.id, isEdit: true },
    });
  };

  let RenderedComponent = null;
  if (!loanProduct && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt py-5">
            <i className="fas fa-bell" /> Unable to Fetch Loan Products Details
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading loan product details..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Products Details, Please Reload The Page.
            </h4>
          </div>
        </div>
      );
    } else if (
      (loanProduct &&
        loanProduct?.data &&
        loanProduct?.data.data.loan_product_details.length === 0) ||
      !loanProduct
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5 ">
              <i className="fas fa-bell" /> There are currently no loan products
              details.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover id="table-to-xls">
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Date</th>
                <th>Maximum Amount</th>
                <th>Minimum Amount</th>
                <th>Maximum Interest Rate</th>
                <th>Minimum Interest Rate</th>
                <th>Maximum Tenure</th>
                <th>Minimum Tenure</th>
                <th>Amortization Type</th>
                <th>Repayment Type</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {loanProduct &&
                loanProduct?.data?.data.loan_product_details.length > 0 &&
                loanProduct?.data?.data.loan_product_details.map(
                  (data, _id) => {
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>
                          {moment(loanProduct?.data?.data.pub_date).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            Number(data?.maximum_amount),
                            data?.currency
                          )}
                        </td>
                        <td>
                          {formatMoney(
                            Number(data?.minimum_amount),
                            data?.currency
                          )}
                        </td>
                        <td>{data?.maximum_interest_rate}</td>
                        <td>{data?.minimum_interest_rate}</td>
                        <td>{data?.maximum_tenor}</td>
                        <td>{data?.minimum_tenor}</td>
                        <td>{data?.amortization_type}</td>
                        <td>{data?.repayment_type}</td>
                        <td>
                          <div className="dropdown">
                            <button
                              className="btn zoom-element btn-slim dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span
                                className="Loan cursor-pointer dropdown-item"
                                onClick={() => handleMapFeesClick(data)}
                              >
                                Map Fees
                              </span>
                              <span
                                className="Loan cursor-pointer dropdown-item"
                                onClick={() => handleViewMore(data)}
                              >
                                View More
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }

  return (
    <div className="animated fadeInRight">
      <div className="mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Loan Product ({state?.name})
        </h3>
      </div>

      <div className={styles.ActionContainer}>
        <div className={styles.FormButtonContainer}>
          <button
            className={`btn advancly-btn btn-md ${styles.CreateActionButton}`}
            onClick={handleEditLoanProduct}
          >
            Edit Product
          </button>
        </div>
      </div>

      <div className="mt-5">{RenderedComponent}</div>
    </div>
  );
};

export default ViewLoanProduct;
