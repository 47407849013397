import React from "react";
import NoTableDataAvailable from "../../../../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";
import useDocumentViewer from "../../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { showModal } from "../../../../../../redux/actions/modal";
import { useDispatch } from "react-redux";
import useConditionsPredisbursement from "../../../../../../custom-hooks/useConditionsPredisbursement";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { Alert } from "antd";

const PreDisbursementChecklistReadOnly = ({
  loanApplicationId,
}: {
  loanApplicationId: number;
}) => {
  const { handleViewDocument } = useDocumentViewer();
  const dispatch = useDispatch();
  const {
    data: conditionsPredisbursmentData,
    status: conditionsPredisbursmentStatus,
    error: conditionsPredisbursmentError,
  } = useConditionsPredisbursement({
    loanApplicationId: loanApplicationId,
  });

  const conditionStatusId = {
    done: 2,
    deferred: 3,
  };
  const handleView = (pdf_url: string) => {
    if (pdf_url) {
      handleViewDocument(false, pdf_url);
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve document",
          },
        })
      );
    }
  };
  return (
    <div style={{ position: "relative" }}>
      {conditionsPredisbursmentError && (
        <Alert message={conditionsPredisbursmentError} />
      )}
      {conditionsPredisbursmentStatus === "loading" ? (
        <Loader centered={true} text="Loading  Loan Conditions..." />
      ) : (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table
              className="table"
              style={{ overflow: "hidden", minHeight: "25vh" }}
            >
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Conditions</th>
                  <th>Status</th>
                  <th>Documents Upload</th>
                </tr>
              </thead>
              <tbody>
                {conditionsPredisbursmentData?.data?.length > 0 ? (
                  conditionsPredisbursmentData?.data?.map((request: any) => {
                    const {
                      id,
                      condition,
                      loanApplicationConditionDocuments,
                      status,
                      statusId,
                      deferralDueDate,
                    } = request;
                    return (
                      <tr key={id}>
                        <td>{condition}</td>
                        <td>
                          {" "}
                          {status
                            ? ` ${status}   ${
                                statusId === conditionStatusId.deferred &&
                                deferralDueDate
                                  ? `-${deferralDueDate.substring(0, 10)}`
                                  : ``
                              }`
                            : "N/A"}
                        </td>
                        <td>
                          {loanApplicationConditionDocuments ? (
                            loanApplicationConditionDocuments?.length > 0 &&
                            loanApplicationConditionDocuments?.map(
                              (conditionDocuments: any) => {
                                const { id, document_url, document_file_name } =
                                  conditionDocuments;
                                return (
                                  <ul
                                    key={id}
                                    className="  p-0 cursor-pointer "
                                    style={{ listStyle: "none" }}
                                  >
                                    <li>
                                      <small>
                                        {document_file_name.slice(0, 20)}
                                      </small>
                                      <small
                                        className={` pl-1`}
                                        onClick={() => {
                                          handleView(document_url);
                                        }}
                                        style={{ color: "#713fff" }}
                                      >
                                        View
                                      </small>
                                    </li>
                                  </ul>
                                );
                              }
                            )
                          ) : (
                            <small>No Document has been uploaded yet</small>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoTableDataAvailable text={"No Condition is Available"} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreDisbursementChecklistReadOnly;
