import { useDispatch, useSelector } from "react-redux";

import { Table, Dropdown } from "antd";
import TableAccordion from "../../../Components/TableAccordion";
import { ColumnsType } from "antd/es/table";
import { formatMoney } from "../../../../../../helpers/formatter";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ReactComponent as Ellipses } from "../../../../../../assets/svg/icons/dots-v.svg";
import { showModal } from "../../../../../../redux/actions/modal";
import useAllRepaymentCapacity, {
  TRepaymentCapacity,
} from "../../../../../../custom-hooks/useAllRepaymentCapacity";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import ConfirmationModal from "../../../../../../NewComponents/ConfirmationModal";
import { deleteData } from "../../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../../config/appInsights";
import { RootState } from "../../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../../helpers/roleTypes";

const RepaymentCapacityTable = () => {
  const [selectedRepaymentCapacityId, setSelectedRepaymentCapacityId] =
    useState(0);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [error, setError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as { id: number };

  const { data: repaymentCapacity, refetch } = useAllRepaymentCapacity(
    state?.id
  );

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const handleDisableRepaymentCapacity = (): boolean => {
    if (
      loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPLICATION_APPROVER) ||
      loggedInUser?.roles.includes(ROLE_TYPES.MCC)
    ) {
      return true;
    }
    return false;
  };

  const handleCreateUpdateRepaymentCapacity = (
    data: any,
    action: "create" | "update"
  ) => {
    return dispatch(
      showModal({
        modalIdentifier: "repaymentcapacity",
        dataRecord: {
          repaymentCapacity: data,
          refetch,
          action,
        },
      })
    );
  };

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Update",
      disabled: handleDisableRepaymentCapacity(),
    },
    {
      key: "delete",
      label: "Delete",
      disabled: handleDisableRepaymentCapacity(),
    },
  ];

  const columns: ColumnsType<TRepaymentCapacity> = [
    {
      title: "Principal payment obligations",
      dataIndex: "all_principal_payment_obligations",
      key: "all_principal_payment_obligations",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {_data?.all_principal_payment_obligations &&
              formatMoney(_data?.all_principal_payment_obligations)}
          </span>
        );
      },
    },
    {
      title: "Average free net inflow",
      dataIndex: "average_free_net_inflow",
      width: "12%",
      key: "average_free_net_inflow",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {_data?.average_free_net_inflow &&
              formatMoney(_data?.average_free_net_inflow)}
          </span>
        );
      },
    },
    {
      title: "Principal Interest Obligtaions",
      dataIndex: "all_interest_obligations",
      key: "all_interest_obligations",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {_data?.all_interest_obligations &&
              formatMoney(_data?.all_interest_obligations)}
          </span>
        );
      },
    },
    {
      title: "Other Obligations",
      dataIndex: "other_obligations",
      key: "other_obligations",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {_data?.other_obligations && formatMoney(_data?.other_obligations)}
          </span>
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      align: "center",
      render: (_, _data) => {
        return <span>{_data?.date_created.split("T")[0]}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleCreateUpdateRepaymentCapacity(_data, "update");
              }

              if (key === "delete") {
                handleDeleteRepaymentCapacity(_data?.id);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];

  const handleDeleteRepaymentCapacity = (id: number) => {
    setIsShowConfirmationModal(true);
    return setSelectedRepaymentCapacityId(id);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteData(
        `${ajaxEndpoints.REPAYMENT_CAPACITY}/${selectedRepaymentCapacityId}`
      );
      if (response?.data?.status === true) {
        setIsShowConfirmationModal(false);
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetch();
      } else {
        setError(response?.data?.message);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "(RepaymentCapacityTable)" },
      });
    }
    setIsDeleting(false);
  };

  return (
    <div className="mt-3 mb-5">
      <TableAccordion
        title={
          <>
            <span style={{ display: "inline-block" }}>Repayment Capacity</span>

            {!handleDisableRepaymentCapacity() && (
              <span
                style={createActionStyle}
                onClick={() =>
                  handleCreateUpdateRepaymentCapacity(undefined, "create")
                }
              >
                Create
              </span>
            )}
          </>
        }
        isDefaultOpen
      >
        <Table
          columns={columns}
          dataSource={repaymentCapacity?.data}
          scroll={{ x: 1300 }}
          pagination={false}
        />
      </TableAccordion>

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleDelete();
          }}
          error={error}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default RepaymentCapacityTable;

const createActionStyle: React.CSSProperties = {
  fontWeight: 400,
  color: "#713fff",
  textDecoration: "underline",
  cursor: "pointer",
  marginRight: "1.5rem",
};
