import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import { postData, putData } from "../../../../../newApis/dotNetApiMethods";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import useFees, { TFees } from "../../../../../custom-hooks/useFees";
import { NumericFormat } from "react-number-format";
import { useHistory, useLocation } from "react-router-dom";
import { handleFormatAmountNoCurrency } from "../../../../../helpers/formatter";

import styles from "./mapfees.module.scss";

const schema = yup.object().shape({});

const MapFees = (props: any) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedFees, setSelectedFees] = useState<{
    label: string;
    value: string;
    proposedValue: number;
  }>();
  const [selectedType, setSelectedType] = useState(
    props?.dataRecord?.oldData?.calculation_type_id
  );
  const [selectedClass, setSelectedClass] = useState(
    props?.dataRecord?.oldData?.class_id
  );

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const state = location?.state as { id: number };

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const hanndleChangeFee = (data: {
    label: string;
    value: string;
    proposedValue: number;
  }) => {
    return setSelectedFees(data);
  };

  const onSubmit = (values: any) => {
    return handleCreate(values);
  };

  const handleCreate = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = {
        loan_product_detail_id: Number(state?.id),
        fees: [
          {
            fee_id: selectedFees?.value,
            value: handleFormatAmountNoCurrency(values?.value),
          },
        ],
      };

      const response = await postData(
        ajaxEndpoints.LOAN_PRODUCT_DETAIL_FEE,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
        history.goBack();
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);

    if (!selectedType) {
      return setErrorMessage("You have not selected calculation type");
    }

    if (!selectedClass) {
      return setErrorMessage("You have not selected loan class");
    }

    try {
      const payload = {
        name: values?.name,
        description: values?.description,
        calculation_type: selectedType,
        value: values?.value,
        class: selectedClass,
        id: props?.dataRecord?.oldData?.id,
      };

      const response = await putData(ajaxEndpoints.FEES, payload);

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  const { data: fees } = useFees();

  const getDeafultOptions = (_fees: TFees) => {
    if (_fees) {
      const result = _fees?.data?.data.map((_fee) => {
        return {
          label: _fee?.name,
          value: _fee?.id,
          proposedValue: _fee?.value,
        };
      });

      return result;
    }

    return [];
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 ${styles.Title}`}>Map Fees</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12">
                <label>Fee</label>
                <div className="col-md-12 p-0">
                  <CustomSearchDropDown
                    defaultOptions={fees && getDeafultOptions(fees)}
                    onChange={hanndleChangeFee}
                    isSearchable={false}
                    isMulti={false}
                    placeholder="Select Fees"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <label>Value</label>
                <div className="form-group inputWithIcon">
                  <NumericFormat
                    allowNegative={false}
                    thousandSeparator={true}
                    allowLeadingZeros
                    className="form-control"
                    getInputRef={register}
                    name="value"
                    value={selectedFees?.proposedValue}
                  />
                </div>
              </div>
            </div>

            <div className="Form__action-buttons mt-1 mb-3">
              <button type="submit" className="btn zoom-element btn login-btn">
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MapFees;
