import { useState } from "react";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { appInsights } from "../../../../../config/appInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextEditor } from "../../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";

const schema = yup.object().shape({
  comments: yup.string().required("Please enter comments"),
});

const ApproveDeclineLoanApplication = (props: any) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [getApprovedRichTextComments, setGetApprovedRichTextComments] =
    useState<any>();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    // resolver: yupResolver(schema),
  });

  const history = useHistory();
  const location = useLocation();
  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const {
    dataRecord: { refetch, action },
  } = props;

  const state = location?.state as {
    id: number;
  };
  const handleChange = (data: string) => {
    setGetApprovedRichTextComments(data);
  };
  const handleApproveDecline = async (value: { comments: string }) => {
    setIsLoading(true);
    const payload = {
      loan_application_id: Number(state?.id),
      comment:
        action === "approve" ? getApprovedRichTextComments : value?.comments,
    };
    try {
      const response = await putData(
        `${
          action === "approve"
            ? ajaxEndpoints.APPROVE_LOAN_APPLICATION
            : ajaxEndpoints.DECLINE_LOAN_APPLICATION
        }`,
        payload
      );
      console.log(payload);
      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
          history.goBack();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message ?? error.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "ApproveDeclineLoanApplication" },
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown "
      role="document"
    >
      <div
        className="LoanModal Modal__content"
        style={{ overflow: "visible", minHeight: "70vh", height: "auto" }}
      >
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 font-weight-bold`}>
            {action === "approve"
              ? "Approve Loan Application"
              : "Decline Loan Application"}
          </h2>
          <form onSubmit={handleSubmit(handleApproveDecline)}>
            {action === "approve" ? (
              <TextEditor
                onChange={handleChange}
                defaultValue={""}
                includeImageOptions={true}
              />
            ) : (
              <CustomTextArea
                maxLength={256}
                placeholder="Enter message here..."
                label="Comments"
                name="comments"
                reference={register}
                // errors={errors?.comments}
              />
            )}

            <button
              className="create zoom-element btn login-btn mt-3"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveDeclineLoanApplication;
