import { ChangeEvent, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../helpers/formatter";
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import useAutoPaidRepaymentdetails from "../../../custom-hooks/useAutoRepaymentDetails";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import useAllAggregatorsTyped from "../../../custom-hooks/useAllAggregatorsTyped";
import { getTokenFromLocalStorage } from "../../../helpers/localStorage";
import { BASE_URL } from "../../../appConstants";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { appInsights } from "../../../config/appInsights";
import useAllRepaymentChannel from "../../../custom-hooks/useRepaymentChannel";
import { showModal } from "../../../redux/actions/modal";
import { useHistory } from "react-router-dom";

function AutoRepaymentLoans() {
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [search, setSearch] = useState({
    PageSize: 20,
    Page: 1,
    Reference: "",
    Aggregator_Id: "",
    DateRepaidFrom: "",
    DateRepaidTo: "",
    RepaymentStatus: "",
    RepaymentChannel: "",
  });

  const [presearch, setPresearch] = useState({
    Page: 1,
    PageSize: 20,
    Reference: "",
    Aggregator_Id: "",
    DateRepaidFrom: "",
    DateRepaidTo: "",
    RepaymentStatus: "",
    RepaymentChannel: "",
  });
  const { data, status, error } = useAutoPaidRepaymentdetails(presearch);

  const { data: repaymentChannels } = useAllRepaymentChannel();

  const { data: aggregatorData } = useAllAggregatorsTyped();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleDisplayApprovalTrail = (
    transactionReference: string,
    corebankingStatusMessage: string,
    repaymentChannelMessage: string
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "loanrepaymentapprovaltrail",
        dataRecord: {
          trans_ref: transactionReference,
          corebankingStatusMessage,
          repaymentChannelMessage,
        },
        action: "customrange",
        type: "alert",
      })
    );
  };

  const handleDownload = () => {
    setDownloadLoading(true);

    let token = getTokenFromLocalStorage();

    const query = { ...search };

    Object.keys(query).map((item) => {
      if (
        query[item as keyof typeof query] === null ||
        query[item as keyof typeof query] === undefined ||
        query[item as keyof typeof query] === ""
      ) {
        delete query[item as keyof typeof query];
      }
    });

    fetch(
      BASE_URL +
        `${
          ajaxEndpoints.DOWNLOAD_ALL_PAID_REPAYMENT_DETAILS
        }?${new URLSearchParams({
          ...query,
          Page: String(query.Page),
          PageSize: String(query.PageSize),
        })}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${`AutoRepamentLoans.csv`}`);
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AutoRepaymentLoans.jsx (Download)",
          },
        });

        setDownloadLoading(false);
      });
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setSearch({ ...search, [name]: value, Page: 1, PageSize: 20 });
  };
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Repayment
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = <Loader centered={true} text="Loading Repayment..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              repayment, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Repayment ID</th>
                  <th>Customer</th>
                  <th>Reference</th>
                  <th>Core Banking Status</th>
                  <th>Repayment Amount</th>
                  <th>Repayment Channel</th>
                  <th>Payment Date</th>
                  <th>Due Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((data: any) => {
                  const {
                    loanRefNumber,
                    repaymentAmount,
                    repaymentDate,
                    dueDate,
                    coreBankingStatus,
                    corebankingStatusMessage,
                    repaymentChannelMessage,
                    repaymentStatus,
                    customerNames,
                    repaymentChannel,
                    id,
                    makerCheckerReference,
                  } = data;
                  return (
                    <tr key={id}>
                      <td>{id}</td>
                      <td>{customerNames}</td>
                      <td>{loanRefNumber}</td>
                      <td>
                        <span
                          className={attachClassNameToTag(coreBankingStatus)}
                        >
                          {coreBankingStatus}
                        </span>
                      </td>
                      <td>{formatMoney(repaymentAmount)}</td>
                      <td>{repaymentChannel}</td>
                      <td>{repaymentDate?.substring(0, 10)}</td>
                      <td>{dueDate?.substring(0, 10)}</td>
                      <td>
                        <span className={attachClassNameToTag(repaymentStatus)}>
                          {repaymentStatus}
                        </span>
                      </td>

                      <td>
                        <div className="dropdown">
                          <button
                            className="btn zoom-element btn-slim dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span
                              className="Loan cursor-pointer dropdown-item"
                              onClick={() =>
                                handleDisplayApprovalTrail(
                                  makerCheckerReference,
                                  corebankingStatusMessage,
                                  repaymentChannelMessage
                                )
                              }
                            >
                              View More
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <form className="d-flex flex-row flex-wrap">
            <div style={{ marginBottom: "10px" }}>
              <CustomSelectDropdown
                onChange={(e) => handleChange(e)}
                name="Aggregator_Id"
                value={search.Aggregator_Id}
                className="round user__form form-control"
              >
                <option value="">All Aggregators</option>
                {aggregatorData &&
                  aggregatorData?.data?.data.map((aggregator) => {
                    const { biz_name, aggregator_id } = aggregator;
                    return (
                      <option value={Number(aggregator_id)} key={aggregator_id}>
                        {biz_name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
            </div>
            <div style={{ marginBottom: "10px", width: "150px" }}>
              <CustomSelectDropdown
                name="RepaymentStatus"
                value={search.RepaymentStatus}
                className="round user__form form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="">All Status</option>
                <option value={1}>Pending</option>
                <option value={2}>Successful</option>
                <option value={3}>Failed</option>
                <option value={4}>Cancelled</option>
                <option value={5}>Initiated</option>
              </CustomSelectDropdown>
            </div>
            <div style={{ marginBottom: "10px", width: "200px" }}>
              <CustomSelectDropdown
                name="RepaymentChannel"
                value=""
                className="round user__form form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="">All Repayment channel</option>
                {repaymentChannels &&
                  repaymentChannels.length > 0 &&
                  repaymentChannels?.map((_item: any) => (
                    <option key={_item?.id} value={_item?.id}>
                      {_item?.channel_name}
                    </option>
                  ))}
              </CustomSelectDropdown>
            </div>

            <div className="form-group mr-3 mt-2">
              <div className="input-group ">
                <label className="mr-2">From </label>
                <input
                  type="date"
                  name="DateRepaidFrom"
                  className="form-control"
                  value={search.DateRepaidFrom}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group mt-2 mr-3">
              <div className="input-group">
                <label className="mr-2">To </label>
                <input
                  type="date"
                  className="form-control"
                  name="DateRepaidTo"
                  value={search.DateRepaidTo}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div>
              <button
                type="button"
                className="btn advancly-btn btn-md"
                disabled={status === "loading" || !data}
                onClick={() => {
                  setPresearch(search);
                }}
              >
                Search
              </button>
              <button
                className="btn advancly-btn btn-md ml-2"
                type="button"
                onClick={handleDownload}
              >
                Download
              </button>
            </div>
          </form>

          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          <Paginator
            size={presearch.PageSize}
            page={presearch.Page}
            count={data?.totalCount}
            changeCurrentPage={(Page: number) =>
              setPresearch((prev) => ({ ...prev, Page }))
            }
          />
        </div>
      </div>
    </Fragment>
  );
}
export default AutoRepaymentLoans;
