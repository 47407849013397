export const getCountry = (_code: string, countryData: any) => {
  if (_code) {
    const result =
      countryData &&
      countryData.find(
        (_item: any) =>
          _item?.country_code.toLowerCase() === _code?.toLowerCase()
      );
    return result?.name;
  }
  return "";
};
