import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { ReactComponent as MessageIcon } from "../../../../../assets/svg/icons/message-icon-2.svg";
import { ReactComponent as BackButtonIcon } from "../../../../../assets/svg/icons/back-button.svg";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "./industryReview.module.scss";

const IndustryReview = (props: any) => {
  const [comments, setComments] = useState("");
  const [isShowComments, setIsShowComments] = useState(false);

  const {
    dataRecord: { data },
  } = props;

  const handleViewComments = (
    e: any,
    existingComments: string,
    type: string,
    itemId: number
  ) => {
    setIsShowComments(true);
    return setComments(existingComments);
  };

  const handleGoBack = () => {
    setIsShowComments(false);
    setComments("");
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div
          className="Modal__body"
          style={{
            height: "80vh",
            overflowY: "scroll",
          }}
        >
          <h3 className={`Modal__title p-b-2 ${styles.BackButton}`}>
            {isShowComments && <BackButtonIcon onClick={handleGoBack} />}
            Industry Details
          </h3>
          {!isShowComments ? (
            <form>
              <React.Fragment>
                {data &&
                  data.length > 0 &&
                  data.map((_questionObject: any) => (
                    <div key={_questionObject?.id} className="row">
                      <div className="col-sm-8 col-md-8 col-lg-8">
                        <CustomTextArea
                          label={_questionObject?.question}
                          readOnly
                          defaultValue={
                            _questionObject?.selectedAnswer?.questionAnswerText
                          }
                        />
                      </div>
                      <div
                        className={`${styles.commentsAction} col-sm-4 col-md-4 col-lg-4 `}
                        onClick={(e) =>
                          handleViewComments(
                            e,
                            _questionObject?.selectedAnswer?.adminComment,
                            "business_information",
                            _questionObject?.aggregatorQuestionnaireDetailId
                          )
                        }
                      >
                        <MessageIcon className={styles.messageIcon} />
                        <span className={styles.commentText}>Comment</span>
                      </div>
                    </div>
                  ))}

                {data && data.length < 1 && (
                  <div className="w-100 d-flex align-items-center justify-content-center py-3">
                    <h3 className="font-weight-bold">
                      Aggregator is yet to submit Industry Information
                    </h3>
                  </div>
                )}
              </React.Fragment>
            </form>
          ) : (
            <div>
              <CustomTextArea
                maxLength={256}
                label="RM Comments"
                name="message"
                defaultValue={comments}
                disabled
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndustryReview;
