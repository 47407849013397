import React, { Component, useCallback, useEffect, useState } from "react";

import { MDBDataTable } from "mdbreact";
import moment from "moment";

import { filterDataByColumns } from "./../../../../helpers/tableControllers";

// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";
import { getData } from "../../../../newApis/dotNetApiMethods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

// Redux
import { connect } from "react-redux";

// UI Components
import Tabs from "../../../../components/Tabs/Tabs";
import AllLoansTiedToABorrower from "../../Loans/Details/AllLoansTiedToABorrower";
import { accurateQuantifier } from "../../../../helpers/accurateQuantifier";
import ViewRolledOverLoans from "../../Loans/ViewRolledOverLoans/ViewRolledOverLoans";
import { appInsights } from "../../../../config/appInsights";
import CustomerBankStatementData from "../../../../components/Settings/components/CustomerBankStatementsData/CustomerBankStatementData";
import CustomerStockData from "../../../../components/Settings/components/CustomerStockData/CustomerStockData";
import CustomerOrderData from "../../../../components/Settings/components/CustomerOrdersData/CustomerOrderData";
import DisbursementSummaryReadOnly from "../DisbursementSummaryReadOnly/DisbursementSummaryReadOnly";
import GenerateLoanScheduleApprovals from "../DisbursementSummaryReadOnly/GenerateLoanScheduleApprovals";

class Detail extends Component {
  state = {
    scene: "form",
    formScene: "disbursement_summary",
    maker_checker_reference: "",
    approval_trail: [],
    borrower_identity: {
      name: "",
      address: "",
      gender: "",
      bvn: "",
    },
    borrower_bank_balance: {
      available_balance: "",
      currency: "",
      account_name: "",
      ledger_balance: "",
      last_updated: "",
    },
    borrower_full_statement: [],
    borrower_income: {
      last_updated: "",
      max_income_streams: "",
      last_year_income: "",
      number_of_income_streams: "",
      projected_yearly_income: "",
      avg_monthly_income: "",
      income_type: "",
      confidence: "",
    },
    tabs: [
      {
        name: "Application",
        id: "disbursement_summary",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: " Loan Schedule",
        id: "generate_loan_schedule",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Account",
        id: "account_details",
        icon: "far fa-building",
        identifier: "loanInfoTab",
      },
      {
        name: "Borrower",
        id: "borrower_details",
        icon: "fas fa-hourglass-start",
        identifier: "loanInfoTab",
      },
      {
        name: "Loan",
        id: "loan_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "All Loans",
        id: "all_loans_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Roll Over Loans",
        id: "rolled_over_loans",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Approval Trail",
        id: "approval_trail",
        icon: "fas fa-comments",
        identifier: "loanInfoTab",
      },
      {
        name: "Bank Statements",
        id: "bank_statement_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Stock Data",
        id: "stock_data_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
      {
        name: "Orders",
        id: "orders_data_details",
        icon: "fas fa-chart-bar",
        identifier: "loanInfoTab",
      },
    ],
  };

  componentDidMount() {
    this.getLoanDetails();
    this.fetchApprovalDetails();
  }

  getLoanDetails = () => {
    ajaxMethods
      .get(
        ajaxEndpoints.GET_LOAN_DETAILS +
          "?loan_ref=" +
          this.props.properties.dataRecord.loan_ref
      )
      .then((response) => {
        let res = response.data;

        if (res) {
          this.setState({
            maker_checker_reference: res.maker_checker_reference,
          });
          if (res?.has_deal_slip) {
            this.setState({
              tabs: [
                ...this.state.tabs,
                {
                  name: "Deal Slip",
                  id: "deal_slip",
                  icon: "fas fa-file",
                  identifier: "loanInfoTab",
                },
              ],
            });
          }
          this.getApprovalTrail(res.maker_checker_reference);
        }
        // if (response) {
        //     const reOrderedTableData = reOrderRows(creditHistoryColumns, response.data);
        //     setTableData( reOrderedTableData );
        // } else {

        // }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "DetailMakerChecker.js",
          },
        });
      });
  };

  async fetchApprovalDetails() {
    try {
      const data = await getData(
        `${ajaxEndpoints.GET_APPROVAL_DETAILS}?customer_id=${Number(
          this.props.properties.dataRecord?.borrowerDetails?.customer_id
        )}&loan_application_detail_id=${
          this.props.properties.dataRecord?.loan_application_detail_id
        }&loan_ref=${this.props.properties.dataRecord?.loan_ref}`
      );
      this.setState({
        approvalDetailsData: data?.data,
        approvalDetailIsLoading: false,
      });
      setTimeout(() => {
        this.generateLoanSchedule();
      }, 1000);
    } catch (error) {
      this.setState({ error, approvalDetailIsLoading: false });
    }
  }

  getApprovalTrail = (trans_ref) => {
    ajaxMethods
      .get(
        ajaxEndpoints.GET_APPROVAL_TRAIL + "?transaction_reference=" + trans_ref
      )
      .then((response) => {
        if (response) {
          this.setState({
            approval_trail: response.data.data,
          });
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "DetailMakerChecker.js",
          },
        });
      });
  };

  async generateLoanSchedule() {
    // Preserving the rest of the state while updating loanScheduleResponse
    this.setState((prevState) => ({
      ...prevState,
      loanScheduleResponse: { status: "loading", error: false },
    }));
    const { approvalDetailsData } = this.state;

    const payload = {
      loanId: this.props.properties.dataRecord?.loan_id,
      principal: Number(approvalDetailsData?.data?.requested_amount),
      loanEffectiveDate: this.props.properties.dataRecord?.loan_effective_date,
      loanTenor: Number(approvalDetailsData?.data?.requested_tenor),
      firstRepaymentDate: approvalDetailsData?.data?.first_repayment_date
        ? approvalDetailsData?.data?.first_repayment_date?.substring(0, 10)
        : "",
    };

    try {
      const response = await ajaxMethods.post(
        `${ajaxEndpoints.GENERATE_PENDING_LOAN_SCHEDULE}`,
        payload
      );

      if (response?.data?.status === true) {
        this.setState((prevState) => ({
          ...prevState,
          loanScheduleResponse: {
            data: response?.data?.data,
            status: "success",
            error: false,
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          loanScheduleResponse: {
            data: null,
            status: "error",
            error: true,
          },
        }));
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DetailMakerChecker.js",
        },
      });
      this.setState((prevState) => ({
        ...prevState,
        loanScheduleResponse: {
          data: null,
          status: "error",
          error: true,
        },
      }));
    }
  }

  // Toggle Tab & Tab content for Data Table
  toggleTabContent = (content) => {
    this.setState({
      formScene: content,
    });
  };

  render() {
    // Get data from component state
    const { scene, tabs, formScene } = this.state;

    const {
      borrowerDetails,
      loan_amount,
      loan_amount_repay,
      loan_id,
      loan_ref,
      loan_tenure,
      pub_date,
      settlement_day,
      debited_account_no,
      debited_account_name,
      debited_bank_name,
      debited_bank_code,
      credited_account_no,
      credited_account_name,
      credited_bank_name,
      credited_bank_code,
      loan_effective_date,
      funder_name,
      is_rollover,
      rollover_loan_reference,
      parent_rollover_loan_reference,
    } = this.props.properties.dataRecord;
    return (
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            Loan ID: <span>{loan_id}</span>
          </h3>
          {/* Partner Form */}
          {(() => {
            switch (scene) {
              case "form":
                return (
                  <form>
                    <div className="row m-b-1">
                      <Tabs
                        onContentToggle={this.toggleTabContent}
                        tabs={tabs}
                      />
                    </div>
                    {(() => {
                      switch (formScene) {
                        case "disbursement_summary":
                          return (
                            <DisbursementSummaryReadOnly
                              setCurrentTab={this.toggleTabContent}
                              properties={this.props.properties.dataRecord}
                            />
                          );
                        case "generate_loan_schedule":
                          return (
                            <GenerateLoanScheduleApprovals
                              loanScheduleResponse={
                                this.state?.loanScheduleResponse
                              }
                              setCurrentTab={this.toggleTabContent}
                              properties={this.props.properties.dataRecord}
                            />
                          );
                        case "account_details":
                          return (
                            <AccountDetails
                              details={{
                                debited_account_no,
                                debited_account_name,
                                debited_bank_name,
                                debited_bank_code,
                                credited_account_no,
                                credited_account_name,
                                credited_bank_name,
                                credited_bank_code,
                              }}
                            />
                          );

                        case "borrower_details":
                          return (
                            <BorrowerDetails
                              details={this.props.properties.dataRecord}
                            />
                          );

                        case "deal_slip":
                          return (
                            <DealSlip
                              details={this.props.properties.dataRecord}
                            />
                          );

                        case "loan_details":
                          return (
                            <LoanDetails
                              details={{
                                loan_amount,
                                loan_amount_repay,
                                loan_ref,
                                loan_tenure,
                                pub_date,
                                settlement_day,
                                loan_effective_date,
                                funder_name,
                                is_rollover,
                                rollover_loan_reference,
                                parent_rollover_loan_reference,
                              }}
                            />
                          );

                        case "all_loans_details":
                          return (
                            <AllLoansTiedToABorrower
                              dataRecord={this.props.properties.dataRecord}
                            />
                          );
                        case "rolled_over_loans":
                          return (
                            <ViewRolledOverLoans
                              dataRecord={this.props.properties.dataRecord}
                            />
                          );
                        case "approval_trail":
                          return (
                            <ApprovalTrail
                              details={this.state.approval_trail}
                            />
                          );

                        case "bank_statement_details":
                          return (
                            <CustomerBankStatementData
                              customerId={borrowerDetails?.customer_id}
                            />
                          );
                        case "stock_data_details":
                          return (
                            <CustomerStockData
                              customerId={borrowerDetails?.customer_id}
                            />
                          );
                        case "orders_data_details":
                          return (
                            <CustomerOrderData
                              customerId={borrowerDetails?.customer_id}
                            />
                          );

                        default:
                          return null;
                      }
                    })()}
                    <div className="Form__action-buttons m-t-2">
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn"
                        onClick={this.props.onCloseModal}
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        &nbsp; Close
                      </button>
                    </div>
                  </form>
                );

              case "loader":
                return (
                  <div>
                    {/* 2. login Form Loader */}
                    <div
                      id="adv-login-loader"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-circle" />
                            &nbsp; Processing your Partner
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "success":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Logged In Successfully
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "fail":
                return (
                  <div>
                    {/* 3. Login Response */}
                    <div
                      id="adv-login-response"
                      className="loading-overlay speed-1x fadeInRight"
                    >
                      <div className="form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading success-loader">
                            <i className="webapp-ripple-notification success-text fa fa-close-circle" />
                          </div>
                          <div className="center-txt webapp-ripple-text">
                            Login Failed, try again later
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

// Account Details
const AccountDetails = (props) => {
  const {
    debited_account_no,
    debited_account_name,
    debited_bank_name,
    debited_bank_code,
    credited_account_no,
    credited_account_name,
    credited_bank_name,
    credited_bank_code,
  } = props.details;

  return (
    <div className="m-b-2">
      <h5>DEBIT ACCOUNT</h5>
      <div className="row m-t-1">
        <div className="col-md-6">
          <label className="label-txt">Bank Name To Be Debited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={debited_bank_name || "Unavailable"}
            />
            <i className="fa fa-bank" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Account Name To Be Debited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={debited_account_name || "Unavailable"}
            />
            <i className="fas fa-user " />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Account Number To Be Debited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={debited_account_no || "Unavailable"}
            />
            <i className="far fa-envelope" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Bank Code To Be Debited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={debited_bank_code || "Unavailable"}
            />
            <i className="far fa-envelope" />
          </div>
        </div>
      </div>
      <br />
      <h5>CREDIT ACCOUNT</h5>
      <div className="row m-t-1">
        <div className="col-md-6">
          <label className="label-txt">Bank Name To Be Credited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={credited_bank_name || "Unavailable"}
            />
            <i className="fa fa-bank " />
          </div>
        </div>
        <div className="col-md-6">
          {" "}
          <label className="label-txt">Account Name To Be Credited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={credited_account_name || "Unavailable"}
            />
            <i className="fa fa-user " />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Account Number To Be Credited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={credited_account_no || "Unavailable"}
            />
            <i className="far fa-envelope" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Bank Code To Be Credited</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={credited_bank_code || "Unavailable"}
            />
            <i className="far fa-envelope" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Borrower Details
const BorrowerDetails = (props) => {
  const {
    borrower_id,
    first_name,
    last_name,
    personal_phone,
    gender,
    rc_number,
    company_name,
    request_dob,
    request_firstname,
    request_lastname,
    request_phone,
    request_gender,
    kyc_score,
    crc_score,
    crc_status,
    xds_score,
    xds_status,
    sf_score,
    sf_status,
    disbursement_decision,
  } = props.details;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Borrower ID</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={borrower_id || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Full Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={`${first_name} ${last_name}` || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Phone Number</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={personal_phone || "Unavailable"}
            />
            <i className="fas fa-phone" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Gender</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={gender}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Company Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={company_name || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">RC Number</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={rc_number || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={kyc_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Request Full Name</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={
                request_firstname
                  ? `${request_firstname} ${request_lastname}`
                  : "Unavailable"
              }
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Request DOB</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_dob ? request_dob.substring(0, 10) : "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">KYC Request Gender</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_gender || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">KYC Request Phone Number</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={request_phone || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">CRC Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={crc_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">CRC Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={crc_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">First Central Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={xds_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">First Central Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={xds_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Superfluid Score</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={sf_score || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="label-txt">Superfluid Status</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={sf_status || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="label-txt">Consolidated Score Decision</label>
          <div className="form-group inputWithIcon">
            <input
              className="user__form form-control"
              readOnly
              value={disbursement_decision || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Loan Details
const LoanDetails = (props) => {
  const {
    loan_amount,
    loan_amount_repay,
    loan_ref,
    loan_tenure,
    pub_date,
    settlement_day,
    loan_effective_date,
    funder_name,
    is_rollover,
    rollover_loan_reference,
    parent_rollover_loan_reference,
  } = props.details;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Reference</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_ref || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Loan Tenure</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={accurateQuantifier(loan_tenure, "day")}
            />
            <i className="fas fa-hourglass-start" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Loan Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={loan_amount || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Repayment Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_amount_repay || "Unavailable"}
            />
            <i className="fas fa-coins" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Funder Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={funder_name || "Unavailable"}
            />
            <i className="fas fa-user-circle" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Effective Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={loan_effective_date || "Unavailable"}
            />
            <i className="far fa-calendar-alt" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Date Created</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={new Date(pub_date)}
            />
            {/* <i className="far fa-calendar-alt" /> */}
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Settlement Date</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={settlement_day}
            />
            <i className="fas fa-calendar-alt" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-4">
          <label className="label-txt">Rollover Status</label>
          <div className="form-group">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={is_rollover}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <label className="label-txt">Previous Loan Ref</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={parent_rollover_loan_reference}
            />
          </div>
        </div>
        <div className="col-xs-4">
          <label className="label-txt">Rollover Loan Ref</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={rollover_loan_reference}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

// Approval Trail
const ApprovalTrail = (props) => {
  let rows = [];
  props?.details.map((detail, index) => {
    let row_data = {
      id: index + 1,
      email: detail.email,
      approvalComment: detail.approvalComment,
      createdAt: detail.createdAt,
    };
    rows.push(row_data);
    return rows;
  });

  let approvalTrail = {
    columns: [
      { field: "id", label: "#", sort: "asc", width: 150 },
      { field: "email", label: "Email", sort: "asc", width: 150 },
      {
        field: "approvalComment",
        label: "Approval Comment",
        sort: "asc",
        width: 250,
      },
      { field: "createdAt", label: "Date/Time", sort: "asc", width: 250 },
    ],
    rows: [],
  };

  const columnsToExclude = [];
  let filteredTableData = filterDataByColumns(
    approvalTrail,
    "exclude",
    columnsToExclude
  );
  filteredTableData = {
    columns: [...approvalTrail.columns],
    rows: [...rows],
  };

  return (
    <div style={{ maxHeight: "50vh", overflowX: "hidden" }}>
      {rows.length > 0 ? (
        <MDBDataTable
          responsive
          minimal="true"
          striped
          bordered
          hover
          paging={false}
          // scrollY
          // maxHeight='35vh'
          data={filteredTableData}
        />
      ) : (
        <div className="centerLoader m-b-40" style={{ paddingTop: "0" }}>
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" />
              No approval trail available.
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

const DealSlip = (props) => {
  const [documentData, setDocumentData] = useState(null);
  const [isDownloadDealSlip, setIsDownLoadDealSlip] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState({});

  const { loan_id } = props.details;

  const prepareDownloadLink = (e, content, fileName) => {
    e.preventDefault();
    const appendSource = "application/pdf";

    const linkSource = `data:${appendSource};base64,${content}`;
    const documentLink = document.createElement("a");
    documentLink.download = fileName;
    documentLink.href = linkSource;
    documentLink.click();
  };

  const getDocument = async (signed_slip_upload_path) => {
    try {
      const data = await getData(
        `${ajaxEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${signed_slip_upload_path}`
      );

      if (data?.status === 200) {
        setIsLoading(false);
        setIsDownLoadDealSlip(true);
        setDocumentData(data?.data);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DetailMakerChecker.js",
        },
      });
      setIsDownLoadDealSlip(false);
      setMessage(error?.response?.data?.message);
    }
  };

  const getLoanInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getData(
        `${ajaxEndpoints.LOAN_DEAL_SLIP}/${loan_id}`
      );

      if (response?.data?.status_code === 200) {
        setMetaData(response?.data?.data);
        getDocument(response?.data?.data?.signed_slip_upload_path);
      } else {
        setIsLoading(false);
        setMessage(response?.data?.message);
      }
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DetailMakerChecker.js",
        },
      });
      setIsDownLoadDealSlip(false);
      setMessage(error?.response?.data?.message);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => getLoanInfo(), [getLoanInfo]);

  return (
    <div style={{ maxHeight: "50vh", overflowX: "hidden" }}>
      {isLoading ? (
        <div>Loading.....</div>
      ) : (
        <>
          {isDownloadDealSlip && (
            <>
              <p>
                <h4>Status:</h4> {metaData?.status}
              </p>
              <p>
                <h4>Date:</h4>
                {moment(metaData?.datetime_created).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </p>
              <p>
                {" "}
                <h4>FileName:</h4> {metaData?.signed_slip_filename}
              </p>
              <button
                className="btn advancly-white-btn  mr-2"
                onClick={(e) =>
                  prepareDownloadLink(
                    e,
                    documentData?.base64_string,
                    documentData?.file_name
                  )
                }
              >
                Download
              </button>
            </>
          )}
          {message && (
            <p className="alert alert-danger mb-4 small">{message}</p>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    disbursements: { records },
  } = state.loansReducer;
  return {
    records,
  };
};

export default connect(mapStateToProps)(Detail);
