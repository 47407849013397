import { ChangeEvent, useEffect, useRef, useState } from "react";

import { RefetchOptions } from "react-query/types/core/query";
import { TPendingLoanResponse } from "../../../../../custom-hooks/usePendingLoanApplicationById";
import RepaymentCapacityTable from "./Components/RepaymentCapacityTable";
import CreditScoreComments from "./Components/CreditScoreComments";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";
import { appInsights } from "../../../../../config/appInsights";
import { useLocation } from "react-router-dom";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";
import { convertToBase64 } from "../../../../../helpers/convertToBase64";
import { DownloadUploadedDocument } from "../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";

import styles from "./creditScore.module.scss";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";

interface ICreditScore {
  data: any;
  refetchData: (
    options?: RefetchOptions | undefined
  ) => Promise<TPendingLoanResponse | undefined>;
}

const CreditScore = ({ data, refetchData }: ICreditScore) => {
  const [score, setScore] = useState<number>(0);
  const [creditDecision, setCreditDecision] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [comments, setComments] = useState("");
  const [error, setError] = useState<null | string>(null);
  const [fileToUpload, setFileToUpload] = useState<FileList | null>();
  const [documentPayload, setDocumentPayload] = useState({});

  const { handleViewDocument } = useDocumentViewer();

  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as { id: number };

  const handleUploadClick = () => {
    if (fileInputElement.current !== null) {
      return fileInputElement.current.click();
    }
  };

  const fileInputElement = useRef<HTMLInputElement>(null);

  const validateFileToUpload = async (file: FileList | null) => {
    setError(null);

    if (file && file.length) {
      if (file[0].name.endsWith(".pdf")) {
        setFileToUpload(file);
        const fileBase64 = await convertToBase64(file[0]);

        const documentToUpload = {
          loan_application_id: Number(state?.id),
          base64_file_string: fileBase64,
          filename_with_extension: file[0].name,
        };

        setDocumentPayload(documentToUpload);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
      }
    }
  };

  const handleUploadCreditScore = async () => {
    setIsUploading(true);
    try {
      const payload = {
        ...documentPayload,
      };

      const response = await putData(
        ajaxEndpoints.UPLOAD_CREDIT_SCORE_DOCUMENT,
        payload
      );

      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );

        refetchData();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreditScore",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsUploading(false);
  };

  const handleChangeScore = (e: ChangeEvent<HTMLInputElement>) => {
    return setScore(Number(e.target.value));
  };

  const handleChangeComments = (data: string) => {
    return setComments(data);
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      const payload = {
        loan_application_id: Number(state?.id),
        credit_score: Number(score),
        accept_credit_score: creditDecision,
        credit_decision_comment: comments,
      };
      const response = await putData(
        ajaxEndpoints.UPDATE_CREDIT_SCORE,
        payload
      );
      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetchData();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreditScore" },
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (data) {
      setCreditDecision(data?.data?.data?.accept_credit_score);
    }
  }, [data]);

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const handleDisableCreditScore = (): boolean => {
    if (
      loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPLICATION_APPROVER) ||
      loggedInUser?.roles.includes(ROLE_TYPES.MCC)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`col-xs-12 col-sm-12 col-md-10 col-lg-10 ${styles.CreditScore}`}
    >
      <div className="row">
        <div className={`col-sm-6 col-xs-12 ${styles.InputContainer}`}>
          <label>Enter Credit Score</label>
          <div className="form-group inputWithIcon">
            <input
              type="number"
              className={`form-control ${styles.Input}`}
              name="creditScore"
              step="any"
              placeholder="e.g. 90"
              defaultValue={data?.data?.data?.credit_score}
              onChange={handleChangeScore}
            />
          </div>
        </div>
      </div>
      <div className={styles.UploadCreditScore}>
        <div className={styles.InputContainer}>
          <label>Credit Score </label>
          <div className={styles.CustomInput}>
            <p className={styles.Text} onClick={handleUploadClick}>
              Upload File (pdf)
            </p>
            <input
              type="file"
              className="d-none"
              ref={fileInputElement}
              id="deal-slip-upload-input"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                validateFileToUpload(e.target.files);
              }}
            />
          </div>
        </div>
        <div className={styles.ActionButton}>
          <button
            className={`create zoom-element btn login-btn`}
            disabled={handleDisableCreditScore()}
            onClick={handleUploadCreditScore}
          >
            {isUploading ? <ButtonLoader /> : "Upload Credit Score"}
          </button>
        </div>
      </div>
      <small className={styles.UploadedFile}>
        {fileToUpload && fileToUpload[0].name}
      </small>
      {error && (
        <small className="text-danger font-weight-bold text-center">
          {error}
        </small>
      )}

      {data?.data?.data?.credit_score_document_url && (
        <div className={styles.uploadedDocsContainer}>
          <div className="row mb-5">
            <div className="col-5 d-flex">
              <div className={`w-100 ${styles.downloadUploadDocContainer}`}>
                <DownloadUploadedDocument
                  name={data?.data?.data?.credit_score_document_file_name}
                  pathname={data?.data?.data?.credit_score_document_url}
                  handleView={() =>
                    handleViewDocument(
                      false,
                      data?.data?.data?.credit_score_document_url
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.RepaymetCapacity}>
        <RepaymentCapacityTable />
      </div>

      <div className={styles.CreditDecision}>
        <p>
          Credit Decision:{" "}
          {creditDecision && creditDecision === true
            ? "YES"
            : creditDecision === false
            ? "NO"
            : ""}
        </p>
        <div className={styles.Action}>
          <button
            className={`${styles.Yes} ${
              creditDecision === true && styles.Active
            }`}
            disabled={handleDisableCreditScore()}
            onClick={() => setCreditDecision(true)}
          >
            Yes
          </button>
          <button
            className={`${styles.No} ${
              creditDecision === false && styles.Active
            }`}
            disabled={handleDisableCreditScore()}
            onClick={() => setCreditDecision(false)}
          >
            No
          </button>
        </div>
      </div>

      <div className={styles.Comments}>
        <CreditScoreComments
          defaultValue={data?.data?.data?.credit_decision_comment ?? ""}
          handleChange={handleChangeComments}
          readOnly={handleDisableCreditScore()}
        />
      </div>

      <button
        className={`create zoom-element btn login-btn mb-3`}
        onClick={handleSave}
        disabled={handleDisableCreditScore()}
      >
        {isLoading ? <ButtonLoader /> : "Save"}
      </button>
    </div>
  );
};

export default CreditScore;
