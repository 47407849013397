import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type Tdata = {
  id: number;
  name: string;
  value: string;
};

type TMoratoriumTypesResponse = {
  data: {
    data: Tdata[];
  };
};

const getMoratoriumTypes = async () => {
  const data = await getData(ajaxEndpoints.GET_MORATORIUM_TYPES);

  return data;
};

export default function useMoratoriumTypes() {
  return useQuery<TMoratoriumTypesResponse>(
    "getMoratoriumTypes",
    getMoratoriumTypes,
    {
      refetchOnWindowFocus: false,
    }
  );
}
