import { ChangeEvent, useState } from "react";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { appInsights } from "../../../../../config/appInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";
import { useLocation } from "react-router-dom";
import { loanApplicationStatus } from "../../../../../components/AutomatedCredit/util";

const BusinessInformationComments = (props: any) => {
  const [comments, setComments] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const {
    dataRecord: {
      type,
      itemId,
      loanApplicationId,
      comments: existingComments,
      refetchData,
      statusId,
    },
  } = props;

  const handleCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
  };

  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: string;
  };

  const handleAddComments = async () => {
    setIsLoading(true);
    try {
      const payload = {
        id: Number(itemId),
        loan_application_id: Number(loanApplicationId),
        comment: comments ?? existingComments,
      };

      let response = {} as any;

      if (type === "financial_information") {
        response = await putData(
          ajaxEndpoints.RM_FINANCIAL_INFORMATION_COMMENTS,
          payload
        );
      } else if (type === "collateral") {
        response = await putData(
          ajaxEndpoints.RM_COLLATERAL_INFORMATION_COMMENTS,
          payload
        );
      } else if (type === "business_information" || type === "industry") {
        const payload = {
          loan_application_id: Number(loanApplicationId),
          question_comments: [
            {
              aggregator_questionnaire_detail_id: itemId,
              comment: comments ?? existingComments,
            },
          ],
        };
        response = await putData(
          ajaxEndpoints.RM_ELIGIBILITY_COMMENTS,
          payload
        );
      }

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "RMCommentsModal" },
      });
    }
    setIsLoading(false);
  };

  const handleDisableSaveComment = () => {
    if (statusId === loanApplicationStatus?.OFFER_LETTER_DECLINED_BY_CUSTOMER) {
      return true;
    }

    return false;
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <div>
            <CustomTextArea
              maxLength={256}
              placeholder="Enter message here..."
              label="RM Comments"
              name="message"
              onChange={handleCommentsChange}
              defaultValue={existingComments}
              disabled={
                loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) &&
                JSON.parse(state?.isViewOnly) === false
                  ? false
                  : true
              }
            />
            <div></div>
            {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) &&
              JSON.parse(state?.isViewOnly) === false && (
                <button
                  className={`create zoom-element btn login-btn`}
                  onClick={handleAddComments}
                  disabled={handleDisableSaveComment()}
                >
                  {isLoading ? "Loading..." : "Save Comment"}
                </button>
              )}
          </div>

          {/* <div className="Form__action-buttons m-t-2">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={props.onCloseModal}
              data-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              &nbsp; Close
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BusinessInformationComments;
