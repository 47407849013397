import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { appInsights } from "../../../../../config/appInsights";

import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import useConditions from "../../../../../custom-hooks/useConditions";

import styles from "./conditions.module.scss";
import { showModal } from "../../../../../redux/actions/modal";
import { Dropdown } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";

const ConditionsSetup = () => {
  const dispatch = useDispatch();

  const { data: conditions, refetch, error, status } = useConditions();

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
    },
    { key: "delete", label: "Delete" },
  ];

  const handleEditCondition = (data: any) => {
    dispatch(
      showModal({
        modalIdentifier: "createeditconditions",
        dataRecord: {
          refetchData: refetch,
          type: "edit",
          oldData: data,
        },
      })
    );
  };

  const handleCreateConditions = () => {
    dispatch(
      showModal({
        modalIdentifier: "createeditconditions",
        dataRecord: {
          refetchData: refetch,
          type: "create",
        },
      })
    );
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loan Applications  - (LoanApplications.tsx)",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;
  if (!conditions && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Loan Applications
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Pending Loan Application..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Applications, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (conditions && conditions.data && conditions.data?.data.length === 0) ||
      (conditions && !conditions.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              applications.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Class</th>
                  <th>Condition</th>
                  <th>Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {conditions &&
                  conditions?.data.data.length > 0 &&
                  conditions.data?.data.map((request, _id) => {
                    const { condition, class: _class, type } = request;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{_class}</td>
                        <td>{condition}</td>
                        <td>{type}</td>
                        <td>
                          <Dropdown
                            menu={{
                              items,
                              onClick: ({ key }) => {
                                if (key === "edit") {
                                  handleEditCondition(request);
                                }

                                // if (key === "delete") {
                                //   handleDeleteOtherLendersLoan(_data);
                                // }
                              },
                            }}
                          >
                            <button
                              className="btn cursor-pointer"
                              type="button"
                            >
                              <Ellipses />
                            </button>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="speed-1x animated fadeInRight">
      <div className={`row ${styles.ActionBar}`}>
        <div>
          <button
            className="btn advancly-btn btn-md"
            onClick={handleCreateConditions}
          >
            Create Conditions
          </button>
        </div>
      </div>

      {RenderedComponent}
    </div>
  );
};

export default ConditionsSetup;
