import React, { Fragment, useRef, useState } from "react";
import styles from "./UploadOfferDocuments.module.scss";
import FileUploadIcon from "../../../../assets/svg/icons/file-upload-fill.svg";
import { useDispatch } from "react-redux";
import * as apiEndpoints from "../../../../api/ajax-endpoints";
import { convertToBase64 } from "../../../../helpers/convertToBase64";
import { showModal } from "../../../../redux/actions/modal";
import { putData } from "../../../../newApis/dotNetApiMethods";
import { useQueryCache } from "react-query";

interface UploadOfferDocumentsProps {
  properties: any;
  onCloseModal: any;
}

// interface FileProps {
//   type: string;
//   name: string;
// }

const UploadOfferDocuments = ({
  properties: {
    customer_id,
    loan_application_id,
    id,
    document_type_id,
    offer_letter_details_id,
  },
  onCloseModal,
}: UploadOfferDocumentsProps) => {
  const [fileToUpload, setFileToUpload] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [documentPayload, setDocumentPayload] = useState({});
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  // const [fileUploading, setFileUploading] = useState<FileProps[]>([]);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer && e.dataTransfer.files) {
      validateFileToUpload(e.dataTransfer.files);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const validateFileToUpload = async (file: any) => {
    if (file && file.length) {
      if (
        file[0].name.endsWith(".pdf") ||
        file[0].name.endsWith(".doc") ||
        file[0].name.endsWith(".docx")
      ) {
        setFileToUpload(file);

        const fileBase64 = await convertToBase64(file[0]);

        const documentToUpload = {
          loan_application_id: loan_application_id,
          customer_id: customer_id,
          offer_letter_details: [
            {
              document_type_id: document_type_id,
              base64_file_string: fileBase64,
              filename_with_extension: file[0].name,
              id: offer_letter_details_id,
            },
          ],
          id: id,
        };

        setDocumentPayload(documentToUpload);
      } else {
        setFileToUpload(null);
      }
    }
  };

  const resetAllFields = () => {
    setFileToUpload(null);
  };
  // const removeDoc = (index: number) => {
  //   setFileUploading((prev) => {
  //     const newArray = prev.slice();
  //     newArray.splice(index, 1);
  //     return newArray;
  //   });
  // };

  const handleUploadDocAndFileType = async () => {
    setIsLoading(true);

    try {
      const response = await putData(
        apiEndpoints.UPLOAD_SIGNED_OFFER_SLA,
        documentPayload
      );
      if (response?.data?.status === true) {
        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.data?.message },
            })
          );
        }, 2000);
        if (customer_id && loan_application_id) {
          queryCache.invalidateQueries([
            { customer_id, loan_application_id },
            "fetchOfferAndSLA",
          ]);
        }
      } else {
        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "failure", message: response?.data?.message },
            })
          );
        }, 2000);
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoading(false);
      resetAllFields();
    }
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <Fragment>
            <h3 className={styles.modal_title}>Upload Document</h3>

            <div className={styles.upload_box}>
              <input
                type="file"
                className="d-none"
                ref={fileInput}
                id="batch-upload-input"
                accept=".docx, .doc, .pdf" // Accept Word and PDF files only
                onChange={(e) => {
                  validateFileToUpload(e?.target?.files);
                }}
              />
              <div>
                <Fragment>
                  <div className="upload-box-section">
                    <div
                      className="upload-box-icon-border"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <img src={FileUploadIcon} alt="Upload File" />
                      {!fileToUpload ? (
                        <Fragment>
                          <div className="color-blue font-weight-bold">
                            Upload File
                          </div>
                          <div style={{ textAlign: "center" }}>
                            Drag & drop your file here or{" "}
                            <span
                              className="color-light-blue"
                              onClick={() => fileInput.current?.click()}
                            >
                              browse
                            </span>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="color-blue font-weight-bold">
                            {fileToUpload[0].name.length > 50
                              ? fileToUpload[0].name.substring(0, 50) + "..."
                              : fileToUpload[0].name}
                          </div>
                          <div>
                            {(parseFloat(fileToUpload[0].size) / 1024).toFixed(
                              2
                            )}
                            kb
                          </div>
                          <div
                            className="color-light-blue"
                            onClick={resetAllFields}
                          >
                            <u>Remove</u>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>

            <button
              className="btn advancly-btn btn-md"
              onClick={handleUploadDocAndFileType}
              disabled={isLoading}
            >
              {isLoading ? "Processing..." : "Upload File"}
            </button>
          </Fragment>
        </div>
      </div>
    </div>
  );
};
export default UploadOfferDocuments;
