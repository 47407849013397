import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { TPendingLoanResponse } from "../../../../../custom-hooks/usePendingLoanApplicationById";
import { RefetchOptions } from "react-query/types/core/query";
import Industry from "./Components/Industry";
import BusinessInfo from "./Components/BusinessInformation";
import Ownership from "./Components/Ownership";
import GovernanceAndManagement from "./Components/GovernanceManagement";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { putData } from "../../../../../newApis/dotNetApiMethods";
import { showModal } from "../../../../../redux/actions/modal";
import { appInsights } from "../../../../../config/appInsights";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";
import BorrowerInfo from "./Components/BorrowerInformation";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";

import styles from "./BusinessInfo.module.scss";

interface IBusinessInformation {
  data: TPendingLoanResponse | undefined;
  refetchData: (
    options?: RefetchOptions | undefined
  ) => Promise<TPendingLoanResponse | undefined>;
}

const BusinessInformation = ({ data, refetchData }: IBusinessInformation) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [businessComments, setBusinessComments] = useState<{
    business_comment: string;
    industry_comment: string;
    ownership_comment: string;
    governance_and_management_comment: string;
    borrower_info: string;
  }>({
    business_comment: data?.data?.data?.credit_business_comment ?? "",
    governance_and_management_comment:
      data?.data?.data?.credit_governance_and_management_comment ?? "",
    industry_comment: data?.data?.data?.credit_industry_comment ?? "",
    ownership_comment: data?.data?.data?.credit_ownership_comment ?? "",
    borrower_info: data?.data?.data?.borrower_info ?? "",
  });

  const match = useRouteMatch();
  const dispatch = useDispatch();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const handleDisableTextEditor = (): boolean => {
    if (
      loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPLICATION_APPROVER) ||
      loggedInUser?.roles.includes(ROLE_TYPES.MCC)
    ) {
      return true;
    }
    return false;
  };

  const { id } = match.params as any;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        loan_application_id: Number(id),
        ...businessComments,
      };
      const response = await putData(
        ajaxEndpoints.UPDATE_CREDIT_COMMENTS,
        payload
      );

      if (response?.data?.status_code === 200) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "success",
              message: response?.data?.message,
            },
          })
        );

        refetchData();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message: response?.data?.message,
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "CreditAnalysis/Tabs/BusinessInformation" },
      });

      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className={`col-xs-12 col-sm-12 col-md-10 col-lg-10 ${styles.BusinessInformation}`}
    >
      <BorrowerInfo
        data={data?.data?.data.borrower_info}
        setBusinessComments={setBusinessComments}
        defaultValue={businessComments?.borrower_info}
        readOnly={handleDisableTextEditor()}
      />

      <BusinessInfo
        data={data?.data?.data.business_information}
        setBusinessComments={setBusinessComments}
        defaultValue={businessComments?.business_comment}
        readOnly={handleDisableTextEditor()}
      />

      <Ownership
        data={data?.data?.data?.funder_id}
        setBusinessComments={setBusinessComments}
        defaultValue={businessComments?.ownership_comment}
        readOnly={handleDisableTextEditor()}
      />

      <GovernanceAndManagement
        data={data?.data?.data?.funder_id}
        setBusinessComments={setBusinessComments}
        defaultValue={businessComments?.governance_and_management_comment}
        readOnly={handleDisableTextEditor()}
      />

      <Industry
        data={data?.data?.data.industry_information}
        setBusinessComments={setBusinessComments}
        defaultValue={businessComments?.industry_comment}
        readOnly={handleDisableTextEditor()}
      />

      <div className="mb-3">
        <button
          className="btn advancly-btn btn-md m-0"
          onClick={handleSubmit}
          disabled={isSubmitting || handleDisableTextEditor()}
        >
          {isSubmitting ? <ButtonLoader /> : "Save Information"}
        </button>
      </div>
    </div>
  );
};

export default BusinessInformation;
