import React, { useEffect, useState } from "react";

import { ReactComponent as PlusIcon } from "../../../../assets/svg/icons/plus-round.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/svg/icons/minus-round.svg";
import useMoratoriumTypes from "../../../../custom-hooks/useMoratoriumTypes";
import useDisbursementChannels from "../../../../custom-hooks/useDisbursementChannels";
import useDisbursementChannelAccount from "../../../../custom-hooks/useDisbursementChannelAccount";
import {
  repaymentFrequencys,
  scheduleType,
} from "../../../../models/product-data-model";
import { useForm } from "react-hook-form";
import useApprovalDetails from "../../../../custom-hooks/useApprovalDetails";
import { dataProps } from "../../Loans/Disburse/CreditAnalysis/LoanSchedule/type";
const DisbursementSummaryReadOnly = ({ properties, setCurrentTab }: any) => {
  const {
    loan_id,
    loan_ref,
    loan_effective_date,
    first_repayment_date,
    borrowerDetails: { customer_id },
    loan_application_detail_id,
  } = properties;
  const [showMoreSettings, setShowMoreSettings] = useState(false);
  const { data: moratoriumTypes } = useMoratoriumTypes();
  const { data: disbursementChannels } = useDisbursementChannels();
  const { data: disbursementChannelAccount } = useDisbursementChannelAccount(1);
  const { data: approvalDetailsData, error: approvalDetailsError } =
    useApprovalDetails({
      loan_application_detail_id: loan_application_detail_id,
      customer_id: customer_id,
      loan_ref: loan_ref,
    });

  const { register, setValue } = useForm({});

  useEffect(() => {
    if (approvalDetailsData) {
      setValue("currency", approvalDetailsData?.currency);
      setValue("product", approvalDetailsData?.product_name);
      setValue("interestRate", approvalDetailsData?.interest_rate);
      setValue("coreBankingTenant", approvalDetailsData?.corebanking_tenant);
      setValue("productVariant", approvalDetailsData?.product_variant);
      setValue("amountApproved", approvalDetailsData?.approved_amount);
      setValue("firstRepaymentDate", first_repayment_date);
      setValue("approvedTenor", approvalDetailsData?.loan_tenure);
      setValue("coreBankingTenant", approvalDetailsData?.corebanking_tenant);
      setValue("comment", approvalDetailsData?.credit_approval_comment);
      setValue(
        "requestedAmount",
        approvalDetailsData?.requested_amount
          ? approvalDetailsData?.requested_amount
          : ""
      );
      setValue("requestedTenor", approvalDetailsData?.requested_tenor);
      setValue(
        "firstRepaymentDate",
        approvalDetailsData?.first_repayment_date
          ? approvalDetailsData?.first_repayment_date.substring(0, 10)
          : ""
      );
    }
    //eslint-disable-next-line
  }, [approvalDetailsData]);
  return (
    <div
      style={{
        padding: "1rem",
        height: "70vh",
        scrollbarColor: "auto",
        overflowY: "scroll",
      }}
    >
      {approvalDetailsError && (
        <p className="text-danger">{approvalDetailsError as Error}</p>
      )}
      <h3 className="Modal__title p-b-1">Application Summary</h3>

      <form>
        <div className="disburseSummary">
          <label style={{ width: "100%" }}>
            Currency
            <input
              ref={register}
              className="form-control"
              name="currency"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Product
            <input
              ref={register}
              className="form-control"
              name="product"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Interest Rate
            <input
              ref={register}
              className="form-control"
              name="interestRate"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Core Banking Tenant
            <input
              ref={register}
              className="form-control"
              name="coreBankingTenant"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Product Variant <span style={{ color: "red" }}>*</span>
            <input
              ref={register}
              className="form-control"
              name="productVariant"
              readOnly
            />
          </label>
          <div style={{ width: "100%" }}></div>
          <label style={{ width: "100%" }}>
            Requested Amount <span style={{ color: "red" }}>*</span>
            <input
              className="form-control"
              ref={register}
              name="requestedAmount"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Requested Tenor <span style={{ color: "red" }}>*</span>
            <input
              ref={register}
              className="form-control"
              name="requestedTenor"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Approved Amount <span style={{ color: "red" }}>*</span>
            <input
              ref={register}
              className="form-control"
              name="amountApproved"
              readOnly
            />
          </label>
          <label style={{ width: "100%" }}>
            Approved Tenor <span style={{ color: "red" }}>*</span>
            <input
              ref={register}
              className="form-control"
              name="approvedTenor"
              readOnly
            />
          </label>

          <label style={{ width: "100%" }}>
            Comment
            <input
              ref={register}
              className="form-control"
              name="comment"
              readOnly
            />
          </label>

          <label style={{ width: "100%" }}>
            Effective Date <span style={{ color: "red" }}>*</span>
            <input
              ref={register}
              value={loan_effective_date?.substring(0, 10)}
              className="round user__form form-control"
              name="selectedDate"
              type="date"
              id="selectedDate"
              readOnly
            />
          </label>

          <label style={{ width: "100%" }}>
            First Repayment Date
            <input
              ref={register}
              value={first_repayment_date?.substring(0, 10)}
              className="round user__form form-control"
              name="firstRepaymentDate"
              type="date"
              id="firstRepaymentDate"
              readOnly
            />
          </label>
        </div>

        {/* More Settings */}
        <div>
          <div className="d-flex justify-content-between align-items-center py-2">
            <h4>More Settings</h4>
            <div></div>
          </div>
          <small
            className="text-muted cursor-pointer"
            onClick={() => {
              setShowMoreSettings(!showMoreSettings);
            }}
          >
            {showMoreSettings ? (
              <>
                Click here <MinusIcon /> to hide additional settings
              </>
            ) : (
              <>
                Click here <PlusIcon /> to view additional settings on the
                existing template
              </>
            )}
          </small>

          {showMoreSettings && (
            <div className="row">
              {/* Start of disbursement channel */}
              <div className="col-6 my-2">
                <label>Disbursement Channel</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="disbursement_channel_id"
                    value={approvalDetailsData?.disbursement_channel_id}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Disbursement Channel</option>
                    {disbursementChannels &&
                      disbursementChannels?.data?.data.map(
                        (_disbursementChannel) => (
                          <option
                            key={_disbursementChannel?.id}
                            value={_disbursementChannel?.id}
                          >
                            {_disbursementChannel?.channel_name}
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>

              <div className="col-6  my-2">
                <label>Disbursement Channel Accounts</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="disbursement_channel_account_id"
                    value={approvalDetailsData?.disbursement_channel_account_id}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">
                      Select Disbursement Channel Account
                    </option>
                    {disbursementChannelAccount &&
                      disbursementChannelAccount?.data?.data.map(
                        (_channelAccount) => (
                          <option
                            key={_channelAccount?.id}
                            value={_channelAccount?.id}
                          >
                            {_channelAccount?.bank_name}/
                            {_channelAccount?.account_number}/
                            {_channelAccount?.account_name}
                          </option>
                        )
                      )}
                  </select>
                </div>
              </div>
              {/* end of disbursement channel */}

              <div className="col-6  my-2">
                <label>Moratorium Type</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="moratorium_type_id"
                    value={approvalDetailsData?.moratorium_type_id}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Moratorium Type</option>
                    {moratoriumTypes &&
                      moratoriumTypes.data?.data.map((_moratoriumType) => (
                        <option
                          key={_moratoriumType?.id}
                          value={_moratoriumType?.id}
                        >
                          {_moratoriumType?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-6  my-2">
                <label>Amortization Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="amortization_type_id"
                    value={approvalDetailsData?.amortization_type_id}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Amortization type</option>
                    <option value={0}>Equal Principal Payments</option>
                    <option value={1}>Equal Installments</option>
                  </select>
                </div>
              </div>

              <div className="col-6  my-2">
                <label>Repayment Type</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="repayment_type"
                    value={
                      approvalDetailsData?.repayment_type ||
                      approvalDetailsData?.repayment_frequency
                    }
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Repayment Type</option>
                    {repaymentFrequencys.map((_repaymentFrequency) => (
                      <option
                        key={_repaymentFrequency?.repayment_Type}
                        value={_repaymentFrequency?.repayment_Type}
                      >
                        {_repaymentFrequency?.repaymentFrequencyName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-6  my-2">
                <label>Interest Type </label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="interest_type_id"
                    value={approvalDetailsData?.interest_type_id}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Interest type</option>
                    {scheduleType.map((_item) => (
                      <option
                        key={_item?.schedule_Type}
                        value={_item?.schedule_Type}
                      >
                        {_item?.scheduleTypeName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default DisbursementSummaryReadOnly;
