import { Fragment, useState, useEffect } from "react";

import moment from "moment";
import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import { useLocation } from "react-router-dom";
import usePendingLoanApplicationsById from "../../../custom-hooks/usePendingLoanApplicationById";
import LoanInformation from "./Tabs/LoanInformation";
import BusinessInformation from "./Tabs/BusinessInformation";
import Collateral from "./Tabs/Collateral/Documents";
import FinancialInformation from "./Tabs/FinancialInformation/Documents";
import Industry from "./Tabs/Industry";
import ButtonLoader, { Loader } from "../../../UI/Loaders/Loaders";
import AuditTrail from "./Tabs/AuditTrail";
import { Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { RootState } from "../../../redux/store";
import { ROLE_TYPES } from "../../../helpers/roleTypes";
import { appInsights } from "../../../config/appInsights";
import Feedback from "./Tabs/Feedback";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { getData, postBlobData } from "../../../newApis/dotNetApiMethods";
import useDocumentViewer from "../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/icons/download_underline.svg";

import styles from "./ViewLoanApplication.module.scss";

const ViewLoanApplication = () => {
  const [activeTab, setActiveTab] = useState("loan-information");
  const [isGeneratingCam, setIsGeneratingCam] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
    customerId: number;
    application_type: string;
    application_type_id: number;
  };

  const { handleViewDocument } = useDocumentViewer();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "Review Onboarding Request  - (AggregatorOnboardingReview.jsx)",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: application,
    refetch,
    status,
    error,
  } = usePendingLoanApplicationsById(state?.id);

  const actionItems: ItemType[] = [
    {
      key: "transfer",
      label: "Transfer",
    },
  ];

  const handleTransfer = () => {
    dispatch(
      showModal({
        modalIdentifier: "transferapplicationcomments",
        dataRecord: {
          refetch,
          action: "rm_transfer",
        },
      })
    );
  };

  const camFormatItems: ItemType[] = [
    {
      key: "pdf",
      label: "PDF",
    },
    {
      key: "docx",
      label: "WORD",
    },
    {
      key: "view",
      label: "VIEW",
    },
  ];

  const handleGenerateCAM = async (documentFormat: string) => {
    setIsGeneratingCam(true);
    const payload = {
      loan_application_id: state?.id,
      document_format: documentFormat,
    };
    try {
      const response = await postBlobData(ajaxEndpoints.GENERATE_CAM, payload);

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `CAM_${state?.id}_${moment()
            .format("l")
            .replaceAll("/", "_")}.${documentFormat}`
        );
        link.click();
      } else {
        dispatch(
          showModal({
            modalIndentifier: "feedback",
            dataRecord: {
              type: "failure",
              message:
                response?.data?.message ?? "Unable to generate CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "CreditAnalysis.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Server Error",
          },
        })
      );
    }
    setIsGeneratingCam(false);
  };

  const handleViewCAM = async () => {
    setIsGeneratingCam(true);

    try {
      const response = await getData(
        `/account/loan_applications/${state?.id}/view_cam`
      );

      if (response.data?.status === true) {
        handleViewDocument(false, response?.data?.data);
      } else {
        dispatch(
          showModal({
            modalIndentifier: "feedback",
            dataRecord: {
              type: "failure",
              message:
                response?.data?.message ?? "Unable to retrieve CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ViewLoanApplication.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Server Error",
          },
        })
      );
    }
    setIsGeneratingCam(false);
  };

  let RenderedComponent = null;
  if (!application && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Loan
            Applications
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Loan Application..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Loan Applications, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div>
          {(() => {
            switch (activeTab) {
              case "loan-information":
                return (
                  <LoanInformation data={application} refetchData={refetch} />
                );
              case "business-information":
                return (
                  <BusinessInformation
                    data={application}
                    refetchData={refetch}
                  />
                );
              case "industry":
                return <Industry data={application} refetchData={refetch} />;
              case "financial-information":
                return (
                  <FinancialInformation
                    data={application}
                    refetchData={refetch}
                  />
                );
              case "collateral":
                return <Collateral data={application} refetchData={refetch} />;
              case "audit_trail":
                return <AuditTrail data={application} />;
              case "feedback":
                return <Feedback data={application} refetchData={refetch} />;

              default:
                return null;
            }
          })()}
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> {state?.title}
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) &&
            state?.isViewOnly && (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: camFormatItems,
                  onClick: ({ key }: { key: string }) => {
                    if (key === "pdf") {
                      handleGenerateCAM("pdf");
                    }
                    if (key === "docx") {
                      handleGenerateCAM("docx");
                    }
                    if (key === "view") {
                      handleViewCAM();
                    }
                  },
                }}
              >
                <button className="btn advancly-btn btn-md mr-3">
                  {isGeneratingCam ? <ButtonLoader /> : "Generate CAM"}
                  <DownloadIcon className={styles.DownloadIcon} />
                </button>
              </Dropdown>
            )}

          {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) &&
            !state?.isViewOnly && (
              <div className="d-flex align-items-center justify-content-end">
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: actionItems,
                    onClick: ({ key }: { key: string }) => {
                      if (key === "transfer") {
                        handleTransfer();
                      }
                    },
                  }}
                >
                  <button className="btn advancly-white-btn mr-2">
                    Take Action
                  </button>
                </Dropdown>
              </div>
            )}
        </div>
      </div>

      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Name</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Customer Email</div>
          <div className={styles.content}>
            {application?.data?.data?.funder_email}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Type</div>
          <div className={styles.content}>{state?.application_type}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Reference:</div>
          <div className={styles.content}>
            {application?.data?.data?.ref_name}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Application Date</div>
          <div className={styles.content}>
            {application?.data?.data?.application_date.split("T")[0]}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Status</div>
          <div className={styles.content}>
            {application?.data?.data?.status}
          </div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Relationship Manager Name</div>
          <div className={styles.content}>
            {application?.data?.data?.relationship_manager.trim()
              ? application?.data?.data?.relationship_manager.trim()
              : "NIL"}
          </div>
        </div>
      </div>

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Loan Information"
          id="loan-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Business Information"
            id="business-information"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Industry"
            id="industry"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Financial Information"
            id="financial-information"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Collateral"
            id="collateral"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Approval Trail"
            id="audit_trail"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text=" Appraisal Feedback"
            id="feedback"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {state?.application_type_id === 1 && (
          <ToggleTab
            text="CAM History"
            id="camhistory"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Offer Letter"
            id="offer_letter"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {state?.application_type_id === 1 && (
          <ToggleTab
            text="Pre-disbursement Checklist "
            id="predisbursementchecklist"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        <ToggleTab
          text="Disbursement "
          id="disbursement"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>{RenderedComponent}</div>
    </Fragment>
  );
};

export default ViewLoanApplication;
