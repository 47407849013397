import { ChangeEvent, DragEvent, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as UploadIcon } from "../../../../../../assets/svg/icons/file_2.svg";
import { DownloadUploadedDocument } from "../../../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomBadge from "../../../../../Badge/badge";
import { showModal } from "../../../../../../redux/actions/modal";
import { ReactComponent as MessageIcon } from "../../../../../../assets/svg/icons/message-icon-2.svg";
import useDocumentViewer from "../../../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { RefetchOptions } from "react-query/types/core/query";
import { TPendingLoanResponse } from "../../../../../../custom-hooks/usePendingLoanApplicationById";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { convertToBase64 } from "../../../../../../helpers/convertToBase64";
import { appInsights } from "../../../../../../config/appInsights";
import { putData } from "../../../../../../newApis/dotNetApiMethods";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import { RootState } from "../../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../../helpers/roleTypes";

import styles from "./AggregatorOnboardingReview.module.scss";

interface IFinancialDocuments {
  data: any;
  refetchData: (
    options?: RefetchOptions | undefined
  ) => Promise<TPendingLoanResponse | undefined>;
}

export default function FinancialDocuments({
  data,
  refetchData,
}: IFinancialDocuments) {
  const [error, setError] = useState<null | string>(null);
  const [fileToUpload, setFileToUpload] = useState<FileList | null>();
  const [documentPayload, setDocumentPayload] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const {
    data: {
      data: {
        financial_information,
        financial_analysis_document_file_name,
        financial_analysis_document_url,
      },
    },
  } = data;

  const { handleViewDocument } = useDocumentViewer();

  const fileInputElement = useRef<HTMLInputElement>(null);

  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { id } = match.params as any;

  const handleViewComments = (
    e: any,
    existingComments: string,
    type: string,
    itemId: number
  ) => {
    e.preventDefault();
    dispatch(
      showModal({
        modalIdentifier: "rmloanapplicationcomments",
        dataRecord: {
          type,
          comments: existingComments,
          itemId,
          loanApplicationId: id,
          refetchData,
        },
      })
    );
  };

  const handleBrowseClick = () => {
    if (fileInputElement.current !== null) {
      return fileInputElement.current.click();
    }
  };

  const enableDropping = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const validateFileToUpload = async (file: FileList | null) => {
    setError(null);

    if (file && file.length) {
      if (file[0].name.endsWith(".doc") || file[0].name.endsWith(".docx")) {
        setFileToUpload(file);
        const fileBase64 = await convertToBase64(file[0]);

        const documentToUpload = {
          loan_application_id: Number(id),
          base64_file_string: fileBase64,
          filename_with_extension: file[0].name,
        };

        setDocumentPayload(documentToUpload);
      } else {
        setError("Invalid file type");
        setFileToUpload(null);
      }
    }
  };

  const handleUploadFinancialAnalysis = async () => {
    setIsUploading(true);
    try {
      const payload = {
        ...documentPayload,
      };

      const response = await putData(
        ajaxEndpoints.UPLOAD_FINANCIAL_ANALYSIS,
        payload
      );
      if (response?.data?.status === true) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetchData();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FinancialInformation",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsUploading(false);
  };

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const handleDisableTextEditor = (): boolean => {
    if (
      loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPLICATION_APPROVER) ||
      loggedInUser?.roles.includes(ROLE_TYPES.MCC)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="onboardingOuterLayout">
      <div className="onboardingInnerLayout">
        <div className="w-100">
          <div className={styles.uploadedDocsContainer}>
            {financial_information && financial_information.length ? (
              <div>
                {financial_information.map(
                  (uploadedDocument: any, _id: number) => (
                    <div className="row mb-5" key={_id}>
                      <div className="col-12 d-flex justify-content-between">
                        <label className="form-label">
                          {_id + 1}. {uploadedDocument.financial_type}
                        </label>
                      </div>
                      <div className="col-12 d-flex">
                        <div>
                          {uploadedDocument.documents.length < 1 ? (
                            <small className="text-center">
                              No documents uploaded for this category
                            </small>
                          ) : (
                            uploadedDocument.documents.map((item: any) => (
                              <div className="d-flex" key={uuidv4()}>
                                <div
                                  className={`w-100 ${styles.downloadUploadDocContainer}`}
                                >
                                  <DownloadUploadedDocument
                                    name={item.file_name}
                                    pathname={item.upload_path}
                                    handleView={() =>
                                      handleViewDocument(
                                        false,
                                        item?.upload_path
                                      )
                                    }
                                  />
                                  {/* <CustomTextArea
                                    label="Description"
                                    readOnly
                                    defaultValue={item?.description}
                                  /> */}
                                </div>
                              </div>
                            ))
                          )}
                          <div className={`${styles.commentsAction} `}>
                            <MessageIcon className={styles.messageIcon} />
                            <span
                              className={styles.commentText}
                              onClick={(e) =>
                                handleViewComments(
                                  e,
                                  uploadedDocument?.comment,
                                  "financial_information",
                                  uploadedDocument?.id
                                )
                              }
                            >
                              RM Comments
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        {uploadedDocument?.productTypes &&
                          uploadedDocument.productTypes.map(
                            (item: any, index: number) => (
                              <CustomBadge key={index} text={item?.product} />
                            )
                          )}
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-center py-3">
                <h3 className="font-weight-bold">
                  Aggregator is yet to upload financial documents
                </h3>
              </div>
            )}
            <div className={styles.uploadContainer}>
              <input
                type="file"
                className="d-none"
                ref={fileInputElement}
                id="deal-slip-upload-input"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validateFileToUpload(e.target.files);
                }}
              />

              <div
                className={styles.uploadCard}
                id="deal-slip-upload-div"
                onDragOver={enableDropping}
                onDrop={(e: DragEvent<HTMLDivElement>) => {
                  e.preventDefault();
                  validateFileToUpload(e.dataTransfer.files);
                }}
              >
                <div>
                  <div className={styles.iconContainer}>
                    <UploadIcon />
                  </div>
                  <h3 className={styles.header}>Upload Document</h3>
                  <p>
                    Drag & drop your file here or{" "}
                    <span
                      className={styles.browseAction}
                      onClick={handleBrowseClick}
                    >
                      browse
                    </span>
                  </p>
                  {error && (
                    <h5 className="text-danger font-weight-bold text-center ">
                      {error}
                    </h5>
                  )}
                </div>
              </div>
              <p> {fileToUpload && fileToUpload[0].name}</p>

              <div className={styles.existingFinancialAnalystDoc}>
                {financial_analysis_document_file_name && (
                  <DownloadUploadedDocument
                    name={financial_analysis_document_file_name}
                    pathname={financial_analysis_document_url}
                    handleView={() =>
                      handleViewDocument(false, financial_analysis_document_url)
                    }
                  />
                )}
              </div>

              <button
                className={`create zoom-element btn login-btn ${styles.sendDocument}`}
                onClick={handleUploadFinancialAnalysis}
                disabled={!fileToUpload || handleDisableTextEditor()}
              >
                {isUploading ? <ButtonLoader /> : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
