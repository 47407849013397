import { useState } from "react";
import styles from "./ApproveDeclineMccApprovalSignature.module.scss";
import MccApprovalSignaturePad from "./MccApprovalSignaturePad";
import MccApprovalSignatureUpload from "./MccApprovalSignatureUpload";

const signatureMethod = {
  SIGNATUREPAD: "signaturepad",
  IMAGESIGNATURE: "imagesignature",
};

interface IApproveDeclineMccApprovalSignature {
  successMessage: string;
  errorMessage: string;
  isLoading: boolean;
  handleApproveDecline: (base64: string, fileName: string) => void;
}
const ApproveDeclineMccApprovalSignature = ({
  successMessage,
  errorMessage,
  isLoading,
  handleApproveDecline,
}: IApproveDeclineMccApprovalSignature) => {
  const [selectedSignatureOption, setSelectedSignatureOption] = useState(
    signatureMethod.IMAGESIGNATURE
  );

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown "
      role="document"
    >
      <div
        className="LoanModal Modal__content"
        style={{ overflow: "visible", minHeight: "70vh", height: "auto" }}
      >
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-3 ${styles.signatureModaltitle}`}>
            How will you prefer to sign
          </h2>

          <div>
            <div className={styles.radiobuttongroup}>
              <input
                type="radio"
                id={signatureMethod.IMAGESIGNATURE}
                name="signatureSwitch"
                checked={
                  selectedSignatureOption === signatureMethod.IMAGESIGNATURE
                }
                onChange={() =>
                  setSelectedSignatureOption(signatureMethod.IMAGESIGNATURE)
                }
              />
              <label
                htmlFor={signatureMethod.IMAGESIGNATURE}
                className={` ${styles.radiobutton}  ${
                  selectedSignatureOption === signatureMethod.IMAGESIGNATURE
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Upload Signature
              </label>

              <input
                type="radio"
                id={signatureMethod.SIGNATUREPAD}
                name="signatureSwitch"
                checked={
                  selectedSignatureOption === signatureMethod.SIGNATUREPAD
                }
                onChange={() =>
                  setSelectedSignatureOption(signatureMethod.SIGNATUREPAD)
                }
              />
              <label
                htmlFor={signatureMethod.SIGNATUREPAD}
                className={` ${styles.radiobutton}  ${
                  selectedSignatureOption === signatureMethod.SIGNATUREPAD
                    ? `${styles.active}`
                    : ""
                }`}
              >
                Sign On Screen
              </label>
            </div>
          </div>

          <div>
            {selectedSignatureOption === signatureMethod.IMAGESIGNATURE ? (
              <MccApprovalSignatureUpload
                handleApproveDecline={handleApproveDecline}
                isLoading={isLoading}
              />
            ) : (
              <MccApprovalSignaturePad
                handleApproveDecline={handleApproveDecline}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveDeclineMccApprovalSignature;
