import React, { useState } from "react";

import { ReactComponent as MessageIcon } from "../../../../../assets/svg/icons/message-icon-2.svg";
import { ReactComponent as BackButtonIcon } from "../../../../../assets/svg/icons/back-button.svg";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

import styles from "./businessInfoReview.module.scss";

const BusinessInfoReview = (props: any) => {
  const [comments, setComments] = useState("");
  const [isShowComments, setIsShowComments] = useState(false);

  const {
    dataRecord: { data },
  } = props;

  const handleViewComments = (existingComments: string) => {
    setIsShowComments(true);
    return setComments(existingComments);
  };

  const handleGoBack = () => {
    setIsShowComments(false);
    setComments("");
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div
          className="Modal__body"
          style={{
            height: "80vh",
            overflowY: "scroll",
          }}
        >
          <h3 className={`Modal__title p-b-2 ${styles.BackButton}`}>
            {isShowComments && <BackButtonIcon onClick={handleGoBack} />}
            Business Information
          </h3>

          <form>
            <React.Fragment>
              {data &&
                data?.questions.length > 0 &&
                data?.questions.map((_questionObject: any) => (
                  <div
                    key={_questionObject?.id}
                    className={`row ${styles.BusinessInfoCard}`}
                  >
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <CustomTextArea
                        label={_questionObject?.question}
                        readOnly
                        defaultValue={
                          _questionObject?.selectedAnswer?.questionAnswerText
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <CustomTextArea
                        maxLength={256}
                        label="RM Comments"
                        name="message"
                        defaultValue={
                          _questionObject?.selectedAnswer?.adminComment
                        }
                        disabled
                      />
                    </div>
                    {/* <div
                      className={`${styles.commentsAction} col-sm-4 col-md-4 col-lg-4 `}
                      onClick={(e) =>
                        handleViewComments(
                          _questionObject?.selectedAnswer?.adminComment
                        )
                      }
                    >
                      <MessageIcon className={styles.messageIcon} />
                      <span className={styles.commentText}>Comment</span>
                    </div> */}
                  </div>
                ))}

              {data && data?.questions.length < 1 && (
                <div className="w-100 d-flex align-items-center justify-content-center py-3">
                  <h3 className="font-weight-bold">
                    Aggregator is yet to submit Business Information
                  </h3>
                </div>
              )}
            </React.Fragment>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoReview;
