import style from "./Comments.module.scss";

interface IComments {
  comments: string;
}

const Comments = ({ comments }: IComments) => {
  return (
    <div className={style.commentsContainer}>
      <div className={style.commentsSection}>
        <div className={style.existingComments}>
          <p className={style.comments}>{comments}</p>
        </div>
      </div>
    </div>
  );
};

export default Comments;
