import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Table } from "antd";
import TableAccordion from "../../../Components/TableAccordion";
import { ColumnsType } from "antd/es/table";
import { DataType, TotalIndebtednessDataType } from "../util";
import { RootState } from "../../../../../../redux/store";
import doubleIcon from "../../../../../../assets/svg/icons/double-arrow.svg";
import useLoanTotalIndebtedness from "../../../../../../custom-hooks/useLoanTotalIndebtedness";
import { formatMoney } from "../../../../../../helpers/formatter";
import { v4 as uuidv4 } from "uuid";

interface ITotalIndebtednessTable {
  refetchData: any;
}

const TotalIndebtednessTable = ({ refetchData }: ITotalIndebtednessTable) => {
  const [selectedRecord, setSelectedRecord] = useState<DataType | null>();

  const modalState = useSelector((state: RootState) => state?.modalReducer);
  const location = useLocation();
  const state = location?.state as { id: number };

  const { data: totalIndebtedness, refetch } = useLoanTotalIndebtedness(
    state?.id
  );

  useEffect(() => {
    if (modalState?.showModal === false) {
      setSelectedRecord(null);
    }
  }, [modalState.showModal]);

  const columns: ColumnsType<TotalIndebtednessDataType> = [
    {
      title: "Current Loan",
      dataIndex: "totalCurrentLoan",
      key: "totalCurrentLoan",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.currentLoanAmount)}</span>;
      },
    },
    {
      title: "Advancly",
      dataIndex: "totalAdvanclyLoanAmount",
      key: "totalAdvanclyLoanAmount",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.totalAdvanclyLoanAmount)}</span>;
      },
    },
    {
      title: "Other Lenders",
      dataIndex: "totalOtherLendersLoanAmount",
      key: "totalOtherLendersLoanAmount",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.totalOtherLendersLoanAmount)}</span>;
      },
    },
    {
      title: "Total",
      dataIndex: "totalIndebtedness",
      key: "totalIndebtedness",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.totalIndebtedness)}</span>;
      },
    },
  ];
  return (
    <div className="mt-3 mb-5">
      <TableAccordion
        title={
          <>
            <span>
              Total Indebtedness <img src={doubleIcon} alt="" />
              <span style={{ color: "#713fff" }}>Advancly + Other Lenders</span>
            </span>

            <span
              style={{
                fontWeight: 400,
                color: "#713fff",
                textDecoration: "underline",
                cursor: "pointer",
                marginRight: "1.5rem",
              }}
              onClick={() => refetch()}
            >
              Refresh
            </span>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={[{ ...totalIndebtedness?.data, key: uuidv4() }]}
          scroll={{ x: 1300 }}
          pagination={false}
        />
      </TableAccordion>
    </div>
  );
};

export default TotalIndebtednessTable;
