import { getData } from "../newApis/apiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { useQuery } from "react-query";
type IloanApplicationDetailId = {
  loan_application_ref: string;
};
const getFinalDisbursements = async ({
  loan_application_ref,
}: IloanApplicationDetailId) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_APPLICATION_DISBURSEMENT_HISTORY}?loanApplicationRef=${loan_application_ref}`
  );
  return data;
};

export default function useFinalDisbursements({
  loan_application_ref,
}: IloanApplicationDetailId) {
  return useQuery(
    [{ loan_application_ref }, "getFinalDisbursements"],
    getFinalDisbursements
  );
}
