import { Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import useLoanApplicationDetailFeeLog from "../../../../../../custom-hooks/useLoanApplicationDetailFeeLog";
import { DataType } from "./type";
import { formatMoney } from "../../../../../../helpers/formatter";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import moment from "moment";

const LoanInformationDetailLog = ({ detailId }: any) => {
  const {
    data: loanInformationDetailFeeLog,
    error,
    status,
  } = useLoanApplicationDetailFeeLog(detailId);

  const addSerialNumber = (dataInQuestion: any[]) => {
    if (dataInQuestion && dataInQuestion.length > 0) {
      const result = dataInQuestion.map((_item, index) => {
        return {
          serialNo: index + 1,
          ..._item,
        };
      });

      return result;
    }

    return [];
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "serialNo",
      key: "serialNo",
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      align: "center",
    },
    {
      title: "Loan Application Detail",
      dataIndex: "loan_application_detail",
      width: "12%",
      key: "loan_application_detail",
      align: "center",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.value)}</span>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_date",
      key: "created_date",
      align: "center",
      render: (_, data) => {
        return <span>{moment(data?.created_date).format("LL")}</span>;
      },
    },
  ];

  let RenderedComponent = null;
  if (!loanInformationDetailFeeLog && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Loan Transaction
            Transaction Trail
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Loan Transacton trail..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Transaction Trail, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <Table
          columns={columns}
          dataSource={addSerialNumber(loanInformationDetailFeeLog?.data?.data)}
          scroll={{ x: 1300 }}
        />
      );
    }
  }

  return <div className="mt-3 mb-5">{RenderedComponent}</div>;
};

export default LoanInformationDetailLog;
