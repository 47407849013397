import React, { useState } from "react";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import ajaxMethods from "../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { Loader } from "../../../UI/Loaders/Loaders";
import { accurateQuantifier } from "../../../helpers/accurateQuantifier";
import { currencyFormatter } from "../../../helpers/stringControllers";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import useFinalDisbursements from "../../../custom-hooks/useFinalDisbursements";
import { useDispatch } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { appInsights } from "../../../config/appInsights";
import { dataProps } from "../../../modals/Modals/Loans/Disburse/CreditAnalysis/LoanSchedule/type";

type IdisbursementProps = {
  refName: string;
};
interface Currency {
  code: string;
  name: string;
  decimalPlaces: number;
  inMultiplesOf: number;
  displaySymbol: string | null;
  nameCode: string;
  displayLabel: string;
}

interface Period {
  dueDate: number[]; // [year, month, day]
  principalDisbursed: number;
  principalLoanBalanceOutstanding: number;
  feeChargesDue: number;
  feeChargesPaid: number;
  totalOriginalDueForPeriod: number;
  totalDueForPeriod: number;
  totalPaidForPeriod: number;
  totalActualCostOfLoanForPeriod: number;
  period: number;
  fromDate: number[] | null; // [year, month, day] or null
  obligationsMetOnDate: number[] | null; // [year, month, day] or null
  complete: boolean;
  daysInPeriod: number;
  principalOriginalDue: number;
  principalDue: number;
  principalPaid: number;
  principalWrittenOff: number;
  principalOutstanding: number;
  interestOriginalDue: number;
  interestDue: number;
  interestPaid: number;
  interestWaived: number;
  interestWrittenOff: number;
  interestOutstanding: number;
  feeChargesWaived: number;
  feeChargesWrittenOff: number;
  feeChargesOutstanding: number;
  penaltyChargesDue: number;
  penaltyChargesPaid: number;
  penaltyChargesWaived: number;
  penaltyChargesWrittenOff: number;
  penaltyChargesOutstanding: number;
  totalPaidInAdvanceForPeriod: number;
  totalPaidLateForPeriod: number;
  totalWaivedForPeriod: number;
  totalWrittenOffForPeriod: number;
  totalOutstandingForPeriod: number;
  totalInstallmentAmountForPeriod: number;
}

interface LoanData {
  currency: Currency;
  loanTermInDays: number;
  totalPrincipalDisbursed: number;
  totalPrincipalExpected: number;
  totalPrincipalPaid: number;
  totalInterestCharged: number;
  totalFeeChargesCharged: number;
  totalPenaltyChargesCharged: number;
  totalWaived: number;
  totalWrittenOff: number;
  totalRepaymentExpected: number;
  totalRepayment: number;
  totalPaidInAdvance: number;
  totalPaidLate: number;
  totalOutstanding: number;
  periods: Period[];
}
const Disbursement = ({ refName }: IdisbursementProps) => {
  const { data, error, isLoading } = useFinalDisbursements({
    loan_application_ref: refName,
  });

  const [loanScheduleResponse, setLoanScheduleResponse] = useState<dataProps>();
  const [currentLoanId, setCurrentLoanId] = useState<dataProps>();
  const [loadingScheduleModal, setloadingScheduleModal] = useState(false);
  const generateLoanSchedule = async (
    loan_id: number,
    amountApproved: number,
    loan_effective_date: string,
    approvedTenor: number,
    firstRepaymentDate: string | null
  ) => {
    setloadingScheduleModal(true);
    setLoanScheduleResponse({ status: "loading", data: null, error: false });
    const payload = {
      loanId: loan_id,
      principal: Number(amountApproved),
      loanEffectiveDate: loan_effective_date,
      loanTenor: Number(approvedTenor),
      firstRepaymentDate: firstRepaymentDate || null,
    };

    try {
      const response = await ajaxMethods.post(
        `${ajaxEndpoints.GENERATE_PENDING_LOAN_SCHEDULE}`,
        payload
      );

      if (
        response?.data?.status === true &&
        response?.data?.status_code !== 500
      ) {
        setLoanScheduleResponse({
          data: response?.data?.data,
          status: "success",
          error: false,
        });
        if (response?.data?.data) {
          handleLoanSchedule({
            data: response?.data?.data,
            status: "success",
            error: false,
          });
          setloadingScheduleModal(false);
        }
      } else {
        setLoanScheduleResponse({
          data: null,
          status: "error",
          error: true,
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Disbursement.tsx",
        },
      });
      setLoanScheduleResponse({
        data: null,
        status: "error",
        error: true,
      });
    }
  };
  const dispatch = useDispatch();

  const handleLoanSchedule = (responseScheduleData: {
    data: LoanData;
    status: string;
    error: boolean;
  }) => {
    dispatch(
      showModal({
        modalIdentifier: "viewloanschedule",
        loanScheduleResponse: responseScheduleData,
      })
    );
  };

  let RenderedComponent = null;
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to fetch Disbursement Data
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    RenderedComponent = (
      <Loader text="Loading Disbursements ..." centered={true} />
    );
  } else if (data) {
    if (data?.data?.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Disbursement Available
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Loan Ref</th>
                <th>Product Name</th>
                <th>Tenure</th>
                <th>Repayment Frequency</th>
                <th>Amount</th>
                <th>Amount to Repay</th>
                <th>Effective Date</th>
                <th>Due Date</th>
                <th>Loan Status</th>
                <th>Loan Schedule</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                data?.data &&
                data?.data.map((_data: any, _id: number) => {
                  const {
                    loan_id,
                    loan_amount,
                    loan_amount_repay,
                    loan_due_date,
                    status,
                    loan_effective_date,
                    loan_effective_date2,
                    loan_ref,
                    loan_tenure,
                    repayment_type,
                    currency,
                    product_name,
                    first_repayment_date,
                    loan_status,
                  } = _data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{loan_ref}</td>
                      <td>{product_name}</td>
                      <td>{accurateQuantifier(loan_tenure, "day")}</td>
                      <td>{repayment_type}</td>
                      <td>
                        {currencyFormatter(Number(loan_amount), currency)}
                      </td>
                      <td>
                        {currencyFormatter(Number(loan_amount_repay), currency)}
                      </td>
                      <td>
                        {loan_effective_date
                          ? loan_effective_date.substring(0, 10)
                          : ""}
                      </td>
                      <td>{loan_due_date.substring(0, 10)}</td>
                      <td>
                        <span className={attachClassNameToTag(status)}>
                          {attachClassNameToTag(status)}
                        </span>
                      </td>
                      <td>
                        <button
                          disabled={loan_status !== "active" ? true : false}
                          className="cursor-pointer  m-r-1 create btn advancly-btn
"
                          onClick={() => {
                            setCurrentLoanId(loan_id);
                            generateLoanSchedule(
                              loan_id,
                              loan_amount,
                              loan_effective_date2,
                              loan_tenure,
                              first_repayment_date
                            );
                          }}
                        >
                          {loadingScheduleModal && currentLoanId === loan_id
                            ? "loading...."
                            : "View Loan Schedule"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      {loanScheduleResponse?.error === true && (
        <p className="alert alert-danger small">Error generating schedule</p>
      )}

      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div
          style={{
            height: "25rem",
            overflowY: "scroll",
          }}
        >
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default Disbursement;
