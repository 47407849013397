import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../config/appInsights";
import {
  deleteData,
  postData,
  putData,
} from "../../../../../newApis/dotNetApiMethods";
import CustomSearchDropDown from "../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { ReactComponent as BackButtonIcon } from "../../../../../assets/svg/icons/back-button.svg";
import TableWrapper from "../../../../../components/AutomatedCredit/CreditAnalysis/Components/TableWrapper";
import { Dropdown, Table } from "antd";
import { addSerialNumber } from "../../../../../components/AutomatedCredit/CreditAnalysis/util";
import { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/es/menu/hooks/useItems";
import useLoanApplicationDetailFees from "../../../../../custom-hooks/useLoanApplicationDetailFees";
import useFees, { TFees } from "../../../../../custom-hooks/useFees";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import ConfirmationModal from "../../../../../NewComponents/ConfirmationModal";

import styles from "./loanAppplicationDetailFees.module.scss";

const schema = yup.object().shape({
  value: yup.string().required("Please enter value"),
});

enum ClassficationType {
  Percentage = 1,
  Value = 2,
}

type TColumn = {
  serialNumber?: string;
  fee_name: string;
  calculation_type?: string;
  calculation_type_id: number;
  proposed_value: string;
  id: number;
  fee_id: number;
};

const LoanApplicationDetailFees = (props: any) => {
  const [isAddOrEditFee, setIsAddOrEditFee] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<TColumn>();
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedFees, setSelectedFees] = useState<{
    label: string;
    value: string;
    data: TColumn;
  }>();

  const {
    dataRecord: {
      loan_info_data: { id },
    },
  } = props;

  const {
    data: loanApplicationDetailFees,
    refetch,
    status,
    error,
  } = useLoanApplicationDetailFees(id);
  const { data: fees } = useFees();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeType = () => {
    return null;
  };

  const onSubmit = (values: any) => {
    if (isEdit) {
      return handleEdit(values);
    }

    return handleCreate(values);
  };

  const handleCreate = async (values: any) => {
    setIsLoading(true);

    if (!selectedFees) {
      setIsLoading(false);
      return setErrorMessage("You have not selected Fee");
    }

    try {
      const payload = {
        loan_application_detail_id: Number(id),
        fee_id: selectedFees?.value,
        calculation_type: selectedFees?.data.calculation_type_id,
        proposed_value: values?.value,
      };

      const response = await postData(
        ajaxEndpoints.LOAN_APPLICATION_DETAIL_FEES,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        refetch();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  const handleEdit = async (values: any) => {
    setIsLoading(true);

    if (!selectedFees) {
      setIsLoading(false);
      return setErrorMessage("You have not selected Fee");
    }

    try {
      const payload = {
        loan_application_detail_id: Number(id),
        fee_id: selectedFees?.data?.fee_id,
        calculation_type: selectedFees?.data?.calculation_type_id,
        proposed_value: Number(values?.value),
        id: Number(selectedFees?.data?.id),
      };

      const response = await putData(
        ajaxEndpoints.LOAN_APPLICATION_DETAIL_FEES,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsLoading(false);
  };

  const hanndleChangeFee = (data: {
    label: string;
    value: string;
    data: TColumn;
  }) => {
    setSelectedFees(undefined);
    setTimeout(() => {
      return setSelectedFees(data);
    }, 1000);
  };

  const handleEditClick = (_data: TColumn) => {
    setSelectedFees({
      data: {
        calculation_type_id: _data?.calculation_type_id,
        id: _data?.id,
        fee_id: _data?.fee_id,
        fee_name: _data?.fee_name,
        proposed_value: _data?.proposed_value,
      },
      label: _data?.fee_name,
      value: String(_data?.id),
    });
    setIsAddOrEditFee(true);
    setIsEdit(true);
  };

  const handlDeleteClick = (_data: TColumn) => {
    setSelectedData(_data);
    setIsShowConfirmationModal(true);
  };

  const handleDeleteFee = async () => {
    setIsDeleting(true);

    try {
      const response = await deleteData(
        `${ajaxEndpoints.LOAN_APPLICATION_DETAIL_FEES}/${selectedData?.id}`
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props.onCloseModal();
        refetch();
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Modals/LoanApplication/Fees/index.tsx" },
      });
    }
    setIsDeleting(false);
  };

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
    },
    { key: "delete", label: "Delete" },
  ];

  const columns: ColumnsType<TColumn> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Fee",
      dataIndex: "fee_name",
      key: "fee_name",
      align: "center",
    },
    {
      title: "Fee Calculation Type",
      dataIndex: "calculation_type",
      key: "calculation_type",
      align: "center",
    },
    {
      title: "Proposed Value",
      dataIndex: "proposed_value",
      key: "proposed_value",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleEditClick(_data);
              }

              if (key === "delete") {
                handlDeleteClick(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];

  const handleGoBack = () => {
    setIsAddOrEditFee(false);
    setIsEdit(false);
  };

  const getDeafultOptions = (_fees: TFees) => {
    if (_fees) {
      const result = _fees?.data?.data.map((_fee) => {
        return {
          label: _fee?.name,
          value: _fee?.id,
          data: _fee,
        };
      });

      return result;
    }

    return [];
  };

  const getDefaultCalculationType = (
    _selectedFees:
      | {
          label: string;
          value: string;
          data: TColumn;
        }
      | undefined
  ) => {
    if (_selectedFees) {
      const result = {
        label: ClassficationType[_selectedFees?.data?.calculation_type_id],
        value: _selectedFees?.data?.calculation_type_id,
      };

      return result;
    }

    return {};
  };

  let RenderedComponent = null;
  if (!loanApplicationDetailFees && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Loan Application
            Detail Fee
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Loan Applicaton Detail Fees..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Application Application Detail Fees.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <TableWrapper>
          <Table
            columns={columns}
            loading={false}
            dataSource={addSerialNumber(loanApplicationDetailFees?.data?.data)}
            pagination={false}
            rowKey={(record) => record?.id}
          />
        </TableWrapper>
      );
    }
  }

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content" style={{ height: "80vh" }}>
        {errorMessage && (
          <p className="alert alert-danger mb-4 small">{errorMessage}</p>
        )}
        {successMessage && (
          <p className="alert alert-success mb-4 small">{successMessage}</p>
        )}
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className={`Modal__title p-b-2 ${styles.BackButton}`}>
            {(isAddOrEditFee || isEdit) && (
              <BackButtonIcon onClick={handleGoBack} />
            )}
            Fees
          </h3>

          {!isAddOrEditFee && (
            <div className={styles.ActionContainer}>
              <div className={styles.FormButtonContainer}>
                <button
                  className={`btn advancly-btn btn-md ${styles.CreateActionButton}`}
                  onClick={() => {
                    setIsAddOrEditFee(true);
                    setSelectedFees(undefined);
                  }}
                >
                  Add Fee
                </button>
              </div>
            </div>
          )}

          {!isAddOrEditFee && <>{RenderedComponent}</>}

          {isAddOrEditFee && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-12">
                  <label>Fee</label>
                  <div className="col-md-12 p-0">
                    <CustomSearchDropDown
                      defaultOptions={fees && getDeafultOptions(fees)}
                      onChange={hanndleChangeFee}
                      isSearchable={false}
                      isMulti={false}
                      defaultValue={{
                        label: selectedFees?.label,
                        value: selectedFees?.value,
                      }}
                      placeholder="Select Fees"
                    />
                  </div>
                </div>
              </div>

              {(selectedFees || selectedData) && (
                <div className="row">
                  <div className="col-sm-12">
                    <label>Calculation Type</label>
                    <div className="col-md-12 p-0">
                      <CustomSearchDropDown
                        defaultOptions={[
                          {
                            label: "Percentage",
                            value: ClassficationType.Percentage,
                          },
                          { label: "Value", value: ClassficationType.Value },
                        ]}
                        defaultValue={getDefaultCalculationType(selectedFees)}
                        onChange={handleChangeType}
                        isSearchable={false}
                        isMulti={false}
                        placeholder="Select Calculation Type"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  <label>Proposed Value</label>
                  <div className="form-group inputWithIcon">
                    <input
                      type="text"
                      className="form-control"
                      step="any"
                      name="value"
                      ref={register}
                      defaultValue={selectedFees?.data?.proposed_value}
                    />
                  </div>
                </div>
              </div>

              <div className="Form__action-buttons mt-1 mb-3">
                <button
                  type="submit"
                  className="btn zoom-element btn login-btn"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleDeleteFee();
          }}
          error={""}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default LoanApplicationDetailFees;
