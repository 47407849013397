import LoanInformationDetailLog from "../LoanApprovalTrail/LoanInformationDetailLog";

const LoanApprovalTrail = (props: any) => {
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className="Modal__title mb-5">Loan Approval Trail</h2>

          <LoanInformationDetailLog
            detailId={props?.dataRecord?.loan_info_data?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default LoanApprovalTrail;
