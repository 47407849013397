import { useState } from "react";

import ConditionsSetup from "./Tabs/Conditions";
import ConvenantsSetup from "./Tabs/Convenants";
import ToggleTab from "../../../NewComponents/ToggleTab/ToggleTab.component";
import FeesSetup from "./Tabs/Fees";

import styles from "./setup.module.scss";

export default function CreditAnalysisSettings() {
  const [activeTab, setActiveTab] = useState("conditions");

  return (
    <div className="datatable-wrapper">
      <div className="table-view animated speed-1x fadeInRight">
        <div className="block-header">
          <h3 className="page-title mb-4">Credit Analysis Setup</h3>
          <div className="alignToggleTabItems mb-3">
            <ToggleTab
              text="Conditions"
              id="conditions"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Covenants"
              id="convenants"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Fees"
              id="fees"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>

        {(() => {
          switch (activeTab) {
            case "conditions":
              return <ConditionsSetup />;

            case "convenants":
              return <ConvenantsSetup />;

            case "fees":
              return <FeesSetup />;

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
}
