import React, { useState } from "react";
import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import OfferLetterComp from "./OfferLetterComp";
import { Tdata } from "../../custom-hooks/usePendingLoanApplications";
import { Toaster } from "react-hot-toast";
import OfferLetterPendingApplication from "./OfferLetterPendingApplication";
import { ReactComponent as BackButtonIcon } from "../../assets/svg/icons/back-button.svg";
import styles from "./PrepareOfferLetter.module.scss";

export enum PrepareOfferLetterSection {
  PendingLoans = "PendingLoans",
  OfferLetter = "OfferLetter",
}

const PrepareOfferLetter = (props: any) => {
  const [selectedLoanData, setSelectedLoanData] = useState<Tdata | null>(null);
  const [currentSection, setCurrentSection] =
    useState<PrepareOfferLetterSection>(PrepareOfferLetterSection.PendingLoans);

  const renderPrepareOfferLetterSection = () => {
    switch (currentSection) {
      case PrepareOfferLetterSection.PendingLoans:
        return (
          <OfferLetterPendingApplication
            setCurrentSection={setCurrentSection}
            setSelectedLoanData={setSelectedLoanData}
          />
        );
      case PrepareOfferLetterSection.OfferLetter:
        return (
          <OfferLetterComp
            selectedLoanData={selectedLoanData}
            setCurrentSection={setCurrentSection}
          />
        );

      default:
        return null;
    }
  };

  const goBack = () => {
    setCurrentSection(PrepareOfferLetterSection.PendingLoans);
  };

  return (
    <div className="speed-1x animated fadeInRight">
      <header>
        {currentSection === PrepareOfferLetterSection.OfferLetter ? (
          <h3 className={styles.backButton} onClick={goBack}>
            <BackButtonIcon />
          </h3>
        ) : (
          <h3 className="page-title d-flex align-items-center mb-3">
            <BackButtonComponent /> Prepare Offer Letter
          </h3>
        )}
      </header>

      {renderPrepareOfferLetterSection()}
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
};

export default PrepareOfferLetter;
