import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as apiEndpoints from "../api/ajax-endpoints";
import { IResponse } from "../interfaces/response";

export type OfferLetterDetails = {
  id: number;
  loan_application_offer_letter_id: number;
  document_type: string;
  document_type_id: number;
  legal_document_url: string;
  legal_document_filename: string;
  customer_document_url: string;
  customer_document_filename: string;
  customer_datetime_uploaded: string;
  approval_status_id: number;
  approval_status: string;
  legal_signed_document_url: string;
  legal_signed_document_filename: string;
  legal_signed_user: string;
  legal_signed_user_id: number;
  legal_signed_datetime: string;
  approved_by_user: string;
  approved_by_user_id: number;
  approved_on_datetime: string;
  created_by_user: string;
  created_by_user_id: number;
  datetime_created: string;
  feedbacks: [];
};

type TOfferAndSla = {
  id: number;
  loan_application_id: number;
  reference_number: string;
  is_current: boolean;
  customer_comment: string;
  rm_comment: string;
  status_id: number;
  status: string;
  customer_id: number;
  customer: string;
  created_by_user_id: number;
  created_by_user: string;
  date_time_created: string;
  offer_letter_details: OfferLetterDetails[];
};

async function fetchOfferAndSLA({
  customer_id,
  loan_application_id,
  is_current,
}: {
  customer_id: number;
  loan_application_id: number;
  is_current?: boolean;
}) {
  let query = `?customer_id=${customer_id}&loan_application_id=${loan_application_id}`;

  if (is_current) {
    query = `${query}&is_current=${is_current}`;
  }

  const { data } = await getData(`${apiEndpoints.GET_OFFER_AND_SLA}${query}`);

  return data;
}

export default function useFetchOfferAndSLA({
  customer_id,
  loan_application_id,
  is_current,
}: {
  customer_id: number;
  loan_application_id: number;
  is_current?: boolean;
}) {
  return useQuery<IResponse<TOfferAndSla>>(
    [{ customer_id, loan_application_id, is_current }, "fetchOfferAndSLA"],
    fetchOfferAndSLA,
    {
      refetchOnWindowFocus: false,
    }
  );
}
