import { useDispatch } from "react-redux";
import TableAccordion from "../../../../Components/TableAccordion";
import { TextEditor } from "../../../../Components/TextEditor";
import { showModal } from "../../../../../../../redux/actions/modal";

import styles from "./creditScoreComments.module.scss";

interface ICreditScoreCommments {
  defaultValue: string;
  handleChange: any;
  readOnly?: boolean;
}

const CreditScoreComments = ({
  defaultValue,
  handleChange,
  readOnly,
}: ICreditScoreCommments) => {
  return (
    <div className={styles.CreditScoreComments}>
      <TableAccordion
        title={<span className={styles.Title}>CREDIT DECISION COMMENTS</span>}
        isDefaultOpen
      >
        <TextEditor
          onChange={handleChange}
          defaultValue={defaultValue}
          readOnly={readOnly}
        />
      </TableAccordion>
    </div>
  );
};

export default CreditScoreComments;
