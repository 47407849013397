import { useState } from "react";

import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { putData } from "../../../../newApis/dotNetApiMethods";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { useForm } from "react-hook-form";
import { TextEditor } from "../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";
import { useQueryCache } from "react-query";
import CustomTextArea from "../../../../NewComponents/CustomHTMLElements/CustomTextArea";

const ApproveRejectOfferSla = (props: any) => {
  const [getApprovedRichTextComments, setGetApprovedRichTextComments] =
    useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const queryCache = useQueryCache();

  const { register, handleSubmit, errors } = useForm({
    // resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const {
    dataRecord: {
      id,
      customer_id,
      loan_application_id,
      offer_letter_detail_id,
      action,
    },
    onCloseModal,
  } = props;

  const handleChange = (data: string) => {
    setGetApprovedRichTextComments(data);
  };
  const handleEventComments = (e: any) => {
    setGetApprovedRichTextComments(e.target.value);
  };

  const handleApprovalOrReject = async () => {
    setIsLoading(true);
    const reqBody = {
      id: id,
      loan_application_id: loan_application_id,
      offer_letter_details: [
        {
          offer_letter_detail_id: offer_letter_detail_id,
          status: action === "approve" ? 2 : 3,
          comment: getApprovedRichTextComments,
        },
      ],
      customer_id: customer_id,
    };
    try {
      const response = await putData(
        ajaxEndpoints.APPROVE_INDIVIDUAL_OFFER_DOC,
        reqBody
      );

      if (response?.data?.status === true) {
        queryCache.invalidateQueries([
          { customer_id, loan_application_id },
          "fetchOfferAndSLA",
        ]);
        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.data?.message },
            })
          );
        }, 2000);
      } else {
        onCloseModal();

        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.data?.message },
            })
          );
        }, 2000);
      }
    } catch (error: any) {
      queryCache.invalidateQueries([
        { customer_id, loan_application_id },
        "fetchOfferAndSLA",
      ]);
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="Modal__dialog md-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h2 className={`Modal__title mb-5 font-weight-bold`}>
            {action === "approve"
              ? "Approve Offer Document"
              : "Reject Offer Document"}
          </h2>
          <form onSubmit={handleSubmit(handleApprovalOrReject)}>
            {action === "approve" ? (
              <TextEditor onChange={handleChange} defaultValue={""} />
            ) : (
              <CustomTextArea
                maxLength={256}
                placeholder="Enter message here..."
                label="Feedback message"
                name="feedback"
                onChange={handleEventComments}
              />
            )}
            {/* <TextEditor onChange={handleChange} defaultValue={""} /> */}

            <button
              className="create zoom-element btn login-btn"
              type="submit"
              disabled={isLoading}
            >
              {isLoading
                ? "Loading..."
                : action === "approve"
                ? "Approve"
                : "Reject"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApproveRejectOfferSla;
