import { useState } from "react";
import { Table } from "antd";
import TableWrapper from "../../../../../../components/AutomatedCredit/CreditAnalysis/Components/TableWrapper";
import { ColumnsType } from "antd/es/table";
import useConditions from "../../../../../../custom-hooks/useConditions";
import { addSerialNumber } from "../../../../../../components/AutomatedCredit/CreditAnalysis/util";
import { postData } from "../../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../../../../../config/appInsights";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import CustomSearchDropDown from "../../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";

type DataType = {
  serialNumber: number;
  condition: string;
  type: string;
  class: string;
  id: number;
};

interface IConditionFromSetup {
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | null>>;
  data: any;
}

const ConditionFromSetup = ({
  data: props,
  setErrorMessage,
  setSuccessMessage,
}: IConditionFromSetup) => {
  const [selectedRecords, setSelectedRecords] = useState<DataType[]>();
  const { data: conditions, isLoading: isLoadingConditions } = useConditions();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLoanApplicationDetail, setSelectedLoanApplicationDetail] =
    useState<number | null>(null);

  const location = useLocation();
  const state = location?.state as { id: number };

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      align: "center",
    },
  ];

  const {
    dataRecord: {
      data: {
        data: {
          data: { loan_application_details },
        },
      },
    },
  } = props;

  const handleApplicationRequestChange = (selectedItem: { value: string }) => {
    return setSelectedLoanApplicationDetail(Number(selectedItem?.value));
  };

  const getLoanApplicationDetailList = (
    _listInQuestion:
      | { approved_loan_product_detail: string; id: number }[]
      | undefined
  ) => {
    if (_listInQuestion) {
      const result = _listInQuestion.map((_item) => {
        return {
          label: _item?.approved_loan_product_detail,
          value: _item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const getDefaultLoanApplicationDetail = (
    _listInQuestion:
      | { approved_loan_product_detail: string; id: number }[]
      | undefined,
    loanApplicationDetailId: number
  ) => {
    if (_listInQuestion) {
      const result = _listInQuestion.find(
        (_item) => _item?.id === loanApplicationDetailId
      );

      return {
        label: result?.approved_loan_product_detail,
        value: result?.id,
      };
    }

    return {};
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      return setSelectedRecords(selectedRows);
    },
  };

  const cleanupRecordsForPosting = (records: DataType[] | undefined) => {
    if (records) {
      const result = records.map((_record) => {
        return {
          class: _record?.class,
          type: _record?.type,
          condition: _record?.condition,
          loanApplicationId: state?.id,
          conditionId: _record?.id,
          loanApplicationDetailId: selectedLoanApplicationDetail,
        };
      });

      return result;
    }

    return [];
  };

  const handleCreate = async () => {
    setIsLoading(true);

    try {
      const payload = cleanupRecordsForPosting(selectedRecords);

      const response = await postData(
        ajaxEndpoints.LOAN_APPLICATION_CONDITIONS,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName:
            "Modals/Loans/LoanApplication/LoanSpecificCondtions/FromSetup",
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-sm-12">
          <label>Loan Application Request</label>
          <div className="col-md-12 p-0">
            <CustomSearchDropDown
              defaultOptions={getLoanApplicationDetailList(
                loan_application_details
              )}
              defaultValue={getDefaultLoanApplicationDetail(
                loan_application_details,
                props?.dataRecord?.oldData?.loanApplicationDetailId
              )}
              onChange={() => {
                handleApplicationRequestChange(
                  props?.dataRecord?.oldData?.loanApplicationDetailId
                );
              }}
              isSearchable={false}
              isMulti={false}
              placeholder="Select Type"
            />
          </div>
        </div>
      </div>
      <TableWrapper>
        <Table
          columns={columns}
          loading={isLoadingConditions}
          dataSource={addSerialNumber(conditions?.data?.data)}
          pagination={false}
          rowKey={(record) => record?.serialNumber}
          rowSelection={{
            ...rowSelection,
          }}
        />
      </TableWrapper>
      <div className="mt-5">
        <button
          className="btn advancly-btn btn-md m-0"
          onClick={handleCreate}
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader /> : "Save Condition"}
        </button>
      </div>
    </div>
  );
};

export default ConditionFromSetup;
