import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { showModal } from "../../../../../../redux/actions/modal";
import { postData } from "../../../../../../newApis/apiMethods";
import { TextEditor } from "../../../../../../components/AutomatedCredit/CreditAnalysis/Components/TextEditor";
interface CompleteModalProps {
  dataRecord: {
    loanId: number;
    customerId: number;
  };
  onCloseModal: () => void;
}
const PreDisbursementCompleteModal = ({
  onCloseModal,
  dataRecord,
}: CompleteModalProps) => {
  const [getApprovedRichTextComments, setGetApprovedRichTextComments] =
    useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (data: string) => {
    setGetApprovedRichTextComments(data);
  };
  const handleCompletePredisbursement = async () => {
    setIsLoading(true);
    const reqBody = {
      loan_application_id: dataRecord?.loanId,
      customer_id: dataRecord?.customerId,
      comment: getApprovedRichTextComments,
    };

    try {
      const response = await postData(
        ajaxEndpoints.COMPLETE_PRE_DISBURSEMENT,
        reqBody
      );

      if (response?.data?.status === true) {
        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.message },
            })
          );
        }, 2000);
      } else {
        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.message },
            })
          );
        }, 2000);
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content">
        <div className="Modal__body">
          <div className="d-flex justify-content-center ">
            <h2 className=" font-weight-bold" style={{ color: "#200865" }}>
              Are you sure you want to Complete Pre Disbursement
            </h2>
          </div>

          <div>
            <form>
              <h5
                className={` p-1 font-weight-bold`}
                style={{ color: "#200865" }}
              >
                Add Comments{" "}
              </h5>
              <TextEditor onChange={handleChange} defaultValue={""} />
            </form>
          </div>

          <div className="d-flex justify-content-around px-4 py-4">
            <button
              className="create zoom-element btn  login-btn"
              onClick={handleCompletePredisbursement}
            >
              Yes{" "}
              {isLoading && (
                <span className="spinner-border spinner-border-sm ml-2"></span>
              )}
            </button>
            <button
              className="create zoom-element btn login-btn"
              onClick={onCloseModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreDisbursementCompleteModal;
