import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export type TResponse = {
  data: {
    data: {
      class: string;
      condition: string;
      type: string;
    }[];
  };
};

const getLoanApplicationConditions = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_APPLICATION_CONDITIONS}?loanApplicationId=${id}`
  );

  return data;
};

export default function useLoanApplicationConditions(id: number) {
  return useQuery<TResponse>(
    [id, "getLoanApplicationConditions"],
    getLoanApplicationConditions,
    {
      refetchOnWindowFocus: false,
    }
  );
}
