import { Table } from "antd";

import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import useLoanApplicationTransactionTrail from "../../../../../../custom-hooks/useLoanApplicationTransactionTrail";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { DataType } from "./type";

interface ILoanApplicationTransactionTrail {
  id: number;
}

const LoanApplicationTransactionTrail = ({
  id,
}: ILoanApplicationTransactionTrail) => {
  const {
    data: transactionTrail,
    status,
    error,
  } = useLoanApplicationTransactionTrail(id);

  const columns: ColumnsType<DataType> = [
    {
      title: "Loan Reference",
      dataIndex: "loan_application_ref",
      key: "loan_application_ref",
      align: "center",
    },
    {
      title: "Start Date",
      dataIndex: "start_date_time",
      width: "12%",
      key: "start_date_time",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {_data?.start_date_time
              ? moment(_data?.start_date_time).format("ll")
              : ""}
          </span>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date_time",
      width: "12%",
      key: "end_date_time",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {_data?.end_date_time
              ? moment(_data?.end_date_time).format("ll")
              : ""}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Comment",
      dataIndex: "user_comment",
      key: "user_comment",
      align: "center",
    },
    {
      title: "Started By",
      dataIndex: "started_by_user_name",
      key: "started_by_user_name",
      align: "center",
    },
    {
      title: "Completed By",
      dataIndex: "completed_by_user_name",
      key: "completed_by_user_name",
      align: "center",
    },
  ];

  let RenderedComponent = null;
  if (!transactionTrail && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Loan Transaction
            Transaction Trail
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Loan Transacton trail..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Loan Transaction Trail, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <Table columns={columns} dataSource={transactionTrail?.data?.data} />
      );
    }
  }

  return <div className="mt-3 mb-5">{RenderedComponent}</div>;
};

export default LoanApplicationTransactionTrail;
