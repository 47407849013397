import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type Tdata = {
  class: string;
  condition: string;
  id: number;
  type: string;
};

type TResponse = {
  data: {
    data: Tdata[];
  };
};

const getConditions = async () => {
  const data = await getData(ajaxEndpoints.GET_CONDITIONS);

  return data;
};

export default function useConditions() {
  return useQuery<TResponse>("getConditions", getConditions, {
    refetchOnWindowFocus: false,
  });
}
