import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type Tdata = {
  id: number;
  name: string;
  is_active: boolean;
};

export type TProductCategoryResponse = {
  data: {
    data: Tdata[];
  };
};

export const getProductCategories = async () => {
  const data = await getData(ajaxEndpoints.LOAN_PRODUCT_CATEGORY);

  return data;
};

export default function useProductCategory() {
  return useQuery<TProductCategoryResponse>(
    "getProductCategories",
    getProductCategories,
    {
      refetchOnWindowFocus: false,
    }
  );
}
