import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type Tdata = {
  id: number;
  name: string;
  is_active: boolean;
  loan_product_category_id: string;
  pub_date: string;
  loan_product_details: {
    amortization_type: number;
    core_banking_product_id: string;
    core_banking_tenant: string;
    currency: string;
    disbursement_account_name: string;
    disbursement_account_number: string;
    disbursement_channel_id: number;
    disbursement_channel_name: string;
    disbursement_channel_account_id: number;
    interest_type: string;
    interest_type_id: number;
    loan_type_id: number;
    loan_type: string;
    maximum_amount: number;
    maximum_interest_rate: number;
    maximum_tenor: number;
    minimum_amount: number;
    minimum_interest_rate: number;
    minimum_tenor: number;
    moratorium_type: string;
    moratorium_type_id: number;
    repayment_account_name: string;
    repayment_account_number: string;
    repayment_channel_account_id: number;
    repayment_channel_id: number;
    repayment_channel_name: string;
    repayment_frequency: string;
  }[];
};

type TLoanProductResponse = {
  data: {
    data: Tdata;
  };
};

const getLoanApplicationDetailFees = async (id: number) => {
  const data = await getData(
    `${ajaxEndpoints.LOAN_APPLICATION_DETAIL_FEES}?loan_application_detail_id=${id}`
  );

  return data;
};

export default function useLoanApplicationDetailFees(id: number) {
  return useQuery(
    [id, "getLoanApplicationDetailFees"],
    getLoanApplicationDetailFees,
    {
      refetchOnWindowFocus: false,
    }
  );
}
