import { useState } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { Table } from "antd";
import TableAccordion from "../../../Components/TableAccordion";
import { ColumnsType } from "antd/es/table";
import { AdvanclyLoansDataType } from "../util";
import doubleIcon from "../../../../../../assets/svg/icons/double-arrow.svg";
import { formatMoney } from "../../../../../../helpers/formatter";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import useAdvanclyIndebtedness from "../../../../../../custom-hooks/useAdvanclyIndebtedness";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import { postData } from "../../../../../../newApis/dotNetApiMethods";
import { showModal } from "../../../../../../redux/actions/modal";

interface IAdvanclyLoansTable {
  loanApplication: any;
}

const AdvanclyLoansTable = ({ loanApplication }: IAdvanclyLoansTable) => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const location = useLocation();
  const state = location?.state as { id: number };
  const dispatch = useDispatch();

  const { data: loansByAdvancly, refetch } = useAdvanclyIndebtedness(state?.id);

  const handleRefresh = () => {
    loanCustomerDebt();
  };

  const loanCustomerDebt = async () => {
    setIsRefreshing(true);
    try {
      const response = await postData(ajaxEndpoints.LOAD_CUSTOMER_DEBTS, {
        customerId: loanApplication?.data?.data?.funder_id,
        loanApplicationId: state?.id,
      });

      if (response?.data?.status === true) {
        refetch();
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsRefreshing(false);
  };

  const columns: ColumnsType<AdvanclyLoansDataType> = [
    {
      title: "Loan Reference",
      dataIndex: "loanReference",
      width: "12%",
      key: "loanReference",
      align: "center",
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      key: "loanAmount",
      width: "12%",
      align: "center",
      render: (_, data) => {
        return <span>{formatMoney(data?.loanAmount, data?.currency)}</span>;
      },
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      align: "center",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
      align: "center",
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      width: "12%",
      align: "center",
      render: (_, data) => {
        return (
          <span>{formatMoney(data?.outstandingAmount, data?.currency)}</span>
        );
      },
    },
    {
      title: "Exchange Amount",
      dataIndex: "exchangeAmount",
      key: "exchangeAmount",
      width: "12%",
      align: "center",
      render: (_, data) => {
        return (
          <span>
            {formatMoney(data?.exchangeAmount, data?.exchangeCurrency)}
          </span>
        );
      },
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      key: "tenor",
      align: "center",
    },
    {
      title: "Loan Status",
      dataIndex: "loanStatus",
      key: "loanStatus",
      align: "center",
    },
  ];
  return (
    <div className="mt-3 mb-5">
      <TableAccordion
        title={
          <>
            <span style={{ display: "inline-block" }}>
              Indebtedness <img src={doubleIcon} alt="" />
              <span style={{ color: "#713fff" }}>Advancly</span>
            </span>

            {isRefreshing ? (
              <ButtonLoader />
            ) : (
              <span
                style={{
                  fontWeight: 400,
                  color: "#713fff",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginRight: "1.5rem",
                }}
                onClick={handleRefresh}
              >
                Refresh
              </span>
            )}
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={loansByAdvancly?.data}
          scroll={{ x: 1300 }}
          pagination={false}
        />
      </TableAccordion>
    </div>
  );
};

export default AdvanclyLoansTable;
