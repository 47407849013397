import { ChangeEvent, useEffect, useState } from "react";

// import { appInsights } from "../../config/appInsights";

import Paginator from "../Pagination/Pagination.component";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader, { Loader } from "../../UI/Loaders/Loaders";
import useLoanApplications, {
  TQuery,
  Tdata,
} from "../../custom-hooks/usePendingLoanApplications";
import styles from "./PrepareOfferLetter.module.scss";
import { PrepareOfferLetterSection } from "../PrepareOfferLetter/PrepareOfferLetter";

interface CreditAnalystPendingLoanApplicationProps {
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<PrepareOfferLetterSection>
  >;
  setSelectedLoanData?: React.Dispatch<React.SetStateAction<Tdata | null>>;
}

const OfferLetterPendingApplication = ({
  setCurrentSection,
  setSelectedLoanData,
}: CreditAnalystPendingLoanApplicationProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(10);

  const [searchQuery, setSearchQuery] = useState({});
  const [query, setQuery] = useState<TQuery>({
    Page: 1,
    PageSize: 10,
    search_value: "",
    start_date: "",
    end_date: "",
    status: `status=14&status=17`,
  });
  //   useEffect(() => {
  //     appInsights.trackPageView({
  //       name: "Credit Analyst Pending Loan Applications  - (CreditAnalystPendingLoanApplications.tsx)",
  //       isLoggedIn: true,
  //     });
  //   }, []);

  const handleSearch = () => {
    const _query = searchQuery as TQuery;

    return setQuery((prev) => {
      return {
        ...prev,
        ..._query,
      };
    });
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setSearchQuery((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSelectedLoan = (id: number) => {
    pendingLoanApplications?.data?.find((loan) => {
      if (loan.id === id) {
        if (setSelectedLoanData) {
          setSelectedLoanData(loan);
          return true;
        }
      }
      return false;
    });

    if (setCurrentSection) {
      setCurrentSection(PrepareOfferLetterSection.OfferLetter);
    }
  };

  const {
    data: pendingLoanApplications,
    status,
    error,
  } = useLoanApplications({
    Page: pageNumber,
    PageSize: size,
    end_date: query?.end_date,
    search_value: query?.search_value,
    start_date: query?.start_date,
    status: query?.status,
  });

  let RenderedComponent = null;
  if (!pendingLoanApplications && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Loan
            Applications
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Pending Loan Application..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Loan Applications, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (pendingLoanApplications &&
        pendingLoanApplications.data &&
        pendingLoanApplications.data.length === 0) ||
      (pendingLoanApplications && !pendingLoanApplications.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              applications.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Customer Name</th>
                  <th>Customer Email</th>
                  <th>Status</th>
                  <th>Reference</th>
                  <th>Application Date</th>
                  <th>Approval Date</th>
                  <th>Review</th>
                </tr>
              </thead>
              <tbody>
                {pendingLoanApplications &&
                  pendingLoanApplications?.data?.length > 0 &&
                  pendingLoanApplications.data?.map((request, _id) => {
                    const {
                      status,
                      ref_name,
                      application_date,
                      approval_date,
                      id,
                      funder_email,
                      funder_name,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{(pageNumber - 1) * size + (_id + 1)}</td>
                        <td>{funder_name}</td>
                        <td>{funder_email}</td>
                        <td> {status}</td>
                        <td>{ref_name}</td>
                        <td>
                          {application_date &&
                            application_date.substring(0, 10)}
                        </td>
                        <td>
                          {approval_date
                            ? approval_date.substring(0, 10)
                            : "NIL"}
                        </td>
                        <td>
                          <button
                            className={styles.prepareOffer}
                            onClick={() => handleSelectedLoan(id)}
                          >
                            Prepare Offer
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="speed-1x animated fadeInRight">
      <h4
        style={{
          textAlign: "center",
          color: "#200865",
          fontWeight: 600,
          fontSize: "18px",
          marginBottom: "20px",
        }}
      >
        Pending Loans
      </h4>
      <div className={`row ${styles.ActionBar}`}>
        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Customer Name:
          </span>
          <input
            type="text"
            name="search_value"
            className="form-control"
            placeholder="customer name"
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Reference:
          </span>
          <input
            type="text"
            name="search_value"
            className="form-control"
            placeholder="reference"
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Start:
          </span>
          <input
            type="date"
            name="start_date"
            className="form-control"
            placeholder="start"
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            End:
          </span>
          <input
            type="date"
            name="end_date"
            className="form-control"
            placeholder="end"
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <span
            style={{
              display: "inline-block",
              marginTop: "0.5rem",
              marginRight: "0.5rem",
            }}
          >
            Number of Results:
          </span>
          <div style={{ marginTop: "-0.65rem" }}>
            <CustomSelectDropdown
              onChange={(e) => setSize(Number(e.target.value))}
              value={size}
              name=""
            >
              <option value="">All</option>
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>

        <div className="col-3 d-flex justify-content-end">
          <button
            className="btn advancly-btn btn-md"
            disabled={status === "loading"}
            onClick={handleSearch}
          >
            Search{status === "loading" && <ButtonLoader />}
          </button>
        </div>
      </div>

      {RenderedComponent}
      <Paginator
        size={size}
        page={pageNumber}
        count={pendingLoanApplications?.totalCount}
        changeCurrentPage={(pageNumber: number) => setPageNumber(pageNumber)}
      />
    </div>
  );
};

export default OfferLetterPendingApplication;
