export const loanApplicationStatus = {
  PENDING_SUBMISSION: 1,
  RELATIONSHIP_MANAGER: 2,
  RETURNED_TO_RELATIONSHIP_MANAGER: 5,
  CREDIT_REVIEW: 3,
  RETURNED_TO_CUSTOMER: 4,
  APPROVED: 7,
  DECLINED: 8,
  RETURNED_TO_CREDIT_ANALYST: 6,
  APPROVED_BY_CREDIT_ANALYST: 9,
  DECLINED_BY_CREDIT_APPROVER: 10,
  APPROVED_BY_CREDIT_APPROVER: 11,
  SENT_TO_MCC: 12,
  DECLINED_BY_MCC: 13,
  APPROVED_BY_MCC: 14,
  OFFER_LETTER_WITH_CUSTOMER: 15,
  OFFER_LETTER_DECLINED_BY_CUSTOMER: 16,
  OFFER_LETTER_ACCEPTED_BY_CUSTOMER: 17,
  OFFER_LETTER_EXCEUTED_BY_LEGAL: 18,
};

export const loanApplicationOfferLetterStatus = {
  CustomerOfferLetterNotSent: 1,
  CustomerOfferLetterAcceptance: 2,
  CustomerOfferLetterDeclined: 3,
  AdvanclyOfferLetterAcceptance: 4,
  AdvanclyOfferLetterApproved: 5,
};
