import TableAccordion from "../../../Components/TableAccordion";
import { TextEditor } from "../../../Components/TextEditor";
import { showModal } from "../../../../../../redux/actions/modal";

import styles from "./collateralComments.module.scss";

interface ICollateralComments {
  defaultValue: string;
  handleChange: any;
}

const CollateralComments = ({
  defaultValue,
  handleChange,
}: ICollateralComments) => {
  return (
    <div className={styles.CreditScoreComments}>
      <TableAccordion
        title={<span className={styles.Title}>COLLATERAL COMMENTS</span>}
        isDefaultOpen
      >
        <TextEditor onChange={handleChange} defaultValue={defaultValue} />
      </TableAccordion>
    </div>
  );
};

export default CollateralComments;
