import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type Tdata = {
  id: number;
  name: string;
};

type TLoanTypesResponse = {
  data: {
    data: Tdata[];
  };
};

const getLoanTypes = async () => {
  const data = await getData(ajaxEndpoints.GET_LOAN_TYPE);

  return data;
};

export default function useLoanTypes() {
  return useQuery<TLoanTypesResponse>("getLoanTypes", getLoanTypes, {
    refetchOnWindowFocus: false,
  });
}
