import React, { useState } from "react";
import Paginator from "../../../../components/Pagination/Pagination.component";
import useCustomerWalletTransactions from "../../../../custom-hooks/useCustomerWalletTransactions";
import { formatMoney } from "../../../../helpers/formatter";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import NoTableDataAvailable from "../../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";

const CustomerWalletTransactions = ({ walletDetails }) => {
  const { accountNumber } = walletDetails;
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    accountNumber: accountNumber,
    consumerType: "Customer",
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    accountNumber: accountNumber,
    consumerType: "Customer",
  });
  const [count, setCount] = useState(null);
  const { data, status, error } = useCustomerWalletTransactions(preState);

  if (data) {
    if (count === null) {
      setCount(data?.total);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };

  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="card">
            {status === "loading" ? (
              <div className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="d-flex justify-content-between align-items-center py-3">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    <div className="form-group mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={state.startDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <input
                          type="date"
                          className="form-control"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm mt-2"
                        value="submit"
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <h3 className="page-subtitle mb-3 ">
                  Available Balance:{" "}
                  {status === "loading" ? (
                    <Loader />
                  ) : data?.availableBalance ? (
                    formatMoney(data?.availableBalance, data?.currency)
                  ) : (
                    "N/A"
                  )}
                </h3>
                <h3 className="page-subtitle mb-3 ">
                  Account Number:{" "}
                  {status === "loading" ? (
                    <Loader />
                  ) : data?.accountNumber ? (
                    accountNumber
                  ) : (
                    "N/A"
                  )}
                </h3>

                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Created Date</th>
                        {/* <th>Transaction ID</th> */}
                        <th>Transaction Amount</th>
                        <th>Transaction Type</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Transaction Status</th>
                        <th>Transaction Charge</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.transactions?.length > 0 ? (
                        data?.transactions?.map((request, _id) => {
                          const {
                            transactionDate,
                            amount,
                            transactionType,
                            // transactionId,
                            debitAmount,
                            creditAmount,
                            transactionStatus,
                            transactionCharge,
                          } = request;
                          return (
                            <tr key={_id}>
                              <td>{_id + 1}</td>
                              <td>{transactionDate.substring(0, 10)}</td>
                              {/* <td>{transactionId}</td> */}
                              <td>{formatMoney(amount, data?.currency)}</td>
                              <td>{transactionType}</td>
                              <td>
                                {formatMoney(creditAmount, data?.currency)}
                              </td>
                              <td>
                                {formatMoney(debitAmount, data?.currency)}
                              </td>
                              <td>{transactionStatus}</td>
                              <td>
                                {formatMoney(transactionCharge, data?.currency)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.transactions && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerWalletTransactions;
