import { ChangeEvent, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BackButtonComponent from "../../../NewComponents/BackButton/BackButton.component";
import LoanProductDetail from "./LoanProductDetail/LoanProductDetail";
import { showModal } from "../../../redux/actions/modal";
import useProductCategory from "../../../custom-hooks/useProductCategory";

import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import { appInsights } from "../../../config/appInsights";
import { postData } from "../../../newApis/dotNetApiMethods";
import { v4 as uuidv4 } from "uuid";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { TFormData } from "../types";

import styles from "./CreateLoanProduct.module.scss";

const CreateLoanProduct = () => {
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [alertError, setAlertError] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [productDetailList, setProductDetailList] = useState<TFormData[]>([
    {
      unique_id: uuidv4(),
      amortization_type_id: "",
      core_banking_product_id: "",
      core_banking_tenant: "",
      interest_type_id: "",
      loan_product_category_id: "",
      loan_type_id: "",
      maximum_amount: "",
      maximum_interest_rate: "",
      maximum_tenor: "",
      minimum_amount: "",
      minimum_interest_rate: "",
      minimum_tenor: "",
      name: "",
      disbursement_channel_id: "",
      repayment_channel_id: "",
      repayment_channel_account_id: "",
      repayment_bank_id: "",
      disbursement_channel_account_id: "",
      disbursement_bank_id: "",
      disbursement_account_number: "",
      disbursement_account_name: "",
      repayment_account_number: "",
      repayment_account_name: "",
      moratorium_type_id: "",
      moratorium_duration: "",
      maximum_running_loans: "",
      repayment_type: "",
      repayerTypeId: "",
      disbursementTypeId: "",
    },
  ]);

  const dispatch = useDispatch();
  const history = useHistory();

  const { data: productCategories } = useProductCategory();

  const handleChangeProductName = (e: ChangeEvent<HTMLInputElement>) => {
    return setProductName(e.target.value);
  };

  const handleChangeCategory = (e: ChangeEvent<HTMLSelectElement>) => {
    return setProductCategory(e.target.value);
  };

  const handleCreateLoanProduct = async () => {
    setIsCreating(true);

    if (productName === "" || productCategory === "") {
      setAlertError("You have not entered product name or category");
      return setTimeout(() => {
        setAlertError("");
      }, 3000);
    }

    setAlertError("");

    let _productDetail = productDetailList.map((data) => {
      return {
        minimum_amount: Number(data?.minimum_amount),
        maximum_amount: Number(data?.maximum_amount),
        minimum_interest_rate: Number(data?.minimum_interest_rate),
        maximum_interest_rate: Number(data?.maximum_interest_rate),
        interest_type_id: Number(data?.interest_type_id),
        minimum_tenor: Number(data?.minimum_tenor),
        maximum_tenor: Number(data?.maximum_tenor),
        disbursement_channel_id: Number(data?.disbursement_channel_id),
        disbursement_bank_id: Number(data?.disbursement_bank_id),
        disbursement_account_number: String(data?.disbursement_account_number),
        disbursement_account_name: String(data?.disbursement_account_name),
        repayment_account_number: String(data?.repayment_account_number),
        repayment_account_name: String(data?.repayment_account_name),
        moratorium_type_id: Number(data?.moratorium_type_id),
        moratorium_duration: String(data?.moratorium_duration),
        maximum_running_loans: Number(data?.maximum_running_loans),
        repayment_type: String(data?.repayment_type),
        repayment_channel_id: Number(data?.repayment_channel_id),
        repayment_bank_id: Number(data?.repayment_bank_id),
        repayment_channel_account_id: Number(
          data?.repayment_channel_account_id
        ),
        core_banking_tenant: data?.core_banking_tenant,
        core_banking_product_Id: Number(data?.core_banking_product_id),
        amortization_type_id: Number(data?.amortization_type_id),
        loan_type_id: Number(data?.loan_type_id),
        repayerTypeId: Number(data?.repayerTypeId),
        disbursementTypeId: Number(data?.disbursementTypeId),
      };
    });

    try {
      const payload = {
        name: productName,
        loan_product_category_id: Number(productCategory),
        loan_product_details: _productDetail,
      };

      const response = await postData(ajaxEndpoints.LOAN_PRODUCT, {
        ...payload,
      });
      if (response?.data?.status_code === 201) {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        setTimeout(() => {
          history.push(`/loan-product`);
        }, 2000);
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "failure", message: response?.data?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message,
          },
        })
      );
    }
    setIsCreating(false);
  };

  const handelAddProductDetail = () => {
    setProductDetailList((prev) => {
      return [
        ...prev,
        {
          unique_id: uuidv4(),
          amortization_type_id: "",
          core_banking_product_id: "",
          core_banking_tenant: "",
          interest_type_id: "",
          loan_product_category_id: "",
          loan_type_id: "",
          maximum_amount: "",
          maximum_interest_rate: "",
          maximum_tenor: "",
          minimum_amount: "",
          minimum_interest_rate: "",
          minimum_tenor: "",
          moratorium_type_id: "",
          name: "",
          repayment_type: "",
          disbursement_channel_id: "",
          disbursement_bank_id: "",
          disbursement_account_number: "",
          disbursement_account_name: "",
          repayment_channel_id: "",
          repayment_channel_account_id: "",
          repayment_account_name: "",
          repayment_bank_id: "",
          repayment_account_number: "",
          disbursement_channel_account_id: "",
          moratorium_duration: "",
          maximum_running_loans: "",
          repayerTypeId: "",
          disbursementTypeId: "",
        },
      ];
    });
  };

  const handleDeleteProductDetail = (id: string) => {
    let result = productDetailList.filter(
      (_detail) => _detail?.unique_id !== id
    );
    setProductDetailList(result);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Create Loan Product - (CreateLoanProduct.tsx)",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div className="animated fadeInRight">
      <div className="mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Create Loan Product
        </h3>
      </div>

      {alertError && <Alert message={alertError} />}

      <div className={styles.ActionContainer}>
        <div className={styles.FormButtonContainer}>
          <button
            className={`btn advancly-btn btn-md ${styles.SearchActionButton}`}
            onClick={handleCreateLoanProduct}
          >
            Create
          </button>
        </div>
      </div>

      <div className="row p-3">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className="row">
            <div className="col-sm-6">
              <label>Product Name</label>
              <div className="form-group inputWithIcon">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={handleChangeProductName}
                />
                <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              </div>
            </div>
            <div className="col-sm-6">
              <label>Product Category</label>
              <div className="form-group inputWithIcon">
                <select
                  className="round user__form form-control"
                  name="loan_product_category_id"
                  placeholder="Select a Product"
                  onChange={handleChangeCategory}
                >
                  <option value="">Select a Product Category</option>
                  {productCategories &&
                    productCategories?.data?.data.map((_productCategory) => (
                      <option
                        key={_productCategory?.id}
                        value={_productCategory?.id}
                      >
                        {_productCategory?.name}
                      </option>
                    ))}
                </select>
                <i className="adv-aggregator-icon fas fa-university"></i>{" "}
              </div>
            </div>
          </div>
          <div className={styles.ProductDetailsContainer}>
            {productDetailList.map((_productDetail, index) => (
              <LoanProductDetail
                key={index}
                handleDeleteProductDetail={handleDeleteProductDetail}
                data={_productDetail}
                productDetailList={productDetailList}
                setProductDetailList={setProductDetailList}
              />
            ))}
          </div>

          <div className="row d-flex justify-content-end pl-2 pr-3 mt-4">
            <button
              className="btn advancly-btn btn-sm m-0 transition-3d-hover"
              onClick={handelAddProductDetail}
            >
              <i className="fas fa-plus mr-3" />
              Add Product Detail
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLoanProduct;
