import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { appInsights } from "../../../../../config/appInsights";

import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import { showModal } from "../../../../../redux/actions/modal";
import { Dropdown } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";

import ConfirmationModal from "../../../../../NewComponents/ConfirmationModal";
import { deleteData } from "../../../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";
import useFees, { Tdata } from "../../../../../custom-hooks/useFees";

import styles from "./fees.module.scss";

const FeesSetup = () => {
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Tdata>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const { data: fees, refetch, error: convenantError, status } = useFees();

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
    },
    { key: "delete", label: "Delete" },
  ];

  const handleEditFees = (data: any) => {
    dispatch(
      showModal({
        modalIdentifier: "createeditfees",
        dataRecord: {
          refetchData: refetch,
          type: "edit",
          oldData: data,
        },
      })
    );
  };

  const handleCreateFee = () => {
    dispatch(
      showModal({
        modalIdentifier: "createeditfees",
        dataRecord: {
          refetchData: refetch,
          type: "create",
        },
      })
    );
  };

  const handleDeleteFee = (data: Tdata) => {
    setIsShowConfirmationModal(true);
    return setSelectedRecord(data);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteData(
        `${ajaxEndpoints.FEES}/${selectedRecord?.id}`
      );

      if (response?.data?.status_code === 200) {
        setIsShowConfirmationModal(false);
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.data?.message },
          })
        );
        refetch();
      } else {
        setError(response?.data?.message);
      }
    } catch (error: any) {
      setError(error?.response?.data?.message);
      appInsights.trackException({
        exception: error,
        properties: { fileName: "(Setup/Tabs/Fees/index.tsx)" },
      });
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "Setup Fees  - (AutomatedCredit/Setup/Tabs/Fees/index.tsx)",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;
  if (!fees && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Covenants
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered="true" text="Loading Pending Covenants..." />
    );
  } else {
    if (convenantError) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Covenants, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (fees && fees.data && fees.data?.data.length === 0) ||
      (fees && !fees.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no covenants
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Calculation Type</th>
                  <th>Class</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {fees &&
                  fees?.data.data.length > 0 &&
                  fees.data?.data.map((request, _id: number) => {
                    const {
                      class: _class,
                      calculation_type,
                      name,
                      value,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{_id + 1}</td>
                        <td>{name}</td>
                        <td>{value}</td>
                        <td>{calculation_type}</td>
                        <td>{_class}</td>
                        <td>
                          <Dropdown
                            menu={{
                              items,
                              onClick: ({ key }) => {
                                if (key === "edit") {
                                  handleEditFees(request);
                                }

                                if (key === "delete") {
                                  handleDeleteFee(request);
                                }
                              },
                            }}
                          >
                            <button
                              className="btn cursor-pointer"
                              type="button"
                            >
                              <Ellipses />
                            </button>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div className="speed-1x animated fadeInRight">
      <div className={`row ${styles.ActionBar}`}>
        <div className={styles.ActionButtons}>
          <button className="btn advancly-btn btn-md" onClick={handleCreateFee}>
            Create Fee
          </button>
        </div>
      </div>

      {RenderedComponent}

      {isShowConfirmationModal && (
        <ConfirmationModal
          isOpen={isShowConfirmationModal}
          onClose={() => {
            setIsShowConfirmationModal(false);
          }}
          onConfirm={() => {
            handleDelete();
          }}
          error={error}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default FeesSetup;
