import { ChangeEvent, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import useMoratoriumTypes from "../../../../custom-hooks/useMoratoriumTypes";
import useLoanTypes from "../../../../custom-hooks/useLoanTypes";
import useCoreBankingTenantInfo from "../../../../custom-hooks/useCoreBankingTenant";
import useDisbursementChannels from "../../../../custom-hooks/useDisbursementChannels";
import useRepaymentChannels from "../../../../custom-hooks/useRepaymentChannels";
import useCoreBankingByLoanType from "../../../../custom-hooks/useCoreBankingByLoanType";
import {
  repaymentFrequencys,
  scheduleType,
} from "../../../../models/product-data-model";

import { editProductSchema } from "../../loanProductSchema";
import useDisbursementChannelAccount from "../../../../custom-hooks/useDisbursementChannelAccount";
import useRepaymentChannelAccount from "../../../../custom-hooks/useRepaymentChannelAccount";
import { TEditProductData } from "../../types";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../redux/actions/modal";
import { formatMoney } from "../../../../helpers/formatter";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

import styles from "./LoanProductDetail.module.scss";
import useBankList from "../../../../custom-hooks/useBankList";
import { appInsights } from "../../../../config/appInsights";
import { errorHandler } from "../../../../helpers/errorHandler";
import { postData } from "../../../../newApis/apiMethods";
import { useLocation } from "react-router-dom";

interface ILoanProductDetail {
  handleDeleteProductDetail: (arg: string) => void;
  data: TEditProductData;
  productDetailList: TEditProductData[];
  setProductDetailList: any;
}

type Tstate = {
  disbursement_channel_id: number;
  disbursement_channel_account_id: number;
  repayment_channel_id: number;
  repayment_channel_account_id: number;
  core_banking_tenant: string;
  loan_type_id: number;
  core_banking_product_id: string;
  currency: string;
  minimum_amount: number;
  maximum_amount: number;
  interest_type_id: number;
  moratorium_type_id: number;
  repayment_type: string;
  amortization_type_id: number;
  repayment_bank_id: number;
  disbursement_bank_id: number;
  disbursement_account_name: string;
  disbursement_account_number: string;
  repayment_account_number: string;
  repayment_account_name: string;
  moratorium_duration: string;
};

const LoanProductDetail = ({
  handleDeleteProductDetail,
  data,
  productDetailList,
  setProductDetailList,
}: ILoanProductDetail) => {
  useState<string>("");
  const [state, setState] = useState<Tstate>({
    disbursement_channel_id: 0,
    disbursement_channel_account_id: 0,
    disbursement_bank_id: 0,
    disbursement_account_name: "",
    disbursement_account_number: "",
    repayment_channel_id: 0,
    repayment_channel_account_id: 0,
    repayment_account_number: "",
    core_banking_tenant: "",
    loan_type_id: 0,
    core_banking_product_id: "",
    currency: "",
    minimum_amount: 0,
    maximum_amount: 0,
    interest_type_id: 0,
    moratorium_type_id: 0,
    amortization_type_id: 0,
    repayment_bank_id: 0,
    repayment_type: "",
    repayment_account_name: "",
    moratorium_duration: "",
  });
  const [coreBankingCurrency, setCoreBankingCurrency] = useState<
    string | undefined
  >("");
  const [loadingDisbursementName, setLoadingDisbursementName] = useState(false);
  const [disbursementNameError, setDisbursementNameError] =
    useState<string>("");
  const [loadingRepaymentName, setLoadingRepaymentName] = useState(false);
  const [repaymentNameError, setRepaymentNameError] = useState<string>("");
  const [currentDisbursementBank, setCurrentDisbursementBank] =
    useState<string>("");
  const [currentRepaymentBank, setCurrentRepaymentBank] = useState<string>("");

  const [currentRepaymentVerifiedName, setCurrentRepaymentVerifiedName] =
    useState("");
  const [repaymentCurrentAccountNumber, setRepaymentCurrentAccountNumber] =
    useState<string>("");
  const [disbursmentCurrentAccountNumber, setDisbursmentCurrentAccountNumber] =
    useState<string>("");
  const [currentDisburesmentVerifiedName, setCurrentDisburesmentVerifiedName] =
    useState("");
  const location = useLocation();

  const { data: bankListData } = useBankList();

  const { data: moratoriumTypes } = useMoratoriumTypes();
  const { data: coreBankingTenant } = useCoreBankingTenantInfo();
  const { data: loanTypes } = useLoanTypes();
  const { data: disbursementChannels } = useDisbursementChannels();
  const { data: repaymentChannels } = useRepaymentChannels();
  const { data: disbursementChannelAccount } = useDisbursementChannelAccount(
    state?.disbursement_channel_id
  );
  const { data: repaymentChannelAccount } = useRepaymentChannelAccount(
    state?.repayment_channel_id
  );
  const { data: mifosProduct } = useCoreBankingByLoanType({
    productType: state?.loan_type_id,
    TenantId: state?.core_banking_tenant,
  });

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(editProductSchema),
  });

  const dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

    if (e.target.name === "core_banking_product_id") {
      let currency =
        e.currentTarget.options[e.target.selectedIndex].dataset.currency;
      setCoreBankingCurrency(currency);
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmit = (values: any) => {
    values.id = data?.id;
    values.loan_product_id = data?.loan_product_id;

    let detailToBeUpdated = productDetailList.find(
      (_item) => _item?.unique_id === data?.unique_id
    );
    if (detailToBeUpdated) {
      let indexOfDataToBeUpdated = productDetailList.indexOf(detailToBeUpdated);
      let newProductDetailList = [...productDetailList];
      newProductDetailList[indexOfDataToBeUpdated] = {
        ...values,
        unique_id: data?.unique_id,
      };

      setProductDetailList(newProductDetailList);
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "success",
            message:
              "Saved. Proceed to add another product detail or go ahead to create product",
          },
        })
      );
    }
  };

  useEffect(() => {
    if (data) {
      setState({
        disbursement_channel_id: data?.disbursement_channel_id,
        repayment_channel_id: data?.repayment_channel_id,
        core_banking_tenant: data?.core_banking_tenant,
        loan_type_id: data?.loan_type_id,
        core_banking_product_id: data?.core_banking_product_id,
        currency: data?.currency,
        disbursement_channel_account_id: data?.disbursement_channel_account_id,
        repayment_channel_account_id: data?.repayment_channel_account_id,
        repayment_type: data?.repayment_type.toLowerCase(),
        amortization_type_id: data?.amortization_type_id,
        maximum_amount: data?.maximum_amount,
        minimum_amount: data?.minimum_amount,
        interest_type_id: data?.interest_type_id,
        moratorium_type_id: data?.moratorium_type_id,
        disbursement_bank_id: Number(data?.disbursement_bank_id),
        disbursement_account_number: String(data?.disbursement_account_number),
        disbursement_account_name: String(data?.disbursement_account_name),
        repayment_bank_id: Number(data?.repayment_bank_id),
        repayment_account_number: String(data?.repayment_account_number),
        repayment_account_name: String(data?.repayment_account_name),
        moratorium_duration: String(data?.moratorium_duration),
      });

      setCoreBankingCurrency(data?.currency);
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      setValue("disbursement_channel_id", data?.disbursement_channel_id);
      setValue("repayment_channel_id", data?.repayment_channel_id);
      setValue("core_banking_tenant", data?.core_banking_tenant);
      setValue("loan_type_id", data?.loan_type_id);
      setTimeout(() => {
        setValue("core_banking_product_id", data?.core_banking_product_id);
      }, 3000);
      setValue("currency", data?.currency);
      setValue(
        "disbursement_channel_account_id",
        data?.disbursement_channel_account_id
      );
      setValue(
        "repayment_channel_account_id",
        data?.repayment_channel_account_id
      );
      setValue("repayment_type", data?.repayment_type);
      setValue("amortization_type_id", data?.amortization_type_id);
      setValue("maximum_amount", data?.maximum_amount);
      setValue("minimum_amount", data?.minimum_amount);
      setValue("interest_type_id", data?.interest_type_id);
      setValue("moratorium_type_id", data?.moratorium_type_id);
      setValue("maximum_running_loans", data?.maximum_running_loans);

      setTimeout(() => {
        setValue("disbursement_bank_id", data?.disbursement_bank_id);
      }, 3000);

      setTimeout(
        () =>
          setValue(
            "disbursement_account_number",
            data?.disbursement_account_number
          ),
        4000
      );
      setTimeout(() => {
        setValue("disbursement_account_name", data?.disbursement_account_name);
      }, 4500);
      setTimeout(() => {
        setValue("repayment_bank_id", data?.repayment_bank_id);
      }, 3000);
      setTimeout(() => {
        setValue("repayment_account_number", data?.repayment_account_number);
      }, 4000);
      setTimeout(() => {
        setValue("repayment_account_name", data?.repayment_account_name);
      }, 4500);

      setValue("moratorium_duration", data?.moratorium_duration);
    }
    //eslint-disable-next-line
  }, [data]);

  const handleDisbursementBankChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentDisbursementBank(e.target.value);
  };

  const handleRepaymentBankChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentRepaymentBank(e.target.value);
  };

  const validateDisbursementAccount = (e: ChangeEvent<HTMLInputElement>) => {
    setDisbursmentCurrentAccountNumber(e.target.value);
  };
  const validateRepaymentAccount = (e: ChangeEvent<HTMLInputElement>) => {
    setRepaymentCurrentAccountNumber(e.target.value);
  };
  const locationState = location.state as { isEdit: boolean };

  const getDisbursementAccountName = async (
    bankCode: string,
    bankaccountNumber: string
  ) => {
    const reqBody = {
      bank_code: bankCode,
      bank_account_num: bankaccountNumber,
    };

    try {
      setLoadingDisbursementName(true);
      const responseName = await postData(
        ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER,
        reqBody
      );
      setLoadingDisbursementName(false);
      setValue("disbursement_account_name", "");
      setCurrentDisburesmentVerifiedName(responseName?.bank_account_name);
      setValue("disbursement_account_name", responseName?.bank_account_name);
    } catch (error) {
      setDisbursementNameError(errorHandler(error));

      setTimeout(() => {
        setDisbursementNameError("");
      }, 3000);
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "LoanProductDetail.tsx" },
      });
    }
  };

  const getRepaymentAccountName = async (
    bankCode: string,
    bankaccountNumber: string
  ) => {
    const reqBody = {
      bank_code: bankCode,
      bank_account_num: bankaccountNumber,
    };

    try {
      setLoadingRepaymentName(true);
      const responseName = await postData(
        ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER,
        reqBody
      );
      setLoadingRepaymentName(false);
      setValue("repayment_account_name", "");

      setCurrentRepaymentVerifiedName(responseName?.bank_account_name);
      setValue("repayment_account_name", responseName?.bank_account_name);
    } catch (error) {
      setRepaymentNameError(errorHandler(error));
      setTimeout(() => {
        setRepaymentNameError("");
      }, 3000);
      appInsights.trackException({
        exception: error as Error,
        properties: { fileName: "LoanProductDetail.tsx" },
      });
    }
  };
  const getRepaymentBankCode = bankListData?.find(
    (bank: { id: any }) => Number(bank.id) === Number(currentRepaymentBank)
  )?.code;
  const getDisbursementBankCode = bankListData?.find(
    (bank: { id: any }) => Number(bank.id) === Number(currentDisbursementBank)
  )?.code;

  useEffect(() => {
    if (
      currentDisbursementBank &&
      disbursmentCurrentAccountNumber.length >= 10
    ) {
      getDisbursementAccountName(
        getDisbursementBankCode,
        disbursmentCurrentAccountNumber
      );
    }

    //eslint-disable-next-line
  }, [currentDisbursementBank, disbursmentCurrentAccountNumber]);
  useEffect(() => {
    if (currentRepaymentBank && repaymentCurrentAccountNumber.length >= 10) {
      getRepaymentAccountName(
        getRepaymentBankCode,
        repaymentCurrentAccountNumber
      );
    }
    //eslint-disable-next-line
  }, [currentRepaymentBank, repaymentCurrentAccountNumber]);

  return (
    <div key={data?.unique_id} className={styles.ProductDetailContainer}>
      <div className="d-flex justify-content-end">
        <i
          className="fas fa-times fa-lg cursor-pointer"
          // onClick={() => handleDeleteProductDetail(data?.unique_id)}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row m-t-1">
          <div className="col-xs-4">
            <label>Minimum Tenure</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="minimum_tenor"
                defaultValue={data?.minimum_tenor}
                ref={register}
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.minimum_tenor?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Maximum Tenure</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="maximum_tenor"
                defaultValue={data?.maximum_tenor}
                ref={register}
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_tenor?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Minimum Interest Rate</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="minimum_interest_rate"
                defaultValue={data?.minimum_interest_rate}
                ref={register}
                step="any"
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.minimum_interest_rate?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-1">
          <div className="col-xs-4">
            <label>Maximum Interest Rate</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="maximum_interest_rate"
                defaultValue={data?.maximum_interest_rate}
                ref={register}
                step="any"
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_interest_rate?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Min Amount {formatMoney(state?.minimum_amount)}</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="minimum_amount"
                defaultValue={data?.minimum_amount}
                onChange={handleChangeInput}
                ref={register}
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-money"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.minimum_amount?.message}
              </span>
            </div>
          </div>

          <div className="col-xs-4">
            <label>Max Amount {formatMoney(state?.maximum_amount)}</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="maximum_amount"
                defaultValue={data?.maximum_amount}
                onChange={handleChangeInput}
                ref={register}
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-money"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_amount?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Disbursement Channel</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursement_channel_id"
                ref={register}
                onChange={handleChange}
                value={state?.disbursement_channel_id}
                disabled={!locationState.isEdit}
              >
                <option value="">Select Disbursement Channel</option>
                {disbursementChannels &&
                  disbursementChannels?.data.data.map(
                    (_disbursementChannel) => (
                      <option
                        key={_disbursementChannel?.id}
                        value={_disbursementChannel?.id}
                      >
                        {_disbursementChannel?.channel_name}
                      </option>
                    )
                  )}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.disbursement_channel_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Channel Accounts</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursement_channel_account_id"
                ref={register}
                onChange={handleChange}
                value={state?.disbursement_channel_account_id}
                disabled={!locationState.isEdit}
              >
                <option value="">
                  {state?.disbursement_channel_id
                    ? "Select Disbursement Channel Account"
                    : "Select Disbursement Channel first"}
                </option>
                {disbursementChannelAccount &&
                  disbursementChannelAccount?.data.data.map(
                    (_channelAccount) => (
                      <option
                        key={_channelAccount?.id}
                        value={_channelAccount?.id}
                      >
                        {_channelAccount?.bank_name}/
                        {_channelAccount?.account_number}/
                        {_channelAccount?.account_name}
                      </option>
                    )
                  )}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.disbursement_channel_account_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Bank Name</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="disbursement_bank_id"
                ref={register}
                onChange={handleDisbursementBankChange}
                disabled={!locationState.isEdit}
              >
                <option value="">Select Disbursment Bank Name</option>
                {bankListData &&
                  bankListData?.map((bank: { id: number; name: string }) => (
                    <option key={bank?.id} value={bank?.id}>
                      {bank?.name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Disbursement Account Number</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="disbursement_account_number"
                ref={register}
                onChange={validateDisbursementAccount}
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
          <div className="col-xs-4">
            <label>Disbursement Account Name</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="disbursement_account_name"
                ref={register}
                readOnly
                defaultValue={currentDisburesmentVerifiedName}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              {loadingDisbursementName && <span>loading.....</span>}
              <span className={styles.errorMessage}>
                {disbursementNameError}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Repayment Channel</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_channel_id"
                ref={register}
                onChange={handleChange}
                defaultValue={state?.repayment_channel_id}
                disabled={!locationState.isEdit}
              >
                <option value="">Select Repayment Channel</option>
                {repaymentChannels &&
                  repaymentChannels?.data.data.map((_repaymentChannel) => (
                    <option
                      key={_repaymentChannel?.id}
                      value={_repaymentChannel?.id}
                    >
                      {_repaymentChannel?.channel_name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.repayment_channel_id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Repayment Channel Account</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_channel_account_id"
                ref={register}
                onChange={handleChange}
                defaultValue={state?.repayment_channel_account_id}
                disabled={!locationState.isEdit}
              >
                <option value="">
                  {state?.repayment_channel_id
                    ? "Select Repayment Channel Account"
                    : "Select Repayment Channel first"}
                </option>
                {repaymentChannelAccount &&
                  repaymentChannelAccount?.data.data.map(
                    (_repaymentChannelAccount) => (
                      <option
                        key={_repaymentChannelAccount?.id}
                        value={_repaymentChannelAccount?.id}
                      >
                        {_repaymentChannelAccount?.bank_name}/
                        {_repaymentChannelAccount?.account_number}/
                        {_repaymentChannelAccount?.account_name}
                      </option>
                    )
                  )}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.repayment_channel_account_id?.message}
              </span>
            </div>
          </div>
        </div>
        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Repayment Bank Name</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_bank_id"
                ref={register}
                onChange={handleRepaymentBankChange}
                disabled={!locationState.isEdit}
              >
                <option value="">Select Repayment Bank Name</option>
                {bankListData &&
                  bankListData?.map((bank: { id: number; name: string }) => (
                    <option key={bank?.id} value={bank?.id}>
                      {bank?.name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>

          <div className="col-xs-4">
            <label>Repayment Account Number</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                ref={register}
                name="repayment_account_number"
                onChange={validateRepaymentAccount}
                readOnly={!locationState.isEdit}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>

          <div className="col-xs-4">
            <label>Repayment Account Name</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="repayment_account_name"
                readOnly
                ref={register}
                defaultValue={currentRepaymentVerifiedName}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              {loadingRepaymentName && <span>loading.....</span>}
              <span className={styles.errorMessage}>{repaymentNameError}</span>
            </div>
          </div>
        </div>
        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Maximum Running Loans</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="maximum_running_loans"
                readOnly={!locationState.isEdit}
                ref={register}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
          <div className="col-xs-4">
            <label>Core Banking Tenant</label>
            <div className="form-group ">
              <select
                className="round user__form form-control"
                name="core_banking_tenant"
                placeholder="Select tenant"
                disabled={!locationState.isEdit}
                ref={register}
                defaultValue={state?.core_banking_tenant}
                onChange={handleChange}
              >
                <option value="">Select Core Banking Tenant</option>
                {coreBankingTenant &&
                  coreBankingTenant?.data.map(
                    (_tenant: {
                      id: number;
                      tenant_id: string;
                      full_name: string;
                    }) => (
                      <option key={_tenant?.id} value={_tenant?.tenant_id}>
                        {_tenant?.full_name}
                      </option>
                    )
                  )}
              </select>
              <span className={styles.errorMessage}>
                {errors?.core_banking_tenant?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Loan Type </label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="loan_type_id"
                ref={register}
                disabled={!locationState.isEdit}
                onChange={handleChange}
                defaultValue={state?.loan_type_id}
              >
                <option value="">Select Loan Type</option>
                {loanTypes &&
                  loanTypes?.data?.data.map((_loanType) => (
                    <option key={_loanType?.id} value={_loanType?.id}>
                      {_loanType?.name}
                    </option>
                  ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.loan_type_id?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Core Banking Product</label>
            <div className="form-group ">
              <select
                className="round user__form form-control"
                name="core_banking_product_id"
                disabled={!locationState.isEdit}
                ref={register}
                onChange={handleChange}
                defaultValue={state?.core_banking_product_id}
              >
                <option value="">
                  {state?.loan_type_id
                    ? "Select core banking product"
                    : "Select loan type first"}
                </option>
                {mifosProduct &&
                  mifosProduct?.data?.length > 0 &&
                  mifosProduct.data.map((_item) => (
                    <option
                      key={_item?.id}
                      value={_item?.id}
                      data-currency={_item?.currencyCode}
                    >
                      {_item?.name}
                    </option>
                  ))}
              </select>
              <span className={styles.errorMessage}>
                {errors?.core_banking_product_Id?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Moratorium Duration</label>
            <div className="form-group inputWithIcon">
              <input
                type="number"
                className="form-control"
                name="moratorium_duration"
                ref={register}
                readOnly={!locationState.isEdit}
                defaultValue={state?.moratorium_duration}
              />
              <i className="adv-aggregator-icon fas fa-money"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.maximum_amount?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Currency</label>
            <div className="form-group inputWithIcon">
              <input
                type="text"
                className="form-control"
                name="currency"
                readOnly
                value={coreBankingCurrency}
              />
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
            </div>
          </div>
          <div className="col-xs-4">
            <label>Moratorium Type</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="moratorium_type_id"
                disabled={!locationState.isEdit}
                ref={register}
                onChange={handleChange}
                defaultValue={state?.moratorium_type_id}
              >
                <option value="">Select Moratorium Type</option>
                {moratoriumTypes &&
                  moratoriumTypes.data?.data.map((_moratoriumType) => (
                    <option
                      key={_moratoriumType?.id}
                      value={_moratoriumType?.id}
                    >
                      {_moratoriumType?.name}
                    </option>
                  ))}
              </select>
              <span className={styles.errorMessage}>
                {errors?.moratorium_type?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Amortization Type</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="amortization_type_id"
                disabled={!locationState.isEdit}
                ref={register}
                onChange={handleChange}
                defaultValue={state?.amortization_type_id}
              >
                <option value="">Select Amortization type</option>
                <option value={0}>Equal Principal Payments</option>
                <option value={1}>Equal Installments</option>
              </select>
              <span className={styles.errorMessage}>
                {errors?.amortization_type?.message}
              </span>
            </div>
          </div>
          <div className="col-xs-4">
            <label>Repayment Type</label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="repayment_type"
                disabled={!locationState.isEdit}
                ref={register}
                onChange={handleChange}
                defaultValue={state?.repayment_type.toLowerCase()}
              >
                <option value="">Select Repayment Type</option>
                {repaymentFrequencys.map((_repaymentFrequency) => (
                  <option
                    key={_repaymentFrequency?.repayment_Type}
                    value={_repaymentFrequency?.repayment_Type.toLowerCase()}
                  >
                    {_repaymentFrequency?.repaymentFrequencyName}
                  </option>
                ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.repayment_type?.message}
              </span>
            </div>
          </div>
        </div>

        <div className="row m-t-2">
          <div className="col-xs-4">
            <label>Interest Type </label>
            <div className="form-group inputWithIcon">
              <select
                className="round user__form form-control"
                name="interest_type_id"
                disabled={!locationState.isEdit}
                ref={register}
                onChange={handleChange}
                defaultValue={state?.interest_type_id}
              >
                <option value="">Select Interest type</option>
                {scheduleType.map((_item) => (
                  <option
                    key={_item?.schedule_Type}
                    value={_item?.schedule_Type}
                  >
                    {_item?.scheduleTypeName}
                  </option>
                ))}
              </select>
              <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
              <span className={styles.errorMessage}>
                {errors?.interest_type?.message}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          {locationState.isEdit && (
            <button
              className="btn advancly-btn btn-sm m-0 transition-3d-hover"
              type="submit"
            >
              <i className="fas fa-save mr-3" />
              Save
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoanProductDetail;
