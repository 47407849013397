import React, { useState } from "react";
import moment from "moment";

import NoTableDataAvailable from "../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";
import { showModal } from "../../../redux/actions/modal";
import { appInsights } from "../../../config/appInsights";
import { useDispatch } from "react-redux";
import useDocumentViewer from "../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { postBlobData } from "../../../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../../../api/ajax-endpoints";
import styles from "./ViewLoanApplication.module.scss";
const CamHistoryForViewLoanApplications = ({
  predisbursementData,
  loanApplicationId,
  predisbursementStatus,
  predisbursementError,
}: {
  predisbursementStatus: string;
  predisbursementError: unknown;
  predisbursementData: any;
  loanApplicationId: number;
}) => {
  const [isDownloadingCAM, setIsDownloadingCAM] = useState(false);
  const [getCurrentDocumentFilePath, setGetCurrentDocumentFilePath] =
    useState<string>("");
  const dispatch = useDispatch();
  const { handleViewDocument } = useDocumentViewer();

  const handleDownloadCAM = async (document_file_path: string) => {
    setGetCurrentDocumentFilePath(document_file_path);
    setIsDownloadingCAM(true);
    const payload = {
      loan_application_id: loanApplicationId,
      //  document_format: documentFormat,
      document_file_path: document_file_path,
    };
    try {
      const response = await postBlobData(ajaxEndpoints.DOWNLOAD_CAM, payload);

      if (response.status === 200) {
        const blob = response?.data;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `CAM_${loanApplicationId}_${moment()
            .format("l")
            .replaceAll("/", "_")}.${document_file_path}`
        );
        link.click();
        setGetCurrentDocumentFilePath("");
      } else {
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: {
              type: "failure",
              message:
                response?.data?.message ?? "Unable to download CAM document",
            },
          })
        );
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PredisbursementCamOfferLetter.tsx",
        },
      });
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message:
              error?.response?.data?.message ??
              "Unable to download CAM document",
          },
        })
      );
    }
    setIsDownloadingCAM(false);
  };

  const handleView = (pdf_url: string) => {
    if (pdf_url) {
      handleViewDocument(false, pdf_url);
    } else {
      dispatch(
        showModal({
          modalIndentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: "Unable to retrieve CAM document",
          },
        })
      );
    }
  };

  if (predisbursementStatus === "error" && predisbursementError) {
    return (
      <div className="d-flex justify-content-center align-items-center ">
        Error Unable to fetch Data
      </div>
    );
  }
  return (
    <div>
      <h5>Cam Document</h5>
      <div className="row m-x-sm m-y-2 grid__padding">
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th>User Name</th>
                <th>Date</th>
                <th>Status</th>
                <th>Approval Level</th>
                <th colSpan={2}>Download Type</th>
              </tr>
            </thead>
            <tbody>
              {predisbursementData &&
              predisbursementData?.data?.data?.loan_application_cams.length >
                0 ? (
                predisbursementData?.data?.data?.loan_application_cams?.map(
                  (request: any, _id: any) => {
                    const {
                      id,
                      created_by_user_name,
                      date_time_created,
                      approval_status,
                      approval_level,
                      pdf_url,
                    } = request;
                    return (
                      <tr key={id}>
                        <td>{created_by_user_name}</td>
                        <td>
                          {moment(date_time_created).format("DD/MM/YYYY")}
                        </td>
                        <td> {approval_status}</td>
                        <td>{approval_level}</td>
                        <td
                          className={`cursor-pointer ${styles.styleLink} `}
                          onClick={() => handleView(pdf_url)}
                        >
                          View
                        </td>
                        <td
                          className={`cursor-pointer ${styles.styleLink} `}
                          onClick={() => {
                            handleDownloadCAM(pdf_url);
                          }}
                        >
                          Download{" "}
                          {isDownloadingCAM &&
                            getCurrentDocumentFilePath === pdf_url && (
                              <span className="spinner-border spinner-border-sm ml-2"></span>
                            )}
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <NoTableDataAvailable text={"No Data Available"} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CamHistoryForViewLoanApplications;
