import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Table } from "antd";
import { DataType, ILoanInformation } from "./util";
import type { ColumnsType } from "antd/es/table";
import { attachClassNameToTag } from "../../../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../../../helpers/formatter";
import { showModal } from "../../../../../redux/actions/modal";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";
import SubmitToCreditActionModal from "./SubmitToCreditActionModal";
import useLoanProductDetails from "../../../../../custom-hooks/useLoanProductDetail";
import ButtonLoader from "../../../../../UI/Loaders/Loaders";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { loanApplicationStatus } from "../../../util";

const LoanInformation = ({ data, refetchData }: ILoanInformation) => {
  const [loanInformationDetails, setLoanInformationDetails] =
    useState<DataType[]>();
  const [selectedRecord, setSelectedRecord] = useState<DataType | null>();

  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: string;
  };

  const dispatch = useDispatch();

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const modalState = useSelector((state: RootState) => state?.modalReducer);

  useEffect(() => {
    if (modalState?.showModal === false) {
      setSelectedRecord(null);
    }
  }, [modalState.showModal]);

  const { data: productDetails, isLoading } = useLoanProductDetails(
    selectedRecord?.currency
  );

  const handleEditLoanInformation = (_data: DataType) => {
    return setSelectedRecord(_data);
  };

  const items: ItemType[] = [
    {
      key: "edit",
      label: "Edit",
    },
    { key: "fees", label: "Fees" },
    { key: "viewFeeLog", label: "View Fee Log" },
  ];

  const handleFees = (_data: DataType) => {
    dispatch(
      showModal({
        modalIdentifier: "loanapplicationdetailfees",
        dataRecord: {
          loan_info_data: _data,
        },
      })
    );
  };

  const handleFeeLog = (_data: DataType) => {
    dispatch(
      showModal({
        modalIdentifier: "loanapplicationdetailfeelog",
        dataRecord: {
          loan_info_data: _data,
        },
      })
    );
  };

  const handleDisableAction = () => {
    if (JSON.parse(state?.isViewOnly)) return true;

    if (
      data?.data?.data?.status_id ===
      loanApplicationStatus.OFFER_LETTER_DECLINED_BY_CUSTOMER
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (productDetails) {
      dispatch(
        showModal({
          modalIdentifier: "loaninformationedit",
          dataRecord: {
            loan_info_data: selectedRecord,
            loan_application_data: data?.data?.data,
            refetch: refetchData,
            productDetails,
          },
        })
      );
    }
  }, [productDetails, selectedRecord]);
  const columns: ColumnsType<DataType> = [
    {
      title: "Proposed Amount",
      dataIndex: "proposed_amount",
      key: "proposed_amount",
      align: "center",
      render: (_, _data) => {
        return (
          <span>{formatMoney(_data?.proposed_amount, _data?.currency)}</span>
        );
      },
    },
    {
      title: "Approved Amount",
      dataIndex: "approved_amount",
      width: "12%",
      key: "approved_amount",
      align: "center",
      render: (_, _data) => {
        return (
          <span>{formatMoney(_data?.approved_amount, _data?.currency)}</span>
        );
      },
    },
    {
      title: "Proposed Interest Rate",
      dataIndex: "proposed_interest_rate",
      key: "proposed_interest_rate",
      align: "center",
    },
    {
      title: "Approved Interest Rate",
      dataIndex: "approved_interest_rate",
      key: "approved_interest_rate",
      align: "center",
    },
    {
      title: "Proposed Tenor",
      dataIndex: "proposed_tenor",
      key: "proposed_tenor",
      align: "center",
    },
    {
      title: "Approved Tenor",
      dataIndex: "approved_tenor",
      key: "approved_tenor",
      align: "center",
    },
    {
      title: "Proposed Loan Product",
      dataIndex: "proposed_loan_product_detail",
      key: "proposed_loan_product_detail",
      align: "center",
    },
    {
      title: "Approved Loan Product",
      dataIndex: "approved_loan_product_detail",
      key: "approved_loan_product_detail",
      align: "center",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      align: "center",
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status",
      key: "approval_status",
      align: "center",
      render: (_, _data) => {
        return (
          <span className={attachClassNameToTag(_data?.approval_status)}>
            {attachClassNameToTag(_data?.approval_status)}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          disabled={handleDisableAction()}
          menu={{
            items,
            onClick: ({ key }) => {
              if (key === "edit") {
                handleEditLoanInformation(_data);
              }

              if (key === "fees") {
                handleFees(_data);
              }

              if (key === "viewFeeLog") {
                handleFeeLog(_data);
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            {isLoading ? <ButtonLoader /> : <Ellipses />}
          </button>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setLoanInformationDetails(data?.data?.data.loan_application_details);
    }
  }, [data]);

  return (
    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div className="mt-3 mb-5">
        <Table columns={columns} dataSource={loanInformationDetails} />
      </div>
      <div className="Form__action-buttons m-t-2 d-flex justify-content-end">
        {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) &&
          data?.data?.data?.status_id === 2 && (
            <button
              type="submit"
              className="btn zoom-element m-0 login-btn"
              data-toggle="modal"
              data-target="#submitToCreditActionModal"
            >
              Submit to Credit
            </button>
          )}
      </div>

      <SubmitToCreditActionModal data={data} refetchData={refetchData} />
    </div>
  );
};

export default LoanInformation;
