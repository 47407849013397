import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

export type Tdata = {
  id: number;
  name: string;
  currency: string;
  interest_type: string;
  maximum_interest_rate: number;
  maximum_tenor: number;
  minimum_interest_rate: number;
  minimum_tenor: number;
  moritorium_type: string;
  repayment_type?: string;
  minimum_amount?: number;
  maximum_amount?: number;
  interest_type_id?: number;
  amortization_type_id?: number;
  moratorium_type_id?: number;
  core_banking_tenant: string;
  core_banking_product_id: number;
  loan_type_id: number;
  repayment_channel_account_id?: number;
  disbursement_channel_account_id?: number;
  disbursement_channel_id?: number;
  repayment_channel_id?: number;
};

export type TProductDetailsResponse = {
  data: {
    data: Tdata[];
  };
};

export const getLoanProductDetailNoQuery = async () => {
  const data = await getData(`${ajaxEndpoints.LOAN_PRODUCT_DETAILS}`);

  return data;
};

export default function useLoanProductDetailNoQuery() {
  return useQuery<TProductDetailsResponse>(
    ["getLoanProductDetailNoQuery"],
    getLoanProductDetailNoQuery,
    {
      refetchOnWindowFocus: false,
    }
  );
}
