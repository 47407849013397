import { Table, Dropdown } from "antd";

import type { ColumnsType } from "antd/es/table";
import { TFeedbackComments } from "../../../../../components/AutomatedCredit/CreditAnalysis/Tabs/Feedback";
import {
  addSerialNumber,
  creditUserTypes,
} from "../../../../../components/AutomatedCredit/CreditAnalysis/util";
import { ReactComponent as Ellipses } from "../../../../../assets/svg/icons/dots-v.svg";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../redux/actions/modal";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../../redux/store";
import { ROLE_TYPES } from "../../../../../helpers/roleTypes";

const ViewFeedbackComment = (props: any) => {
  const {
    dataRecord: {
      data: { comments, id: loan_application_feedback_id },
      refetchData,
    },
  } = props;

  const loggedInUser = useSelector(
    (state: RootState) => state?.authReducer?.loggedInUser
  );

  const location = useLocation();
  const state = location?.state as {
    id: number;
    title: string;
    isViewOnly: boolean;
  };

  const dispatch = useDispatch();

  const getDropDownItems = (_data: TFeedbackComments): ItemType[] => {
    if (loggedInUser.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL)) {
      if (
        _data?.user_type_id === creditUserTypes.RELATIONSHIP_MANAGER ||
        _data?.user_type_id === creditUserTypes?.CUSTOMER
      ) {
        return [
          {
            key: "view",
            label: "View",
          },
        ];
      } else {
        return [
          {
            key: "update",
            label: "Update",
          },
        ];
      }
    } else {
      if (
        _data?.user_id === creditUserTypes?.CREDIT_ANALYST ||
        _data?.user_type_id === creditUserTypes?.CUSTOMER
      ) {
        return [
          {
            key: "view",
            label: "View",
          },
        ];
      } else {
        return [
          {
            key: "update",
            label: "Update",
          },
        ];
      }
    }
  };

  const columns: ColumnsType<TFeedbackComments> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNo",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      align: "center",
    },
    {
      title: "Document",
      dataIndex: "document_file_name",
      key: "document_file_name",
      align: "center",
      render: (_, _data) => {
        return (
          <span>
            {!_data?.document_file_name ? "NIL" : _data?.document_file_name}
          </span>
        );
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      align: "center",
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (_, _data) => (
        <Dropdown
          menu={{
            items: getDropDownItems(_data),
            onClick: ({ key }) => {
              if (key === "update") {
                dispatch(
                  showModal({
                    modalIdentifier: "addfeedbackcomment",
                    dataRecord: {
                      refetchData,
                      selectedComment: {
                        loan_application_feedback_id,
                        ..._data,
                      },
                      action: "update",
                    },
                  })
                );
              }

              if (key === "view") {
                dispatch(
                  showModal({
                    modalIdentifier: "addfeedbackcomment",
                    dataRecord: {
                      refetchData,
                      selectedComment: {
                        loan_application_feedback_id,
                        ..._data,
                      },
                      action: "view",
                    },
                  })
                );
              }
            },
          }}
        >
          <button className="btn cursor-pointer" type="button">
            <Ellipses />
          </button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <Table columns={columns} dataSource={addSerialNumber(comments)} />
        </div>
      </div>
    </div>
  );
};

export default ViewFeedbackComment;
