import { useState } from "react";

import { Table } from "antd";
import TableWrapper from "../../../../../../components/AutomatedCredit/CreditAnalysis/Components/TableWrapper";
import { ColumnsType } from "antd/es/table";
import useConvenants from "../../../../../../custom-hooks/useConvenants";
import { addSerialNumber } from "../../../../../../components/AutomatedCredit/CreditAnalysis/util";
import { appInsights } from "../../../../../../config/appInsights";
import { useLocation } from "react-router-dom";
import { postData } from "../../../../../../newApis/dotNetApiMethods";

import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import ButtonLoader from "../../../../../../UI/Loaders/Loaders";
import CustomSearchDropDown from "../../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";

type DataType = {
  covenant: string;
  id: number;
  serialNumber: number;
};

interface IConvenantFromSetup {
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | null>>;
  data: any;
}

const CovenantsFromSetup = ({
  data: props,
  setErrorMessage,
  setSuccessMessage,
}: IConvenantFromSetup) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState<DataType[]>();
  const [selectedLoanApplicationDetail, setSelectedLoanApplicationDetail] =
    useState<number | null>(null);

  const { data: convenants, isLoading: isLoadingConvenants } = useConvenants();

  const location = useLocation();
  const state = location?.state as { id: number };

  const columns: ColumnsType<DataType> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      align: "center",
    },
    {
      title: "Covenant",
      dataIndex: "covenant",
      key: "covenant",
      align: "center",
    },
  ];

  const {
    dataRecord: {
      data: {
        data: {
          data: { loan_application_details },
        },
      },
    },
  } = props;

  const handleApplicationRequestChange = (selectedItem: { value: number }) => {
    return setSelectedLoanApplicationDetail(Number(selectedItem?.value));
  };

  const getLoanApplicationDetailList = (
    _listInQuestion:
      | { approved_loan_product_detail: string; id: number }[]
      | undefined
  ) => {
    if (_listInQuestion) {
      const result = _listInQuestion.map((_item) => {
        return {
          label: _item?.approved_loan_product_detail,
          value: _item?.id,
        };
      });

      return result;
    }

    return [];
  };

  const getDefaultLoanApplicationDetail = (
    _listInQuestion:
      | { approved_loan_product_detail: string; id: number }[]
      | undefined,
    loanApplicationDetailId: number
  ) => {
    if (_listInQuestion) {
      const result = _listInQuestion.find(
        (_item) => _item?.id === loanApplicationDetailId
      );

      return {
        label: result?.approved_loan_product_detail,
        value: result?.id,
      };
    }

    return {};
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      return setSelectedRecords(selectedRows);
    },
  };

  const cleanupRecordsForPosting = (records: DataType[] | undefined) => {
    if (records) {
      const result = records.map((_record) => {
        return {
          covenant: _record?.covenant,
          loanApplicationId: state?.id,
          covenantId: _record?.id,
          loanApplicationDetailId: selectedLoanApplicationDetail,
        };
      });

      return result;
    }

    return [];
  };

  const handleCreate = async () => {
    setIsLoading(true);

    try {
      const payload = cleanupRecordsForPosting(selectedRecords);

      const response = await postData(
        ajaxEndpoints.LOAN_APPLICATION_CONVENANTS,
        payload
      );

      if (response?.data?.status === true) {
        setSuccessMessage(response?.data?.message);
        props?.dataRecord?.refetchData();
        setTimeout(() => {
          setSuccessMessage("");
          props.onCloseModal();
        }, 2000);
      } else {
        setErrorMessage(response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      appInsights.trackException({
        exception: error,
        properties: {
          fileName:
            "Modals/Loans/LoanApplication/LoanSpecificCondtions/FromSetup",
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <div>
      {" "}
      <div className="row">
        <div className="col-sm-12">
          <label>Loan Application Request</label>
          <div className="col-md-12 p-0">
            <CustomSearchDropDown
              defaultOptions={getLoanApplicationDetailList(
                loan_application_details
              )}
              defaultValue={getDefaultLoanApplicationDetail(
                loan_application_details,
                props?.dataRecord?.oldData?.loanApplicationDetailId
              )}
              onChange={handleApplicationRequestChange}
              isSearchable={false}
              isMulti={false}
              placeholder="Select Type"
            />
          </div>
        </div>
      </div>
      <TableWrapper>
        <Table
          columns={columns}
          loading={isLoadingConvenants}
          dataSource={addSerialNumber(convenants?.data?.data)}
          rowKey={(record) => record?.serialNumber}
          pagination={false}
          rowSelection={{
            ...rowSelection,
          }}
        />
      </TableWrapper>
      <div className="mt-5">
        <button
          className="btn advancly-btn btn-md m-0"
          onClick={handleCreate}
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader /> : "Save Covenant"}
        </button>
      </div>
    </div>
  );
};

export default CovenantsFromSetup;
