import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TQuery = {
  productType?: number;
  TenantId?: string;
};

type TResponse = {
  data: {
    id: number;
    name: string;
    currencyCode: string;
  }[];
};

const getCoreBankingByLoanType = async ({ productType, TenantId }: TQuery) => {
  let query = `?producttype=${productType}&TenantId=${TenantId}`;

  const data = await getData(
    `${ajaxEndpoints.GET_MIFOS_PRODUCTS_DETAILS}${query}`
  );
  return data;
};

export default function useCoreBankingByLoanType({
  productType,
  TenantId,
}: TQuery) {
  return useQuery<TResponse>(
    [{ productType, TenantId }, "getCoreBankingByLoanType"],
    getCoreBankingByLoanType,
    {
      refetchOnWindowFocus: false,
      enabled: productType && TenantId ? true : false,
    }
  );
}
