import React from "react";

import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import CustomTextArea from "../../../../../NewComponents/CustomHTMLElements/CustomTextArea";
import { TPendingLoanResponse } from "../../../../../custom-hooks/usePendingLoanApplicationById";
import { ReactComponent as MessageIcon } from "../../../../../assets/svg/icons/message-icon-2.svg";
import { showModal } from "../../../../../redux/actions/modal";
import { RefetchOptions } from "react-query/types/core/query";

import styles from "./BusinessInfo.module.scss";
import { loanApplicationStatus } from "../../../util";

interface IBusinessInformation {
  data: TPendingLoanResponse | undefined;
  refetchData: (
    options?: RefetchOptions | undefined
  ) => Promise<TPendingLoanResponse | undefined>;
}

const BusinessInformation = ({ data, refetchData }: IBusinessInformation) => {
  const dispatch = useDispatch();

  const match = useRouteMatch();

  const { id } = match.params as any;

  const handleViewComments = (
    e: any,
    existingComments: string,
    type: string,
    itemId: number,
    statusId: number
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "rmloanapplicationcomments",
        dataRecord: {
          type,
          comments: existingComments,
          itemId,
          loanApplicationId: id,
          refetchData,
          statusId,
        },
      })
    );
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 pl-4">
        <form>
          <React.Fragment>
            {data &&
              data?.data?.data?.business_information &&
              data?.data?.data?.business_information.questions.length > 0 &&
              data?.data?.data?.business_information.questions.map(
                (_questionObject) => (
                  <div key={_questionObject?.id} className="row">
                    <div className="col-sm-8 col-md-8 col-lg-8">
                      <CustomTextArea
                        label={_questionObject?.question}
                        readOnly
                        defaultValue={
                          _questionObject?.selectedAnswer?.questionAnswerText
                        }
                      />
                    </div>
                    <div
                      className={`${styles.commentsAction} col-sm-4 col-md-4 col-lg-4 `}
                      onClick={(e) =>
                        handleViewComments(
                          e,
                          _questionObject?.selectedAnswer?.adminComment,
                          "business_information",
                          _questionObject?.aggregatorQuestionnaireDetailId,
                          data?.data?.data?.status_id
                        )
                      }
                    >
                      <MessageIcon className={styles.messageIcon} />
                      <span className={styles.commentText}>Comment</span>
                    </div>
                  </div>
                )
              )}
          </React.Fragment>
        </form>

        {data &&
          data?.data?.data?.business_information &&
          data?.data?.data?.business_information.questions.length < 1 && (
            <div className="w-100 d-flex align-items-center justify-content-center py-3">
              <h3 className="font-weight-bold">
                Aggregator is yet to submit Business Information
              </h3>
            </div>
          )}
      </div>
    </div>
  );
};

export default BusinessInformation;
