import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/dotNetApiMethods";

type TResponse = {
  data: TRepaymentCapacity[];
};

export type TRepaymentCapacity = {
  all_interest_obligations: number;
  all_principal_payment_obligations: number;
  average_free_net_inflow: number;
  date_created: string;
  id: number;
  modified_date: string;
  other_obligations: number;
};

export const getAllRepaymentCapacity = async (loanApplicationId: number) => {
  const data = await getData(
    `${ajaxEndpoints.REPAYMENT_CAPACITY}?loanApplicationId=${loanApplicationId}`
  );
  return data.data;
};

export default function useAllRepaymentCapacity(loanApplicationId: number) {
  return useQuery<TResponse>(
    [loanApplicationId, "allRepaymentCapacity"],
    getAllRepaymentCapacity,
    {
      refetchOnWindowFocus: false,
    }
  );
}
