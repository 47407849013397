// @ts-ignore
import accounting from "accounting";

export const formatMoney = (amount: any, currency?: any) => {
  // Check if there is a value passed. Convert value passed to number
  const amountIncoming = amount ? Number(amount) : 0;

  // Check the type of value passed. If type is "number", convert to two decimal places. Else, return 0.
  const amountToFormat =
    typeof amountIncoming === "number" ? amountIncoming : 0;

  // Check if currency is specified. If specified, use the specified currency. Else, use Naira
  return accounting.formatMoney(amountToFormat, {
    symbol: currency ? currency : " ",
    precision: 2, // Precision is same as toFixed(2)
  });
};

export const handleFormatAmountNoCurrency = (amount: string) => {
  return Number(amount.replace(/,/g, ""));
};
