import React, { Component } from "react";

// Ajax Methods
import ajaxMethods from "../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../api/ajax-endpoints";

//import Forms
import ProductForm from "./Forms/ProductForm";
import ProductCategoryInterface from "./Forms/ProductCategoryForm/ProductCategoryInterface";
import { appInsights } from "../../../../../config/appInsights";

export default class ProductSettings extends Component {
  //State
  state = {
    systemEdit: false,
    loading: false,
    systemForm: {
      productCategoryList: [],
      productCategoryName: "",
      productCategoryCode: "",
    },
    systemFormCopy: {
      productCategoryList: [],
      productCategoryName: "",
      productCategoryCode: "",
    },
  };
  // Life Cycle Hooks
  componentDidMount() {
    this.populateCategories();
  }

  // Component Methods
  editModeHandler = (e) => {
    this.setState({
      systemEdit: true,
    });
  };

  inputChangeHandler = (target) => {
    const { name, value, dataset } = target;
    let selectedForm = dataset["form"];

    this.setState({
      [`${selectedForm}FormCopy`]: {
        ...this.state[`${selectedForm}FormCopy`],
        [name]: value,
      },
    });
  };

  cancelUpdateHandler = (e) => {
    this.populateCategories();
    this.setState({
      systemEdit: false,
    });
  };

  populateCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.PRODUCT_CATEGORY_LIST)
      .then((response) => {
        let productCategoryList = [];

        if (response.data.length > 0) {
          productCategoryList = [...response.data];
        } else {
          productCategoryList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }

        this.setState({
          systemEdit: false,
          systemForm: {
            ...this.state.systemForm,
            productCategoryList: [...productCategoryList],
          },
          systemFormCopy: {
            ...this.state.systemFormCopy,
            productCategoryList: [...productCategoryList],
          },
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductSettings.js",
          },
        });
      });
  };
  addCategoryHandler = () => {
    const productCategoryName = {
      productCategoryName: this.state.systemFormCopy.productCategoryName,
      code: this.state.systemFormCopy.productCategoryCode,
    };

    ajaxMethods
      .post(ajaxEndpoints.ADD_PRODUCT_CATEGORY, productCategoryName)
      .then((response) => {
        let message = "";
        if (response.status === 200) {
          message = response.data.message;
          this.props.onSetToast("success", message);
          this.populateCategories();
        } else if (response.status === 400) {
          message = response.data.message;
          this.props.onSetToast("error", message);
        } else {
          this.props.onSetToast(
            "warning",
            "PUnable to connect to Advancly services"
          );
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductSettings.js",
          },
        });
        //
        this.props.onSetToast(
          "warning",
          "Unable to connect to Advancly services"
        );
      });
  };

  render() {
    const { systemFormCopy } = this.state;
    return (
      <div className="GlobalSettings col-sm-12 mt-30">
        <div className="row">
          {/* <div className="col-lg-3 col-md-3 col-sm-12">
            <ProductForm
              inputs={systemFormCopy}
              onEditMode={this.editModeHandler}
              onCancelUpdate={this.cancelUpdateHandler}
              onInputChange={this.inputChangeHandler}
              onAddCategory={this.addCategoryHandler}
              systemEdit={this.state.systemEdit}
              setToast={this.props.onSetToast}
            />
          </div> */}

          <div className="col-lg-12 col-md-12 col-sm-12">
            <ProductCategoryInterface />
          </div>
        </div>
      </div>
    );
  }
}
