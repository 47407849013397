import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { postData } from "../../../../../../newApis/apiMethods";
import { showModal } from "../../../../../../redux/actions/modal";
import CustomTextArea from "../../../../../../NewComponents/CustomHTMLElements/CustomTextArea";

interface feedbackeModalProps {
  dataRecord: {
    loanApplicationId: number;
    conditionId: number;
  };
  onCloseModal: () => void;
}
const PredisbursementAddFeedbacks = ({
  onCloseModal,
  dataRecord,
}: feedbackeModalProps) => {
  const [comments, setComments] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleFeedbackChange = (e: any) => {
    setComments(e.target.value);
  };
  const handlePredisbursementFeedbacks = async () => {
    setIsLoading(true);
    const reqBody = {
      loan_application_id: dataRecord?.loanApplicationId,
      target_id: dataRecord?.conditionId,
      feedback: comments,
    };

    try {
      const response = await postData(
        `${ajaxEndpoints.ADD_FEEDBACKS}?type=7`,
        reqBody
      );

      if (response?.data?.status === true) {
        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.message },
            })
          );
        }, 2000);
      } else {
        setTimeout(() => {
          dispatch(
            showModal({
              modalIdentifier: "feedback",
              dataRecord: { type: "success", message: response?.message },
            })
          );
        }, 2000);
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.message ?? error.message,
          },
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content">
        <div className="Modal__body">
          <div className="d-flex justify-content-center ">
            <h2 className=" font-weight-bold" style={{ color: "#200865" }}>
              Add Pre-disbursment feedback
            </h2>
          </div>

          <div>
            <form>
              <CustomTextArea
                maxLength={256}
                placeholder="Enter message here..."
                label="Add Feedbacks"
                name="message"
                onChange={handleFeedbackChange}
              />{" "}
              <button
                type="button"
                className="create zoom-element btn  login-btn"
                onClick={handlePredisbursementFeedbacks}
              >
                Submit Feedback
                {isLoading && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredisbursementAddFeedbacks;
