import React, { useRef } from "react";
import styles from "./ApproveDeclineMccApprovalSignature.module.scss";
import SignatureCanvas from "react-signature-canvas";
import { normalizeBase64 } from "../../../../../helpers/convertToBase64";
const MccApprovalSignaturePad = ({ handleApproveDecline, isLoading }: any) => {
  const sigCanvas = useRef<SignatureCanvas>(null);

  const clearSignature = () => {
    sigCanvas.current?.clear();
  };

  const saveSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      const signaturebase64 = normalizeBase64(dataUrl);
      handleApproveDecline(signaturebase64);
    }
  };
  return (
    <div className={styles.signaturecontainer}>
      <h2>Signature Pad</h2>
      <SignatureCanvas
        ref={sigCanvas}
        canvasProps={{ className: styles.signaturecanvas }}
        penColor="black"
      />
      <div className={styles.signaturebutton}>
        <div>
          <button
            className={styles.signaturebuttonclear}
            onClick={clearSignature}
          >
            Clear
          </button>
        </div>
        <div>
          <button
            className={styles.signaturebuttonsave}
            disabled={isLoading}
            onClick={saveSignature}
          >
            {isLoading ? "loading..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MccApprovalSignaturePad;
