import React, { Fragment, useState } from "react";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { showModal } from "../../redux/actions/modal";
import { useDispatch } from "react-redux";
import { deleteData } from "../../newApis/apiMethods";
import { appInsights } from "../../config/appInsights";
import { useQueryCache } from "react-query";

const DeleteOfferLetter = ({
  dataRecord: {
    offerLetterId,
    offerLetterDocumentId,
    loanApplicationId,
    customerId,
  },
  onCloseModal,
}: {
  dataRecord: any;
  onCloseModal: () => void;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const queryCache = useQueryCache();

  const dispatch = useDispatch();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteData(
        `${ajaxEndpoints.DELETE_OFFER_LETTER_SLA}?offer_letter_id=${offerLetterId}&offer_letter_document_id=${offerLetterDocumentId}`
      );
      if (response?.status_code === 200) {
        queryCache.invalidateQueries([
          { customer_id: customerId, loan_application_id: loanApplicationId },
          "fetchOfferAndSLA",
        ]);
        onCloseModal();
        dispatch(
          showModal({
            modalIdentifier: "feedback",
            dataRecord: { type: "success", message: response?.message },
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showModal({
          modalIdentifier: "feedback",
          dataRecord: {
            type: "failure",
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : "failed to delete offer letter",
          },
        })
      );

      appInsights.trackException({
        exception: error,
        properties: { fileName: "DeleteOfferLetter.tsx" },
      });
    }
    setIsDeleting(false);
  };
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className=" Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <p className="d-flex justify-content-center">
            Are you sure you want to delete?
          </p>
          <div className="Form__action-buttons m-t-2">
            {
              <Fragment>
                <button
                  type="button"
                  className="btn zoom-element btn login-btn"
                  onClick={() => handleDelete()}
                >
                  {isDeleting ? "processing" : "Yes"}
                </button>
                <button
                  type="button"
                  className="btn zoom-element btn reject_btn"
                  onClick={() => onCloseModal()}
                >
                  No
                </button>
              </Fragment>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteOfferLetter;
