import { Component } from "react";

//Redux
import { connect } from "react-redux";
import { setToast } from "./../../../redux/actions/toast";

// Custom Components
import Tabs from "../../Tabs/Tabs";

// Component Settings
import ProductSettings from "./Tabs/Product/ProductSettings.js";
import ListOfProducts from "./Tabs/Product/ListOfProducts/ListOfProducts";
import LegacyAggregatorProduct from "./Tabs/Product/ListOfProducts/LegacyAggregatorProduct";

class AdminSettings extends Component {
  //State
  state = {
    activeTab: "product",
    tabs: [
      {
        name: "Products Category",
        id: "product",
        icon: "fas fa-university",
        identifier: "settingsTab",
      },
      {
        name: "Legacy Aggregator Product",
        id: "aggregatorlegacyproduct",
        icon: "fas fa-university",
        identifier: "settingsTab",
      },
      {
        name: "Embed Products ",
        id: "embedProductSetup",
        icon: "fas fa-database",
        identifier: "settingsTab",
      },
      {
        name: "Funder Products",
        id: "funderProducts",
        icon: "fas fa-building",
        identifier: "settingsTab",
      },
    ],
  };

  toggleTabContent = (content) => {
    this.setState({
      activeTab: content,
    });
  };

  setToastHandler = (type, message) => {
    this.props.setToast(type, message);
  };

  componentDidMount = () => {};

  render() {
    const { activeTab, tabs } = this.state;

    return (
      <div className="speed-1x fadeInRight">
        {/* Tabs */}
        <Tabs onContentToggle={this.toggleTabContent} tabs={tabs} />

        <div className="row clearfix grid__padding">
          {(() => {
            switch (activeTab) {
              case "product":
                return (
                  <div className="col-sm-12 animated delay-3s slideInRight mt-20">
                    <ProductSettings onSetToast={this.setToastHandler} />
                  </div>
                );

              case "aggregatorlegacyproduct":
                return (
                  <div className="col-sm-12 animated delay-3s slideInRight mt-20">
                    <LegacyAggregatorProduct />
                  </div>
                );

              case "embedProductSetup":
                return (
                  <div className="col-sm-12 animated delay-3s slideInRight mt-20">
                    <ListOfProducts
                      showProductsView="aggregatorProductsView"
                      onSetToast={this.setToastHandler}
                    />
                  </div>
                );

              case "funderProducts":
                return (
                  <div className="col-sm-12 animated delay-3s slideInRight mt-20">
                    <ListOfProducts
                      showProductsView="funderProductsView"
                      onSetToast={this.setToastHandler}
                    />
                  </div>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    );
  }
}

export default connect(null, { setToast })(AdminSettings);
