import React, { Fragment } from "react";

// import styles from ".CamDocumentHistory.module.scss";
import styles from "./CAMHistory.module.scss";

// import downloadIcon from "../../assets/svg/icons/download_underline.svg";
import downloadIcon from "../../../../../assets/svg/icons/download_underline.svg";
import useCAMHistory from "../../../../../custom-hooks/useCAMHistory";
import { useLocation } from "react-router-dom";
import Loader from "../../../../../NewComponents/Loader/Loader.component";

interface CamDocumentHistoryProps {
  dataRecord: any;

  onCloseModal: any;
}
const CAMHistory = ({ dataRecord, onCloseModal }: CamDocumentHistoryProps) => {
  const location = useLocation();
  const state = location?.state as {
    id: number;
  };
  const loanId = state?.id;
  const {
    data: CAMHistoryData,
    isLoading: CAMLoading,
    error: CAMError,
  } = useCAMHistory(loanId);
  const RenderedComponent = CAMHistoryData?.data?.map((historyData: any) => {
    const {
      id,
      loan_application_id,
      pdf_url,
      word_url,
      approval_level,
      approval_status,
      comment,
      created_by_user_id,
      created_by_user_name,
      date_time_created,
    } = historyData;
    return (
      <tr key={id}>
        <td>Customer</td>

        <td>21-05-2024</td>

        <td>
          <span className={styles.pendingStatus}>{approval_status}</span>
        </td>

        <td>{approval_level}</td>

        <td>
          <button className={`btn advancly-btn ${styles.icon_button}`}>
            <span>View CAM</span>

            <img src={downloadIcon} alt="" />
          </button>
        </td>
      </tr>
    );
  });
  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <Fragment>
            <h3 className={styles.modal_title}>CAM Document History</h3>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th>User Name</th>

                  <th>Date</th>

                  <th>Status</th>

                  <th>Approval Level</th>

                  <th>Download Type</th>
                </tr>
              </thead>

              <tbody>
                {CAMLoading ? (
                  <Loader />
                ) : CAMHistoryData ? (
                  { RenderedComponent }
                ) : (
                  <div>
                    <p className="d-flex justify-content-center align-items-center">
                      {" "}
                      CAM History Docs Not Found
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default CAMHistory;
