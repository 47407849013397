import React from "react";

const CreditBureauTableProfileOverview = ({ creditProfileOverview }: any) => {
  return (
    <div>
      <h5 className="table-caption">Credit Profile Overview</h5>

      <table className="table-style">
        <thead>
          <tr>
            <th className="th-td-style th-style" scope="col">
              Indicator Type
            </th>
            <th className="th-td-style th-style" scope="col">
              Indicator
            </th>
            <th className="th-td-style th-style" scope="col">
              value
            </th>
          </tr>
        </thead>
        <tbody>
          {creditProfileOverview?.map((creditOverView: any, index: number) => {
            const { indicatoR_TYPE, indicator, value } = creditOverView;
            return (
              <tr key={index}>
                <td className="th-td-style">{indicatoR_TYPE}</td>
                <td className="th-td-style">{indicator}</td>
                <td className="th-td-style">{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreditBureauTableProfileOverview;
