import { ChangeEvent, FormEvent, useEffect, useState } from "react";

// Ajax Methods
import ajaxMethods from "../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

import { currency } from "../../../helpers/stringControllers";
import ButtonLoader from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";

const ProductModal = (props: any) => {
  const [state, setState] = useState({
    scene: "form",
    productCategoryOptions: [],
    sectorCategoryOptions: [],
    selectedSectorId: props?.properties?.dataRecord?.sector_id || "",
    selectedProductId: props?.properties?.dataRecord?.product_category_id || "",
    maxInterestRate: props?.properties?.dataRecord?.maximum_interest_rate || "",
    minInterestRate: props?.properties?.dataRecord?.minimum_interest_rate || "",
    maxTenure: props?.properties?.dataRecord?.maximum_tenor || "",
    minTenure: props?.properties?.dataRecord?.minimum_tenor || "",
    maxAmount: props?.properties?.dataRecord?.maximum_amount || "",
    minAmount: props?.properties?.dataRecord?.minimum_amount || "",
    message: "",
    showMessage: false,
    showErrorMessage: false,
    loading: false,
  });

  const {
    scene,
    productCategoryOptions,
    sectorCategoryOptions,
    selectedSectorId,
    selectedProductId,
    maxInterestRate,
    minInterestRate,
    maxTenure,
    minTenure,
    maxAmount,
    minAmount,
    message,
    showMessage,
    showErrorMessage,
    loading,
  } = state;

  const getProductCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.PRODUCT_CATEGORY_LIST)
      .then((response) => {
        let productCategoryList = [];

        if (response.data.length > 0) {
          productCategoryList = [...response.data];
        } else {
          productCategoryList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const productCategoryOptions = productCategoryList.map(
          (ecosystem, index) => {
            const { code, id, productCategoryName } = ecosystem;
            return (
              <option key={index} id={code} value={id}>
                {productCategoryName}
              </option>
            );
          }
        );

        setState((prev: any) => {
          return {
            ...prev,
            productCategoryOptions: productCategoryOptions,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductModal.js",
          },
        });
      });
  };

  const getSectorCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.CATEGORY_LIST)
      .then((response) => {
        let categoryList = [];

        if (response.data.length > 0) {
          categoryList = [...response.data];
        } else {
          categoryList = [
            {
              category_id: 0,
              category_name: "No Categories Yet",
              category_slug: "No Categories Yet",
            },
          ];
        }
        const sectorCategoryOptions = categoryList.map((ecosystem, index) => {
          const { category_id, category_name } = ecosystem;
          return (
            <option key={index} id={category_id} value={category_id}>
              {category_name}
            </option>
          );
        });
        setState((prev: any) => {
          return {
            ...prev,
            sectorCategoryOptions: sectorCategoryOptions,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductModal.js",
          },
        });
      });
  };

  const handleEditSubmit = (e: FormEvent) => {
    e.preventDefault();
    setState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const {
      selectedSectorId,
      selectedProductId,
      maxInterestRate,
      minInterestRate,
      maxTenure,
      minTenure,
      maxAmount,
      minAmount,
    } = state;
    const options = {
      sector_id: Number(selectedSectorId),
      product_category_sector_id: Number(props.properties.dataRecord.id),
      maximum_interest_rate: Number(maxInterestRate),
      minimum_interest_rate: Number(minInterestRate),
      maximum_tenor: Number(maxTenure),
      minimum_tenor: Number(minTenure),
      maximum_amount: Number(maxAmount),
      minimum_amount: Number(minAmount),
      product_category_id: Number(selectedProductId),
    };
    ajaxMethods
      .post(ajaxEndpoints.UPDATE_PRODUCT_TO_SECTOR, options)
      .then((response) => {
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        let message = "";
        if (response.status === 200) {
          message = response.data.message;
          setState((prev) => {
            return { ...prev, message: message, showMessage: true };
          });
        } else if (response.status === 400) {
          message = response.data.message;
          return setState((prev) => {
            return { ...prev, message: message, showErrorMessage: true };
          });
        } else {
          message = "Unable to connect to Advancly services";
          return setState((prev) => {
            return { ...prev, message: message, showErrorMessage: true };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductModal.js",
          },
        });
        let message = "Unable to connect to Advancly services";
        return setState((prev) => {
          return {
            ...prev,
            message: message,
            showErrorMessage: true,
            loading: false,
          };
        });
      });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setState((prev) => {
      return { ...prev, loading: true };
    });
    const {
      selectedSectorId,
      selectedProductId,
      maxInterestRate,
      minInterestRate,
      maxTenure,
      minTenure,
      maxAmount,
      minAmount,
    } = state;
    const options = {
      sector_id: Number(selectedSectorId),
      product_category_id: Number(selectedProductId),
      maximum_interest_rate: Number(maxInterestRate),
      minimum_interest_rate: Number(minInterestRate),
      maximum_tenor: Number(maxTenure),
      minimum_tenor: Number(minTenure),
      maximum_amount: Number(maxAmount),
      minimum_amount: Number(minAmount),
    };
    ajaxMethods
      .post(ajaxEndpoints.ADD_PRODUCT_TO_SECTOR, options)
      .then((response) => {
        setState((prev) => {
          return { ...prev, loading: false };
        });
        let message = "";
        if (response.status === 200) {
          message = response.data.message;
          setState((prev) => {
            return { ...prev, message: message, showMessage: true };
          });
        } else if (response.status === 400) {
          message = response.data.message;
          return setState((prev) => {
            return { ...prev, message: message, showErrorMessage: true };
          });
        } else {
          message = "Unable to connect to Advancly services";
          return setState((prev) => {
            return { ...prev, message: message, showErrorMessage: true };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ProductModal.js",
          },
        });
        let message = "Unable to connect to Advancly services";
        return setState((prev) => {
          return {
            ...prev,
            message: message,
            showErrorMessage: true,
            loading: false,
          };
        });
      });
  };

  const handleOptionSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        showMessage: false,
        showErrorMessage: false,
      };
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        showMessage: false,
        showErrorMessage: false,
      };
    });
  };

  useEffect(() => {
    getSectorCategories();
    getProductCategories();
  }, []);

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3
            className="Modal__title m-b-1 p-x-2 p-y-1 "
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            {props.properties.dataRecord && <span>View</span>} Product Category
            Sector
          </h3>
          {/* Product Form */}
          {(() => {
            switch (scene) {
              case "form":
                return (
                  <form>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Sector</label>
                        <div className="form-group inputWithIcon">
                          <select
                            className="round user__form form-control form-load"
                            name="selectedSectorId"
                            placeholder="Select a Sector"
                            title="Select a Sector"
                            id="selectedSectorId"
                            disabled
                            value={selectedSectorId}
                            onChange={(e) => handleOptionSelect(e)}
                          >
                            <option selected>Select a Sector</option>
                            {sectorCategoryOptions}
                          </select>
                          <i className="adv-aggregator-icon fas fa-building"></i>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Product Category</label>
                        <div className="form-group inputWithIcon">
                          <select
                            className="round user__form form-control"
                            name="selectedProductId"
                            placeholder="Select Product category"
                            title="Select a Product"
                            disabled
                            id="selectedProductId"
                            value={selectedProductId}
                            onChange={(e) => handleOptionSelect(e)}
                          >
                            <option selected>Select a Category</option>
                            {productCategoryOptions}
                          </select>

                          <i className="adv-aggregator-icon fas fa-university"></i>
                        </div>
                      </div>
                    </div>

                    <div className="row m-t-1">
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Minimum Interest Rate
                        </label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="number"
                            className="form-control"
                            name="minInterestRate"
                            disabled
                            value={minInterestRate}
                            onChange={handleChange}
                          />
                          <i className="adv-aggregator-icon fas fa-percent"></i>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Maximum Interest Rate
                        </label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="number"
                            className="form-control"
                            disabled
                            value={maxInterestRate}
                            name="maxInterestRate"
                            onChange={handleChange}
                          />
                          <i className="adv-aggregator-icon fas fa-percent"></i>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Minimum Tenure</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="number"
                            className="form-control"
                            disabled
                            data-form="minTenure"
                            name="minTenure"
                            value={minTenure}
                            onChange={handleChange}
                          />
                          <i className="adv-aggregator-icon fas fa-chevron-left"></i>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Maximum Tenure</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="number"
                            className="form-control"
                            data-form="maxTenure"
                            disabled
                            name="maxTenure"
                            value={maxTenure}
                            onChange={handleChange}
                          />
                          <i className="adv-aggregator-icon fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Minimum Amount {currency(minAmount)}
                        </label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="number"
                            className="form-control"
                            disabled
                            data-form="minAmount"
                            name="minAmount"
                            value={minAmount}
                            onChange={handleChange}
                          />
                          <i className="adv-aggregator-icon fas fa-money"></i>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Maximum Amount {currency(maxAmount)}
                        </label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            name="maxAmount"
                            value={maxAmount}
                            onChange={handleChange}
                          />
                          <i className="adv-aggregator-icon fas fa-money"></i>
                        </div>
                      </div>
                    </div>
                    {showMessage && (
                      <span className="text-success">{message}</span>
                    )}
                    {showErrorMessage && (
                      <span className="text-danger">{message}</span>
                    )}
                    {!props.properties.dataRecord && (
                      <div className="grid__full m-t-2">
                        <button
                          type="submit"
                          className="zoom-element btn login-btn"
                          onClick={handleSubmit}
                        >
                          &nbsp; Submit {loading && <ButtonLoader />}
                        </button>
                      </div>
                    )}
                    {/* {props.properties.dataRecord && (
                      <div className="grid__full m-t-2">
                        <button
                          type="submit"
                          className="zoom-element btn login-btn"
                          onClick={handleEditSubmit}
                        >
                          &nbsp; Update {loading && <ButtonLoader />}
                        </button>
                      </div>
                    )} */}
                  </form>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
